import { Component, OnInit } from '@angular/core';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
  standalone:true,
  imports:[MatDialogActions,MatDialogContent]
})
export class DialogModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
