<div class="container" >
  <form #signupForm="ngForm" class="signupStyle" autocomplete="off"   >
    <div class="Form-Link">
      <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
      <ul>
        <li>
          <a [routerLink]="['/login']" class="NotActiveStyle">Sign In</a>
        </li>
        <li>
          <a [routerLink]="['/signup']" routerLinkActive="active">Sign Up</a>
        </li>
      </ul>
    </div>
<!-- <div *ngIf="!successMessage"> -->
    <div class="form-group">
      <div class="group">
        <label for="firstName" class="label">First Name</label>
        <input type="text" class="form-control inputTextStyle" [(ngModel)]="newuser.firstName" name="firstName"
          placeholder="First Name" required />
      <!--   {{ newuser.firstname }} -->
      </div>
    </div>

    <div class="form-group">
      <div class="group">
        <label for="lastname" class="label">Last Name</label>
        <input type="text" class="form-control inputTextStyle" [(ngModel)]="newuser.lastName" name="lastName"
          placeholder="Last Name" required />
       <!--  {{ newuser.lastname }} -->
      </div>
    </div>

    <div class="form-group">
      <div class="group">
        <label for="email" class="label">Email Address</label>
        <input type="email" class="form-control inputTextStyle" (ngModel)="newuser.email" name="email"
          placeholder="Email Address" required />
      </div>
    </div>

    <div class="form-group">
      <div class="group">
        <label for="password" class="label">Phone Number</label>
        <input type="number" class="form-control inputTextStyle" name="phoneForVerification" [(ngModel)]="newuser.phoneForVerification"  placeholder="Phone Number"
          required />
      </div>
    </div>

    <div class="form-group">
      <div class="group">
        <label for="companyname" class="label">Company Name</label>
        <input type="text" class="form-control inputTextStyle" [(ngModel)]="newuser.companyName" name="companyName"
          placeholder="Company Name" required />
        <!-- {{ newuser.companyname }} -->
      </div>
    </div>

    <div class="form-group">
      <div class="group">
        <label for="password" class="label">Password</label>
        <input type="password" class="form-control inputTextStyle" (ngModel)="newuser.password" name="password"
          placeholder="Password" required />
      </div>
    </div>

    <div class="form-group ">
      <div class="group">
        <label for="confirmpassword" class="label">Confirm Password</label>
        <input type="password" class="form-control inputTextStyle" [(ngModel)]="newuser.confirmPassword"
          name="confirmPassword" placeholder="Confirm Password" required />
       
        
        <div class="errorMessage">
          <label class="error alert-danger">{{ errorMessage }}</label>
          <label class="success alert-success">{{ successMessage }}</label>
        </div>
      </div>
    </div>
   <div class="form-group">
      <label class="error alert-danger" >{{ errorMessage }}</label>
      <label class="success alert-success">{{ successMessage }}</label>

    </div> 

    
    <div class="form-group center">
      <div class="hr"></div>
      <button  (click)="tryRegister(signupForm.value)" class="inputButtonStyle">
        SIGN UP
      </button>
    </div>
   
 <!--
  </div>
  <div *ngIf="successMessage">
  <div class="form-group center">
    <label class="alert-success">{{ successMessage }}</label>

    </div>
    <div class="form-group center">

      <button  class="inputButtonStyle">
        Verify Your Email for OTP
      </button>
      <div class="hr"></div>
    </div>
  </div> -->
  </form>
 
  
</div>
