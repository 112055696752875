import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormsModule,NgForm, ReactiveFormsModule } from '@angular/forms';
import { DatabaselayerService } from '../../core/databaselayer.service';
import  firebase from "firebase/compat/app";
import { Device } from './../../modelclasses/device.model';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { LocalStorageService } from '../../core/local-storage.service';
import { AddCompanyProfileComponent } from '../add-company-profile/add-company-profile.component';
import { CommonModule, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-edit-existing-device-information',
  standalone: true,
  providers:[DatePipe],
  imports: [AddCompanyProfileComponent,CommonModule,FormsModule,ReactiveFormsModule],
  templateUrl: './edit-existing-device-information.component.html',
  styleUrl: './edit-existing-device-information.component.scss'
})
export class EditExistingDeviceInformationComponent implements OnInit {
  protected device: Device;
 
  private layoutImage: File |null=null;
  localFileURL: string="";
  deviceAddressList :any[]=[];
  addAddressCollapse: boolean=false;
  private serialNumber: string | null="";
  constructor(
     private toastrService:ToastrService,
     private afAuth: AngularFireAuth, 
     private dbLayerService: DatabaselayerService, 
    private afStorage: AngularFireStorage,
  private localStorageService:LocalStorageService, 
  private route:ActivatedRoute, private router:Router) { 


    this.device = {
      serialNumber: "",
      deviceInformation: {
        userId: 1,
       

        latitude: 0,
        longitude: 0, // default to dark theme
        keyPhrase: "protect me now",
        deactivationKeyPhrase: "protect me now",
        floorPlanUrl: "",
      
      },
      deviceKeysAndLocations: {
        address: "Select the adddress",
        deviceLocation: "",
      },

    
    };
  }
   
  
  ngOnInit() {
  
    this.route.paramMap.subscribe(params => {
      this.serialNumber = params.get('sn');
    console.log("Params",params);
    
    });

   
   
  
    let localStorageValue= this.localStorageService.retrieveLocalStorageData("deviceAddressList");
    if(localStorageValue){
      this.deviceAddressList=JSON.parse( localStorageValue);
    }
    let userId=firebase.auth().currentUser?.uid;
    if(this.serialNumber){
      this.dbLayerService.getDeviceDataByFieldName(this.serialNumber).snapshotChanges().subscribe((device:any)=>{
        console.log("Data on Edit", device.payload.toJSON());
        const temp = device.payload.toJSON();
        temp["serialNumber"] = device.key;
  
        // this.device = device;
        this.device = Object.assign({}, temp as Device );
        
      }
      );
    }
      if(userId){
        let nodeTOFetch=`/users/${userId}/deviceKeysAndLocations/${this.serialNumber}`;
          this.dbLayerService.getUserDataByNode(nodeTOFetch).snapshotChanges().subscribe((deviceKeysAndLocations:any)=>{
            console.log("Data on Edit adrress",deviceKeysAndLocations.payload.toJSON());
            const temp = deviceKeysAndLocations.payload.toJSON();
  
            this.device.deviceKeysAndLocations = temp;
            this.deviceAddressList.push(temp.address);
          });
      }
   
  
      /* this.deviceInfoService
        .getDeviceInformation(sn)
        .snapshotChanges()
        .subscribe((device:any) => {
          const temp = device.payload.toJSON();
          temp["serialNumber"] = device.key;
  
          // this.device = device;
          this.device = Object.assign({}, temp as Device);
          // ;
          console.log("This device in edit", this.device.deviceInformation);
          console.log("This device", this.device, device.key);
        }); */
        
      /* this.editState = true;
      this.listState = false;
      this.healthState = false;
      this.serialNumber = sn; */
      // this.device = Object.assign({}, device);
    }
    
  async onSubmit(deviceForm: NgForm) {
    
    let currentUser= await this.afAuth.currentUser;
    let deviceObject = {
      serialNumber: deviceForm.value.serialNumber,
      

      deviceInformation: {
        userId: currentUser?.uid,
      
        latitude: deviceForm.value.latitude,
        longitude: deviceForm.value.longitude,
        keyPhrase: deviceForm.value.keyPhrase,
        deactivationKeyPhrase: deviceForm.value.deactivationKeyPhrase,
      
        
        floorPlanUrl: this.device.deviceInformation.floorPlanUrl
      },
      deviceKeysAndLocations:{
        address: deviceForm.value.deviceAddress, // default
        deviceLocation: deviceForm.value.deviceLocation, // default

      }

     /*  logs: this.device.logs, */
    };
   
    
    let result=await this.dbLayerService.updateDeviceData(deviceObject);
    if(result?.successMessage){
      
      this.dbLayerService.updateUserData("deviceKeysAndLocations",{[deviceObject.serialNumber]: deviceObject.deviceKeysAndLocations});
    
      let storageRef = this.afStorage.ref(
        "/c3vlar360upload/" + deviceForm.value.serialNumber
      );
     
    
      if (this.layoutImage) {
        const sn=deviceForm.value.serialNumber;
       
      this.dbLayerService.upLoadImage(storageRef, this.layoutImage).then((url) => {
    
      this.dbLayerService.updateSingleDeviceData("deviceInformation",{serialNumber:sn,floorPlanUrl:url});
    
      });
      }
    
      this.toastrService.success("Device Successfully updated", "Device Update");
      this.router.navigate(["/alldevices"]);
    }    


  }
  uploadLayout(event:any) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      this.layoutImage = event.target.files[0];
      const reader = new FileReader();
      // console.log('layout image',this.layoutImage);

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        console.log('This post', event.target?.result);
        // called once readAsDataURL is completed
        // console.log('This post', event.target)
        this.localFileURL = event.target?.result as string;
      };
    }
  }
  onAddressSubmitted() {
 
    let localStorageValue= this.localStorageService.retrieveLocalStorageData("deviceAddressList");
    if(localStorageValue){
      this.deviceAddressList = JSON.parse(localStorageValue);
    }
  }
  onCancel() {
   
    this.localFileURL = "";
   this.router.navigate(["/alldevices"]);
    
  }
  toogleAddAddress() {
  
    this.addAddressCollapse = !this.addAddressCollapse;
  }


}
