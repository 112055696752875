<ul class="nav nav-tabs">
  <li class="nav-item">
    <a
      class="nav-link"
      [ngClass]="isActive ? 'active' : 'danger'"
      [routerLink]="[]"
      (click)="generalInfoTab()"
      >General Information</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      [ngClass]="isActive ? 'danger' : 'active'"
      [routerLink]="[]"
      (click)="wifiConfigTab()"
      >CONFIGURE WIFI</a
    >
  </li>
</ul>
<div>
  <table class="table table-bordered" *ngIf="currentTab">
    <tbody>
      <tr>
        <td>Internet Connection Status</td>
        <td>{{ connectionStatus }}</td>
      </tr>
      <tr>
        <td>Serial Number</td>
        <td>{{ serialNumber }}</td>
      </tr>
    </tbody>
  </table>

  <app-wificonfiguration
    [serialNumber]="serialNumber"
    [wifiList]="wifiList"
    *ngIf="!currentTab"
  ></app-wificonfiguration>
</div>
