import { Component, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core";
//import * as firebase from "firebase/app";
import  firebase from "firebase/compat/app";
//import { multiFactor } from "firebase/auth";
//import "firebase/auth";
//import firebase from '@firebase/app-compat';
import { WindowService } from "../../core/window.service";
import { TwofactorService } from "../../core/twofactor.service";

import { ActivatedRoute, Router } from "@angular/router";

import { Observable, Subscription, timer } from "rxjs";
import { FormsModule } from "@angular/forms";
import { AuthService } from "../../core/auth.service";
import { CommonModule } from "@angular/common";
import { DatabaselayerService } from "../../core/databaselayer.service";


 declare global {
  interface Window {
    recaptchaWidgetId: number;
  }
}
 
@Component({
  selector: "app-phone-two-factor-authentication",
  templateUrl: "./phone-two-factor-authentication.component.html",
  styleUrls: ["./phone-two-factor-authentication.component.scss"],
  providers: [WindowService],
  standalone:true,
  imports:[FormsModule, CommonModule

  ]
})
export class PhoneTwoFactorAuthenticationComponent implements OnInit {
  title = "Twofactor authentication code";
  twofactor = "http://localhost:3000/send_email_2factor";
  

  twofactorservice = new Observable((observer) => {
    console.log("Observable starts");
    observer.next("ranNum");
  });

  users: any;
  twofactorstatus: any;
  windowRef: any;
  phoneForVerification: string="";
  emailForVerification: string="";
  isVerificationSend: boolean = false;
  verificationCode: string="";
  verificationCodeSentByEmail: any;
  verificationId: string="";
  currentUser: any;
  errorMessage="";
  successMessage="";
  firebaseRef: any;
  isLoading = false;
  twoFactorMethod: string = "Phone";
  phoneInputReadOnly = false;
  constructor(
    private win: WindowService,
    private auth: AuthService ,
    private router: Router,
    private twofactorService: TwofactorService,
    private route: ActivatedRoute,
    private dbLayerService:DatabaselayerService
  ) {}

  ngOnInit() {
    debugger;
    this.dbLayerService.getUserDataByFieldName("userInformation").subscribe((data)=>{
      this.phoneForVerification=data[0].phoneForVerification
      ;
      
    });
   
   /* this.route.params.subscribe((params) => {
    alert("2"+JSON.stringify(params))
      this.phoneForVerification = params['phoneNumber'];
    }
    );  */
    
  
  
    /* this.twofactorService.subscribe(
        val=>{console.log(val)},
      )
      .getTwofactorcode
      }
      )
    

   /*
   this.twofactorService.subscribe(
     val=>{console.log(val)},
     error=>{console.log('error')},
     ()=>{console.log('completed')}
   )
   */
    //this.subscription.add(this.everySecond$.subscribe(second => console.log(second)));
    //this.subscription.add(this.everyMinutes$.subscribe(minutes => console.log(minutes)));

    /*this.users =['ranNum','email'];
//this.twofactors = of(this.users);
new Observable(observer =>{
  setTimeout(() =>{
 observer.next('completed');
  }, 2000);
}).subscribe(data=>{
this.twofactorstatus=data
 },error =>{
console.log(error);
 })

    TwofactorService() {
      this.TwofactorService.twofactor()
        .subscribe(resp => {
          const keys = resp.headers.keys();
          this.headers = keys.map(key =>
            `${key}: ${resp.headers.get(key)}`);
    
          //this.send_email_2factor = { ...resp.body! };
        });
    }
    let send_email_2factor;
    this.
  /*this.TwofactorService.getTwofactor().subscribe(
    (send_email_2factor: Twofactor[]) =>this.send_email_2factor = send_email_2factor
  );
  */

    this.currentUser = firebase.auth().currentUser;
  

    this.errorMessage = "";

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response:any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
          console.log("recaptcha solved", response);
        //  this.onSolvedRecaptcha()
       //this.onSolvedRecaptcha();
          debugger;
        },
        "expired-callback": function () {
          console.log("expired-callback");
          debugger;
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      }
    );
    window.recaptchaVerifier.render();/* .then((widgetId:any) => {
      window.recaptchaWidgetId = widgetId;
    });; */
    if (this.currentUser) {
      this.firebaseRef = firebase.database?.().ref("/users/" + this.currentUser.uid);
      this.firebaseRef.on(
        "value",
        (snapshot:any) => {
          console.log(snapshot.val());
          if (snapshot.val().phoneForVerification) {
            this.phoneForVerification = snapshot.val().phoneForVerification;
          }
        },
        (error:any) => {
          this.errorMessage = error.message;
          console.log("Error: " + error.code);
        }
      );
    } else if (window.resolver) {
      this.phoneForVerification = window.resolver.hints[0].phoneNumber;
      this.phoneInputReadOnly = true;
    } else {
      //birhanu this.router.navigate(["login"]);
    } 
  }/* 
  // Enroll a user into MFA (phone number verification)
  async enrollInMFA(phoneNumber: string, appVerifier: firebase.auth.RecaptchaVerifier): Promise<void> {
    try {
      const user = await firebase.auth().currentUser;

      if (user) {
        // Get the multi-factor user object
        const multiFactorUser = firebase.auth..multiFactor(user);

        // Send an SMS verification code to the user's phone
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneNumber, appVerifier);

        // Ask the user for the SMS verification code
        const verificationCode = prompt('Enter the verification code sent to your phone:');

        // Create the phone credential with the verification code
        const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);

        // Enroll the user in multi-factor authentication using the phone credential
        await multiFactorUser.enroll(phoneCredential);

        console.log('User successfully enrolled in MFA.');
      } else {
        console.error('No user is currently logged in.');
      }
    } catch (error) {
      console.error('Failed to enroll in MFA:', error);
    }
  } */
 async onSolvedRecaptcha(): Promise<void> {
  this.isLoading = true;
  const user = firebase.auth().currentUser;
alert('onSolvedRecaptcha'+JSON.stringify(user));
  //const credential = firebase.auth.EmailAuthProvider.credential("birhanu@covertalert.ai", "123456");
  
  
    if (user) {
   
      await user.reload();
      //await user.reauthenticateWithCredential(credential);
      let  multiFactorSession = await user.multiFactor.getSession();
      /* this.firebaseRef
        .child("phoneForVerification")
        .set(this.phoneForVerification); */
     let appVerifier = window.recaptchaVerifier;
      let phoneNumber = ("+1" + this.phoneForVerification).toString();
     
     console.log("phone number",phoneNumber);
     debugger;
      /* let phoneOpts = {
        phoneNumber,
        session,
      }; */
      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession
      };

      let provider = new firebase.auth.PhoneAuthProvider();
      console.log(appVerifier);
      debugger;
      provider
        .verifyPhoneNumber(phoneInfoOptions, appVerifier)
        .then((verificationId) => {
          this.isVerificationSend = true;
          this.isLoading = false;
         
     let recContainer=   <HTMLBodyElement> document.getElementById("recaptcha-container") ;
               recContainer.hidden ;

          
          
          this.verificationId = verificationId;
        })
        .catch((error) => {
          console.log("my error", error.message, error.code);
          debugger;
          this.isLoading = false;
          if (error.code == "auth/too-many-requests") {
            this.errorMessage = error.message;
          } else if (error.code == "auth/invalid-phone-number") {
            this.errorMessage = "Invalid phone number";
          } else if (error.code == "auth/second-factor-already-in-use") {
            this.router.navigate(["/login"]);
          } else if (error.code == "auth/unverified-email") {
            this.errorMessage =
              "We've sent an email to verify your email address and activate your account. The link in the email will expire in 24 hours ";

            this.currentUser.sendEmailVerification();
          } else {
            debugger;
            this.errorMessage = error.message;
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier.render().then((widgetId: any) => {
              window.recaptchaWidgetId = widgetId;
            });
          }
          if (
            error.message ==
            "reCAPTCHA has already been rendered in this element"
          ) {
            window.recaptchaVerifier.render().then((widgetId:any) => {
              window.recaptchaWidgetId = widgetId;
            //  this.windowRef.grecaptcha.reset(widgetId);
            });
          }
        });
    }
  }
  handleRecaptchaVerifier() { 
    debugger;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response:any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
          console.log("recaptcha solved", response);
          this.onSolvedRecaptcha();
          debugger;
        },
        "expired-callback": function () {
          console.log("expired-callback");
          debugger;
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      }
    );
    window.recaptchaVerifier.render();
  }
  handleChange2FactorMethod(event:any) {
   // this.handleRecaptchaVerifier();
    this.currentUser = firebase.auth().currentUser;
    console.log(this.currentUser, firebase.auth().currentUser);
    this.twoFactorMethod = event.target.value;
  }
  async sendLoginCode(value: any) {
    debugger;
    console.log("Current user", firebase.auth().currentUser);
    if (this.twoFactorMethod == "Phone" && window.resolver) {
      const phoneOpts = {
        multiFactorHint: window.resolver.hints[0],
        session: window.resolver.session,
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      console.log(phoneOpts, phoneAuthProvider, this.windowRef,"reCaptcha verifier",window.recaptchaVerifier);
      phoneAuthProvider
        .verifyPhoneNumber(phoneOpts, window.recaptchaVerifier)
        .then((verificationId) => {
          console.log("Verify id", verificationId);
          this.isVerificationSend = true;
          this.verificationId = verificationId;
         let recContainer= <HTMLBodyElement>document.getElementById("recaptcha-container");
         recContainer.hidden;
        })
        .catch((error) => {
          console.log("Error", error.message);
        });
      // this.phoneForVerification = phoneOpts.multiFactorHint.phoneNumber;
    } else if (this.twoFactorMethod == "Phone") {
     
    debugger
   //  window.recaptchaVerifier.clear();
    // this.handleRecaptchaVerifier()
      console.log(value, this.phoneForVerification);
      this.errorMessage = "";
      console.log(this.firebaseRef);
      this.isLoading = true;
    let session = await this.currentUser.multiFactor.getSession();

      if (this.firebaseRef) {
        this.firebaseRef
          .child("phoneForVerification")
          .set(value.phoneForVerification);
       let appVerifier = window.recaptchaVerifier;
        let phoneNumber = ("+1" + this.phoneForVerification).toString();
        let phoneOpts = {
          phoneNumber,
          session,
        };

        let provider = new firebase.auth.PhoneAuthProvider();
        console.log(appVerifier);
        debugger;
        provider
          .verifyPhoneNumber(phoneOpts, appVerifier)
          .then((verificationId) => {
            this.isVerificationSend = true;
            this.isLoading = false;
           
       let recContainer=   <HTMLBodyElement> document.getElementById("recaptcha-container") ;
                 recContainer.hidden ;

            
            
            this.verificationId = verificationId;
          })
          .catch((error) => {
            console.log("my error", error.message, error.code);
            this.isLoading = false;
            if (error.code == "auth/too-many-requests") {
              this.errorMessage = error.message;
            } else if (error.code == "auth/invalid-phone-number") {
              this.errorMessage = "Invalid phone number";
            } else if (error.code == "auth/second-factor-already-in-use") {
              this.router.navigate(["/login"]);
            } else if (error.code == "auth/unverified-email") {
              this.errorMessage =
                "We've sent an email to verify your email address and activate your account. The link in the email will expire in 24 hours ";

              this.currentUser.sendEmailVerification();
            } else {
              this.errorMessage = error.message;
            }
            if (
              error.message ==
              "reCAPTCHA has already been rendered in this element"
            ) {
              window.recaptchaVerifier.render().then((widgetId:any) => {
                window.recaptchaWidgetId = widgetId;
              //  this.windowRef.grecaptcha.reset(widgetId);
              });
            }
          });
      }
    } else {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: "http://localhost:4200/login",
        handleCodeInApp: true,
      };
      firebase
        .auth()
        .sendSignInLinkToEmail(this.emailForVerification, actionCodeSettings)
        .then(() => {
          ;
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem(
            "emailForSignIn",
            this.emailForVerification
          );
          // ...
        })
        .catch((error) => {
          ;
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });

      /* 

      const provider = new firebase.auth.EmailAuthProvider();
    
      this.twofactorService
        .posttwofactor({ email: this.emailForVerification })
        .subscribe(
          (result) => {
            console.log("Code", result);
            this.isVerificationSend = true;
            this.verificationCodeSentByEmail = result;

            this.currentUser = firebase.auth().currentUser;
          },
          (error) => {
            console.log("Code error", error);
          },
          () => {
            console.log("HTTP request completed.");
          }
        ); */
    }
  }
  verifyLoginCode() {
    try {
     debugger
      console.log(
        this.verificationCode,
        this.verificationId,
        this.currentUser,
        this.twoFactorMethod
      );

      if (this.twoFactorMethod == "Email") {
        if (this.verificationCode == this.verificationCodeSentByEmail.ranNum) {
          this.router.navigate(["home"]);
        } else {
          this.errorMessage = "Invalide verification code";
          return;
        }
      } else {
        const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
          this.verificationId,
          this.verificationCode
        );
        this.currentUser = firebase.auth().currentUser;
        const multiFactorAssertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(phoneCredential);

        console.log(phoneCredential);
        if (this.currentUser) {
          const num = "+1" + this.phoneForVerification;
          if (this.currentUser && num !== this.currentUser.phoneNumber) {
            this.currentUser
              .linkWithCredential(phoneCredential)
              .then((usercred:any) => {
                let user = usercred.user;
                console.log("Account linking success", user);
              })
              .catch((error:any) => {
                console.log("Account linking error", error);
                this.errorMessage = error.message;
              });
          } else {
          
            this.router.navigate(["home"]);
          }
        }

        if (this.currentUser) {
          /* firebase
            .auth() */
            this.currentUser.multiFactor.enroll(multiFactorAssertion)
            .then(() => {
              this.router.navigate(["/login"]);
            })
            .catch((error:any) => {
              console.log("Error", error.message);

              this.errorMessage = error.message;
            });
        } else {
          window.resolver
            .resolveSignIn(multiFactorAssertion)
            .then((credential:any) => {
              console.log(credential);
              this.router.navigate(["/login"]);
            })
            .catch((error:any) => {
              console.log("Error", error.message);
              this.errorMessage = error.message;
            });
         // console.log(firebase..currentUser);
        }
      }
    } catch (error:any) {
      console.log("Error", error.message);
    }
  }
  logout() {
    this.auth.signOut();
    const routerTimout = setTimeout( ()=> {
      this.router.navigate(["/login"]);
      clearTimeout(routerTimout);
    }, 1000);
  }
  keyPressPhone(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  keyPressEmail(event: any) {
    console.log("key phrase ");
  }
}
