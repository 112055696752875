{
  "pagination": {
    "DescribeMappedResourceConfiguration": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MappedResourceConfigurationList"
    },
    "ListEdgeAgentConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EdgeConfigs"
    },
    "ListSignalingChannels": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ChannelInfoList"
    },
    "ListStreams": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StreamInfoList"
    }
  }
}