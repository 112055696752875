import { Component, OnInit } from "@angular/core";
import { Router,RouterModule } from "@angular/router";
import { FormsModule, NgForm } from "@angular/forms";
import { AuthService } from "../../core/auth.service";
import { AngularFireDatabase} from '@angular/fire/compat/database';
import { Database } from "aws-sdk/clients/cloudwatchevents";
import { DatabaselayerService } from "../../core/databaselayer.service";

@Component({
  selector: 'app-account-activation',
  standalone: true,
  imports:[RouterModule,FormsModule],
  templateUrl: './account-activation.component.html',
  styleUrl: './account-activation.component.scss'
})
export class AccountActivationComponent {

  errorMessage:string="";
  successMessage:string="";
accountActivationCode: string=""; 
 
    constructor(
    public authService: AuthService,
     private database: AngularFireDatabase,
     private router: Router,
     private dbLayer: DatabaselayerService

  ){
    
  }
  ngOnInit() {  
    
  }
  async verifyAccountActivationCode(value:any) {
    this.successMessage="The activation code verified successfully";
    this.router.navigate(["/signup"]);
  }
  async sendVerificationLink(value:any) {
    this.successMessage="The activation code verified successfully";
    this.router.navigate(["/signup"]);
    /* const {email} = value;
   
  let {successMessage} =  await this.authService.sendEmailVerification(email);
 
  debugger;
 if(successMessage){
   this.successMessage=successMessage
  
  }  */
   // this.sendVerificationLink(value.email);
  }

//    ngOnInit() {
//   this.router.navigate(["/signup"]);
//  }
}

