import { Component, OnInit } from "@angular/core";
import { User } from "../../modelclasses/user";
import { AuthService } from "../../core/auth.service";
import { Router, RouterModule} from "@angular/router";

import { FormsModule } from "@angular/forms";
import { DatabaselayerService } from "../../core/databaselayer.service";
import { NgClass, NgIf } from "@angular/common";
import { ReusableDialogService } from "../../shared/reusable-dialog.service";
import { first } from "rxjs";

@Component({
  selector: "app-signupform",
  templateUrl: "./signupform.component.html",
  styleUrls: ["./signupform.component.scss"],
  standalone: true,
  imports:[ FormsModule,RouterModule,NgIf,NgClass],
 
})
export class SignupformComponent implements OnInit {
  newuser = new User("", "", "", "", "","","");
  errorMessage:string="";
  successMessage:string="";
  submitted = false;

  onSubmit() {
    this.submitted = true;
  }

  constructor(public authService: AuthService, private router: Router,
    private databaselayer:DatabaselayerService,private reusableDialogService:ReusableDialogService) {}

  ngOnInit() {
    this.newuser.email = "";
    console.log("new user", this.newuser);
    this.newuser.firstName = "";
    this.newuser.lastName = "";
    this.newuser.companyName = "";
    this.newuser.phoneForVerification= ""; 
    this.newuser.password ="";
    this.newuser.confirmPassword ="";

  }


  
  tryRegister(value:any) {
   
   
    //console.log(value);
    if (!value.firstName) {
      this.errorMessage = "First name is required!!";
      return;
    }
    if (!value.lastName) {
      this.errorMessage = "Last name is required!!";
      return;
    }
    if (!value.email) {
      this.errorMessage = "Email is required!!";
      return;
    }
    if (!value.companyName) {
      this.errorMessage = "company Name is required!!";
      return;
    }
    if (!value.password) {
      this.errorMessage = "Password is required!!";
      return;
    }
    if (value.password !== value.confirmPassword) {
      this.errorMessage = "Passwords do not match";
      return;
    }
    let that=this
    this.authService.doRegister(value).then(
      (res) => {
        const {password,confirmPassword,...rest}=value;
        this.databaselayer.updateUserData("userInformation",rest  );
    //this.databaselayer.addUserData(res.user.uid,value);
         //console.log(res);
       
      
        this.errorMessage = "";
        this.successMessage = "Your account has been created";
      // this.router.navigate(["home"]);
    // 
       this.reusableDialogService.openDialog("Success","Your account has been created and please verify your email for OTP");
       this.router.navigate(['/']);
    //   this.router.navigate(["/multifactor",{phoneNumber:value.phoneNumber}]);
      },
      (err) => {
        console.log(err);
        debugger  
        ;
        that.errorMessage = err;
        that.successMessage = "";
      }
    );
  }
  initilizeDatabase(){
    //
  }
}
