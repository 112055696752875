<form #deviceForm="ngForm" (ngSubmit)="onSubmit(deviceForm)">

  
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="">Serial Number:</label>
        <input class="form-control" name="serialNumber" [ngModel]="device.serialNumber" readonly required />
      </div>

     <!--  <div class="form-group col-md-6">
        <label for="">Address:</label>
        <input class="form-control" name="address" [ngModel]="device.deviceKeysAndLocations.address" required />
      </div> -->
      <div class="form-group col-md-6 border">
        <label for="">Address</label>
       
  
        <div class="input-group">
          <select class="form-control" name="deviceAddress" [(ngModel)]="device.deviceKeysAndLocations.address">
            <option *ngFor="let op of deviceAddressList" >
              {{op}}
            
            </option>
          </select>
         
          <span class="input-group-addon"><a (click)="toogleAddAddress()">New</a></span>
        </div>
        
        <div *ngIf="addAddressCollapse">
          <app-add-company-profile (addressSubmitted)="onAddressSubmitted()"></app-add-company-profile>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6 col-lg-3">
        <label for="">Latitude:</label>
        <input class="form-control" type="number" name="latitude" #latitude="ngModel"
          [(ngModel)]="device.deviceInformation.latitude" appLatitudeValidator required />

        <div *ngIf="latitude.invalid && (latitude.dirty || latitude.touched)" class="alert alert-danger">
          <div *ngIf="latitude.errors?.['required']; then thenBlockL; else elseBlockL"></div>

          <ng-template #thenBlockL> Latitude is required </ng-template>
          <ng-template #elseBlockL>
            {{ latitude.errors?.['invalidLatitude'] }}
          </ng-template>
        </div>
      </div>
      <div class="form-group col-md-6 col-lg-3">
        <label for="">Longitude:</label>
        <input class="form-control" type="number" name="longitude" #longitude="ngModel"
          [(ngModel)]="device.deviceInformation.longitude" appLongitudevalidator required />
        <div *ngIf="longitude.invalid && (longitude.dirty || longitude.touched)" class="alert alert-danger">
          <div *ngIf="
              longitude.errors?.['required'];
              then thenBlockLo;
              else elseBlockLo
            "></div>

          <ng-template #thenBlockLo> Longitude is required </ng-template>
          <ng-template #elseBlockLo>
            {{ longitude.errors?.['invalidLongitude'] }}

          </ng-template>
        </div>
      </div>

      <!--  <div class="form-group col-md-6">
        <label for="">Room Number:</label>mydevice
        <input
          class="form-control"
          name="roomNumber"
          #name="ngModel"
          [(ngModel)]="device.deviceInformation.roomNumber"
          (keyup)="onKey($event)"
          placeholder="201"
          required
        />
      </div> -->
</div>
<div class="form-row">
      <div class="form-group col-md-6">
        <label for="">Device Location:</label>
        <input class="form-control" name="deviceLocation" #name="ngModel"
          [(ngModel)]="device.deviceKeysAndLocations.deviceLocation" placeholder="Device Location" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="">Key Phrase(Activation):</label>
        <input class="form-control" name="keyPhrase" #name="ngModel" [(ngModel)]="device.deviceInformation.keyPhrase"
          placeholder="protect me now" />
      </div>

      <div class="form-group col-md-6">
        <label for="">Key Phrase(Deactivation):</label>
        <input class="form-control" name="deactivationKeyPhrase" #name="ngModel" [(ngModel)]="device.deviceInformation.deactivationKeyPhrase"
          placeholder="stop covert" />
      </div>
    </div>
    
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for=""> Change Facility Floor Plan:</label>
        <span>
          <input class="form-control" name="uploadLayout" (change)="uploadLayout($event)" type="file" />
        </span>
        
      </div>
      </div>
        <div class="form-row">
      <div class="form-group col-md-6">
        <label for="">Existing Facility Floor Plan:</label>
        <span>
          <img src="{{ device.deviceInformation.floorPlanUrl }}" alt="" width="30%"  />
        </span>

        <span>
         
            <img [src]="localFileURL" alt="Preview image here" width="30%"  />
          </span>
       <!--  <span>
          <a class="btn" (click)="
              openImagePdf(
                device.deviceInformation.floorPlanUrl,
                device.serialNumber
              )
            ">
            Click Here to View
          </a>
        </span> -->
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-5 col-md-3">
        <button class="btn submitStyle center-block" type="submit" [disabled]="!deviceForm.valid">
          <i class="glyphicon glyphicon-edit"></i> Submit
        </button>
      </div>
      <div class="form-group col-sm-5 col-md-3">
        <button class="inputButtonStyle center-block" (click)="onCancel()">
          <i class="glyphicon glyphicon-remove"></i> cancel
        </button>
      </div>
    </div>
  </form>