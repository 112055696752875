import { Component, OnInit, Input } from "@angular/core";
import { Device } from "../../modelclasses/device.model";
import { CommonModule } from '@angular/common';
import { DeviceInfoService } from "../../core/device-info.service";
import { ManageDeviceSocketioService } from "../../shared/managedevicesocketio.service";
import { DeviceStatusInfo } from "../../modelclasses/device-status-info";
import { WificonfigService } from "../../core/wificonfig.service";
import { FormsModule } from "@angular/forms";

//import { BrowserModule } from "@angular/platform-browser";
@Component({ 
  standalone: true,
  selector: "app-wificonfiguration",
  imports: [CommonModule, FormsModule],
  templateUrl: "./wificonfiguration.component.html",
  styleUrls: ["./wificonfiguration.component.scss"],
})
export class WificonfigurationComponent implements OnInit {
  // @Input() device: Device;

  private socket=null;;
  ssidInput: string="";
  passwordInput: string="";
  errorMessage: String="";
  loading: boolean = false;
  listC3vlar360: any;

  wifi: string="";
  @Input()
  wifiList: string[]=[];
  @Input()
  serialNumber: string="";
  constructor(
    private deviceInfoService: DeviceInfoService,
    private manageDeviceSocketIo: ManageDeviceSocketioService,
    private wificonfig: WificonfigService
  ) {
    //  this.clientSender();
    /* this.socket = socketIo("https://socketio.c3vlar360.com/", {
      secure: true,
      rejectUnauthorized: false
    }); */
    // this.clientSender();
  }

  ngOnInit() {
    console.log("wifi list", this.wifiList);
  }

  /*clientSender(): void {
    console.log("client sender",this.socket);
  

    this.socket.on("123", (mydata) => {
    
    
      console.log("my data 1",mydata);
    })


  }*/

  sendWifiDetails(): void {
    console.log("sending ");
    console.log("I am config wifi details");
    this.errorMessage = "";
    console.log(this.ssidInput, this.passwordInput);
    if (!this.ssidInput) {
      this.errorMessage = "Please put valid SSID";
      return;
    }

    this.loading = true;
    this.manageDeviceSocketIo.configureWifi({
      sn: this.serialNumber,
      ssid: this.ssidInput,
      password: this.passwordInput,
    });
  }
  /*
  configureWifi(): void {


    console.log("wifi serial", this.device.serialNumber);
    this.socket.emit('wifiClientEvent', this.device);
    this.socket.on("123", (mydata) => {
    
    
      console.log("my data 2",mydata);
    })
  }*/
}
