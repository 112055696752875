{
  "pagination": {
    "DescribeStream": {
      "input_token": "ExclusiveStartShardId",
      "limit_key": "Limit",
      "more_results": "StreamDescription.HasMoreShards",
      "output_token": "StreamDescription.Shards[-1].ShardId",
      "result_key": "StreamDescription.Shards"
    },
    "ListStreamConsumers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListStreams": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "more_results": "HasMoreStreams",
      "output_token": "NextToken",
      "result_key": [
        "StreamNames",
        "StreamSummaries"
      ]
    }
  }
}