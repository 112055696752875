<!-- <div id="overlay"># of C3vlar360s: {{ numberofC3vlar360 }}</div> -->



<div class="titleme">
<!--   <h1 class="title">My first AGM project</h1>  -->
  @if (apiLoaded | async) {
  <google-map height="100vh"
  width="100vw" 
  [options]="mapOptions"
 
  
  >
  <!-- @for (position of markerPositions; track position) { -->
    @for (marker of markers; track marker) {
   <!--  <map-marker #marker="mapMarker"  [position]="position" (mapClick)="openInfoWindow(marker)" /> -->
  
  <map-marker  #markerRef="mapMarker" [options]="marker" (mapClick)="openInfoWindow(markerRef)" /> 
  }
  
   <map-info-window>
    
  

 <div [innerHTML]="mapInfoContent"></div> 
  </map-info-window>
  <!-- <map-marker *ngFor="let marker of markers" [options]="marker"></map-marker>
 -->
 <!--  @for (position of markerPositions; track position) {
    <map-marker [position]="position"  />
  } -->
  </google-map> 
  }
  

 <!--  <google-map
   height="100%"
    [zoom]="zoom"
    [options]="mapOptions"
    (mapInitialized)="handleMapInitialized($event)"
    />  -->
 
 <!--birhanu 
   [center]="center" 
  (mapClick)="mapClicked($event)"
    (mapReady)="mapReady($event)"
  <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [fitBounds]="true">
    <agm-marker
      *ngFor="let device of devicesList; let i = index"
      (markerClick)="clickedMarker(device.deviceInformation, i)"
      [latitude]="device.deviceInformation.Latitude"
      [longitude]="device.deviceInformation.Longitude"
      [agmFitBounds]="true"
    >
      <agm-info-window>
        <h4>
          {{ device.serialNumber }}({{
            device.deviceInformation.deviceLocation
          }})
        </h4>
        <strong>{{ device.deviceInformation.address }}</strong>
        <p></p>
      </agm-info-window>
    </agm-marker>
  </agm-map> -->
</div>
