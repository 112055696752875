import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss'
})
export class UserManagementComponent {
  mode: string | null = '';
  oobCode: string | null = '';
  apiKey: string | null = '';
  constructor(private router: Router, private route: ActivatedRoute,private afAuth: AngularFireAuth) { }
  
  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      this.mode = params['mode'];
      this.oobCode = params['oobCode'];
      this.apiKey = params['apiKey'];
      this.afAuth.currentUser.then(user => {
        if (user && user.emailVerified) {
          user.reload();
       
          this.router.navigate(["/2factor",{ replaceUrl: true }]);
          
        } else {
         // alert('No user');
          this.router.navigate(["/login"]);
        }
      });
      if (this.mode === 'verifyEmail' && this.oobCode) {
        this.verifyEmail(this.oobCode);
      }
      
    })
  }
  verifyEmail(oobCode: string) {
    this.afAuth.applyActionCode(oobCode)
      .then(() => {
     //   alert('Email verified!');
        this.router.navigate(["/2factor",{ replaceUrl: true }]);
      })
      .catch(error => {
        console.log('Error verifying email:'+ error.message);
      });
  }



}
