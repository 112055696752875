
<div mat-dialog-content>

  <zxing-scanner [formats]="selectedFormats" (scanSuccess)="onCodeResult($event)"></zxing-scanner>

  <section class="results" *ngIf="qrResultString">
    <div>
      <small>Serial Number: </small>
      <strong>{{ qrResultString }}</strong>
    </div>
    <button mat-icon-button (click)="clearResult()">&times;</button>
  </section>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="scanDone()">Done</button>

</div>