import { Injectable } from "@angular/core";
import * as socketIo from "socket.io-client";

import { DeviceInfoService } from "../core/device-info.service";
import { DeviceStatusInfo } from "../modelclasses/device-status-info";

import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ManageDeviceSocketioService {
  private socket;
  private serialNumber: string="";
  private deviceStatusInfo: DeviceStatusInfo;

  constructor(private deviceInfoService: DeviceInfoService) {
    /* this.socket = socketIo("https://socketio.c3vlar360.com/", {
      secure: true,
      rejectUnauthorized: false,
    }); */
    console.log("socket",environment.SOCKET_ENDPOINT,environment.SOCKET_ENDPOINT_REMOTE);

    this.socket = socketIo.connect(environment.SOCKET_ENDPOINT_REMOTE);
    this.socket.on("connection", (data:any) => {
      console.log("socket data", data);
    });

    //;
    /*  this.socket = socketIo("http://localhost:3000", {
      secure: true,
      rejectUnauthorized: false,
    }); */
    this.deviceStatusInfo = {
      serialNumber: "",
      wifiList: [],
      con_status: "",
    };
  }

  clientReciver(): void {
    this.deviceInfoService.getSerialNumber().subscribe((res) => {
      console.log("client rec", res.data.trim());
      this.serialNumber = res.data.trim();
      this.deviceStatusInfo.serialNumber = this.serialNumber;

      this.socket.on(this.serialNumber, (mydata:any) => {
        console.log("my data", mydata, mydata.sn);
        if (mydata.sn == undefined) {
          this.deviceInfoService.getCheckConnection().subscribe((result) => {
            console.log("con status", result.con_status);

            this.deviceStatusInfo.con_status = result.con_status;
            //  this.deviceStatusInfo.con_status = result.con_status;
            //   this.infoResponse.serialNumber= this.serialNumber;
            this.deviceInfoService.getScanWifiList().subscribe((result) => {
              console.log("scan result", result.data);
              this.deviceStatusInfo.wifiList = result.data
                .split("\n")
                .filter((e:any) => {
                  console.log("leng", e.length);
                  return e.length > 2;
                })
                .map((res:any) => res.slice(1, -1));
              console.log("device status info", this.deviceStatusInfo);
              this.socket.emit("constatus", this.deviceStatusInfo);
            });
          });
        } else {
          console.log("my data else", mydata.sn, mydata.status);
          if (mydata.status === "WiFiConfigured") {
            this.deviceInfoService.getRebootThisDevice().subscribe((reuslt) => {
              console.log("Device rebooted");
            });
          } else {
            //  this.loading=true;
            this.deviceInfoService.postWifiConfiguration(mydata).subscribe(
              (result) => {
                console.log("config Send Detail result", result);
                //  this.router.navigate(['/wifisuccess']);
              },
              (err) => {
                console.log("HTTP Error", err);
              },
              () => {
                console.log("HTTP request completed.");
                this.socket.emit("configstatus", {
                  sn: mydata.sn,
                  ssid: mydata.ssid,
                  password: mydata.password,
                  status: "WiFiConfigured",
                });
              }
            );
          }
        }
      });
      // this.serialNumber = res.data.trim()
      //    this.clientReciver();

      /*  this.deviceInfoService.getDeviceInformation(this.serialNumber).valueChanges().subscribe(res => {
        //    console.log("this device",that.thisDevice,res);
          if (that.thisDevice == undefined) {
       
            that.thisDevice = res;
            this.braodcaseNotify();
     
          
            
          }
     
        });*/
    });

    // console.log("client reciver", this.socket,this.serialNumber);

    // this.socket.on("1234",(mydata)=>{
    //  console.log("client reciver data ",mydata);
    /* this.deviceInfoService.getSerialNumber().subscribe(res => {

       //     console.log(res.data.trim());
 
       this.serialNumber = res.data.trim()
       if(this.device.serialNumber===this.serialNumber){
         
       }
     });*/

    //  })
  }

  clientSender(socket:any): void {
    console.log("client sender", socket);
    /*  this.socket = socketIo('/', {
      secure: true,
      rejectUnauthorized: false
    });*/

    socket.on("123", (mydata:any) => {
      console.log("sender data", mydata);
    });
  }
  configureWifi(wifiSsidInfo:any): void {
    console.log("wifi serial", wifiSsidInfo);
    this.socket.emit("wifiConfigClientEvent", wifiSsidInfo);
    /* this.socket.on("123", (mydata) => {


      console.log("configure data wifi", mydata);
    })*/
  }
  getDeviceInfo(sn:any): void {
    console.log("Device info sn", sn);
    this.socket.emit("deviceInfoClientEvent", sn);
  }
  rebootDevice(wifiConfigedInfo: any): void {
    
    this.socket.on("connect", () => {
      console.log("Socket");
    });
    this.socket.emit("deviceRemoteReboot", wifiConfigedInfo);
  }
  activateDevice(deviceInfo:any): void {

    console.log("Activate Device", deviceInfo);
  
    this.socket.on("connect", () => {
      console.log("Socket");
    });
    this.socket.emit("deviceRemoteActivate", deviceInfo);
  }
  getScanWifiList(sn:any): void {}
}
