import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
  storeinLocalStorage(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
  
    localStorage.setItem(key, value);
    }
  }

  // Get a value from local storage
  retrieveLocalStorageData(key: string): string | null {
    if (isPlatformBrowser(this.platformId)) {
    return localStorage.getItem(key);
    }
    return null;
  }

  // Remove a value from local storage
  removeItemsinLocalStorage(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
    localStorage.removeItem(key);
    }
  }

  // Clear all items from local storage
  clearLocalStorage(): void {
    if (isPlatformBrowser(this.platformId)) {
    localStorage.clear();
    }
  }
}
