import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { finalize, firstValueFrom, map, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaselayerService {
  devicesList: AngularFireList<any> | null = null;
  constructor(private db:AngularFireDatabase,private afAuth:AngularFireAuth,private afStorage:AngularFireStorage  ) { }
  addUserData(uid:string,data:any){
    console.log("data",uid,data);
    let userInformation={firstName:data.firstname,lastName:data.lastname,email:data.email,companyName:data.companyname}
 

    return this.db.list('/users/').update(uid,userInformation);
  }
  async updateUserData(childNode:string,object:any){
    console.log("object",object);
  
   
       let user= await this.afAuth.currentUser;
    if (!object) return;
    if(user){
      const userId=user.uid;
      
      return this.db.list('/users/'+userId).update(childNode,object);
    }
 

    
  }
  isDeviceExist(serialNumber:string):Promise<any>{
   return firstValueFrom(this.db.list(`/devices/${serialNumber}`).valueChanges());
  }
  /* getDeviceBySeriaNumber(serialNumber: string):  Observable<any[]> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const userId = user.uid;
          return this.db.list('/devices/'+s , ref => ref.orderByChild(serialNumber)).valueChanges();
        } else {
          return of([]);
        }
      })
    );
   

   
} */
    async updateSingleDeviceData(childNode:string,object:any){
      console.log("object",object);
    const {serialNumber,...restValue}=object;
     
         let user= await this.afAuth.currentUser;
      if (!object) return;
      if(user){
        const userId=user.uid;
        
        return this.db.list('/devices/'+serialNumber).update(childNode,restValue);
      }
   
  
      
    }
  async updateDeviceData(object:any){
    try {
    const {serialNumber,deviceKeysAndLocations,...rest}=object;
    
    
    
    
    if (!object) return;
   
  
 await this.db.list('/devices').update(serialNumber,{...rest} );

return {successMessage:"Device updated successfully"};
  
} catch (error) {
  return {errorMessage:"Error in updating device"};
  
}
  
  }
  // use to fetch device information base on their serial number
  getDeviceDataByFieldName(fieldName: string): any {
   
    try {
      return this.db.object("/devices/" + fieldName);;
    } catch (error) {
      return {errorMessage:"Error in fetching device"};
    }
   
  }
  getList(node: string): AngularFireList<any> | null  {
    try {
      
      return this.db.list(node);
    
    } catch (error) {
      return null; 
      
    }
  

  }
  getUserDataByNode(nodeTOFetch:string): any {
    try
    { 
      
      return this.db.object(nodeTOFetch);

    }catch(error){
      return of({errorMessage:"Error in fetching user data"});
     }
    }
  async getUserDataBySpecificFieldName(parentFieldName:string,fieldName: string): Promise<Observable<any>>  {
   
    try {
      const user=await this.afAuth.currentUser;
    
      if(user){
        const userId=user.uid
     
        alert("user"+`/users/${userId}/${parentFieldName}/${fieldName}`); 
        return this.db.object(`/users/${userId}/${parentFieldName}/${fieldName}`).valueChanges(); 
      }
      else {
        throw new Error('No user is authenticated');
      }
     // return this.db.object("/users/"+parentFieldName+"/" + fieldName);;
    } catch (error) {
      return of({ errorMessage: "Error in fetching device" });
    }
   
  }
  upLoadImage(storageRef:any, image:File):Promise<any>{

    return new Promise((resolve,reject)=>{
    
      const uploadTask = storageRef.put(image);
      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((url:any) => {
            resolve(url);
          });
        }
        )
      ).subscribe();
    });

    
  }
  



  getUserDataByFieldName(fieldName: string):  Observable<any[]> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const userId = user.uid;
          return this.db.list('/users/' + userId, ref => ref.orderByChild(fieldName)).valueChanges();
        } else {
          return of([]);
        }
      })
    );
   

   
}
deleteUserDataByNode(nodeTOFetch:string):any{
  return this.db.object(nodeTOFetch).remove();

}
deleteDeviceDataByNode(nodeTOFetch:string):any{
  return this.db.object(nodeTOFetch).remove();
}
}
