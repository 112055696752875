// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    
    apiKey: "AIzaSyBqOYdiWgUDOzD_bL3ZCfRcjHmpLKlxfzk",
    authDomain: "c3vlar360.firebaseapp.com",
    databaseURL: "https://c3vlar360-default-rtdb.firebaseio.com",
    projectId: "c3vlar360",
    storageBucket: "c3vlar360.appspot.com",
    messagingSenderId: "951142562428",
    appId: "1:951142562428:web:69862dc70cb871bb3ee7f9",
    measurementId: "G-R0KQMEL9MP"
},
  Kinesisoptions: {
    accessKeyId: "AKIAZ3D2MT34TRX3RSXP",
    secretAccessKey: "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",
    region: "us-east-2",
  },
  SOCKET_ENDPOINT: "http://localhost:3000",
  SOCKET_ENDPOINT_REMOTE: "https://socketio.c3vlar360.com/",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/*
apiKey: "AIzaSyBs1mVgB6KXy53UvANN5l83ksbzhgqa6eg",
  authDomain: "c3vlar360-2c872.firebaseapp.com",
  projectId: "c3vlar360-2c872",
  storageBucket: "c3vlar360-2c872.appspot.com",
  messagingSenderId: "284408902563",
  appId: "1:284408902563:web:5dd0e8c13ca7551e1fe016"



*/