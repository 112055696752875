<script src="https://cdnjs.cloudflare.com/ajax/libs/aws-sdk/2.490.0/aws-sdk.min.js"></script>

<h2 mat-dialog-title>Serial Number - {{ data.serialNumber }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="border">
    <div class="row loader"></div>
    <div class="main">
      <div class="row">
        <div class="col-md-12">
          <div id="playerContainer" #playerContainer>
            <video
              #shakaPlayer
              class="player"
              width="1280"
              height="960"
              control
              autoplay
              poster="https://upload.wikimedia.org/wikipedia/commons/c/c4/PM5544_with_non-PAL_signals.png"
            ></video>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/shaka-player/2.4.1/shaka-player.compiled.js"></script>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script src="ui.js"></script>
  <a
    href="https://github.com/aws-samples/amazon-kinesis-video-streams-media-viewer"
  >
  </a>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="cancel()" >Cancel</button>
  
</mat-dialog-actions>

<script src="https://cdnjs.cloudflare.com/ajax/libs/aws-sdk/2.278.1/aws-sdk.min.js"></script>
