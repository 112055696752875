<form #deviceAddressForm="ngForm" (ngSubmit)="onSubmit(deviceAddressForm)" class="formstyle">
  <div class="form-row">
    <!-- <div class="form-group">
      <label for="companyName">Company Name</label>
      <input class="form-control" name="companyName" #name="ngModel" [(ngModel)]="companyName"
        placeholder="Lucrative Innovations Inc." />
    </div> -->
  </div>
  <div class="form-row">
    <div class="form-group fluid">
      <label for="companyName">New Address</label>
      <app-place-auto-complete (setAddress)="getAddress($event)" adressType="geocode"></app-place-auto-complete>
    </div>
  </div>

  <div class="form-row border">
    <small class="alert-danger"> {{ errorMessage }}</small>
    <small class="alert-success"> {{ successMessage }}</small>
    <div class="addButton">
      <button class="btn submitStyle" type="submit">
        <i class="glyphicon glyphicon-add"></i> Add
      </button>
    </div>
  </div>
</form>
<script async defer
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBaOTCUsTcAskunXXrrwryf-uNj1rR5ZLw&libraries=places&sensor=false&language=en"></script>