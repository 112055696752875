import { Injectable } from "@angular/core";
 declare global {
  interface Window {
    resolver: any;
    recaptchaVerifier:any; // replace 'any' with the correct type if known
  }
} 
@Injectable({
  providedIn: "root"
})
export class WindowService {
  get windowRef() {
    return window;
  }
  set windowRef(data) {
    console.log(Object.keys(data)[0], Object.values(data)[0]);
    let windowKey:any=Object.keys(data)[0];
    window[windowKey] = Object.values(data)[0];
  }
}
