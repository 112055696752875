import { Component, OnInit, Input } from "@angular/core";
import { Device } from "./../../modelclasses/device.model";

import { ManageDeviceSocketioService } from "../../shared/managedevicesocketio.service";
import { ToastrService } from "ngx-toastr";
import { WificonfigurationComponent } from "../wificonfiguration/wificonfiguration.component";

import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-configc3vlar360",
  templateUrl: "./configc3vlar360.component.html",
  styleUrls: ["./configc3vlar360.component.scss"],
  standalone:true,
  imports:[WificonfigurationComponent,CommonModule,RouterModule]

})
export class Configc3vlar360Component implements OnInit {
  // @Input() device: Device;

  @Input() serialNumber: any;
  currentTab: Boolean = true;
  isActive: Boolean = true;

  connectionStatus: String="";
  wifiList: string[]=[];
  private socket:any;
  constructor(
    private manageDeviceSocketIo: ManageDeviceSocketioService,
    private toastrService: ToastrService
  ) {
    /*  this.socket = socketIo('https://socketio.c3vlar360.com/', {
      secure: true,
      rejectUnauthorized: false
    }); */
    // this.clientSender();
  }

  ngOnInit() {
    console.log("SN test", this.serialNumber);

    this.serialNumber = this.serialNumber;
    this.manageDeviceSocketIo.getDeviceInfo(this.serialNumber);
    this.socket.on("constatusfinal", (data1:any) => {
      if (this.serialNumber == data1.serialNumber) {
        this.connectionStatus = data1.con_status;
        this.wifiList = data1.wifiList;
      }
    });
    this.socket.on("wificonfigStatus", (data1:any) => {
      console.log("wwifi config final", data1);
      this.toastrService.success(
        "WiFi Successfully Configured",
        "Wifi Configuration"
      );
      this.generalInfoTab();
      this.manageDeviceSocketIo.rebootDevice(data1);
    });

    this.wifiList = ["No avaliable wifi or c3vlar360 have no internet"];
  }
  generalInfoTab(): void {
    this.currentTab = true;
    this.isActive = true;
  }
  wifiConfigTab(): void {
    this.currentTab = false;
    this.isActive = false;

    // console.log("wifi list in tab",this.wifiList);
  }

  clientSender(): void {
    console.log("client sender", this.socket);

    this.socket.on("123", (mydata:any) => {
      console.log("my data 1", mydata);
    });
    this.socket.on("1234", (mydata:any) => {
      console.log("device info client sender", mydata);
    });
  }
}
