import { Component, OnInit, NgZone, OnDestroy, DoCheck } from "@angular/core";
import { Device } from "./../../modelclasses/device.model";
import { DeviceInfoService } from "./../../core/device-info.service";
import { FormsModule, NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import  firebase from "firebase/compat/app";

import {
  AngularFireStorage,
  AngularFireUploadTask,
  AngularFireStorageReference,
} from "@angular/fire/compat/storage";
import { firstValueFrom, Observable, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { Router, RouterModule } from "@angular/router";

import {  MatDialog } from "@angular/material/dialog";
import { QrScannnerComponent } from "./../qr-scannner/qr-scannner.component";
import { AddCompanyProfileComponent } from "../add-company-profile/add-company-profile.component";
import { CommonModule } from "@angular/common";
import { MapService } from "../../core/map.service";
import { LocalStorageService } from "../../core/local-storage.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { DatabaselayerService} from "../../core/databaselayer.service";
//import {} from "googlemaps";
declare var google: any;
interface language {
  address: string;
  
}

@Component({
  selector: "app-add-new-device-information",
  templateUrl: "./add-new-device-information.component.html",
  styleUrls: ["./add-new-device-information.component.scss"],
  standalone:true,

 
  imports:[FormsModule,CommonModule,RouterModule, AddCompanyProfileComponent ]
})
export class AddNewDeviceInformationComponent
  implements OnInit, OnDestroy, DoCheck
{
  public device: Device;
  public devicesList: Device[]=[];
  private layoutImage: File | null = null;
  private addnNwdevicestate: boolean = false;
  selectedAddressCompany: language ={address:""}
 deviceAddressList :any[]=[];
 
  public deviceAddressListT: any[]=[];
  deviceAddressListN: Observable<any[]>=new Observable<any[]>();
  public selected = 0;
  //qrResultString: string;
  //reader:FileReader=new FileReader();
  // Main task
 
  // Download URL
  //downloadURL: Observable<string>;
 downloadURL: Observable<string>=new Observable<string>();
  downloadurl: string="";
  localFileURL: string="";
  addState: boolean=false;
  currentLng: any;
  currentLat: any;
  currentStreet: string="";
  //geocoder:any;
  currentCity: string="";
  currentState: string="";
  currentZip: string="";
  currentCounty: string="";
  errorMessage: string = "";
  addAddressCollapse = false;
 storageRef: AngularFireStorageReference={} as AngularFireStorageReference;
  task: AngularFireUploadTask={} as AngularFireUploadTask;

  constructor(
    private deviceInfoService: DeviceInfoService,
    private toastrService: ToastrService,
    private afStorage: AngularFireStorage,
    private router: Router,
    private ngZone: NgZone,
    private mapService:MapService,
    private localStorageService:LocalStorageService,
    private databaseLayer: DatabaselayerService,
  private afAuth: AngularFireAuth,
   /* birhanu  private apiloader: MapsAPILoader,  */
    public dialog: MatDialog,
   /*birhanu private addressSubscription: Subscription, */
  
  
  ) {
   // debugger;
    this.device = {
      serialNumber: "",
      deviceInformation: {
        userId: 1,
     
        latitude: 0,
        longitude: 0,
        keyPhrase: "",
        deactivationKeyPhrase: "",
        floorPlanUrl: "",
      
        
      },
      deviceKeysAndLocations: {
        address: "",
        deviceLocation: "",
      },
     
    };
  }

  ngOnInit() {
    this.deviceInfoService
      .getDevicesList()
      .snapshotChanges()
      .subscribe(async (item) => {
        await this.initilizeDevicesList(item);
      });
    let localStorageValue= this.localStorageService.retrieveLocalStorageData("deviceAddressList");


    if (localStorageValue) {
     /*  this.deviceAddressList = JSON.parse(
        localStorage.getItem("deviceAddressList")
      ); 
       */
      this.deviceAddressList = JSON.parse(localStorageValue);
      console.log("The device address list",this.deviceAddressList)
      debugger
      if (this.deviceAddressList!== null) {
        /*  this.selectedAddressCompany =
          this.deviceAddressList[this.deviceAddressList.length - 1][1]; 
          this.device.deviceInformation.addressAndCompanyName =
          this.deviceAddressList[this.deviceAddressList.length - 1][1]; 
        ; */
      }
    }
    // ;
    /* birhanu this.addressSubscription = this.deviceInfoService
      .getDeviceAddress()
      .snapshotChanges()
      .subscribe((result) => {
      
        if (result.payload.toJSON()) {
          localStorage.setItem(
            "deviceAddressList",JSON.stringify(Object.entries(result.payload))
           // JSON.stringify(Object.entries(result.payload.toJSON())) 
          );
          //  this.deviceAddressList = Object.entries(result.payload.toJSON());
          if (this.deviceAddressList !== null) {
           this.device.deviceInformation.address =
              this.deviceAddressList[
                this.deviceAddressList.length - 1
              ][1].address; 
          }
        }
        //this.deviceAddressList[this.deviceAddressList.length - 1][0];
      }); */

    if (navigator) {
      navigator.geolocation.getCurrentPosition(async (pos) => {
        this.currentLat = pos.coords.latitude;
        this.currentLng = pos.coords.longitude;
    let {results}=   await this.mapService.reverseGeoCoder(this.currentLat, this.currentLng);
    console.log("The result in add devices", results[0].address_components[5].short_name);
    this.currentCounty = results[0].address_components[5].short_name;
    
      });
    }
  }
  ngDoCheck() {
    //  console.log("Device address list", this.deviceAddressList);
    //;
   /*birhanu  if (
      this.deviceAddressList.length == 0   ||
      JSON.parse(localStorage.getItem("deviceAddressList")).length !=
        this.deviceAddressList.length
    ) {
      this.deviceAddressList =JSON.parse(
        localStorage.getItem("deviceAddressList")
      );
      if (this.deviceAddressList !== null) {
        this.device.deviceInformation.address =
          this.deviceAddressList[this.deviceAddressList.length - 1][1].address;
      }
    } */
  }

  initilizeDevicesList(item: any) {
    this.devicesList = [];
    item.forEach((element:any) => {
      const temp = element.payload.toJSON();
      temp["serialNumber"] = element.key;
      if (
        temp.deviceInformation &&
        temp.deviceInformation.userId &&
        temp.deviceInformation.userId == firebase.auth().currentUser?.uid
      ) {
        this.devicesList.push(temp as Device);
        console.log("Device list", this.devicesList);
      }
    }); 
  }

  onKey(event: any) {
    // without type info
    /* this.device.deviceInformation.deviceLocation =
      this.device.deviceInformation.companyName.split(" ")[0] +
      "-" +
      this.device.serialNumber.substr(this.device.serialNumber.length - 5); */
  }
  onCancel() {
    this.router.navigate(["home"]);
    //  console.log(this.currentLat, this.currentLng);
  }
  async onSubmit(deviceForm: NgForm) {

    
    const deviceExists =(await this.databaseLayer.isDeviceExist(deviceForm.value.serialNumber)).length>0;
      if(deviceExists){
      this.toastrService.error(`Device with ${deviceForm.value.serialNumber} already exist`,
     
        "Device Register"
      );
      return ;
    }
    
    if (deviceForm.value.latitude == 0 && deviceForm.value.longitude == 0) {
      this.errorMessage = "Both latitude and longitude can not be zero";
      return;
    }
    if (deviceForm.value.serialNumber == "") {
      return;
    }
   

    let currentUser= await this.afAuth.currentUser;
 const objectDevice={
  serialNumber: deviceForm.value.serialNumber,
  deviceInformation: {
 

  latitude: deviceForm.value.latitude,
  longitude: deviceForm.value.longitude,
  keyPhrase: deviceForm.value.keyPhrase.toLowerCase(),
  deactivationKeyPhrase: deviceForm.value.dkeyPhrase,
  userId:currentUser?.uid,
  floorPlanUrl: ''
},
deviceKeysAndLocations: {
  address: deviceForm.value.addressAndCompanyName,
  deviceLocation: deviceForm.value.deviceLocation,
},
 }
 //this.deviceInfoService.updateDeviceInformation(objectDevice);
//alert("The object device"+JSON.stringify(objectDevie))

let result=await this.databaseLayer.updateDeviceData(objectDevice);

if(result?.successMessage){
  this.databaseLayer.updateUserData("deviceKeysAndLocations",{[deviceForm.value.serialNumber]:objectDevice.deviceKeysAndLocations});
  let storageRef = this.afStorage.ref(
    "/c3vlar360upload/" + deviceForm.value.serialNumber
  );
 

  if (this.layoutImage) {
    const sn=deviceForm.value.serialNumber;
   
  this.databaseLayer.upLoadImage(storageRef, this.layoutImage).then((url) => {

  this.databaseLayer.updateSingleDeviceData("deviceInformation",{serialNumber:sn,floorPlanUrl:url});

  });
  }
  this.toastrService.success("Successfully added", "Device Register");
 
  
  deviceForm.resetForm();
  this.router.navigate(["home"]);
}
//this.deviceInfoService.updateDeviceInformation(objectDevie);  

    /* const deviceObject = {
      serialNumber: deviceForm.value.serialNumber,
      // default to dark theme

      deviceInformation: {
        userId: 1,
        companyName: deviceForm.value.addressAndCompanyName.companyName,
          street: deviceForm.value.street,
        city: deviceForm.value.city,
        state: deviceForm.value.state,
        zip: deviceForm.value.zip,
        county: deviceForm.value.county,
        address: deviceForm.value.addressAndCompanyName.address,
        deviceLocation: deviceForm.value.deviceLocation, // default to Female
        roomNumber: deviceForm.value.roomNumber,
        Latitude: deviceForm.value.Latitude,
        Longitude: deviceForm.value.Longitude,
        keyPhrase: deviceForm.value.keyPhrase.toLowerCase(),
        dkeyPhrase: deviceForm.value.dkeyPhrase,
        downloadurl: "",
      },
      logs: "",
    }; */
    // ;
    // call to the database to check the existance of the device
    /*birhanu this.deviceInfoService
      .getDeviceInformation(deviceForm.value.serialNumber)
      .valueChanges()
      .subscribe(
        ( snapshot:any) => {
          console.log("device snapshot", snapshot);
          // ;
          if (snapshot === null) { 
            this.addnNwdevicestate = true;
            this.storageRef = this.afStorage.ref(
              "/c3vlar360upload/" + deviceForm.value.serialNumber
            );

            if (this.layoutImage) {
              // ;
              this.task = this.storageRef.put(this.layoutImage);
              this.task
                .snapshotChanges()
                .pipe(
                  finalize(() => {
                    this.downloadURL = this.storageRef.getDownloadURL(); // <-- Here the downloadURL is available.
                    this.downloadURL.subscribe((url) => {
                      this.downloadurl = url; */
                     //birhanu deviceObject.deviceInformation.downloadurl = url;
                      // ;
                     /*birhanu  this.deviceInfoService.updateDeviceInformation(
                        deviceObject
                      ); */
                      /*  this.deviceInfoService.updateDeviceKey(
                      deviceForm.value.serialNumber
                    ); */
                   /*birhanu  });
                  })
                )
                .subscribe();
            } else {
              console.log("two"); */
              // ;

            //birhanu  this.deviceInfoService.updateDeviceInformation(deviceObject);
              /*  this.deviceInfoService.updateDeviceKey(
              deviceForm.value.serialNumber
            ); */
           /*  } */
           /* birhanu  this.toastrService.success("Successfully added", "Device Register");
            deviceForm.resetForm();
            this.router.navigate(["home"]);
          } else {
            // ;
            if (this.addnNwdevicestate === false) {
              this.toastrService.error(
                "The device already registered",
                "Device Register"
              );
            }
          }
        },
        (err: any) => {
          // ;
          console.log("Error get device information", err.message);
        },
        () => {
          //;
          console.log("Get device information completed");
        }
      ); */
  }

  uploadLayout(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.layoutImage = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed

       // console.log("This post", reader.result);

        this.localFileURL = event.target?.result as string;
      };
    }
  }
  fillCurrentLatLog() {
    this.device.deviceInformation.latitude = this.currentLat;
    this.device.deviceInformation.longitude = this.currentLng;
  /*   this.device.deviceInformation.county = this.currentCounty; */

    // this.device.deviceInformation.street = this.currentStreet;
    //this.device.deviceInformation.address = this.address;
    /* this.device.deviceInformation.city = this.currentCity;
    this.device.deviceInformation.state = this.currentState;
    this.device.deviceInformation.zip = this.currentZip;
    this.device.deviceInformation.county = this.currentCounty; */
    // console.log('Lat log', this.currentLat, this.currentLng);
  }

  //QR code

  /*  clearResult(): void {
    this.qrResultString = null;
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
  } */
  onAddressChanged(value:any): void {
    ;
    console.log("Event change", value);
   //birhanu  this.geoCoder(value.address)
  }
  /*birhanu
  async geoCoder(address:any) {
    
    console.log("address in geocoder",address)
    ;
   await this.apiloader.load().then(() => {
     const geocoder = new google.maps.Geocoder();
    
     geocoder.geocode(
       {
         "address": address,
       },
       (results:any) => {
         console.log("The results", results);
         if (results[0]) {
           const addressComponents = results[0].address_components;
          console.log("Lat and long",results[0].geometry.location.lat(),results[0].geometry.location.lng())
           //this.currentCounty = addressComponents[5].long_name;
           this.device.deviceInformation.Latitude=results[0].geometry.location.lat();
          this.device.deviceInformation.Longitude= results[0].geometry.location.lng();
          this.device.deviceInformation.county=addressComponents[6].long_name
         } else {
           console.log("Not found");
         }
       }
     );
   });
 } */
  onOpenQRScanner(): void {

    this.dialog
      .open(QrScannnerComponent, { width: "500px" })
      .afterClosed()
      .subscribe((result) => {
     //   debugger;
        this.device.serialNumber = result.sn;
      });
  }
  onOpenNewCompanyAddress(): void {
    this.dialog
      .open(AddCompanyProfileComponent, { width: "500px" })
      .afterClosed()
      .subscribe((result) => {
        // this.device.serialNumber = result.sn;
      });
  }
  toogleAddAddress() {
    console.log("Testing 100", this.addAddressCollapse);
    this.addAddressCollapse = !this.addAddressCollapse;
  }
  getAddress(place: object) {
    console.log("Testing 100 autocomplete", place);
  }
  ngOnDestroy() {
   //birhanu this.addressSubscription.unsubscribe();
  }
  onAddressSubmitted() {
 
    let localStorageValue= this.localStorageService.retrieveLocalStorageData("deviceAddressList");
    if(localStorageValue){
      this.deviceAddressList = JSON.parse(localStorageValue);
    }
  
    
    
  
  }
}
