import { Routes } from '@angular/router';
import { UserLoginComponent } from './ui/user-login/user-login.component';
import { AllDevicesComponent } from './ui/all-devices/all-devices.component';
import { AuthGuard } from './core/auth.guard';
import { PhoneTwoFactorAuthenticationComponent } from './ui/phone-two-factor-authentication/phone-two-factor-authentication.component';
import { AlldevicesmapComponent } from './ui/alldevicesmap/alldevicesmap.component';
import { Configc3vlar360Component } from './ui/configc3vlar360/configc3vlar360.component';
import { ForgotPasswordComponent } from './ui/forgot-password/forgot-password.component';
import { DeviceInformationRegisterComponent } from './ui/device-information-register/device-information-register.component';
import { AddNewDeviceInformationComponent } from './ui/add-new-device-information/add-new-device-information.component';
import { UserSignupComponent } from './ui/user-signup/user-signup.component';
import { MapComponent } from './ui/map/map.component';

import { UserManagementComponent } from './ui/user-management/user-management.component';
import { Account } from 'aws-sdk';
import { AccountActivationComponent } from './ui/account-activation/account-activation.component';
import { EditExistingDeviceInformationComponent } from './ui/edit-existing-device-information/edit-existing-device-information.component';
import { HealthComponent } from './ui/health/health.component';
//import { PhoneTwoFactorAuthenticationComponent } from './ui/phone-two-factor-authentication/phone-two-factor-authentication.component';

export const routes: Routes = [ 
  
  {path:'signup',component:UserSignupComponent},
  {path:"login",component:UserLoginComponent} ,
  /* {path:"verifyemail",component:VerifyemailComponent}, */
  {path:"activate", component: AccountActivationComponent},
  { path: "2factor", component: PhoneTwoFactorAuthenticationComponent   } ,
/*   { path: "multifactor", component:  MultiFactorAuthenticationComponent   } , */
  {path:"usermgmt",component:UserManagementComponent},
  /*  { path: "home", component: HomeComponent, canActivate: [AuthGuard] }, */
  {
    path: "thisdevice",
    component: DeviceInformationRegisterComponent ,
    canActivate: [AuthGuard],
  },

  {
    path: "addnewdevice",
    component: AddNewDeviceInformationComponent,
    canActivate: [AuthGuard],
  },
  {path:"editdevice/:sn",
    component:EditExistingDeviceInformationComponent ,
/*     canActivate: [AuthGuard] */
  },
  {path:"devicehealth/:sn",component:HealthComponent},  
  {
    path: "alldevices",
    component: AllDevicesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "testmap",
    component: MapComponent,
    canActivate: [AuthGuard],
   
  },
  {
    path: "alldevicesmap",
    component: AlldevicesmapComponent,
    canActivate: [AuthGuard],
  },
  { path: "configc3vlar360", component: Configc3vlar360Component },
  { path: "forgotpassword", component:  ForgotPasswordComponent },
  { path: "", component: AllDevicesComponent , canActivate: [AuthGuard] },
  /* { path: 'wifisuccess', component:WificonfigsuccessComponent} ,*/
  { path: "**", redirectTo: "/login", pathMatch: "full" },
];
