import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {  MAT_DIALOG_DATA, MatDialogActions, MatDialogContent,MatDialogRef } from "@angular/material/dialog";

export interface DialogData1 {
  url: string;
  serialNumber: string;
}
@Component({
  selector: "app-image-pdf-viewer",
  templateUrl: "./image-pdf-viewer.component.html",
  styleUrls: ["./image-pdf-viewer.component.scss"],
  standalone:true,
  imports:[MatDialogActions,MatDialogContent]
})
export class ImagePdfViewerComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData1,private dialogRef: MatDialogRef<ImagePdfViewerComponent>) {
    console.log("Data image to view", data);
  }

  ngOnInit() {}
  cancel() {
    console.log("cancel"  )
    // Implement the cancel logic, if needed
    this.dialogRef.close();
  }
}
