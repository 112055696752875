import { Component, OnInit } from "@angular/core";
import { DeviceInfoService } from "../../core/device-info.service";

import { RouterModule } from "@angular/router";

@Component({
  selector: "app-device-management",
  templateUrl: "./device-management.component.html",
  styleUrls: ["./device-management.component.scss"],
    standalone:true  ,
    imports:[RouterModule]
})
export class DeviceManagementComponent implements OnInit {
  c3vlar360url: string="";
  constructor(private deviceInfo: DeviceInfoService) {}

  ngOnInit() {
    this.deviceInfo.getC3vlar360FromHost("c3vlar360").subscribe(result => {
      console.log("result in device mgt", result);
    });
  }
}
