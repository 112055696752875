export class User {
    constructor(
        
        public firstName: string,
        public lastName: string,
        public email:string,
        public companyName:string,
        public password: string,
        public confirmPassword: string,
        public phoneForVerification:string
      ) {  }
}

