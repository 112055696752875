<div class="container">
    <form #accountActivatioForm="ngForm" class="LoginStyle">
        <!-- <form #VerifyForm="ngForm" class="LoginStyle"> -->
         <div class="Form-Link">
            <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
            <ul>
                <li>
                    <a [routerLink]="['/login']" class="NotActiveStyle" routerLinkActive="active">Sign IN</a>
                </li>
               
               <!--  <li>
                    <a [routerLink]="['/emailVerification']" class="NotActiveStyle">Sign Up</a>
                </li> -->
            </ul>
        </div> 

        <div class="form-group">
            <div class="group">
                <label for="email" class="label">Activation Code{{accountActivationCode}}</label>
                <input type="text" class="form-control inputTextStyle" placeholder="Activation Code" (ngModel)="accountActivationCode"
                    name="accountActivationCode" required />
                <!-- <input type="email" class="form-control inputTextStyle" (ngModel)="(newuser.email)" name="email"
                        placeholder="Email Address" required /> -->
            </div>
        </div>
<div class="form-group">
    <label class="error alert-danger">{{ errorMessage }}</label>
    <label class="success alert-success">{{ successMessage }}</label>
</div>
        <div class="form-group">


            <button type="submit" class="inputButtonStyle" (click)="verifyAccountActivationCode(accountActivatioForm.value)">
                Verify Activation Code
            </button>
        </div>
    </form>
</div>