import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { CommonModule } from '@angular/common';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-alldevicesmap',
  templateUrl: './alldevicesmap.component.html',
  styleUrls: ['./alldevicesmap.component.scss'],
  standalone:true,
  imports:[CommonModule,MapComponent]
})
export class AlldevicesmapComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

}
