
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import  firebase from "firebase/compat/app";
@Component({
  selector: 'app-reusable-dialog',
  standalone: true,
  imports: [],
  templateUrl: './reusable-dialog.component.html',
  styleUrl: './reusable-dialog.component.scss'
})
export class ReusableDialogComponent {
  emailVerified = false;
  errorMessage = "";  
  constructor(
    public dialogRef: MatDialogRef<ReusableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }, private router: Router,
  ) { }

  onClose(): void {
    this.dialogRef.close();
  }
  onOK(): void {

    this.dialogRef.close();
  }
  async checkEmailVerification():  Promise<void> {
  
const user = firebase.auth().currentUser;
 await user?.reload(); 
    console.log("user", user);
 
    if (user) {
    
      this.emailVerified = user.emailVerified;
      if(this.emailVerified){
     
        

      this.dialogRef.close();
      
      
    }
      else{
        this.errorMessage = "Please verify your email address";
        
      }
    }
  }
}
