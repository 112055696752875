{
  "pagination": {
    "DescribeAutoScalingGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "AutoScalingGroups"
    },
    "DescribeAutoScalingInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "AutoScalingInstances"
    },
    "DescribeInstanceRefreshes": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken"
    },
    "DescribeLaunchConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "LaunchConfigurations"
    },
    "DescribeLoadBalancerTargetGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken"
    },
    "DescribeLoadBalancers": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken"
    },
    "DescribeNotificationConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "NotificationConfigurations"
    },
    "DescribePolicies": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "ScalingPolicies"
    },
    "DescribeScalingActivities": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "Activities"
    },
    "DescribeScheduledActions": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "ScheduledUpdateGroupActions"
    },
    "DescribeTags": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "Tags"
    },
    "DescribeTrafficSources": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken"
    },
    "DescribeWarmPool": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "Instances"
    }
  }
}