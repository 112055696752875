
<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
  <p>{{emailVerified}}</p>
  <p class="errorMessage">{{errorMessage}}</p>
</div>
<!-- <div mat-dialog-actions>
  <button mat-button (click)="onClose()">Close</button>
</div> -->
<div mat-dialog-actions>
  <button mat-button (click)="checkEmailVerification()">OK</button>
</div>