{
  "pagination": {
    "DescribeCacheClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheClusters"
    },
    "DescribeCacheEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheEngineVersions"
    },
    "DescribeCacheParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheParameterGroups"
    },
    "DescribeCacheParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeCacheSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheSecurityGroups"
    },
    "DescribeCacheSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeGlobalReplicationGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "GlobalReplicationGroups"
    },
    "DescribeReplicationGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReplicationGroups"
    },
    "DescribeReservedCacheNodes": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedCacheNodes"
    },
    "DescribeReservedCacheNodesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedCacheNodesOfferings"
    },
    "DescribeServerlessCacheSnapshots": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ServerlessCacheSnapshots"
    },
    "DescribeServerlessCaches": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ServerlessCaches"
    },
    "DescribeServiceUpdates": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ServiceUpdates"
    },
    "DescribeSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Snapshots"
    },
    "DescribeUpdateActions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "UpdateActions"
    },
    "DescribeUserGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "UserGroups"
    },
    "DescribeUsers": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Users"
    }
  }
}