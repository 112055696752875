<div *ngIf="auth.user | async; then authenticated; else guest"></div>
<ng-template #guest> </ng-template>
<ng-template #authenticated>
  <div *ngIf="auth.user | async as user" class="userProfileContainer">
    <div class="photoContainer">
      <img
        [src]="user.photoURL || 'assets/images/avatar.svg'"
        class="img-fluid"
        id="photoStyle"
      />
     
    </div>

    <div class="nameContainer">
    
      
     
  <h1>{{ userFullName }}</h1>
  <h3>{{email}}</h3>
      <div class="form-group">
        
        <button class="inputButtonStyle" (click)="logout()">
          <span class="glyphicon glyphicon-log-out"></span> Log out
        </button>
      </div>
    </div>
  </div>
</ng-template>
