import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { DeviceInfoService } from "./../../core/device-info.service" 
import { PlaceAutoCompleteComponent } from "../place-auto-complete/place-auto-complete.component";
import { CommonModule } from "@angular/common";
import { LocalStorageService } from "../../core/local-storage.service";
//import {} from "googlemaps";
@Component({
  selector: "app-add-company-profile",
  templateUrl: "./add-company-profile.component.html",
  styleUrls: ["./add-company-profile.component.scss"],
  standalone:true,
  imports:[PlaceAutoCompleteComponent,FormsModule]
})
export class AddCompanyProfileComponent implements OnInit, OnDestroy {
  @Output() addressSubmitted = new EventEmitter<string>();
  address:any = "";
  errorMessage: string = "";
  successMessage: string = "";
  deviceAddressList: string[] = [];
  companyName = "";
  isAddressRegister = false;
 public getAddressSubscription: Subscription=new Subscription();
  constructor(private deviceInfoService: DeviceInfoService,private localStorageService:LocalStorageService) {}

  ngOnInit() {
   /*  this.getAddressSubscription = this.deviceInfoService
      .getDeviceAddress()
      .snapshotChanges()
      .subscribe((result) => {
        console.log("Testing 200", result.payload, result.payload.toJSON());
      let payloadResult=  result.payload.toJSON();
        if (payloadResult) {
          localStorage.setItem(
            "deviceAddressList",
            JSON.stringify(Object.entries(payloadResult))
          );
          // this.deviceAddressList = Object.entries(result.payload.toJSON());
          let localstorageData = localStorage.getItem("deviceAddressList");
          if (localstorageData) {
            this.deviceAddressList = JSON.parse(localstorageData);
          }
         
        }

        //this.deviceAddressList[this.deviceAddressList.length - 1][0];
      }); */
  }

  getAddress(place: any ) {
    this.address = place["formatted_address"];
    console.log("Testing 100 autocomplete", this.address);
  }
  onSubmit(deviceAddressForm: NgForm) {
    alert("Testing 100"+ JSON.stringify(this.deviceAddressList));
    if (this.address == "") {
      this.successMessage = "";
      this.errorMessage = "Address of the device is required!!";
      return;
    }
  const deviceAddresses  = this.localStorageService.retrieveLocalStorageData("deviceAddressList");
 
  if (deviceAddresses ) {
    this.deviceAddressList = JSON.parse(deviceAddresses);
  } else {
    this.deviceAddressList = []
  }
  if(this.deviceAddressList.includes(this.address)){
    this.successMessage = "";
    this.errorMessage = "Address is already registered!!";
    return;
  }
  else {
    this.deviceAddressList.push(this.address);
    this.localStorageService.storeinLocalStorage("deviceAddressList", JSON.stringify(this.deviceAddressList));
   this.errorMessage = "";
    this.successMessage = "The address of the device successfully submited";
    this.addressSubmitted.emit(this.address);
  }
/*     debugger;
    if (this.deviceAddressList) { */
     /* this.isAddressRegister = this.deviceAddressList.some((data:any) => {
        return this.address == data[1].address;
      });  */
   // }
    // console.log("Addres register", isAddressRegister.length);
   
    
   /*  if (this.isAddressRegister == true) {
      this.successMessage = "";
      this.errorMessage = "Address is already registered!!";
      return;
    }
    const deviceAddressObject = {
      companyName: deviceAddressForm.value.companyName,
      address: this.address,
    };
    this.deviceInfoService.updateDeviceAddress(deviceAddressObject);
    this.errorMessage = "";
    this.successMessage = "The address of the device successfully submited"; */
  }
  ngOnDestroy() {
    this.getAddressSubscription.unsubscribe();
  }
}
