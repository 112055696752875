{
  "pagination": {
    "GetBehaviorModelTrainingSummaries": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "summaries"
    },
    "ListActiveViolations": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "activeViolations"
    },
    "ListAttachedPolicies": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "policies"
    },
    "ListAuditFindings": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "findings"
    },
    "ListAuditMitigationActionsExecutions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "actionsExecutions"
    },
    "ListAuditMitigationActionsTasks": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "tasks"
    },
    "ListAuditSuppressions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "suppressions"
    },
    "ListAuditTasks": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "tasks"
    },
    "ListAuthorizers": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "authorizers"
    },
    "ListBillingGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "billingGroups"
    },
    "ListCACertificates": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "certificates"
    },
    "ListCertificates": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "certificates"
    },
    "ListCertificatesByCA": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "certificates"
    },
    "ListCustomMetrics": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "metricNames"
    },
    "ListDetectMitigationActionsExecutions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "actionsExecutions"
    },
    "ListDetectMitigationActionsTasks": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "tasks"
    },
    "ListDimensions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "dimensionNames"
    },
    "ListDomainConfigurations": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "domainConfigurations"
    },
    "ListFleetMetrics": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "fleetMetrics"
    },
    "ListIndices": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "indexNames"
    },
    "ListJobExecutionsForJob": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "executionSummaries"
    },
    "ListJobExecutionsForThing": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "executionSummaries"
    },
    "ListJobTemplates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "jobTemplates"
    },
    "ListJobs": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "jobs"
    },
    "ListManagedJobTemplates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "managedJobTemplates"
    },
    "ListMetricValues": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "metricDatumList"
    },
    "ListMitigationActions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "actionIdentifiers"
    },
    "ListOTAUpdates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "otaUpdates"
    },
    "ListOutgoingCertificates": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "outgoingCertificates"
    },
    "ListPackageVersions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "packageVersionSummaries"
    },
    "ListPackages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "packageSummaries"
    },
    "ListPolicies": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "policies"
    },
    "ListPolicyPrincipals": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "principals"
    },
    "ListPrincipalPolicies": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "policies"
    },
    "ListPrincipalThings": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "things"
    },
    "ListProvisioningTemplateVersions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "versions"
    },
    "ListProvisioningTemplates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "templates"
    },
    "ListRelatedResourcesForAuditFinding": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "relatedResources"
    },
    "ListRoleAliases": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "roleAliases"
    },
    "ListScheduledAudits": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "scheduledAudits"
    },
    "ListSecurityProfiles": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "securityProfileIdentifiers"
    },
    "ListSecurityProfilesForTarget": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "securityProfileTargetMappings"
    },
    "ListStreams": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "streams"
    },
    "ListTagsForResource": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "tags"
    },
    "ListTargetsForPolicy": {
      "input_token": "marker",
      "limit_key": "pageSize",
      "output_token": "nextMarker",
      "result_key": "targets"
    },
    "ListTargetsForSecurityProfile": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "securityProfileTargets"
    },
    "ListThingGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "thingGroups"
    },
    "ListThingGroupsForThing": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "thingGroups"
    },
    "ListThingPrincipals": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "principals"
    },
    "ListThingRegistrationTaskReports": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "reportType"
      ],
      "output_token": "nextToken",
      "result_key": "resourceLinks"
    },
    "ListThingRegistrationTasks": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "taskIds"
    },
    "ListThingTypes": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "thingTypes"
    },
    "ListThings": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "things"
    },
    "ListThingsInBillingGroup": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "things"
    },
    "ListThingsInThingGroup": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "things"
    },
    "ListTopicRuleDestinations": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "destinationSummaries"
    },
    "ListTopicRules": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "rules"
    },
    "ListV2LoggingLevels": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "logTargetConfigurations"
    },
    "ListViolationEvents": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "violationEvents"
    }
  }
}