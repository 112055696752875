<!-- 

<mat-dialog-content>
    <h4>Password Reset</h4>
        A link to reset your password has 
        been sent to the email  address you provided.
      </mat-dialog-content>
      <mat-dialog-actions>
          
        <button mat-button mat-dialog-close>Okay</button>
      </mat-dialog-actions> -->
    
      
      <h1 mat-dialog-title>Delete file</h1>
<div mat-dialog-content>
  Would you like to delete cat.jpeg?
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>No</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>