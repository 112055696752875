import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class TwoFactorAuthenticationService {
  private isVerificationSendValue = false;
  constructor() {}
  getIsVerificationSend() {
    return this.isVerificationSendValue;
  }
  updateIsVerificationSend(value:any) {
    this.isVerificationSendValue = value;
  }
}
