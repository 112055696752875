import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, Observer, throwError } from "rxjs";
//import { request } from 'http';
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class TwofactorService {
  twofactorcode = {
    //email : 'string',
    //const url ='http://localhost:3000/send_email_2factor',
    //Phone : 'Number'
  };

  constructor(private http: HttpClient) {}
  getdata(): string[] {
    return ["email", "phone"];
  }
  posttwofactorauthentication(send_email_2factor:any): Observable<any> | null{
    /* console.log("form data", send_email_2factor);
  return this.http.post(
    "http://localhost:3000/api/send_email_2factor",
    send_email_2factor,
    httpOptions
  ); */
    return null;
  }

  /*public gettwofacorData

  url = 'http://localhost:3000/send_email_2factor?'

  sendEmail(email, code) {
    const url = 'http://localhost:3000/send_email_2factor?';
    const obj = {
      //email: 'email',
      code: 'ranNum',
      //Phone :'phone'
    };
    return this.http.post(url, obj);
}*/
  /*getTwofactorService() {
  return this.http.get('${this.url}/TwofactorService');
}*/

  /*
sendPostRequest(url: string) {
    return this._httpClient.post(url, {});
  }

*/

  posttwofactor(emailData:any): Observable<any> {
    return this.http.post(
      "http://localhost:3000/send_email_2factor",
      emailData,
      //send_email_2factor,
      httpOptions
    );
  }
}
