import { Component, OnInit, Input } from "@angular/core";
import { formatDistanceToNow } from 'date-fns';
import { DeviceInfoService } from "../../core/device-info.service";
import { Observable, of } from "rxjs";
import { CommonModule } from "@angular/common";
import { DatabaselayerService } from "../../core/databaselayer.service";
import { Device } from "../../modelclasses/device.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ValueSetter } from "date-fns/parse/_lib/Setter";
// import { LogsService } from '../../shared/logs.service'
@Component({
  selector: "app-health",
  templateUrl: "./health.component.html",
  styleUrls: ["./health.component.scss"],
  standalone:true,
  imports:[CommonModule]
    
    

})
export class HealthComponent implements OnInit {
  // @Input() device: Device;
  @Input() serialNumber: any;
  // logs: any;
  reportedtimeAgo:any;
  processedLogs: any[] = [];

protected healthLog:any[]=[];
  device: Device;
  constructor(private deviceInfoService: DeviceInfoService, private dbLayerService: DatabaselayerService, 
    private route: ActivatedRoute, private router: Router) {
   /*birhanur   this.healthlogs = this.deviceInfoService
      .getDeviceLog(this.serialNumber)
      .valueChanges(); */

    
      this.device = {
        serialNumber: "",
        deviceInformation: {
          userId: 1,
         
  
          latitude: 0,
          longitude: 0, // default to dark theme
          keyPhrase: "protect me now",
          deactivationKeyPhrase: "protect me now",
          floorPlanUrl: "",
        
        },
        deviceKeysAndLocations: {
          address: "Select the adddress",
          deviceLocation: "",
        },
       
  
      
      };

  }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.serialNumber = params.get('sn');
    console.log("Params",params);
    
    });

    if(this.serialNumber){
      this.dbLayerService.getDeviceDataByFieldName(this.serialNumber).snapshotChanges().subscribe((device:any)=>{
        console.log("Data on Edit device payload", device.payload.toJSON());
        const temp = device.payload.toJSON();
        temp["serialNumber"] = device.key;
       // this.healthLog = temp.healthLog
        console.log("Health logs",temp.healthLog
          );
   this.healthLog=Object.entries(temp.healthLog).map(([key,value]: [string, any]) => { 
          //let payload = action.payload.val(); 
          console.log("Action payload",key,value);
        
      
       let  timeAgo= this.getTimeAgo(new Date(value?.logtime*1));
       console.log("Time Ago",timeAgo);
        
         return { key: key, ...value,timeAgo:timeAgo }; 
     
        }).reverse(); 
  
        // this.device = device;
        this.device = Object.assign({}, temp as Device );
       
        
      }
      );
    }
    /* if (this.serialNumber) {

      this.deviceInfoService
      .getDeviceLog(this.serialNumber).snapshotChanges(['child_added'])
  .subscribe((actions:any) => {
   
    this.processedLogs = actions.map((action:any) => { 
      let payload = action.payload.val(); 
    
  
  let  timeAgo= this.getTimeAgo(new Date(payload.logtime*1));
    
     return { key: action.key, ...payload,timeAgo:timeAgo };
    }).reverse();
   
  });
       this.healthlogs = this.deviceInfoService
        .getDeviceLog(this.serialNumber)
        .valueChanges(); 
    } */
    //  this.logs = Object.keys(this.device.logs).map(key => ({ key: key, value: this.device.logs[key] })).reverse();//sort(this.descOrder);

    // console.log("logs",this.device.logs);
  }
  descOrder = (a:any, b:any) => {
    return b.key - a.key;
  };
 getTimeAgo(reportedDate:Date): string {
    return formatDistanceToNow(reportedDate, { addSuffix: true });
} 
onCancel() {
   

 this.router.navigate(["/alldevices"]);
  
}
}
