import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from '../ui/reusable/reusable-dialog/reusable-dialog.component';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ReusableDialogService {

 
  constructor(private dialog: MatDialog,private router: Router) { }

  openDialog(title: string, message: string): void {
    this.dialog.open(ReusableDialogComponent, {
      width: '400px',
      data: { title, message },   disableClose: true
    }).afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`); // Pizza!
     this.router.navigate(['/']);
    });
    
  }
}
