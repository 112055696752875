
<div *ngIf="healthLog.length == 0">
  <p>No health log available</p>
</div>
<div class="table-responsive-sm pt-4">
  <table class="table table-bordered">
  <thead class="thead-light"  *ngIf="healthLog.length > 0">
      <tr>
        <th width="5%">No.</th>
        <td width="15%">Date</td>
        <th width="15%">Time</th>
        <th width="25%">Internet Status</th>
        <th width="25%">Recognition Status</th>
        <th width="15%">Reported</th>
       
      </tr>
    </thead> 
    <tbody class="tableStyle">
 
    
      <tr *ngFor="let log of healthLog; let i = index">
        
        <td>{{ i }}</td>
        <td>{{ log.logtime | date: "MMM d, y" }}</td>
        <td>{{ log.logtime | date: "h:mm a" }}</td> 
       <td>{{ log.internet }}</td>
       <td>{{ log.recognition}}</td>
        <td>{{ log.timeAgo}}</td>
      
      </tr> 
     
    </tbody>
  </table>
  <div class=" text-right border-2 ">
    <button class="inputButtonStyle w-25 " (click)="onCancel()">
      <i class="glyphicon glyphicon-remove"></i> cancel
    </button>
  </div>
  <ng-template #thenBlockL>
    <p style="color: green">Info</p>
  </ng-template>
  <ng-template #elseBlockL>
    <p style="color: red">error</p>
  </ng-template>
</div>
