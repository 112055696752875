import { Component } from '@angular/core';
import { AuthService } from '../../core/auth.service';
//import { AngularFireDatabase } from 'angularfire2/database';
import {Router} from '@angular/router';

import { AngularFireDatabase} from '@angular/fire/compat/database';
import { CommonModule } from '@angular/common';
import { Database } from 'aws-sdk/clients/cloudwatchevents';
import { DatabaselayerService } from '../../core/databaselayer.service';
//import {IpcService} from './../../core/ipc.service';
//the pipe 'asnyc' could not be found


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class UserProfileComponent  {
  userFullName: string="";
  email: string="";
  userInformationObservable: any;  
  userFirstNameObservable: any;
  constructor(public auth: AuthService,private database: AngularFireDatabase, private dbLayerService: DatabaselayerService,  
     private router: Router){//,private readonly _ipc: IpcService) {



auth.user.subscribe(async user => {
  if(user!== null) {
    let nodeTOFetch="/users/"+user.uid+"/userInformation"
    this.userInformationObservable=  this.dbLayerService.getUserDataByNode(nodeTOFetch).snapshotChanges().subscribe(async (item:any) => {
      let fn=item.payload.val()["firstName"];
      let ln=item.payload.val()["lastName"];
      this.userFullName = fn +" "+ ln;
      this.email = item.payload.val()["email"];

    }   );
   
}

}


)

   }

  logout() {
   this.auth.signOut();
   console.log("Navigate",this.router.navigate)
   
    const routerTimout = setTimeout(() =>{
      ;
      this.router.navigate(['/login']);
      clearTimeout(routerTimout);
    }, 1000);
  }

}

