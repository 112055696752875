import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/compat/database";
import { Device } from "./../modelclasses/device.model";
import  firebase from "firebase/compat/app";


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
@Injectable({
  providedIn: "root",
})
export class DeviceInfoService {
  devicesList: AngularFireList<any> | null = null;
  devicesKeyList: AngularFireList<Device> | null = null;
  deviceStreaming: AngularFireList<any> | null = null;
  // devicesList1: Observable<any>;
  deviceInfo: AngularFireObject<any> | null = null;

  deviceInfoField: AngularFireObject<any> | null = null;

  deviceHealthLog: AngularFireList<any> | null = null;

  private socket: any;
  constructor(
    private http: HttpClient,
    private database: AngularFireDatabase
  ) {}
  // to extract device serial number information
  getSerialNumber(): Observable<any> {
    return this.http.get("http://localhost:3000/api/serialnumber");
  }

  // use to stream video when the voice recognized
  getLiveStreamVideo(sn: string): Observable<any> {
    return this.http.get("http://localhost:3000/api/livestream/?sn=" + sn);
  }
  // notify other  registered devices
  getNotifyDevices(): Observable<any> {
    return this.http.get("http://localhost:3000/api/notifydevices");
  }

  // use to stream video when the voice recognized
  getStopLiveStreamVideo(): Observable<any> {
    return this.http.get("http://localhost:3000/api/stoplivestream");
  }

  getC3vlar360(): Observable<any> {
    return this.http.get(
      "http://c3vlar360.local:3000/api/allc3vlar360",
      httpOptions
    );
    // return this.http.get('http://192.168.0.18:3000/api/allc3vlar360', httpOptions);
  }
  getC3vlar360FromHost(hostname:string): Observable<any> {
    return this.http.get(
      "http://" + hostname + ":3000/api/allc3vlar360",
      httpOptions
    );
    // return this.http.get('http://192.168.0.18:3000/api/allc3vlar360', httpOptions);
  }
  getCheckConnectionForWifi(ipaddress:string): Observable<any> {
    return this.http.get("http://" + ipaddress + ":3000/api/checkconnection");
  }
  getIamC3vlar360(ipaddress:string): Observable<any> {
    return this.http.get("http://" + ipaddress + ":3000/api/iamc3vlar360");
  }
  getCheckConnection(): Observable<any> {
    //  console.log('ip in get connection', ipaddress);

    return this.http.get("http://localhost:3000/api/checkconnection");
  }

  getScanWifiList(): Observable<any> {
    // console.log('ip in get connection', ipaddress);

    return this.http.get("http://localhost:3000/api/scanwifi");
  }

  getSerialNumberForWifi(ipaddress:string): Observable<any> {
    console.log("ip in get connection", ipaddress);

    return this.http.get("http://" + ipaddress + ":3000/api/serialnumber");
  }

  postWifiConfiguration(wifiConfigData:any): Observable<any> {
    // let formData={'ssid1':ssid , 'passphrase1':passphrase};
    console.log("form data", wifiConfigData);

    return this.http.post(
      "http://localhost:3000/api/wificonfig",
      wifiConfigData,
      httpOptions
    );
  }

  getRebootDevice(ipaddres:string): Observable<any> {
    return this.http.get("http://" + ipaddres + ":3000/api/rebootDevice");
  }
  getRebootThisDevice(): Observable<any> {
    return this.http.get("http://localhost:3000/api/rebootdevice");
  }

  // use to get information about all devices.
  getDevicesList() {
    this.devicesList = this.database.list("/devices");
    return this.devicesList;
  }
  getList(node: string) {
    this.devicesList = this.database.list(node);
    return this.devicesList;

  }
  
  getDevicesKeyList(userId:string) {
    this.devicesKeyList = this.database.list<Device>("/devices/keys", (ref) =>
      ref.orderByChild("userId").equalTo(userId)
    );
    return this.devicesKeyList;
  }
  
  getDeviceAddress(): AngularFireObject<any> {
    // ;
    const userId = firebase.auth().currentUser?.uid;
    return this.database.object("/devices/device_users/" + userId);
  }

  
  // use to check device streaming  base on their serial number
  isDeviceStreaming(sn:string): any {
    this.deviceStreaming = this.database.list(
      "/devices/" + sn + "/latlongtimestamp",
      (ref) =>
        ref
          .orderByKey()

          //  .orderByChild("time")
          // .startAt(1631737791883)
          .limitToLast(1)
    );

    return this.deviceStreaming; // this.deviceStreaming;
  }
  isValidKeyPhrase(serialNo: string): any {
    console.log("SN", serialNo);

    this.deviceInfoField = this.database.object(
      "/devices/" + serialNo + "/deviceInformation/isValidkeyPhrase"
    );

    return this.deviceInfoField;
  }
  isDeviceStreamingObject(serialNo: string): any {
    console.log("SN", serialNo);

    this.deviceInfoField = this.database.object(
      "/devices/" + serialNo + "/latlongtimestamp"
    );
    console.log("SN", serialNo);
    return this.deviceInfoField;
  }
  getDeviceInformationField(serialNo: string): any {
    console.log("SN", serialNo);

    
    this.deviceInfoField = this.database.object(
      "/devices/" + serialNo + "/deviceInformation"
    );
    return this.deviceInfoField;
  }
  // use to fetch device log base on their serial number
  getDeviceLog(serialNo: string): any {
    console.log("SN", serialNo);
    this.deviceHealthLog = this.database.list(
      "/devices/" + serialNo + "/healthlog",
      (ref) => ref.limitToLast(100)
    );
    return this.deviceHealthLog;
  }
  deleteObject(sn: string, oNamee: string) {
    try {
    } catch (erro) {}
  }
  deleteDevice(serialNo: string) {
  //debugger
   // this.database.list("/devices/keys").remove(serialNo);
    /*  this.database
      .list("/devices/0000000030e771b0/logs/", (ref) => ref.limitToFirst(500))
      .snapshotChanges()
      .subscribe((vals) => {
        vals.forEach((val) => {
          console.log("my value", val.key, val.payload.val());
          this.database.list("/devices/0000000030e771b0/logs/").remove(val.key);
        });
      }); */
    /*    .remove()
      .then((res) => console.log(res))
      .catch((error) => console.log("my error", error)); */
    /* this.database
      .list("/devices/0000000030e771b0/")
      .update("logs", { eh: "me" })
      .then((res) => console.log(res))
      .catch((error) => {
        console.log("my error", error);
      }); */

    this.database
      .list("/devices")
      .remove(serialNo)
      .then(() => {
           this.database.list("/devices/keys").remove(serialNo);
      })
      .then((result) => {
        console.log("successfully deleted", result);
      })
      .catch((error) =>{ 
        console.log("Deletion error", error)
        this.database.list("/devices/keys").remove(serialNo);
    
    }); 
  }
 /* 
  updateDeviceInformation(device: Device) {
    ;
    console.log("update service called");
    console.log(device.deviceInformation.downloadurl);
    const userId = firebase.auth().currentUser?.uid;
    try {
      this.database
        .list("/devices")
        .update(device.serialNumber, {
          deviceInformation: {
            userId:userId,
            companyName: device.deviceInformation.companyName,
           street: device.deviceInformation.street,
            city: device.deviceInformation.city,
            state: device.deviceInformation.state,
            roomNumber: device.deviceInformation.roomNumber,
            zip: device.deviceInformation.zip, 
            county: device.deviceInformation.county,
            address: device.deviceInformation.address,
            deviceLocation: device.deviceInformation.deviceLocation,

            Latitude: Number(device.deviceInformation.Latitude),
            Longitude: Number(device.deviceInformation.Longitude),
            keyPhrase: device.deviceInformation.keyPhrase,
            dkeyPhrase: device.deviceInformation.dkeyPhrase,
            downloadurl: device.deviceInformation.downloadurl,
          },
        })
        .then(() => {
          // const key = device.serialNumber ;

          this.database.list("/devices").update("keys", {
            [device.serialNumber]: { userId: userId },
          });
          //   this.updateDeviceKey(device.serialNumber);
        });
    } catch (error:any) {
      console.log("Error Device update", error.message);
    }
  }*/

  // Device list key manger
  updateDeviceKey(key: any) {
    this.devicesKeyList = this.database.list("/devices/keys");
    this.devicesKeyList.update(key, key);
  }
  updateDeviceAddress(deviceAddressObject: any) {
    const userId = firebase.auth().currentUser?.uid;
    console.log("Testing 100 address", deviceAddressObject);
    /* this.database.list("/devices/device_users").update(user.uid,{
      
    }); */
    this.database
      .list("/devices/device_users/" + userId)
      .push(deviceAddressObject);
  }

  // http error handle method
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
  // to get devices information on the newwork
  getDevicesInformationOnNetwork(): Observable<any> {
    return this.http.get("/api/serialnumber");
  }

  sendMessage(): void {
    this.socket.on("message", function (data:any) {
      document.write(data);
    });
    //  this.socket.emit('newclientconnect', 'message');
  }
}

/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import{Device} from './../modelclasses/device.model';

import * as firebase from 'firebase/app';
import * as socketIo from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
//const SERVER_URL = 'http://localhost:300';
export class DeviceInfoService {
  devicesList:AngularFireList<any>;
  deviceInfo:AngularFireObject<any>;
  private socket;
  constructor(private http:HttpClient,private database : AngularFireDatabase) {
  
  }
  //to extract device serial number information
 getSerialNumber(): Observable<any> {
  return  this.http.get('http://localhost:3000/api/serialnumber');

  }
  // use to get information about all devices.
  getDevicesInformation(){
    this.devicesList = this.database.list('/devices')
    return this.devicesList;
  }
  getLiveStreamVideo(): Observable<any>{
    return this.http.get('http://localhost:3000/api/livestream')
  }
  //use to fetch device information base on their serial number
  getDeviceInformation(serialNo:string):any{
   // return this.database.list('/devices/'+serialNo);
  //. console.log(this.devicesList);
  this.deviceInfo=   this.database.object('/devices/'+serialNo);
    
   /*  this.deviceInfo=   this.database.object("/devices/"+serialNo).valueChanges().subscribe(res=>{
         console.log(res);
                     return res;
       }); 
    return this.deviceInfo; */
/*  return this.deviceInfo;

  }
  deleteDevice(serialNo:string){
    this.devicesList.remove(serialNo)
  }
  updateDeviceInformation(device:Device){
    console.log("update service called");
    console.log(device.deviceInformation);
    var user = firebase.auth().currentUser;
    this.devicesList.update(device.serialNumber,
      {deviceCode:device.deviceCode,
        roomNumber:device.roomNumber,
        Latitude:Number(device.Latitude),
        Longitude:Number(device.Longitude),
       
        keyPhrase:device.keyPhrase,
       deviceInformation:{
       userId: user.uid,
       companyName:device.deviceInformation.companyName,
       address:device.deviceInformation.address,
       city:device.deviceInformation.city,
       state:device.deviceInformation.state,
       zip :device.deviceInformation.zip


       }
      
    });
    

  }
  //to get devices information on the newwork
 getDevicesInformationOnNetwork(): Observable<any> {
      return  this.http.get('/api/serialnumber');;

  }
  
  sendMessage(): void {
    this.socket.on('message', function(data){
      
      document.write(data)}
    
    );
  //  this.socket.emit('newclientconnect', "message");
  } 
 
}
*/
