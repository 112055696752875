import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { environment } from "./../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent,MatDialogRef } from "@angular/material/dialog";
import { DeviceInfoService } from "./../../core/device-info.service";
import * as AWS from "aws-sdk";

declare let shaka: any;
export interface DialogData {
  serialNumber: string;
  url: string;
}

@Component({
  selector: "app-c3vlarplayer",
  templateUrl: "./c3vlarplayer.component.html",
  styleUrls: ["./c3vlarplayer.component.scss"],
  standalone:true,
  imports:[MatDialogActions,MatDialogContent]
})
export class C3vlarplayerComponent implements OnInit {
  @ViewChild("shakaPlayer", { static: true }) shakaPlayerRef:
    | ElementRef
    | undefined;
  @ViewChild("playerContainer", { static: true }) playerContainerRef:
    | ElementRef
    | undefined;
  videoElement: HTMLVideoElement | undefined;
  videoContainerElement: HTMLDivElement | undefined;
  kinesisVideo: any = new AWS.KinesisVideo(environment.Kinesisoptions);
  kinesisVideoArchivedContent: any = new AWS.KinesisVideoArchivedMedia(
    environment.Kinesisoptions
  );
  manifestUri =
    "https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd";
  streamName:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private deviceInfoService: DeviceInfoService,private dialogRef: MatDialogRef<C3vlarplayerComponent>
  ) {
    console.log("data", this.data);
  }

  ngOnInit() {
    // this.startStream("test");
    // this.initPlayer();
    // Install built-in polyfills to patch browser incompatibilities.
 
    shaka.polyfill.installAll();

    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      this.videoElement =(<ElementRef> this.shakaPlayerRef).nativeElement;
      this.videoContainerElement = (<ElementRef>this.playerContainerRef).nativeElement;
      if (this.data.url && this.data.serialNumber) {
        this.startStreamManual(this.data.url);
      } else if (this.data.serialNumber) {
        this.startStream(this.data.serialNumber);
      } else {
      }

      // this.initPlayer();
    } else {
      // This browser does not have the minimum set of APIs we need.
      console.error("Browser not supported!");
    }

    //this.divView.nativeElement.innerHTML = "Hello Angular 10!";
    // this.playC3vlarLiveService.startStream(123);
    // $("button").click(() => {
    // alert("Wass up!" + environment.Kinesisoptions.sessionToken);

    //var kinesisVideo = new AWS.KinesisVideo(environment.Kinesisoptions);

    // });
   //Birhanu console.log(this.shakaPlayerRef.nativeElement);
  }
  private initPlayer() {
    // Create a Player instance.
    // var video = document.getElementById('video');
    const player = new shaka.Player(this.videoElement);
    /*  const ui = new shaka.Player.ui.Overlay(
      player,
      this.videoContainerElement,
      this.videoElement
    ); */

    // Attach player to the window to make it easy to access in the JS console.
    // window.player = player;

    // Listen for error events.
    player.addEventListener("error", this.onErrorEvent);

    // Try to load a manifest.
    // This is an asynchronous process.
    player
      .load(this.manifestUri)
      .then(() => {
        // This runs if the asynchronous load is successful.
        console.log("The video has now been loaded!");
       (<HTMLVideoElement> this.videoElement).play();
      })
      .catch(this.onError); // onError is executed if the asynchronous load fails.
  }
  private onErrorEvent(event:any) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  private onError(error:any) {
    // Log the error.
    console.error("Error code", error.code, "object", error);
  }
  startStreamManual(url:any) {
    console.log("bieh", url);
    const player = new shaka.Player(this.videoElement);

    player
      .load(url)
      .then(() => {
        // This runs if the asynchronous load is successful.
        console.log("The video has now been loaded!");
       (<HTMLVideoElement>this.videoElement).play();
      })
      .catch(this.onError); //
  }
  //accept serialNumbe of the c3vlar as streamName
  startStream(streamName:any) {
    this.streamName = streamName;
    // Step 2: Get a data endpoint for the stream
    console.log("Fetching data endpoint");
    ;
    let that = this;
    this.kinesisVideo.getDataEndpoint(
      {
        StreamName: streamName,
        APIName: "GET_HLS_STREAMING_SESSION_URL",
      },
      (err:any, response:any) => {
        if (err) {
          return console.error(err);
        }
        console.log(
          "Live Data endpoint: ",
          response,
          response.DataEndpoint,
          that.kinesisVideoArchivedContent
        );
        that.kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(
          response.DataEndpoint
        );

        // Step 3: Get a Streaming Session URL
        that.kinesisVideoArchivedContent.getHLSStreamingSessionURL(
          {
            StreamName: streamName,

            HLSFragmentSelector: {
              FragmentSelectorType: "SERVER_TIMESTAMP",
            },
          },
          (err:any, response:any) => {
            if (err) {
              return console.error("HLS Streaming Session error", err);
            }
            console.log(
              "Live HLS Streaming Session URL: " +
                response.HLSStreamingSessionURL
            );

            // Step 4: Give the URL to the video player.

            //  var playerElement = $("#shaka");
            // console.log("That shaka", that.shaka.nativeElement.show());
            // that.shaka.show();
            // playerElement.show();
            const player = new shaka.Player(that.videoElement);
            console.log("brie 2", response.HLSStreamingSessionURL);

            player
              .load(response.HLSStreamingSessionURL)
              .then(() => {
                // This runs if the asynchronous load is successful.
                console.log("The video has now been loaded!");
              (<HTMLVideoElement>this.videoElement).play();
              })
              .catch(this.onError); // onError is executed if the asynchronous load fails.
          }
        );
      }
    );
  }
  cancel() {
    console.log("cancel"  )
    // Implement the cancel logic, if needed
    this.dialogRef.close();
  }
}
