import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
//import {} from "@types/googlemaps";
import { FormsModule } from "@angular/forms";
@Component({
  selector: "app-place-auto-complete",
  templateUrl: "./place-auto-complete.component.html",
  styleUrls: ["./place-auto-complete.component.scss"],
  standalone: true,
  imports:[FormsModule],
})
export class PlaceAutoCompleteComponent implements OnInit, AfterViewInit {
  @Input() adressType: string="";
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild("addresstext") addresstext: any;

  autocompleteInput: string="";
  queryWait: boolean=false;
  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }
  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country: "US" },
        types: [this.adressType], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
     
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
