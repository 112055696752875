{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-15",
    "endpointPrefix": "ec2",
    "protocol": "ec2",
    "protocols": [
      "ec2"
    ],
    "serviceAbbreviation": "Amazon EC2",
    "serviceFullName": "Amazon Elastic Compute Cloud",
    "serviceId": "EC2",
    "signatureVersion": "v4",
    "uid": "ec2-2016-11-15",
    "xmlNamespace": "http://ec2.amazonaws.com/doc/2016-11-15",
    "auth": [
      "aws.auth#sigv4"
    ]
  },
  "operations": {
    "AcceptAddressTransfer": {
      "input": {
        "type": "structure",
        "required": [
          "Address"
        ],
        "members": {
          "Address": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressTransfer": {
            "shape": "Sa",
            "locationName": "addressTransfer"
          }
        }
      }
    },
    "AcceptReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "Se",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "Sg",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExchangeId": {
            "locationName": "exchangeId"
          }
        }
      }
    },
    "AcceptTransitGatewayMulticastDomainAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "TransitGatewayAttachmentId": {},
          "SubnetIds": {
            "shape": "So"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "shape": "Sq",
            "locationName": "associations"
          }
        }
      }
    },
    "AcceptTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Sx",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "AcceptTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "S16",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "AcceptVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointIds": {
            "shape": "S1e",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "AcceptVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "S1n",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "AdvertiseByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "Asn": {},
          "DryRun": {
            "type": "boolean"
          },
          "NetworkBorderGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1y",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "AllocateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "Domain": {},
          "Address": {},
          "PublicIpv4Pool": {},
          "NetworkBorderGroup": {},
          "CustomerOwnedIpv4Pool": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "AllocationId": {
            "locationName": "allocationId"
          },
          "PublicIpv4Pool": {
            "locationName": "publicIpv4Pool"
          },
          "NetworkBorderGroup": {
            "locationName": "networkBorderGroup"
          },
          "Domain": {
            "locationName": "domain"
          },
          "CustomerOwnedIp": {
            "locationName": "customerOwnedIp"
          },
          "CustomerOwnedIpv4Pool": {
            "locationName": "customerOwnedIpv4Pool"
          },
          "CarrierIp": {
            "locationName": "carrierIp"
          }
        }
      }
    },
    "AllocateHosts": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "InstanceFamily": {},
          "Quantity": {
            "locationName": "quantity",
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "HostRecovery": {},
          "OutpostArn": {},
          "HostMaintenance": {},
          "AssetIds": {
            "locationName": "AssetId",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostIds": {
            "shape": "S2f",
            "locationName": "hostIdSet"
          }
        }
      }
    },
    "AllocateIpamPoolCidr": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Cidr": {},
          "NetmaskLength": {
            "type": "integer"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "PreviewNextCidr": {
            "type": "boolean"
          },
          "AllowedCidrs": {
            "locationName": "AllowedCidr",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DisallowedCidrs": {
            "locationName": "DisallowedCidr",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPoolAllocation": {
            "shape": "S2l",
            "locationName": "ipamPoolAllocation"
          }
        }
      }
    },
    "ApplySecurityGroupsToClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "VpcId",
          "SecurityGroupIds"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "VpcId": {},
          "SecurityGroupIds": {
            "shape": "S2r",
            "locationName": "SecurityGroupId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupIds": {
            "shape": "S2r",
            "locationName": "securityGroupIds"
          }
        }
      }
    },
    "AssignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S2v",
            "locationName": "ipv6Addresses"
          },
          "Ipv6PrefixCount": {
            "type": "integer"
          },
          "Ipv6Prefixes": {
            "shape": "S2w",
            "locationName": "Ipv6Prefix"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignedIpv6Addresses": {
            "shape": "S2v",
            "locationName": "assignedIpv6Addresses"
          },
          "AssignedIpv6Prefixes": {
            "shape": "S2w",
            "locationName": "assignedIpv6PrefixSet"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "AssignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "AllowReassignment": {
            "locationName": "allowReassignment",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "S30",
            "locationName": "privateIpAddress"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "Ipv4Prefixes": {
            "shape": "S2w",
            "locationName": "Ipv4Prefix"
          },
          "Ipv4PrefixCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "AssignedPrivateIpAddresses": {
            "locationName": "assignedPrivateIpAddressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                }
              }
            }
          },
          "AssignedIpv4Prefixes": {
            "shape": "S34",
            "locationName": "assignedIpv4PrefixSet"
          }
        }
      }
    },
    "AssignPrivateNatGatewayAddress": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId"
        ],
        "members": {
          "NatGatewayId": {},
          "PrivateIpAddresses": {
            "shape": "S38",
            "locationName": "PrivateIpAddress"
          },
          "PrivateIpAddressCount": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "NatGatewayAddresses": {
            "shape": "S3b",
            "locationName": "natGatewayAddressSet"
          }
        }
      }
    },
    "AssociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationId": {},
          "InstanceId": {},
          "PublicIp": {},
          "AllowReassociation": {
            "locationName": "allowReassociation",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "AssociateClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "SubnetId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "SubnetId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "Status": {
            "shape": "S3m",
            "locationName": "status"
          }
        }
      }
    },
    "AssociateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId",
          "VpcId"
        ],
        "members": {
          "DhcpOptionsId": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "AssociateEnclaveCertificateIamRole": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "RoleArn"
        ],
        "members": {
          "CertificateArn": {},
          "RoleArn": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateS3BucketName": {
            "locationName": "certificateS3BucketName"
          },
          "CertificateS3ObjectKey": {
            "locationName": "certificateS3ObjectKey"
          },
          "EncryptionKmsKeyId": {
            "locationName": "encryptionKmsKeyId"
          }
        }
      }
    },
    "AssociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "InstanceId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S3v"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S3x",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "AssociateInstanceEventWindow": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceEventWindowId",
          "AssociationTarget"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceEventWindowId": {},
          "AssociationTarget": {
            "type": "structure",
            "members": {
              "InstanceIds": {
                "shape": "S43",
                "locationName": "InstanceId"
              },
              "InstanceTags": {
                "shape": "S6",
                "locationName": "InstanceTag"
              },
              "DedicatedHostIds": {
                "shape": "S44",
                "locationName": "DedicatedHostId"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceEventWindow": {
            "shape": "S47",
            "locationName": "instanceEventWindow"
          }
        }
      }
    },
    "AssociateIpamByoasn": {
      "input": {
        "type": "structure",
        "required": [
          "Asn",
          "Cidr"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Asn": {},
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AsnAssociation": {
            "shape": "S20",
            "locationName": "asnAssociation"
          }
        }
      }
    },
    "AssociateIpamResourceDiscovery": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId",
          "IpamResourceDiscoveryId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "IpamResourceDiscoveryId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscoveryAssociation": {
            "shape": "S4l",
            "locationName": "ipamResourceDiscoveryAssociation"
          }
        }
      }
    },
    "AssociateNatGatewayAddress": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId",
          "AllocationIds"
        ],
        "members": {
          "NatGatewayId": {},
          "AllocationIds": {
            "shape": "S4r",
            "locationName": "AllocationId"
          },
          "PrivateIpAddresses": {
            "shape": "S38",
            "locationName": "PrivateIpAddress"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "NatGatewayAddresses": {
            "shape": "S3b",
            "locationName": "natGatewayAddressSet"
          }
        }
      }
    },
    "AssociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "GatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "AssociationState": {
            "shape": "S4x",
            "locationName": "associationState"
          }
        }
      }
    },
    "AssociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "Ipv6IpamPoolId": {},
          "Ipv6NetmaskLength": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S52",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "AssociateTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId",
          "TransitGatewayAttachmentId",
          "SubnetIds"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "TransitGatewayAttachmentId": {},
          "SubnetIds": {
            "shape": "S59"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "shape": "Sq",
            "locationName": "associations"
          }
        }
      }
    },
    "AssociateTransitGatewayPolicyTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayPolicyTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayPolicyTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S5e",
            "locationName": "association"
          }
        }
      }
    },
    "AssociateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S5j",
            "locationName": "association"
          }
        }
      }
    },
    "AssociateTrunkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "BranchInterfaceId",
          "TrunkInterfaceId"
        ],
        "members": {
          "BranchInterfaceId": {},
          "TrunkInterfaceId": {},
          "VlanId": {
            "type": "integer"
          },
          "GreKey": {
            "type": "integer"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InterfaceAssociation": {
            "shape": "S5m",
            "locationName": "interfaceAssociation"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "AssociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          },
          "CidrBlock": {},
          "VpcId": {
            "locationName": "vpcId"
          },
          "Ipv6CidrBlockNetworkBorderGroup": {},
          "Ipv6Pool": {},
          "Ipv6CidrBlock": {},
          "Ipv4IpamPoolId": {},
          "Ipv4NetmaskLength": {
            "type": "integer"
          },
          "Ipv6IpamPoolId": {},
          "Ipv6NetmaskLength": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S5s",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "CidrBlockAssociation": {
            "shape": "S5v",
            "locationName": "cidrBlockAssociation"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "Groups",
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S5x",
            "locationName": "SecurityGroupId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "AttachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceIndex",
          "InstanceId",
          "NetworkInterfaceId"
        ],
        "members": {
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "NetworkCardIndex": {
            "type": "integer"
          },
          "EnaSrdSpecification": {
            "shape": "S62"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          },
          "NetworkCardIndex": {
            "locationName": "networkCardIndex",
            "type": "integer"
          }
        }
      }
    },
    "AttachVerifiedAccessTrustProvider": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessInstanceId",
          "VerifiedAccessTrustProviderId"
        ],
        "members": {
          "VerifiedAccessInstanceId": {},
          "VerifiedAccessTrustProviderId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProvider": {
            "shape": "S69",
            "locationName": "verifiedAccessTrustProvider"
          },
          "VerifiedAccessInstance": {
            "shape": "S6i",
            "locationName": "verifiedAccessInstance"
          }
        }
      }
    },
    "AttachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "Device",
          "InstanceId",
          "VolumeId"
        ],
        "members": {
          "Device": {},
          "InstanceId": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S6n"
      }
    },
    "AttachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "VpnGatewayId"
        ],
        "members": {
          "VpcId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcAttachment": {
            "shape": "S6s",
            "locationName": "attachment"
          }
        }
      }
    },
    "AuthorizeClientVpnIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "TargetNetworkCidr"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetNetworkCidr": {},
          "AccessGroupId": {},
          "AuthorizeAllGroups": {
            "type": "boolean"
          },
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S6w",
            "locationName": "status"
          }
        }
      }
    },
    "AuthorizeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "IpPermissions": {
            "shape": "S6z",
            "locationName": "ipPermissions"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          },
          "SecurityGroupRules": {
            "shape": "S7a",
            "locationName": "securityGroupRuleSet"
          }
        }
      }
    },
    "AuthorizeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "CidrIp": {},
          "FromPort": {
            "type": "integer"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S6z"
          },
          "IpProtocol": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "ToPort": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          },
          "SecurityGroupRules": {
            "shape": "S7a",
            "locationName": "securityGroupRuleSet"
          }
        }
      }
    },
    "BundleInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Storage"
        ],
        "members": {
          "InstanceId": {},
          "Storage": {
            "shape": "S7j"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S7o",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelBundleTask": {
      "input": {
        "type": "structure",
        "required": [
          "BundleId"
        ],
        "members": {
          "BundleId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S7o",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CancelCapacityReservationFleets": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationFleetIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "CapacityReservationFleetIds": {
            "shape": "S7y",
            "locationName": "CapacityReservationFleetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetCancellations": {
            "locationName": "successfulFleetCancellationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentFleetState": {
                  "locationName": "currentFleetState"
                },
                "PreviousFleetState": {
                  "locationName": "previousFleetState"
                },
                "CapacityReservationFleetId": {
                  "locationName": "capacityReservationFleetId"
                }
              }
            }
          },
          "FailedFleetCancellations": {
            "locationName": "failedFleetCancellationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CapacityReservationFleetId": {
                  "locationName": "capacityReservationFleetId"
                },
                "CancelCapacityReservationFleetError": {
                  "locationName": "cancelCapacityReservationFleetError",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "CancelConversionTask": {
      "input": {
        "type": "structure",
        "required": [
          "ConversionTaskId"
        ],
        "members": {
          "ConversionTaskId": {
            "locationName": "conversionTaskId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ReasonMessage": {
            "locationName": "reasonMessage"
          }
        }
      }
    },
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportTaskId"
        ],
        "members": {
          "ExportTaskId": {
            "locationName": "exportTaskId"
          }
        }
      }
    },
    "CancelImageLaunchPermission": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CancelImportTask": {
      "input": {
        "type": "structure",
        "members": {
          "CancelReason": {},
          "DryRun": {
            "type": "boolean"
          },
          "ImportTaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "PreviousState": {
            "locationName": "previousState"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "CancelReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesListingId"
        ],
        "members": {
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S8m",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CancelSpotFleetRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestIds": {
            "shape": "S8y",
            "locationName": "spotFleetRequestId"
          },
          "TerminateInstances": {
            "locationName": "terminateInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetRequests": {
            "locationName": "successfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentSpotFleetRequestState": {
                  "locationName": "currentSpotFleetRequestState"
                },
                "PreviousSpotFleetRequestState": {
                  "locationName": "previousSpotFleetRequestState"
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                }
              }
            }
          },
          "UnsuccessfulFleetRequests": {
            "locationName": "unsuccessfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                }
              }
            }
          }
        }
      }
    },
    "CancelSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotInstanceRequestIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S99",
            "locationName": "SpotInstanceRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelledSpotInstanceRequests": {
            "locationName": "spotInstanceRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SpotInstanceRequestId": {
                  "locationName": "spotInstanceRequestId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          }
        }
      }
    },
    "ConfirmProductInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ProductCode"
        ],
        "members": {
          "InstanceId": {},
          "ProductCode": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OwnerId": {
            "locationName": "ownerId"
          },
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CopyFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "SourceFpgaImageId",
          "SourceRegion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "SourceFpgaImageId": {},
          "Description": {},
          "Name": {},
          "SourceRegion": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageId": {
            "locationName": "fpgaImageId"
          }
        }
      }
    },
    "CopyImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SourceImageId",
          "SourceRegion"
        ],
        "members": {
          "ClientToken": {},
          "Description": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "Name": {},
          "SourceImageId": {},
          "SourceRegion": {},
          "DestinationOutpostArn": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "CopyImageTags": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceRegion",
          "SourceSnapshotId"
        ],
        "members": {
          "Description": {},
          "DestinationOutpostArn": {},
          "DestinationRegion": {
            "locationName": "destinationRegion"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "PresignedUrl": {
            "locationName": "presignedUrl",
            "type": "string",
            "sensitive": true
          },
          "SourceRegion": {},
          "SourceSnapshotId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "CreateCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "InstancePlatform",
          "InstanceCount"
        ],
        "members": {
          "ClientToken": {},
          "InstanceType": {},
          "InstancePlatform": {},
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "Tenancy": {},
          "InstanceCount": {
            "type": "integer"
          },
          "EbsOptimized": {
            "type": "boolean"
          },
          "EphemeralStorage": {
            "type": "boolean"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "EndDateType": {},
          "InstanceMatchCriteria": {},
          "TagSpecifications": {
            "shape": "S3"
          },
          "DryRun": {
            "type": "boolean"
          },
          "OutpostArn": {},
          "PlacementGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityReservation": {
            "shape": "S9z",
            "locationName": "capacityReservation"
          }
        }
      }
    },
    "CreateCapacityReservationBySplitting": {
      "input": {
        "type": "structure",
        "required": [
          "SourceCapacityReservationId",
          "InstanceCount"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "SourceCapacityReservationId": {},
          "InstanceCount": {
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceCapacityReservation": {
            "shape": "S9z",
            "locationName": "sourceCapacityReservation"
          },
          "DestinationCapacityReservation": {
            "shape": "S9z",
            "locationName": "destinationCapacityReservation"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          }
        }
      }
    },
    "CreateCapacityReservationFleet": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceTypeSpecifications",
          "TotalTargetCapacity"
        ],
        "members": {
          "AllocationStrategy": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "InstanceTypeSpecifications": {
            "locationName": "InstanceTypeSpecification",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceType": {},
                "InstancePlatform": {},
                "Weight": {
                  "type": "double"
                },
                "AvailabilityZone": {},
                "AvailabilityZoneId": {},
                "EbsOptimized": {
                  "type": "boolean"
                },
                "Priority": {
                  "type": "integer"
                }
              }
            }
          },
          "Tenancy": {},
          "TotalTargetCapacity": {
            "type": "integer"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "InstanceMatchCriteria": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityReservationFleetId": {
            "locationName": "capacityReservationFleetId"
          },
          "State": {
            "locationName": "state"
          },
          "TotalTargetCapacity": {
            "locationName": "totalTargetCapacity",
            "type": "integer"
          },
          "TotalFulfilledCapacity": {
            "locationName": "totalFulfilledCapacity",
            "type": "double"
          },
          "InstanceMatchCriteria": {
            "locationName": "instanceMatchCriteria"
          },
          "AllocationStrategy": {
            "locationName": "allocationStrategy"
          },
          "CreateTime": {
            "locationName": "createTime",
            "type": "timestamp"
          },
          "EndDate": {
            "locationName": "endDate",
            "type": "timestamp"
          },
          "Tenancy": {
            "locationName": "tenancy"
          },
          "FleetCapacityReservations": {
            "shape": "Sag",
            "locationName": "fleetCapacityReservationSet"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "CreateCarrierGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CarrierGateway": {
            "shape": "Sak",
            "locationName": "carrierGateway"
          }
        }
      }
    },
    "CreateClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientCidrBlock",
          "ServerCertificateArn",
          "AuthenticationOptions",
          "ConnectionLogOptions"
        ],
        "members": {
          "ClientCidrBlock": {},
          "ServerCertificateArn": {},
          "AuthenticationOptions": {
            "locationName": "Authentication",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ActiveDirectory": {
                  "type": "structure",
                  "members": {
                    "DirectoryId": {}
                  }
                },
                "MutualAuthentication": {
                  "type": "structure",
                  "members": {
                    "ClientRootCertificateChainArn": {}
                  }
                },
                "FederatedAuthentication": {
                  "type": "structure",
                  "members": {
                    "SAMLProviderArn": {},
                    "SelfServiceSAMLProviderArn": {}
                  }
                }
              }
            }
          },
          "ConnectionLogOptions": {
            "shape": "Sau"
          },
          "DnsServers": {
            "shape": "So"
          },
          "TransportProtocol": {},
          "VpnPort": {
            "type": "integer"
          },
          "Description": {},
          "SplitTunnel": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "SecurityGroupIds": {
            "shape": "S2r",
            "locationName": "SecurityGroupId"
          },
          "VpcId": {},
          "SelfServicePortal": {},
          "ClientConnectOptions": {
            "shape": "Sax"
          },
          "SessionTimeoutHours": {
            "type": "integer"
          },
          "ClientLoginBannerOptions": {
            "shape": "Say"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointId": {
            "locationName": "clientVpnEndpointId"
          },
          "Status": {
            "shape": "Sb0",
            "locationName": "status"
          },
          "DnsName": {
            "locationName": "dnsName"
          }
        }
      }
    },
    "CreateClientVpnRoute": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "DestinationCidrBlock",
          "TargetVpcSubnetId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DestinationCidrBlock": {},
          "TargetVpcSubnetId": {},
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "Sb4",
            "locationName": "status"
          }
        }
      }
    },
    "CreateCoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr",
          "CoipPoolId"
        ],
        "members": {
          "Cidr": {},
          "CoipPoolId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipCidr": {
            "shape": "Sb9",
            "locationName": "coipCidr"
          }
        }
      }
    },
    "CreateCoipPool": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipPool": {
            "shape": "Sbd",
            "locationName": "coipPool"
          }
        }
      }
    },
    "CreateCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "BgpAsn": {
            "type": "integer"
          },
          "PublicIp": {},
          "CertificateArn": {},
          "Type": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DeviceName": {},
          "IpAddress": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "BgpAsnExtended": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateway": {
            "shape": "Sbh",
            "locationName": "customerGateway"
          }
        }
      }
    },
    "CreateDefaultSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AvailabilityZone": {},
          "DryRun": {
            "type": "boolean"
          },
          "Ipv6Native": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "Sbk",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateDefaultVpc": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "Sbs",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpConfigurations"
        ],
        "members": {
          "DhcpConfigurations": {
            "locationName": "dhcpConfiguration",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {},
                "Values": {
                  "shape": "So",
                  "locationName": "Value"
                }
              }
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "shape": "Sc1",
            "locationName": "dhcpOptions"
          }
        }
      }
    },
    "CreateEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "ClientToken": {},
          "DryRun": {
            "type": "boolean"
          },
          "VpcId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "EgressOnlyInternetGateway": {
            "shape": "Sc8",
            "locationName": "egressOnlyInternetGateway"
          }
        }
      }
    },
    "CreateFleet": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateConfigs",
          "TargetCapacitySpecification"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "SpotOptions": {
            "type": "structure",
            "members": {
              "AllocationStrategy": {},
              "MaintenanceStrategies": {
                "type": "structure",
                "members": {
                  "CapacityRebalance": {
                    "type": "structure",
                    "members": {
                      "ReplacementStrategy": {},
                      "TerminationDelay": {
                        "type": "integer"
                      }
                    }
                  }
                }
              },
              "InstanceInterruptionBehavior": {},
              "InstancePoolsToUseCount": {
                "type": "integer"
              },
              "SingleInstanceType": {
                "type": "boolean"
              },
              "SingleAvailabilityZone": {
                "type": "boolean"
              },
              "MinTargetCapacity": {
                "type": "integer"
              },
              "MaxTotalPrice": {}
            }
          },
          "OnDemandOptions": {
            "type": "structure",
            "members": {
              "AllocationStrategy": {},
              "CapacityReservationOptions": {
                "type": "structure",
                "members": {
                  "UsageStrategy": {}
                }
              },
              "SingleInstanceType": {
                "type": "boolean"
              },
              "SingleAvailabilityZone": {
                "type": "boolean"
              },
              "MinTargetCapacity": {
                "type": "integer"
              },
              "MaxTotalPrice": {}
            }
          },
          "ExcessCapacityTerminationPolicy": {},
          "LaunchTemplateConfigs": {
            "shape": "Sco"
          },
          "TargetCapacitySpecification": {
            "shape": "Sdr"
          },
          "TerminateInstancesWithExpiration": {
            "type": "boolean"
          },
          "Type": {},
          "ValidFrom": {
            "type": "timestamp"
          },
          "ValidUntil": {
            "type": "timestamp"
          },
          "ReplaceUnhealthyInstances": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "Context": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {
            "locationName": "fleetId"
          },
          "Errors": {
            "locationName": "errorSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateAndOverrides": {
                  "shape": "Sdz",
                  "locationName": "launchTemplateAndOverrides"
                },
                "Lifecycle": {
                  "locationName": "lifecycle"
                },
                "ErrorCode": {
                  "locationName": "errorCode"
                },
                "ErrorMessage": {
                  "locationName": "errorMessage"
                }
              }
            }
          },
          "Instances": {
            "locationName": "fleetInstanceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateAndOverrides": {
                  "shape": "Sdz",
                  "locationName": "launchTemplateAndOverrides"
                },
                "Lifecycle": {
                  "locationName": "lifecycle"
                },
                "InstanceIds": {
                  "shape": "Seg",
                  "locationName": "instanceIds"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "Platform": {
                  "locationName": "platform"
                }
              }
            }
          }
        }
      }
    },
    "CreateFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIds",
          "ResourceType"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "DeliverLogsPermissionArn": {},
          "DeliverCrossAccountRole": {},
          "LogGroupName": {},
          "ResourceIds": {
            "locationName": "ResourceId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "ResourceType": {},
          "TrafficType": {},
          "LogDestinationType": {},
          "LogDestination": {},
          "LogFormat": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "MaxAggregationInterval": {
            "type": "integer"
          },
          "DestinationOptions": {
            "type": "structure",
            "members": {
              "FileFormat": {},
              "HiveCompatiblePartitions": {
                "type": "boolean"
              },
              "PerHourPartition": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "FlowLogIds": {
            "shape": "So",
            "locationName": "flowLogIdSet"
          },
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "CreateFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "InputStorageLocation"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InputStorageLocation": {
            "shape": "Ses"
          },
          "LogsStorageLocation": {
            "shape": "Ses"
          },
          "Description": {},
          "Name": {},
          "ClientToken": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageId": {
            "locationName": "fpgaImageId"
          },
          "FpgaImageGlobalId": {
            "locationName": "fpgaImageGlobalId"
          }
        }
      }
    },
    "CreateImage": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "BlockDeviceMappings": {
            "shape": "Sev",
            "locationName": "blockDeviceMapping"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Name": {
            "locationName": "name"
          },
          "NoReboot": {
            "locationName": "noReboot",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CreateInstanceConnectEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "SubnetId": {},
          "SecurityGroupIds": {
            "locationName": "SecurityGroupId",
            "type": "list",
            "member": {
              "locationName": "SecurityGroupId"
            }
          },
          "PreserveClientIp": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceConnectEndpoint": {
            "shape": "Sf4",
            "locationName": "instanceConnectEndpoint"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateInstanceEventWindow": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Name": {},
          "TimeRanges": {
            "shape": "Sfa",
            "locationName": "TimeRange"
          },
          "CronExpression": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceEventWindow": {
            "shape": "S47",
            "locationName": "instanceEventWindow"
          }
        }
      }
    },
    "CreateInstanceExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportToS3Task",
          "InstanceId",
          "TargetEnvironment"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "ExportToS3Task": {
            "locationName": "exportToS3",
            "type": "structure",
            "members": {
              "ContainerFormat": {
                "locationName": "containerFormat"
              },
              "DiskImageFormat": {
                "locationName": "diskImageFormat"
              },
              "S3Bucket": {
                "locationName": "s3Bucket"
              },
              "S3Prefix": {
                "locationName": "s3Prefix"
              }
            }
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "TargetEnvironment": {
            "locationName": "targetEnvironment"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTask": {
            "shape": "Sfj",
            "locationName": "exportTask"
          }
        }
      }
    },
    "CreateInternetGateway": {
      "input": {
        "type": "structure",
        "members": {
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateway": {
            "shape": "Sfp",
            "locationName": "internetGateway"
          }
        }
      }
    },
    "CreateIpam": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Description": {},
          "OperatingRegions": {
            "shape": "Sfr",
            "locationName": "OperatingRegion"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tier": {},
          "EnablePrivateGua": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipam": {
            "shape": "Sfv",
            "locationName": "ipam"
          }
        }
      }
    },
    "CreateIpamExternalResourceVerificationToken": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamExternalResourceVerificationToken": {
            "shape": "Sg2",
            "locationName": "ipamExternalResourceVerificationToken"
          }
        }
      }
    },
    "CreateIpamPool": {
      "input": {
        "type": "structure",
        "required": [
          "IpamScopeId",
          "AddressFamily"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamScopeId": {},
          "Locale": {},
          "SourceIpamPoolId": {},
          "Description": {},
          "AddressFamily": {},
          "AutoImport": {
            "type": "boolean"
          },
          "PubliclyAdvertisable": {
            "type": "boolean"
          },
          "AllocationMinNetmaskLength": {
            "type": "integer"
          },
          "AllocationMaxNetmaskLength": {
            "type": "integer"
          },
          "AllocationDefaultNetmaskLength": {
            "type": "integer"
          },
          "AllocationResourceTags": {
            "shape": "Sg9",
            "locationName": "AllocationResourceTag"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "AwsService": {},
          "PublicIpSource": {},
          "SourceResource": {
            "type": "structure",
            "members": {
              "ResourceId": {},
              "ResourceType": {},
              "ResourceRegion": {},
              "ResourceOwner": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPool": {
            "shape": "Sgg",
            "locationName": "ipamPool"
          }
        }
      }
    },
    "CreateIpamResourceDiscovery": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Description": {},
          "OperatingRegions": {
            "shape": "Sfr",
            "locationName": "OperatingRegion"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscovery": {
            "shape": "Sgo",
            "locationName": "ipamResourceDiscovery"
          }
        }
      }
    },
    "CreateIpamScope": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "Description": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamScope": {
            "shape": "Sgs",
            "locationName": "ipamScope"
          }
        }
      }
    },
    "CreateKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName"
        ],
        "members": {
          "KeyName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyType": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "KeyFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyMaterial": {
            "shape": "Sgy",
            "locationName": "keyMaterial"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "KeyPairId": {
            "locationName": "keyPairId"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "CreateLaunchTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateName",
          "LaunchTemplateData"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateName": {},
          "VersionDescription": {},
          "LaunchTemplateData": {
            "shape": "Sh1"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "Sim",
            "locationName": "launchTemplate"
          },
          "Warning": {
            "shape": "Sin",
            "locationName": "warning"
          }
        }
      }
    },
    "CreateLaunchTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateData"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "SourceVersion": {},
          "VersionDescription": {},
          "LaunchTemplateData": {
            "shape": "Sh1"
          },
          "ResolveAlias": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateVersion": {
            "shape": "Sis",
            "locationName": "launchTemplateVersion"
          },
          "Warning": {
            "shape": "Sin",
            "locationName": "warning"
          }
        }
      }
    },
    "CreateLocalGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "LocalGatewayRouteTableId": {},
          "LocalGatewayVirtualInterfaceGroupId": {},
          "DryRun": {
            "type": "boolean"
          },
          "NetworkInterfaceId": {},
          "DestinationPrefixListId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sjy",
            "locationName": "route"
          }
        }
      }
    },
    "CreateLocalGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayId"
        ],
        "members": {
          "LocalGatewayId": {},
          "Mode": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTable": {
            "shape": "Sk5",
            "locationName": "localGatewayRouteTable"
          }
        }
      }
    },
    "CreateLocalGatewayRouteTableVirtualInterfaceGroupAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId",
          "LocalGatewayVirtualInterfaceGroupId"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "LocalGatewayVirtualInterfaceGroupId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociation": {
            "shape": "Sk9",
            "locationName": "localGatewayRouteTableVirtualInterfaceGroupAssociation"
          }
        }
      }
    },
    "CreateLocalGatewayRouteTableVpcAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId",
          "VpcId"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "VpcId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociation": {
            "shape": "Skd",
            "locationName": "localGatewayRouteTableVpcAssociation"
          }
        }
      }
    },
    "CreateManagedPrefixList": {
      "input": {
        "type": "structure",
        "required": [
          "PrefixListName",
          "MaxEntries",
          "AddressFamily"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListName": {},
          "Entries": {
            "shape": "Skg",
            "locationName": "Entry"
          },
          "MaxEntries": {
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "AddressFamily": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrefixList": {
            "shape": "Skj",
            "locationName": "prefixList"
          }
        }
      }
    },
    "CreateNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "AllocationId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SubnetId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ConnectivityType": {},
          "PrivateIpAddress": {},
          "SecondaryAllocationIds": {
            "shape": "S4r",
            "locationName": "SecondaryAllocationId"
          },
          "SecondaryPrivateIpAddresses": {
            "shape": "S38",
            "locationName": "SecondaryPrivateIpAddress"
          },
          "SecondaryPrivateIpAddressCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "NatGateway": {
            "shape": "Sko",
            "locationName": "natGateway"
          }
        }
      }
    },
    "CreateNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcl": {
            "shape": "Skt",
            "locationName": "networkAcl"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "Protocol",
          "RuleAction",
          "RuleNumber"
        ],
        "members": {
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "IcmpTypeCode": {
            "shape": "Sky",
            "locationName": "Icmp"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "PortRange": {
            "shape": "Skz",
            "locationName": "portRange"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "CreateNetworkInsightsAccessScope": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken"
        ],
        "members": {
          "MatchPaths": {
            "shape": "Sl4",
            "locationName": "MatchPath"
          },
          "ExcludePaths": {
            "shape": "Sl4",
            "locationName": "ExcludePath"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScope": {
            "shape": "Sle",
            "locationName": "networkInsightsAccessScope"
          },
          "NetworkInsightsAccessScopeContent": {
            "shape": "Slg",
            "locationName": "networkInsightsAccessScopeContent"
          }
        }
      }
    },
    "CreateNetworkInsightsPath": {
      "input": {
        "type": "structure",
        "required": [
          "Source",
          "Protocol",
          "ClientToken"
        ],
        "members": {
          "SourceIp": {},
          "DestinationIp": {},
          "Source": {},
          "Destination": {},
          "Protocol": {},
          "DestinationPort": {
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "FilterAtSource": {
            "shape": "Sls"
          },
          "FilterAtDestination": {
            "shape": "Sls"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsPath": {
            "shape": "Slv",
            "locationName": "networkInsightsPath"
          }
        }
      }
    },
    "CreateNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "Sh9",
            "locationName": "SecurityGroupId"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "Sj0",
            "locationName": "ipv6Addresses"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "PrivateIpAddresses": {
            "shape": "Shc",
            "locationName": "privateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "Ipv4Prefixes": {
            "shape": "She",
            "locationName": "Ipv4Prefix"
          },
          "Ipv4PrefixCount": {
            "type": "integer"
          },
          "Ipv6Prefixes": {
            "shape": "Shg",
            "locationName": "Ipv6Prefix"
          },
          "Ipv6PrefixCount": {
            "type": "integer"
          },
          "InterfaceType": {},
          "SubnetId": {
            "locationName": "subnetId"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "EnablePrimaryIpv6": {
            "type": "boolean"
          },
          "ConnectionTrackingSpecification": {
            "shape": "Shk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterface": {
            "shape": "Sm2",
            "locationName": "networkInterface"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateNetworkInterfacePermission": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "Permission"
        ],
        "members": {
          "NetworkInterfaceId": {},
          "AwsAccountId": {},
          "AwsService": {},
          "Permission": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InterfacePermission": {
            "shape": "Sml",
            "locationName": "interfacePermission"
          }
        }
      }
    },
    "CreatePlacementGroup": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          },
          "Strategy": {
            "locationName": "strategy"
          },
          "PartitionCount": {
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "SpreadLevel": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlacementGroup": {
            "shape": "Sms",
            "locationName": "placementGroup"
          }
        }
      }
    },
    "CreatePublicIpv4Pool": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "NetworkBorderGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PoolId": {
            "locationName": "poolId"
          }
        }
      }
    },
    "CreateReplaceRootVolumeTask": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "SnapshotId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ImageId": {},
          "DeleteReplacedRootVolume": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplaceRootVolumeTask": {
            "shape": "Smy",
            "locationName": "replaceRootVolumeTask"
          }
        }
      }
    },
    "CreateReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "InstanceCount",
          "PriceSchedules",
          "ReservedInstancesId"
        ],
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "Term": {
                  "locationName": "term",
                  "type": "long"
                }
              }
            }
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S8m",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CreateRestoreImageTask": {
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "ObjectKey"
        ],
        "members": {
          "Bucket": {},
          "ObjectKey": {},
          "Name": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CreateRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DestinationPrefixListId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcEndpointId": {},
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "TransitGatewayId": {},
          "LocalGatewayId": {},
          "CarrierGatewayId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          },
          "CoreNetworkArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CreateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTable": {
            "shape": "Sne",
            "locationName": "routeTable"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Description",
          "GroupName"
        ],
        "members": {
          "Description": {
            "locationName": "GroupDescription"
          },
          "GroupName": {},
          "VpcId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {
            "locationName": "groupId"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "Description": {},
          "OutpostArn": {},
          "VolumeId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Snq"
      }
    },
    "CreateSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceSpecification"
        ],
        "members": {
          "Description": {},
          "InstanceSpecification": {
            "type": "structure",
            "required": [
              "InstanceId"
            ],
            "members": {
              "InstanceId": {},
              "ExcludeBootVolume": {
                "type": "boolean"
              },
              "ExcludeDataVolumeIds": {
                "shape": "Snx",
                "locationName": "ExcludeDataVolumeId"
              }
            }
          },
          "OutpostArn": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "CopyTagsFromSource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "Encrypted": {
                  "locationName": "encrypted",
                  "type": "boolean"
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "State": {
                  "locationName": "state"
                },
                "VolumeSize": {
                  "locationName": "volumeSize",
                  "type": "integer"
                },
                "StartTime": {
                  "locationName": "startTime",
                  "type": "timestamp"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "SseType": {
                  "locationName": "sseType"
                }
              }
            }
          }
        }
      }
    },
    "CreateSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "locationName": "bucket"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Prefix": {
            "locationName": "prefix"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "So4",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "CreateStoreImageTask": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId",
          "Bucket"
        ],
        "members": {
          "ImageId": {},
          "Bucket": {},
          "S3ObjectTags": {
            "locationName": "S3ObjectTag",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ObjectKey": {
            "locationName": "objectKey"
          }
        }
      }
    },
    "CreateSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "CidrBlock": {},
          "Ipv6CidrBlock": {},
          "OutpostArn": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Ipv6Native": {
            "type": "boolean"
          },
          "Ipv4IpamPoolId": {},
          "Ipv4NetmaskLength": {
            "type": "integer"
          },
          "Ipv6IpamPoolId": {},
          "Ipv6NetmaskLength": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "Sbk",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateSubnetCidrReservation": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId",
          "Cidr",
          "ReservationType"
        ],
        "members": {
          "SubnetId": {},
          "Cidr": {},
          "ReservationType": {},
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SubnetCidrReservation": {
            "shape": "Sog",
            "locationName": "subnetCidrReservation"
          }
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources",
          "Tags"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "Soj",
            "locationName": "ResourceId"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "Tag"
          }
        }
      }
    },
    "CreateTrafficMirrorFilter": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilter": {
            "shape": "Son",
            "locationName": "trafficMirrorFilter"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId",
          "TrafficDirection",
          "RuleNumber",
          "RuleAction",
          "DestinationCidrBlock",
          "SourceCidrBlock"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "TrafficDirection": {},
          "RuleNumber": {
            "type": "integer"
          },
          "RuleAction": {},
          "DestinationPortRange": {
            "shape": "Sox"
          },
          "SourcePortRange": {
            "shape": "Sox"
          },
          "Protocol": {
            "type": "integer"
          },
          "DestinationCidrBlock": {},
          "SourceCidrBlock": {},
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRule": {
            "shape": "Sop",
            "locationName": "trafficMirrorFilterRule"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "TrafficMirrorTargetId",
          "TrafficMirrorFilterId",
          "SessionNumber"
        ],
        "members": {
          "NetworkInterfaceId": {},
          "TrafficMirrorTargetId": {},
          "TrafficMirrorFilterId": {},
          "PacketLength": {
            "type": "integer"
          },
          "SessionNumber": {
            "type": "integer"
          },
          "VirtualNetworkId": {
            "type": "integer"
          },
          "Description": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSession": {
            "shape": "Sp2",
            "locationName": "trafficMirrorSession"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorTarget": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {},
          "NetworkLoadBalancerArn": {},
          "Description": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "GatewayLoadBalancerEndpointId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTarget": {
            "shape": "Sp5",
            "locationName": "trafficMirrorTarget"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTransitGateway": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "Options": {
            "type": "structure",
            "members": {
              "AmazonSideAsn": {
                "type": "long"
              },
              "AutoAcceptSharedAttachments": {},
              "DefaultRouteTableAssociation": {},
              "DefaultRouteTablePropagation": {},
              "VpnEcmpSupport": {},
              "DnsSupport": {},
              "SecurityGroupReferencingSupport": {},
              "MulticastSupport": {},
              "TransitGatewayCidrBlocks": {
                "shape": "Spe"
              }
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Spg",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "CreateTransitGatewayConnect": {
      "input": {
        "type": "structure",
        "required": [
          "TransportTransitGatewayAttachmentId",
          "Options"
        ],
        "members": {
          "TransportTransitGatewayAttachmentId": {},
          "Options": {
            "type": "structure",
            "required": [
              "Protocol"
            ],
            "members": {
              "Protocol": {}
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnect": {
            "shape": "Spn",
            "locationName": "transitGatewayConnect"
          }
        }
      }
    },
    "CreateTransitGatewayConnectPeer": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId",
          "PeerAddress",
          "InsideCidrBlocks"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "TransitGatewayAddress": {},
          "PeerAddress": {},
          "BgpOptions": {
            "type": "structure",
            "members": {
              "PeerAsn": {
                "type": "long"
              }
            }
          },
          "InsideCidrBlocks": {
            "shape": "Spr"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeer": {
            "shape": "Spt",
            "locationName": "transitGatewayConnectPeer"
          }
        }
      }
    },
    "CreateTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "Options": {
            "type": "structure",
            "members": {
              "Igmpv2Support": {},
              "StaticSourcesSupport": {},
              "AutoAcceptSharedAssociations": {}
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomain": {
            "shape": "Sq6",
            "locationName": "transitGatewayMulticastDomain"
          }
        }
      }
    },
    "CreateTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId",
          "PeerTransitGatewayId",
          "PeerAccountId",
          "PeerRegion"
        ],
        "members": {
          "TransitGatewayId": {},
          "PeerTransitGatewayId": {},
          "PeerAccountId": {},
          "PeerRegion": {},
          "Options": {
            "type": "structure",
            "members": {
              "DynamicRouting": {}
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Sx",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "CreateTransitGatewayPolicyTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "TagSpecifications": {
            "shape": "S3"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPolicyTable": {
            "shape": "Sqf",
            "locationName": "transitGatewayPolicyTable"
          }
        }
      }
    },
    "CreateTransitGatewayPrefixListReference": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "PrefixListId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "PrefixListId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPrefixListReference": {
            "shape": "Sqj",
            "locationName": "transitGatewayPrefixListReference"
          }
        }
      }
    },
    "CreateTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sqo",
            "locationName": "route"
          }
        }
      }
    },
    "CreateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "TagSpecifications": {
            "shape": "S3"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTable": {
            "shape": "Sqw",
            "locationName": "transitGatewayRouteTable"
          }
        }
      }
    },
    "CreateTransitGatewayRouteTableAnnouncement": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "PeeringAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "PeeringAttachmentId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableAnnouncement": {
            "shape": "Sr0",
            "locationName": "transitGatewayRouteTableAnnouncement"
          }
        }
      }
    },
    "CreateTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId",
          "VpcId",
          "SubnetIds"
        ],
        "members": {
          "TransitGatewayId": {},
          "VpcId": {},
          "SubnetIds": {
            "shape": "S59"
          },
          "Options": {
            "type": "structure",
            "members": {
              "DnsSupport": {},
              "SecurityGroupReferencingSupport": {},
              "Ipv6Support": {},
              "ApplianceModeSupport": {}
            }
          },
          "TagSpecifications": {
            "shape": "S3"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "S16",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "CreateVerifiedAccessEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessGroupId",
          "EndpointType",
          "AttachmentType",
          "DomainCertificateArn",
          "ApplicationDomain",
          "EndpointDomainPrefix"
        ],
        "members": {
          "VerifiedAccessGroupId": {},
          "EndpointType": {},
          "AttachmentType": {},
          "DomainCertificateArn": {},
          "ApplicationDomain": {},
          "EndpointDomainPrefix": {},
          "SecurityGroupIds": {
            "shape": "Srb",
            "locationName": "SecurityGroupId"
          },
          "LoadBalancerOptions": {
            "type": "structure",
            "members": {
              "Protocol": {},
              "Port": {
                "type": "integer"
              },
              "LoadBalancerArn": {},
              "SubnetIds": {
                "locationName": "SubnetId",
                "type": "list",
                "member": {
                  "locationName": "item"
                }
              }
            }
          },
          "NetworkInterfaceOptions": {
            "type": "structure",
            "members": {
              "NetworkInterfaceId": {},
              "Protocol": {},
              "Port": {
                "type": "integer"
              }
            }
          },
          "Description": {},
          "PolicyDocument": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SseSpecification": {
            "shape": "Sri"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessEndpoint": {
            "shape": "Srk",
            "locationName": "verifiedAccessEndpoint"
          }
        }
      }
    },
    "CreateVerifiedAccessGroup": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessInstanceId"
        ],
        "members": {
          "VerifiedAccessInstanceId": {},
          "Description": {},
          "PolicyDocument": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SseSpecification": {
            "shape": "Sri"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessGroup": {
            "shape": "Srs",
            "locationName": "verifiedAccessGroup"
          }
        }
      }
    },
    "CreateVerifiedAccessInstance": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "FIPSEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessInstance": {
            "shape": "S6i",
            "locationName": "verifiedAccessInstance"
          }
        }
      }
    },
    "CreateVerifiedAccessTrustProvider": {
      "input": {
        "type": "structure",
        "required": [
          "TrustProviderType",
          "PolicyReferenceName"
        ],
        "members": {
          "TrustProviderType": {},
          "UserTrustProviderType": {},
          "DeviceTrustProviderType": {},
          "OidcOptions": {
            "type": "structure",
            "members": {
              "Issuer": {},
              "AuthorizationEndpoint": {},
              "TokenEndpoint": {},
              "UserInfoEndpoint": {},
              "ClientId": {},
              "ClientSecret": {
                "shape": "S6e"
              },
              "Scope": {}
            }
          },
          "DeviceOptions": {
            "type": "structure",
            "members": {
              "TenantId": {},
              "PublicSigningKeyUrl": {}
            }
          },
          "PolicyReferenceName": {},
          "Description": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SseSpecification": {
            "shape": "Sri"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProvider": {
            "shape": "S69",
            "locationName": "verifiedAccessTrustProvider"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AvailabilityZone": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "KmsKeyId": {},
          "OutpostArn": {},
          "Size": {
            "type": "integer"
          },
          "SnapshotId": {},
          "VolumeType": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "MultiAttachEnabled": {
            "type": "boolean"
          },
          "Throughput": {
            "type": "integer"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "shape": "Ss0"
      }
    },
    "CreateVpc": {
      "input": {
        "type": "structure",
        "members": {
          "CidrBlock": {},
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          },
          "Ipv6Pool": {},
          "Ipv6CidrBlock": {},
          "Ipv4IpamPoolId": {},
          "Ipv4NetmaskLength": {
            "type": "integer"
          },
          "Ipv6IpamPoolId": {},
          "Ipv6NetmaskLength": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "Ipv6CidrBlockNetworkBorderGroup": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "Sbs",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "ServiceName"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointType": {},
          "VpcId": {},
          "ServiceName": {},
          "PolicyDocument": {},
          "RouteTableIds": {
            "shape": "Ss7",
            "locationName": "RouteTableId"
          },
          "SubnetIds": {
            "shape": "Ss8",
            "locationName": "SubnetId"
          },
          "SecurityGroupIds": {
            "shape": "Ss9",
            "locationName": "SecurityGroupId"
          },
          "IpAddressType": {},
          "DnsOptions": {
            "shape": "Ssb"
          },
          "ClientToken": {},
          "PrivateDnsEnabled": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "SubnetConfigurations": {
            "shape": "Ssd",
            "locationName": "SubnetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoint": {
            "shape": "Ssg",
            "locationName": "vpcEndpoint"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcEndpointConnectionNotification": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationArn",
          "ConnectionEvents"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointId": {},
          "ConnectionNotificationArn": {},
          "ConnectionEvents": {
            "shape": "So"
          },
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionNotification": {
            "shape": "Ssq",
            "locationName": "connectionNotification"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcEndpointServiceConfiguration": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "PrivateDnsName": {},
          "NetworkLoadBalancerArns": {
            "shape": "So",
            "locationName": "NetworkLoadBalancerArn"
          },
          "GatewayLoadBalancerArns": {
            "shape": "So",
            "locationName": "GatewayLoadBalancerArn"
          },
          "SupportedIpAddressTypes": {
            "shape": "So",
            "locationName": "SupportedIpAddressType"
          },
          "ClientToken": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceConfiguration": {
            "shape": "Ssv",
            "locationName": "serviceConfiguration"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PeerOwnerId": {
            "locationName": "peerOwnerId"
          },
          "PeerVpcId": {
            "locationName": "peerVpcId"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "PeerRegion": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "S1n",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "CreateVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId",
          "Type"
        ],
        "members": {
          "CustomerGatewayId": {},
          "Type": {},
          "VpnGatewayId": {},
          "TransitGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Options": {
            "locationName": "options",
            "type": "structure",
            "members": {
              "EnableAcceleration": {
                "type": "boolean"
              },
              "StaticRoutesOnly": {
                "locationName": "staticRoutesOnly",
                "type": "boolean"
              },
              "TunnelInsideIpVersion": {},
              "TunnelOptions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "TunnelInsideCidr": {},
                    "TunnelInsideIpv6Cidr": {},
                    "PreSharedKey": {
                      "shape": "Std"
                    },
                    "Phase1LifetimeSeconds": {
                      "type": "integer"
                    },
                    "Phase2LifetimeSeconds": {
                      "type": "integer"
                    },
                    "RekeyMarginTimeSeconds": {
                      "type": "integer"
                    },
                    "RekeyFuzzPercentage": {
                      "type": "integer"
                    },
                    "ReplayWindowSize": {
                      "type": "integer"
                    },
                    "DPDTimeoutSeconds": {
                      "type": "integer"
                    },
                    "DPDTimeoutAction": {},
                    "Phase1EncryptionAlgorithms": {
                      "shape": "Ste",
                      "locationName": "Phase1EncryptionAlgorithm"
                    },
                    "Phase2EncryptionAlgorithms": {
                      "shape": "Stg",
                      "locationName": "Phase2EncryptionAlgorithm"
                    },
                    "Phase1IntegrityAlgorithms": {
                      "shape": "Sti",
                      "locationName": "Phase1IntegrityAlgorithm"
                    },
                    "Phase2IntegrityAlgorithms": {
                      "shape": "Stk",
                      "locationName": "Phase2IntegrityAlgorithm"
                    },
                    "Phase1DHGroupNumbers": {
                      "shape": "Stm",
                      "locationName": "Phase1DHGroupNumber"
                    },
                    "Phase2DHGroupNumbers": {
                      "shape": "Sto",
                      "locationName": "Phase2DHGroupNumber"
                    },
                    "IKEVersions": {
                      "shape": "Stq",
                      "locationName": "IKEVersion"
                    },
                    "StartupAction": {},
                    "LogOptions": {
                      "shape": "Sts"
                    },
                    "EnableTunnelLifecycleControl": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "LocalIpv4NetworkCidr": {},
              "RemoteIpv4NetworkCidr": {},
              "LocalIpv6NetworkCidr": {},
              "RemoteIpv6NetworkCidr": {},
              "OutsideIpAddressType": {},
              "TransportTransitGatewayAttachmentId": {}
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Stw",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "CreateVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "VpnConnectionId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "VpnConnectionId": {}
        }
      }
    },
    "CreateVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "AvailabilityZone": {},
          "Type": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "AmazonSideAsn": {
            "type": "long"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateway": {
            "shape": "Sut",
            "locationName": "vpnGateway"
          }
        }
      }
    },
    "DeleteCarrierGateway": {
      "input": {
        "type": "structure",
        "required": [
          "CarrierGatewayId"
        ],
        "members": {
          "CarrierGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CarrierGateway": {
            "shape": "Sak",
            "locationName": "carrierGateway"
          }
        }
      }
    },
    "DeleteClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "Sb0",
            "locationName": "status"
          }
        }
      }
    },
    "DeleteClientVpnRoute": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "DestinationCidrBlock"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetVpcSubnetId": {},
          "DestinationCidrBlock": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "Sb4",
            "locationName": "status"
          }
        }
      }
    },
    "DeleteCoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr",
          "CoipPoolId"
        ],
        "members": {
          "Cidr": {},
          "CoipPoolId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipCidr": {
            "shape": "Sb9",
            "locationName": "coipCidr"
          }
        }
      }
    },
    "DeleteCoipPool": {
      "input": {
        "type": "structure",
        "required": [
          "CoipPoolId"
        ],
        "members": {
          "CoipPoolId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipPool": {
            "shape": "Sbd",
            "locationName": "coipPool"
          }
        }
      }
    },
    "DeleteCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId"
        ],
        "members": {
          "CustomerGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId"
        ],
        "members": {
          "DhcpOptionsId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "EgressOnlyInternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnCode": {
            "locationName": "returnCode",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteFleets": {
      "input": {
        "type": "structure",
        "required": [
          "FleetIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FleetIds": {
            "shape": "Svb",
            "locationName": "FleetId"
          },
          "TerminateInstances": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetDeletions": {
            "locationName": "successfulFleetDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentFleetState": {
                  "locationName": "currentFleetState"
                },
                "PreviousFleetState": {
                  "locationName": "previousFleetState"
                },
                "FleetId": {
                  "locationName": "fleetId"
                }
              }
            }
          },
          "UnsuccessfulFleetDeletions": {
            "locationName": "unsuccessfulFleetDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "FleetId": {
                  "locationName": "fleetId"
                }
              }
            }
          }
        }
      }
    },
    "DeleteFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "FlowLogIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FlowLogIds": {
            "shape": "Svl",
            "locationName": "FlowLogId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteInstanceConnectEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceConnectEndpointId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceConnectEndpointId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceConnectEndpoint": {
            "shape": "Sf4",
            "locationName": "instanceConnectEndpoint"
          }
        }
      }
    },
    "DeleteInstanceEventWindow": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceEventWindowId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ForceDelete": {
            "type": "boolean"
          },
          "InstanceEventWindowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceEventWindowState": {
            "locationName": "instanceEventWindowState",
            "type": "structure",
            "members": {
              "InstanceEventWindowId": {
                "locationName": "instanceEventWindowId"
              },
              "State": {
                "locationName": "state"
              }
            }
          }
        }
      }
    },
    "DeleteInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          }
        }
      }
    },
    "DeleteIpam": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "Cascade": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipam": {
            "shape": "Sfv",
            "locationName": "ipam"
          }
        }
      }
    },
    "DeleteIpamExternalResourceVerificationToken": {
      "input": {
        "type": "structure",
        "required": [
          "IpamExternalResourceVerificationTokenId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamExternalResourceVerificationTokenId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamExternalResourceVerificationToken": {
            "shape": "Sg2",
            "locationName": "ipamExternalResourceVerificationToken"
          }
        }
      }
    },
    "DeleteIpamPool": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Cascade": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPool": {
            "shape": "Sgg",
            "locationName": "ipamPool"
          }
        }
      }
    },
    "DeleteIpamResourceDiscovery": {
      "input": {
        "type": "structure",
        "required": [
          "IpamResourceDiscoveryId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscovery": {
            "shape": "Sgo",
            "locationName": "ipamResourceDiscovery"
          }
        }
      }
    },
    "DeleteIpamScope": {
      "input": {
        "type": "structure",
        "required": [
          "IpamScopeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamScopeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamScope": {
            "shape": "Sgs",
            "locationName": "ipamScope"
          }
        }
      }
    },
    "DeleteKeyPair": {
      "input": {
        "type": "structure",
        "members": {
          "KeyName": {},
          "KeyPairId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          },
          "KeyPairId": {
            "locationName": "keyPairId"
          }
        }
      }
    },
    "DeleteLaunchTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "Sim",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "DeleteLaunchTemplateVersions": {
      "input": {
        "type": "structure",
        "required": [
          "Versions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "Versions": {
            "shape": "Swd",
            "locationName": "LaunchTemplateVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfullyDeletedLaunchTemplateVersions": {
            "locationName": "successfullyDeletedLaunchTemplateVersionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateId": {
                  "locationName": "launchTemplateId"
                },
                "LaunchTemplateName": {
                  "locationName": "launchTemplateName"
                },
                "VersionNumber": {
                  "locationName": "versionNumber",
                  "type": "long"
                }
              }
            }
          },
          "UnsuccessfullyDeletedLaunchTemplateVersions": {
            "locationName": "unsuccessfullyDeletedLaunchTemplateVersionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateId": {
                  "locationName": "launchTemplateId"
                },
                "LaunchTemplateName": {
                  "locationName": "launchTemplateName"
                },
                "VersionNumber": {
                  "locationName": "versionNumber",
                  "type": "long"
                },
                "ResponseError": {
                  "locationName": "responseError",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DeleteLocalGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "LocalGatewayRouteTableId": {},
          "DryRun": {
            "type": "boolean"
          },
          "DestinationPrefixListId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sjy",
            "locationName": "route"
          }
        }
      }
    },
    "DeleteLocalGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTable": {
            "shape": "Sk5",
            "locationName": "localGatewayRouteTable"
          }
        }
      }
    },
    "DeleteLocalGatewayRouteTableVirtualInterfaceGroupAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociationId"
        ],
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociation": {
            "shape": "Sk9",
            "locationName": "localGatewayRouteTableVirtualInterfaceGroupAssociation"
          }
        }
      }
    },
    "DeleteLocalGatewayRouteTableVpcAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableVpcAssociationId"
        ],
        "members": {
          "LocalGatewayRouteTableVpcAssociationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociation": {
            "shape": "Skd",
            "locationName": "localGatewayRouteTableVpcAssociation"
          }
        }
      }
    },
    "DeleteManagedPrefixList": {
      "input": {
        "type": "structure",
        "required": [
          "PrefixListId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrefixList": {
            "shape": "Skj",
            "locationName": "prefixList"
          }
        }
      }
    },
    "DeleteNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "NatGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          }
        }
      }
    },
    "DeleteNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      }
    },
    "DeleteNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "RuleNumber"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "DeleteNetworkInsightsAccessScope": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsAccessScopeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "NetworkInsightsAccessScopeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeId": {
            "locationName": "networkInsightsAccessScopeId"
          }
        }
      }
    },
    "DeleteNetworkInsightsAccessScopeAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsAccessScopeAnalysisId"
        ],
        "members": {
          "NetworkInsightsAccessScopeAnalysisId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeAnalysisId": {
            "locationName": "networkInsightsAccessScopeAnalysisId"
          }
        }
      }
    },
    "DeleteNetworkInsightsAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsAnalysisId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "NetworkInsightsAnalysisId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAnalysisId": {
            "locationName": "networkInsightsAnalysisId"
          }
        }
      }
    },
    "DeleteNetworkInsightsPath": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsPathId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "NetworkInsightsPathId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsPathId": {
            "locationName": "networkInsightsPathId"
          }
        }
      }
    },
    "DeleteNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "DeleteNetworkInterfacePermission": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfacePermissionId"
        ],
        "members": {
          "NetworkInterfacePermissionId": {},
          "Force": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeletePlacementGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          }
        }
      }
    },
    "DeletePublicIpv4Pool": {
      "input": {
        "type": "structure",
        "required": [
          "PoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PoolId": {},
          "NetworkBorderGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "returnValue",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteQueuedReservedInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstancesIds": {
            "locationName": "ReservedInstancesId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulQueuedPurchaseDeletions": {
            "locationName": "successfulQueuedPurchaseDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                }
              }
            }
          },
          "FailedQueuedPurchaseDeletions": {
            "locationName": "failedQueuedPurchaseDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                }
              }
            }
          }
        }
      }
    },
    "DeleteRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DestinationPrefixListId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteSecurityGroup": {
      "input": {
        "type": "structure",
        "members": {
          "GroupId": {},
          "GroupName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "SubnetId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSubnetCidrReservation": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetCidrReservationId"
        ],
        "members": {
          "SubnetCidrReservationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeletedSubnetCidrReservation": {
            "shape": "Sog",
            "locationName": "deletedSubnetCidrReservation"
          }
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "Soj",
            "locationName": "resourceId"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tag"
          }
        }
      }
    },
    "DeleteTrafficMirrorFilter": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterId": {
            "locationName": "trafficMirrorFilterId"
          }
        }
      }
    },
    "DeleteTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterRuleId"
        ],
        "members": {
          "TrafficMirrorFilterRuleId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRuleId": {
            "locationName": "trafficMirrorFilterRuleId"
          }
        }
      }
    },
    "DeleteTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorSessionId"
        ],
        "members": {
          "TrafficMirrorSessionId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessionId": {
            "locationName": "trafficMirrorSessionId"
          }
        }
      }
    },
    "DeleteTrafficMirrorTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorTargetId"
        ],
        "members": {
          "TrafficMirrorTargetId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargetId": {
            "locationName": "trafficMirrorTargetId"
          }
        }
      }
    },
    "DeleteTransitGateway": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Spg",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "DeleteTransitGatewayConnect": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnect": {
            "shape": "Spn",
            "locationName": "transitGatewayConnect"
          }
        }
      }
    },
    "DeleteTransitGatewayConnectPeer": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayConnectPeerId"
        ],
        "members": {
          "TransitGatewayConnectPeerId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeer": {
            "shape": "Spt",
            "locationName": "transitGatewayConnectPeer"
          }
        }
      }
    },
    "DeleteTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomain": {
            "shape": "Sq6",
            "locationName": "transitGatewayMulticastDomain"
          }
        }
      }
    },
    "DeleteTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Sx",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "DeleteTransitGatewayPolicyTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayPolicyTableId"
        ],
        "members": {
          "TransitGatewayPolicyTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPolicyTable": {
            "shape": "Sqf",
            "locationName": "transitGatewayPolicyTable"
          }
        }
      }
    },
    "DeleteTransitGatewayPrefixListReference": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "PrefixListId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "PrefixListId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPrefixListReference": {
            "shape": "Sqj",
            "locationName": "transitGatewayPrefixListReference"
          }
        }
      }
    },
    "DeleteTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "DestinationCidrBlock"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "DestinationCidrBlock": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sqo",
            "locationName": "route"
          }
        }
      }
    },
    "DeleteTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTable": {
            "shape": "Sqw",
            "locationName": "transitGatewayRouteTable"
          }
        }
      }
    },
    "DeleteTransitGatewayRouteTableAnnouncement": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableAnnouncementId"
        ],
        "members": {
          "TransitGatewayRouteTableAnnouncementId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableAnnouncement": {
            "shape": "Sr0",
            "locationName": "transitGatewayRouteTableAnnouncement"
          }
        }
      }
    },
    "DeleteTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "S16",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "DeleteVerifiedAccessEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessEndpointId"
        ],
        "members": {
          "VerifiedAccessEndpointId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessEndpoint": {
            "shape": "Srk",
            "locationName": "verifiedAccessEndpoint"
          }
        }
      }
    },
    "DeleteVerifiedAccessGroup": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessGroupId"
        ],
        "members": {
          "VerifiedAccessGroupId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessGroup": {
            "shape": "Srs",
            "locationName": "verifiedAccessGroup"
          }
        }
      }
    },
    "DeleteVerifiedAccessInstance": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessInstanceId"
        ],
        "members": {
          "VerifiedAccessInstanceId": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessInstance": {
            "shape": "S6i",
            "locationName": "verifiedAccessInstance"
          }
        }
      }
    },
    "DeleteVerifiedAccessTrustProvider": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessTrustProviderId"
        ],
        "members": {
          "VerifiedAccessTrustProviderId": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProvider": {
            "shape": "S69",
            "locationName": "verifiedAccessTrustProvider"
          }
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpc": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpcEndpointConnectionNotifications": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationIds": {
            "locationName": "ConnectionNotificationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcEndpointServiceConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceIds": {
            "shape": "Sza",
            "locationName": "ServiceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "S1e",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "VpnConnectionId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "VpnConnectionId": {}
        }
      }
    },
    "DeleteVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpnGatewayId"
        ],
        "members": {
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeprovisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1y",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "DeprovisionIpamByoasn": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId",
          "Asn"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "Asn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Byoasn": {
            "shape": "Szn",
            "locationName": "byoasn"
          }
        }
      }
    },
    "DeprovisionIpamPoolCidr": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPoolCidr": {
            "shape": "Szr",
            "locationName": "ipamPoolCidr"
          }
        }
      }
    },
    "DeprovisionPublicIpv4PoolCidr": {
      "input": {
        "type": "structure",
        "required": [
          "PoolId",
          "Cidr"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PoolId": {},
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PoolId": {
            "locationName": "poolId"
          },
          "DeprovisionedAddresses": {
            "locationName": "deprovisionedAddressSet",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      }
    },
    "DeregisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeregisterInstanceEventNotificationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceTagAttribute"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceTagAttribute": {
            "type": "structure",
            "members": {
              "IncludeAllTagsOfInstance": {
                "type": "boolean"
              },
              "InstanceTagKeys": {
                "shape": "S102",
                "locationName": "InstanceTagKey"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTagAttribute": {
            "shape": "S104",
            "locationName": "instanceTagAttribute"
          }
        }
      }
    },
    "DeregisterTransitGatewayMulticastGroupMembers": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "S106"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeregisteredMulticastGroupMembers": {
            "locationName": "deregisteredMulticastGroupMembers",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "DeregisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "deregisteredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "DeregisterTransitGatewayMulticastGroupSources": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "S106"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeregisteredMulticastGroupSources": {
            "locationName": "deregisteredMulticastGroupSources",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "DeregisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "deregisteredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "AttributeNames": {
            "locationName": "attributeName",
            "type": "list",
            "member": {
              "locationName": "attributeName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountAttributes": {
            "locationName": "accountAttributeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AttributeName": {
                  "locationName": "attributeName"
                },
                "AttributeValues": {
                  "locationName": "attributeValueSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AttributeValue": {
                        "locationName": "attributeValue"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeAddressTransfers": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationIds": {
            "shape": "S4r",
            "locationName": "AllocationId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressTransfers": {
            "locationName": "addressTransferSet",
            "type": "list",
            "member": {
              "shape": "Sa",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "PublicIps": {
            "locationName": "PublicIp",
            "type": "list",
            "member": {
              "locationName": "PublicIp"
            }
          },
          "AllocationIds": {
            "shape": "S4r",
            "locationName": "AllocationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Addresses": {
            "locationName": "addressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                },
                "AllocationId": {
                  "locationName": "allocationId"
                },
                "AssociationId": {
                  "locationName": "associationId"
                },
                "Domain": {
                  "locationName": "domain"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "NetworkInterfaceOwnerId": {
                  "locationName": "networkInterfaceOwnerId"
                },
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "PublicIpv4Pool": {
                  "locationName": "publicIpv4Pool"
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                },
                "CustomerOwnedIp": {
                  "locationName": "customerOwnedIp"
                },
                "CustomerOwnedIpv4Pool": {
                  "locationName": "customerOwnedIpv4Pool"
                },
                "CarrierIp": {
                  "locationName": "carrierIp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAddressesAttribute": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationIds": {
            "locationName": "AllocationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Attribute": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Addresses": {
            "locationName": "addressSet",
            "type": "list",
            "member": {
              "shape": "S112",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeAggregateIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UseLongIdsAggregated": {
            "locationName": "useLongIdsAggregated",
            "type": "boolean"
          },
          "Statuses": {
            "shape": "S116",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeAvailabilityZones": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "ZoneNames": {
            "locationName": "ZoneName",
            "type": "list",
            "member": {
              "locationName": "ZoneName"
            }
          },
          "ZoneIds": {
            "locationName": "ZoneId",
            "type": "list",
            "member": {
              "locationName": "ZoneId"
            }
          },
          "AllAvailabilityZones": {
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "locationName": "availabilityZoneInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "State": {
                  "locationName": "zoneState"
                },
                "OptInStatus": {
                  "locationName": "optInStatus"
                },
                "Messages": {
                  "locationName": "messageSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Message": {
                        "locationName": "message"
                      }
                    }
                  }
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "ZoneName": {
                  "locationName": "zoneName"
                },
                "ZoneId": {
                  "locationName": "zoneId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                },
                "ZoneType": {
                  "locationName": "zoneType"
                },
                "ParentZoneName": {
                  "locationName": "parentZoneName"
                },
                "ParentZoneId": {
                  "locationName": "parentZoneId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAwsNetworkPerformanceMetricSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Subscriptions": {
            "locationName": "subscriptionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Source": {
                  "locationName": "source"
                },
                "Destination": {
                  "locationName": "destination"
                },
                "Metric": {
                  "locationName": "metric"
                },
                "Statistic": {
                  "locationName": "statistic"
                },
                "Period": {
                  "locationName": "period"
                }
              }
            }
          }
        }
      }
    },
    "DescribeBundleTasks": {
      "input": {
        "type": "structure",
        "members": {
          "BundleIds": {
            "locationName": "BundleId",
            "type": "list",
            "member": {
              "locationName": "BundleId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTasks": {
            "locationName": "bundleInstanceTasksSet",
            "type": "list",
            "member": {
              "shape": "S7o",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeByoipCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidrs": {
            "locationName": "byoipCidrSet",
            "type": "list",
            "member": {
              "shape": "S1y",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCapacityBlockOfferings": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "InstanceCount",
          "CapacityDurationHours"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceType": {},
          "InstanceCount": {
            "type": "integer"
          },
          "StartDateRange": {
            "type": "timestamp"
          },
          "EndDateRange": {
            "type": "timestamp"
          },
          "CapacityDurationHours": {
            "type": "integer"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityBlockOfferings": {
            "locationName": "capacityBlockOfferingSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CapacityBlockOfferingId": {
                  "locationName": "capacityBlockOfferingId"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "StartDate": {
                  "locationName": "startDate",
                  "type": "timestamp"
                },
                "EndDate": {
                  "locationName": "endDate",
                  "type": "timestamp"
                },
                "CapacityBlockDurationHours": {
                  "locationName": "capacityBlockDurationHours",
                  "type": "integer"
                },
                "UpfrontFee": {
                  "locationName": "upfrontFee"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Tenancy": {
                  "locationName": "tenancy"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCapacityReservationFleets": {
      "input": {
        "type": "structure",
        "members": {
          "CapacityReservationFleetIds": {
            "shape": "S7y",
            "locationName": "CapacityReservationFleetId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityReservationFleets": {
            "locationName": "capacityReservationFleetSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CapacityReservationFleetId": {
                  "locationName": "capacityReservationFleetId"
                },
                "CapacityReservationFleetArn": {
                  "locationName": "capacityReservationFleetArn"
                },
                "State": {
                  "locationName": "state"
                },
                "TotalTargetCapacity": {
                  "locationName": "totalTargetCapacity",
                  "type": "integer"
                },
                "TotalFulfilledCapacity": {
                  "locationName": "totalFulfilledCapacity",
                  "type": "double"
                },
                "Tenancy": {
                  "locationName": "tenancy"
                },
                "EndDate": {
                  "locationName": "endDate",
                  "type": "timestamp"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "InstanceMatchCriteria": {
                  "locationName": "instanceMatchCriteria"
                },
                "AllocationStrategy": {
                  "locationName": "allocationStrategy"
                },
                "InstanceTypeSpecifications": {
                  "shape": "Sag",
                  "locationName": "instanceTypeSpecificationSet"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCapacityReservations": {
      "input": {
        "type": "structure",
        "members": {
          "CapacityReservationIds": {
            "locationName": "CapacityReservationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservations": {
            "locationName": "capacityReservationSet",
            "type": "list",
            "member": {
              "shape": "S9z",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeCarrierGateways": {
      "input": {
        "type": "structure",
        "members": {
          "CarrierGatewayIds": {
            "locationName": "CarrierGatewayId",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CarrierGateways": {
            "locationName": "carrierGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sak",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClassicLinkInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "locationName": "instancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Groups": {
                  "shape": "Sm8",
                  "locationName": "groupSet"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnAuthorizationRules": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthorizationRules": {
            "locationName": "authorizationRule",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Description": {
                  "locationName": "description"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "AccessAll": {
                  "locationName": "accessAll",
                  "type": "boolean"
                },
                "DestinationCidr": {
                  "locationName": "destinationCidr"
                },
                "Status": {
                  "shape": "S6w",
                  "locationName": "status"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "locationName": "connections",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Timestamp": {
                  "locationName": "timestamp"
                },
                "ConnectionId": {
                  "locationName": "connectionId"
                },
                "Username": {
                  "locationName": "username"
                },
                "ConnectionEstablishedTime": {
                  "locationName": "connectionEstablishedTime"
                },
                "IngressBytes": {
                  "locationName": "ingressBytes"
                },
                "EgressBytes": {
                  "locationName": "egressBytes"
                },
                "IngressPackets": {
                  "locationName": "ingressPackets"
                },
                "EgressPackets": {
                  "locationName": "egressPackets"
                },
                "ClientIp": {
                  "locationName": "clientIp"
                },
                "CommonName": {
                  "locationName": "commonName"
                },
                "Status": {
                  "shape": "S12z",
                  "locationName": "status"
                },
                "ConnectionEndTime": {
                  "locationName": "connectionEndTime"
                },
                "PostureComplianceStatuses": {
                  "shape": "So",
                  "locationName": "postureComplianceStatusSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointIds": {
            "locationName": "ClientVpnEndpointId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpoints": {
            "locationName": "clientVpnEndpoint",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Description": {
                  "locationName": "description"
                },
                "Status": {
                  "shape": "Sb0",
                  "locationName": "status"
                },
                "CreationTime": {
                  "locationName": "creationTime"
                },
                "DeletionTime": {
                  "locationName": "deletionTime"
                },
                "DnsName": {
                  "locationName": "dnsName"
                },
                "ClientCidrBlock": {
                  "locationName": "clientCidrBlock"
                },
                "DnsServers": {
                  "shape": "So",
                  "locationName": "dnsServer"
                },
                "SplitTunnel": {
                  "locationName": "splitTunnel",
                  "type": "boolean"
                },
                "VpnProtocol": {
                  "locationName": "vpnProtocol"
                },
                "TransportProtocol": {
                  "locationName": "transportProtocol"
                },
                "VpnPort": {
                  "locationName": "vpnPort",
                  "type": "integer"
                },
                "AssociatedTargetNetworks": {
                  "deprecated": true,
                  "deprecatedMessage": "This property is deprecated. To view the target networks associated with a Client VPN endpoint, call DescribeClientVpnTargetNetworks and inspect the clientVpnTargetNetworks response element.",
                  "locationName": "associatedTargetNetwork",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "NetworkId": {
                        "locationName": "networkId"
                      },
                      "NetworkType": {
                        "locationName": "networkType"
                      }
                    }
                  }
                },
                "ServerCertificateArn": {
                  "locationName": "serverCertificateArn"
                },
                "AuthenticationOptions": {
                  "locationName": "authenticationOptions",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Type": {
                        "locationName": "type"
                      },
                      "ActiveDirectory": {
                        "locationName": "activeDirectory",
                        "type": "structure",
                        "members": {
                          "DirectoryId": {
                            "locationName": "directoryId"
                          }
                        }
                      },
                      "MutualAuthentication": {
                        "locationName": "mutualAuthentication",
                        "type": "structure",
                        "members": {
                          "ClientRootCertificateChain": {
                            "locationName": "clientRootCertificateChain"
                          }
                        }
                      },
                      "FederatedAuthentication": {
                        "locationName": "federatedAuthentication",
                        "type": "structure",
                        "members": {
                          "SamlProviderArn": {
                            "locationName": "samlProviderArn"
                          },
                          "SelfServiceSamlProviderArn": {
                            "locationName": "selfServiceSamlProviderArn"
                          }
                        }
                      }
                    }
                  }
                },
                "ConnectionLogOptions": {
                  "locationName": "connectionLogOptions",
                  "type": "structure",
                  "members": {
                    "Enabled": {
                      "type": "boolean"
                    },
                    "CloudwatchLogGroup": {},
                    "CloudwatchLogStream": {}
                  }
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "SecurityGroupIds": {
                  "shape": "S2r",
                  "locationName": "securityGroupIdSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "SelfServicePortalUrl": {
                  "locationName": "selfServicePortalUrl"
                },
                "ClientConnectOptions": {
                  "locationName": "clientConnectOptions",
                  "type": "structure",
                  "members": {
                    "Enabled": {
                      "locationName": "enabled",
                      "type": "boolean"
                    },
                    "LambdaFunctionArn": {
                      "locationName": "lambdaFunctionArn"
                    },
                    "Status": {
                      "locationName": "status",
                      "type": "structure",
                      "members": {
                        "Code": {
                          "locationName": "code"
                        },
                        "Message": {
                          "locationName": "message"
                        }
                      }
                    }
                  }
                },
                "SessionTimeoutHours": {
                  "locationName": "sessionTimeoutHours",
                  "type": "integer"
                },
                "ClientLoginBannerOptions": {
                  "locationName": "clientLoginBannerOptions",
                  "type": "structure",
                  "members": {
                    "Enabled": {
                      "locationName": "enabled",
                      "type": "boolean"
                    },
                    "BannerText": {
                      "locationName": "bannerText"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routes",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "DestinationCidr": {
                  "locationName": "destinationCidr"
                },
                "TargetSubnet": {
                  "locationName": "targetSubnet"
                },
                "Type": {
                  "locationName": "type"
                },
                "Origin": {
                  "locationName": "origin"
                },
                "Status": {
                  "shape": "Sb4",
                  "locationName": "status"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnTargetNetworks": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "AssociationIds": {
            "shape": "So"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnTargetNetworks": {
            "locationName": "clientVpnTargetNetworks",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AssociationId": {
                  "locationName": "associationId"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "TargetNetworkId": {
                  "locationName": "targetNetworkId"
                },
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Status": {
                  "shape": "S3m",
                  "locationName": "status"
                },
                "SecurityGroups": {
                  "shape": "So",
                  "locationName": "securityGroups"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCoipPools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "locationName": "PoolId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipPools": {
            "locationName": "coipPoolSet",
            "type": "list",
            "member": {
              "shape": "Sbd",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeConversionTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ConversionTaskIds": {
            "locationName": "conversionTaskId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTasks": {
            "locationName": "conversionTasks",
            "type": "list",
            "member": {
              "shape": "S144",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeCustomerGateways": {
      "input": {
        "type": "structure",
        "members": {
          "CustomerGatewayIds": {
            "locationName": "CustomerGatewayId",
            "type": "list",
            "member": {
              "locationName": "CustomerGatewayId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateways": {
            "locationName": "customerGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sbh",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeDhcpOptions": {
      "input": {
        "type": "structure",
        "members": {
          "DhcpOptionsIds": {
            "locationName": "DhcpOptionsId",
            "type": "list",
            "member": {
              "locationName": "DhcpOptionsId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "locationName": "dhcpOptionsSet",
            "type": "list",
            "member": {
              "shape": "Sc1",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeEgressOnlyInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayIds": {
            "locationName": "EgressOnlyInternetGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EgressOnlyInternetGateways": {
            "locationName": "egressOnlyInternetGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sc8",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeElasticGpus": {
      "input": {
        "type": "structure",
        "members": {
          "ElasticGpuIds": {
            "locationName": "ElasticGpuId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticGpuSet": {
            "locationName": "elasticGpuSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ElasticGpuId": {
                  "locationName": "elasticGpuId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "ElasticGpuType": {
                  "locationName": "elasticGpuType"
                },
                "ElasticGpuHealth": {
                  "locationName": "elasticGpuHealth",
                  "type": "structure",
                  "members": {
                    "Status": {
                      "locationName": "status"
                    }
                  }
                },
                "ElasticGpuState": {
                  "locationName": "elasticGpuState"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "ExportImageTaskIds": {
            "locationName": "ExportImageTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportImageTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportImageTasks": {
            "locationName": "exportImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "ExportImageTaskId": {
                  "locationName": "exportImageTaskId"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "S3ExportLocation": {
                  "shape": "S158",
                  "locationName": "s3ExportLocation"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ExportTaskIds": {
            "locationName": "exportTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportTaskId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTasks": {
            "locationName": "exportTaskSet",
            "type": "list",
            "member": {
              "shape": "Sfj",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeFastLaunchImages": {
      "input": {
        "type": "structure",
        "members": {
          "ImageIds": {
            "locationName": "ImageId",
            "type": "list",
            "member": {
              "locationName": "ImageId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FastLaunchImages": {
            "locationName": "fastLaunchImageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ImageId": {
                  "locationName": "imageId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "SnapshotConfiguration": {
                  "shape": "S15l",
                  "locationName": "snapshotConfiguration"
                },
                "LaunchTemplate": {
                  "shape": "S15m",
                  "locationName": "launchTemplate"
                },
                "MaxParallelLaunches": {
                  "locationName": "maxParallelLaunches",
                  "type": "integer"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "StateTransitionTime": {
                  "locationName": "stateTransitionTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeFastSnapshotRestores": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FastSnapshotRestores": {
            "locationName": "fastSnapshotRestoreSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "EnablingTime": {
                  "locationName": "enablingTime",
                  "type": "timestamp"
                },
                "OptimizingTime": {
                  "locationName": "optimizingTime",
                  "type": "timestamp"
                },
                "EnabledTime": {
                  "locationName": "enabledTime",
                  "type": "timestamp"
                },
                "DisablingTime": {
                  "locationName": "disablingTime",
                  "type": "timestamp"
                },
                "DisabledTime": {
                  "locationName": "disabledTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeFleetHistory": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EventType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetId": {},
          "StartTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "EventInformation": {
                  "shape": "S15z",
                  "locationName": "eventInformation"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "FleetId": {
            "locationName": "fleetId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveInstances": {
            "shape": "S162",
            "locationName": "activeInstanceSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "FleetId": {
            "locationName": "fleetId"
          }
        }
      }
    },
    "DescribeFleets": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetIds": {
            "shape": "Svb",
            "locationName": "FleetId"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Fleets": {
            "locationName": "fleetSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ActivityStatus": {
                  "locationName": "activityStatus"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "FleetId": {
                  "locationName": "fleetId"
                },
                "FleetState": {
                  "locationName": "fleetState"
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "ExcessCapacityTerminationPolicy": {
                  "locationName": "excessCapacityTerminationPolicy"
                },
                "FulfilledCapacity": {
                  "locationName": "fulfilledCapacity",
                  "type": "double"
                },
                "FulfilledOnDemandCapacity": {
                  "locationName": "fulfilledOnDemandCapacity",
                  "type": "double"
                },
                "LaunchTemplateConfigs": {
                  "locationName": "launchTemplateConfigs",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateSpecification": {
                        "shape": "Se0",
                        "locationName": "launchTemplateSpecification"
                      },
                      "Overrides": {
                        "locationName": "overrides",
                        "type": "list",
                        "member": {
                          "shape": "Se1",
                          "locationName": "item"
                        }
                      }
                    }
                  }
                },
                "TargetCapacitySpecification": {
                  "locationName": "targetCapacitySpecification",
                  "type": "structure",
                  "members": {
                    "TotalTargetCapacity": {
                      "locationName": "totalTargetCapacity",
                      "type": "integer"
                    },
                    "OnDemandTargetCapacity": {
                      "locationName": "onDemandTargetCapacity",
                      "type": "integer"
                    },
                    "SpotTargetCapacity": {
                      "locationName": "spotTargetCapacity",
                      "type": "integer"
                    },
                    "DefaultTargetCapacityType": {
                      "locationName": "defaultTargetCapacityType"
                    },
                    "TargetCapacityUnitType": {
                      "locationName": "targetCapacityUnitType"
                    }
                  }
                },
                "TerminateInstancesWithExpiration": {
                  "locationName": "terminateInstancesWithExpiration",
                  "type": "boolean"
                },
                "Type": {
                  "locationName": "type"
                },
                "ValidFrom": {
                  "locationName": "validFrom",
                  "type": "timestamp"
                },
                "ValidUntil": {
                  "locationName": "validUntil",
                  "type": "timestamp"
                },
                "ReplaceUnhealthyInstances": {
                  "locationName": "replaceUnhealthyInstances",
                  "type": "boolean"
                },
                "SpotOptions": {
                  "locationName": "spotOptions",
                  "type": "structure",
                  "members": {
                    "AllocationStrategy": {
                      "locationName": "allocationStrategy"
                    },
                    "MaintenanceStrategies": {
                      "locationName": "maintenanceStrategies",
                      "type": "structure",
                      "members": {
                        "CapacityRebalance": {
                          "locationName": "capacityRebalance",
                          "type": "structure",
                          "members": {
                            "ReplacementStrategy": {
                              "locationName": "replacementStrategy"
                            },
                            "TerminationDelay": {
                              "locationName": "terminationDelay",
                              "type": "integer"
                            }
                          }
                        }
                      }
                    },
                    "InstanceInterruptionBehavior": {
                      "locationName": "instanceInterruptionBehavior"
                    },
                    "InstancePoolsToUseCount": {
                      "locationName": "instancePoolsToUseCount",
                      "type": "integer"
                    },
                    "SingleInstanceType": {
                      "locationName": "singleInstanceType",
                      "type": "boolean"
                    },
                    "SingleAvailabilityZone": {
                      "locationName": "singleAvailabilityZone",
                      "type": "boolean"
                    },
                    "MinTargetCapacity": {
                      "locationName": "minTargetCapacity",
                      "type": "integer"
                    },
                    "MaxTotalPrice": {
                      "locationName": "maxTotalPrice"
                    }
                  }
                },
                "OnDemandOptions": {
                  "locationName": "onDemandOptions",
                  "type": "structure",
                  "members": {
                    "AllocationStrategy": {
                      "locationName": "allocationStrategy"
                    },
                    "CapacityReservationOptions": {
                      "locationName": "capacityReservationOptions",
                      "type": "structure",
                      "members": {
                        "UsageStrategy": {
                          "locationName": "usageStrategy"
                        }
                      }
                    },
                    "SingleInstanceType": {
                      "locationName": "singleInstanceType",
                      "type": "boolean"
                    },
                    "SingleAvailabilityZone": {
                      "locationName": "singleAvailabilityZone",
                      "type": "boolean"
                    },
                    "MinTargetCapacity": {
                      "locationName": "minTargetCapacity",
                      "type": "integer"
                    },
                    "MaxTotalPrice": {
                      "locationName": "maxTotalPrice"
                    }
                  }
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "Errors": {
                  "locationName": "errorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateAndOverrides": {
                        "shape": "Sdz",
                        "locationName": "launchTemplateAndOverrides"
                      },
                      "Lifecycle": {
                        "locationName": "lifecycle"
                      },
                      "ErrorCode": {
                        "locationName": "errorCode"
                      },
                      "ErrorMessage": {
                        "locationName": "errorMessage"
                      }
                    }
                  }
                },
                "Instances": {
                  "locationName": "fleetInstanceSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateAndOverrides": {
                        "shape": "Sdz",
                        "locationName": "launchTemplateAndOverrides"
                      },
                      "Lifecycle": {
                        "locationName": "lifecycle"
                      },
                      "InstanceIds": {
                        "shape": "Seg",
                        "locationName": "instanceIds"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      },
                      "Platform": {
                        "locationName": "platform"
                      }
                    }
                  }
                },
                "Context": {
                  "locationName": "context"
                }
              }
            }
          }
        }
      }
    },
    "DescribeFlowLogs": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filter": {
            "shape": "S10p"
          },
          "FlowLogIds": {
            "shape": "Svl",
            "locationName": "FlowLogId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowLogs": {
            "locationName": "flowLogSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "DeliverLogsErrorMessage": {
                  "locationName": "deliverLogsErrorMessage"
                },
                "DeliverLogsPermissionArn": {
                  "locationName": "deliverLogsPermissionArn"
                },
                "DeliverCrossAccountRole": {
                  "locationName": "deliverCrossAccountRole"
                },
                "DeliverLogsStatus": {
                  "locationName": "deliverLogsStatus"
                },
                "FlowLogId": {
                  "locationName": "flowLogId"
                },
                "FlowLogStatus": {
                  "locationName": "flowLogStatus"
                },
                "LogGroupName": {
                  "locationName": "logGroupName"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "TrafficType": {
                  "locationName": "trafficType"
                },
                "LogDestinationType": {
                  "locationName": "logDestinationType"
                },
                "LogDestination": {
                  "locationName": "logDestination"
                },
                "LogFormat": {
                  "locationName": "logFormat"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "MaxAggregationInterval": {
                  "locationName": "maxAggregationInterval",
                  "type": "integer"
                },
                "DestinationOptions": {
                  "locationName": "destinationOptions",
                  "type": "structure",
                  "members": {
                    "FileFormat": {
                      "locationName": "fileFormat"
                    },
                    "HiveCompatiblePartitions": {
                      "locationName": "hiveCompatiblePartitions",
                      "type": "boolean"
                    },
                    "PerHourPartition": {
                      "locationName": "perHourPartition",
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageAttribute": {
            "shape": "S16v",
            "locationName": "fpgaImageAttribute"
          }
        }
      }
    },
    "DescribeFpgaImages": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageIds": {
            "locationName": "FpgaImageId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Owners": {
            "shape": "S174",
            "locationName": "Owner"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImages": {
            "locationName": "fpgaImageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "FpgaImageId": {
                  "locationName": "fpgaImageId"
                },
                "FpgaImageGlobalId": {
                  "locationName": "fpgaImageGlobalId"
                },
                "Name": {
                  "locationName": "name"
                },
                "Description": {
                  "locationName": "description"
                },
                "ShellVersion": {
                  "locationName": "shellVersion"
                },
                "PciId": {
                  "locationName": "pciId",
                  "type": "structure",
                  "members": {
                    "DeviceId": {},
                    "VendorId": {},
                    "SubsystemId": {},
                    "SubsystemVendorId": {}
                  }
                },
                "State": {
                  "locationName": "state",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "UpdateTime": {
                  "locationName": "updateTime",
                  "type": "timestamp"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "ProductCodes": {
                  "shape": "S16z",
                  "locationName": "productCodes"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tags"
                },
                "Public": {
                  "locationName": "public",
                  "type": "boolean"
                },
                "DataRetentionSupport": {
                  "locationName": "dataRetentionSupport",
                  "type": "boolean"
                },
                "InstanceTypes": {
                  "locationName": "instanceTypes",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHostReservationOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S10p"
          },
          "MaxDuration": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "MinDuration": {
            "type": "integer"
          },
          "NextToken": {},
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "OfferingSet": {
            "locationName": "offeringSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                }
              }
            }
          }
        }
      }
    },
    "DescribeHostReservations": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S10p"
          },
          "HostReservationIdSet": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostReservationSet": {
            "locationName": "hostReservationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Count": {
                  "locationName": "count",
                  "type": "integer"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "HostIdSet": {
                  "shape": "S17p",
                  "locationName": "hostIdSet"
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHosts": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S10p",
            "locationName": "filter"
          },
          "HostIds": {
            "shape": "S17s",
            "locationName": "hostId"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Hosts": {
            "locationName": "hostSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AutoPlacement": {
                  "locationName": "autoPlacement"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "AvailableCapacity": {
                  "locationName": "availableCapacity",
                  "type": "structure",
                  "members": {
                    "AvailableInstanceCapacity": {
                      "locationName": "availableInstanceCapacity",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "AvailableCapacity": {
                            "locationName": "availableCapacity",
                            "type": "integer"
                          },
                          "InstanceType": {
                            "locationName": "instanceType"
                          },
                          "TotalCapacity": {
                            "locationName": "totalCapacity",
                            "type": "integer"
                          }
                        }
                      }
                    },
                    "AvailableVCpus": {
                      "locationName": "availableVCpus",
                      "type": "integer"
                    }
                  }
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "HostId": {
                  "locationName": "hostId"
                },
                "HostProperties": {
                  "locationName": "hostProperties",
                  "type": "structure",
                  "members": {
                    "Cores": {
                      "locationName": "cores",
                      "type": "integer"
                    },
                    "InstanceType": {
                      "locationName": "instanceType"
                    },
                    "InstanceFamily": {
                      "locationName": "instanceFamily"
                    },
                    "Sockets": {
                      "locationName": "sockets",
                      "type": "integer"
                    },
                    "TotalVCpus": {
                      "locationName": "totalVCpus",
                      "type": "integer"
                    }
                  }
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "Instances": {
                  "locationName": "instances",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "InstanceId": {
                        "locationName": "instanceId"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      },
                      "OwnerId": {
                        "locationName": "ownerId"
                      }
                    }
                  }
                },
                "State": {
                  "locationName": "state"
                },
                "AllocationTime": {
                  "locationName": "allocationTime",
                  "type": "timestamp"
                },
                "ReleaseTime": {
                  "locationName": "releaseTime",
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "HostRecovery": {
                  "locationName": "hostRecovery"
                },
                "AllowsMultipleInstanceTypes": {
                  "locationName": "allowsMultipleInstanceTypes"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "AvailabilityZoneId": {
                  "locationName": "availabilityZoneId"
                },
                "MemberOfServiceLinkedResourceGroup": {
                  "locationName": "memberOfServiceLinkedResourceGroup",
                  "type": "boolean"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "HostMaintenance": {
                  "locationName": "hostMaintenance"
                },
                "AssetId": {
                  "locationName": "assetId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIamInstanceProfileAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "locationName": "AssociationId",
            "type": "list",
            "member": {
              "locationName": "AssociationId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociations": {
            "locationName": "iamInstanceProfileAssociationSet",
            "type": "list",
            "member": {
              "shape": "S3x",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "Resource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "S116",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn"
        ],
        "members": {
          "PrincipalArn": {
            "locationName": "principalArn"
          },
          "Resource": {
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "S116",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlockDeviceMappings": {
            "shape": "S18h",
            "locationName": "blockDeviceMapping"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "LaunchPermissions": {
            "shape": "S18i",
            "locationName": "launchPermission"
          },
          "ProductCodes": {
            "shape": "S16z",
            "locationName": "productCodes"
          },
          "Description": {
            "shape": "Sc5",
            "locationName": "description"
          },
          "KernelId": {
            "shape": "Sc5",
            "locationName": "kernel"
          },
          "RamdiskId": {
            "shape": "Sc5",
            "locationName": "ramdisk"
          },
          "SriovNetSupport": {
            "shape": "Sc5",
            "locationName": "sriovNetSupport"
          },
          "BootMode": {
            "shape": "Sc5",
            "locationName": "bootMode"
          },
          "TpmSupport": {
            "shape": "Sc5",
            "locationName": "tpmSupport"
          },
          "UefiData": {
            "shape": "Sc5",
            "locationName": "uefiData"
          },
          "LastLaunchedTime": {
            "shape": "Sc5",
            "locationName": "lastLaunchedTime"
          },
          "ImdsSupport": {
            "shape": "Sc5",
            "locationName": "imdsSupport"
          },
          "DeregistrationProtection": {
            "shape": "Sc5",
            "locationName": "deregistrationProtection"
          }
        }
      }
    },
    "DescribeImages": {
      "input": {
        "type": "structure",
        "members": {
          "ExecutableUsers": {
            "locationName": "ExecutableBy",
            "type": "list",
            "member": {
              "locationName": "ExecutableBy"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "ImageIds": {
            "shape": "S18m",
            "locationName": "ImageId"
          },
          "Owners": {
            "shape": "S174",
            "locationName": "Owner"
          },
          "IncludeDeprecated": {
            "type": "boolean"
          },
          "IncludeDisabled": {
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "locationName": "imagesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Architecture": {
                  "locationName": "architecture"
                },
                "CreationDate": {
                  "locationName": "creationDate"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImageLocation": {
                  "locationName": "imageLocation"
                },
                "ImageType": {
                  "locationName": "imageType"
                },
                "Public": {
                  "locationName": "isPublic",
                  "type": "boolean"
                },
                "KernelId": {
                  "locationName": "kernelId"
                },
                "OwnerId": {
                  "locationName": "imageOwnerId"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "PlatformDetails": {
                  "locationName": "platformDetails"
                },
                "UsageOperation": {
                  "locationName": "usageOperation"
                },
                "ProductCodes": {
                  "shape": "S16z",
                  "locationName": "productCodes"
                },
                "RamdiskId": {
                  "locationName": "ramdiskId"
                },
                "State": {
                  "locationName": "imageState"
                },
                "BlockDeviceMappings": {
                  "shape": "S18h",
                  "locationName": "blockDeviceMapping"
                },
                "Description": {
                  "locationName": "description"
                },
                "EnaSupport": {
                  "locationName": "enaSupport",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ImageOwnerAlias": {
                  "locationName": "imageOwnerAlias"
                },
                "Name": {
                  "locationName": "name"
                },
                "RootDeviceName": {
                  "locationName": "rootDeviceName"
                },
                "RootDeviceType": {
                  "locationName": "rootDeviceType"
                },
                "SriovNetSupport": {
                  "locationName": "sriovNetSupport"
                },
                "StateReason": {
                  "shape": "Sk6",
                  "locationName": "stateReason"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "VirtualizationType": {
                  "locationName": "virtualizationType"
                },
                "BootMode": {
                  "locationName": "bootMode"
                },
                "TpmSupport": {
                  "locationName": "tpmSupport"
                },
                "DeprecationTime": {
                  "locationName": "deprecationTime"
                },
                "ImdsSupport": {
                  "locationName": "imdsSupport"
                },
                "SourceInstanceId": {
                  "locationName": "sourceInstanceId"
                },
                "DeregistrationProtection": {
                  "locationName": "deregistrationProtection"
                },
                "LastLaunchedTime": {
                  "locationName": "lastLaunchedTime"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeImportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p"
          },
          "ImportTaskIds": {
            "locationName": "ImportTaskId",
            "type": "list",
            "member": {
              "locationName": "ImportTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportImageTasks": {
            "locationName": "importImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Architecture": {
                  "locationName": "architecture"
                },
                "Description": {
                  "locationName": "description"
                },
                "Encrypted": {
                  "locationName": "encrypted",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "KmsKeyId": {
                  "locationName": "kmsKeyId"
                },
                "LicenseType": {
                  "locationName": "licenseType"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "SnapshotDetails": {
                  "shape": "S195",
                  "locationName": "snapshotDetailSet"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "LicenseSpecifications": {
                  "shape": "S199",
                  "locationName": "licenseSpecifications"
                },
                "UsageOperation": {
                  "locationName": "usageOperation"
                },
                "BootMode": {
                  "locationName": "bootMode"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeImportSnapshotTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p"
          },
          "ImportTaskIds": {
            "locationName": "ImportTaskId",
            "type": "list",
            "member": {
              "locationName": "ImportTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportSnapshotTasks": {
            "locationName": "importSnapshotTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "SnapshotTaskDetail": {
                  "shape": "S19h",
                  "locationName": "snapshotTaskDetail"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "InstanceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "Sm8",
            "locationName": "groupSet"
          },
          "BlockDeviceMappings": {
            "shape": "S19l",
            "locationName": "blockDeviceMapping"
          },
          "DisableApiTermination": {
            "shape": "S19o",
            "locationName": "disableApiTermination"
          },
          "EnaSupport": {
            "shape": "S19o",
            "locationName": "enaSupport"
          },
          "EnclaveOptions": {
            "shape": "S19p",
            "locationName": "enclaveOptions"
          },
          "EbsOptimized": {
            "shape": "S19o",
            "locationName": "ebsOptimized"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "Sc5",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "InstanceType": {
            "shape": "Sc5",
            "locationName": "instanceType"
          },
          "KernelId": {
            "shape": "Sc5",
            "locationName": "kernel"
          },
          "ProductCodes": {
            "shape": "S16z",
            "locationName": "productCodes"
          },
          "RamdiskId": {
            "shape": "Sc5",
            "locationName": "ramdisk"
          },
          "RootDeviceName": {
            "shape": "Sc5",
            "locationName": "rootDeviceName"
          },
          "SourceDestCheck": {
            "shape": "S19o",
            "locationName": "sourceDestCheck"
          },
          "SriovNetSupport": {
            "shape": "Sc5",
            "locationName": "sriovNetSupport"
          },
          "UserData": {
            "shape": "Sc5",
            "locationName": "userData"
          },
          "DisableApiStop": {
            "shape": "S19o",
            "locationName": "disableApiStop"
          }
        }
      }
    },
    "DescribeInstanceConnectEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "InstanceConnectEndpointIds": {
            "shape": "So",
            "locationName": "InstanceConnectEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceConnectEndpoints": {
            "locationName": "instanceConnectEndpointSet",
            "type": "list",
            "member": {
              "shape": "Sf4",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceCreditSpecifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceCreditSpecifications": {
            "locationName": "instanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "CpuCredits": {
                  "locationName": "cpuCredits"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceEventNotificationAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTagAttribute": {
            "shape": "S104",
            "locationName": "instanceTagAttribute"
          }
        }
      }
    },
    "DescribeInstanceEventWindows": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceEventWindowIds": {
            "locationName": "InstanceEventWindowId",
            "type": "list",
            "member": {
              "locationName": "InstanceEventWindowId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceEventWindows": {
            "locationName": "instanceEventWindowSet",
            "type": "list",
            "member": {
              "shape": "S47",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "IncludeAllInstances": {
            "locationName": "includeAllInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceStatuses": {
            "locationName": "instanceStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "shape": "S1ab",
                    "locationName": "item"
                  }
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "InstanceState": {
                  "shape": "S1ae",
                  "locationName": "instanceState"
                },
                "InstanceStatus": {
                  "shape": "S1ag",
                  "locationName": "instanceStatus"
                },
                "SystemStatus": {
                  "shape": "S1ag",
                  "locationName": "systemStatus"
                },
                "AttachedEbsStatus": {
                  "locationName": "attachedEbsStatus",
                  "type": "structure",
                  "members": {
                    "Details": {
                      "locationName": "details",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "ImpairedSince": {
                            "locationName": "impairedSince",
                            "type": "timestamp"
                          },
                          "Name": {
                            "locationName": "name"
                          },
                          "Status": {
                            "locationName": "status"
                          }
                        }
                      }
                    },
                    "Status": {
                      "locationName": "status"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceTopology": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "InstanceIds": {
            "locationName": "InstanceId",
            "type": "list",
            "member": {}
          },
          "GroupNames": {
            "locationName": "GroupName",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "locationName": "instanceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "NetworkNodes": {
                  "locationName": "networkNodeSet",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "ZoneId": {
                  "locationName": "zoneId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceTypeOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LocationType": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypeOfferings": {
            "locationName": "instanceTypeOfferingSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "LocationType": {
                  "locationName": "locationType"
                },
                "Location": {
                  "locationName": "location"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceTypes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypes": {
            "locationName": "instanceTypeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "CurrentGeneration": {
                  "locationName": "currentGeneration",
                  "type": "boolean"
                },
                "FreeTierEligible": {
                  "locationName": "freeTierEligible",
                  "type": "boolean"
                },
                "SupportedUsageClasses": {
                  "locationName": "supportedUsageClasses",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "SupportedRootDeviceTypes": {
                  "locationName": "supportedRootDeviceTypes",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "SupportedVirtualizationTypes": {
                  "locationName": "supportedVirtualizationTypes",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "BareMetal": {
                  "locationName": "bareMetal",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ProcessorInfo": {
                  "locationName": "processorInfo",
                  "type": "structure",
                  "members": {
                    "SupportedArchitectures": {
                      "locationName": "supportedArchitectures",
                      "type": "list",
                      "member": {
                        "locationName": "item"
                      }
                    },
                    "SustainedClockSpeedInGhz": {
                      "locationName": "sustainedClockSpeedInGhz",
                      "type": "double"
                    },
                    "SupportedFeatures": {
                      "locationName": "supportedFeatures",
                      "type": "list",
                      "member": {
                        "locationName": "item"
                      }
                    },
                    "Manufacturer": {
                      "locationName": "manufacturer"
                    }
                  }
                },
                "VCpuInfo": {
                  "locationName": "vCpuInfo",
                  "type": "structure",
                  "members": {
                    "DefaultVCpus": {
                      "locationName": "defaultVCpus",
                      "type": "integer"
                    },
                    "DefaultCores": {
                      "locationName": "defaultCores",
                      "type": "integer"
                    },
                    "DefaultThreadsPerCore": {
                      "locationName": "defaultThreadsPerCore",
                      "type": "integer"
                    },
                    "ValidCores": {
                      "locationName": "validCores",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "integer"
                      }
                    },
                    "ValidThreadsPerCore": {
                      "locationName": "validThreadsPerCore",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "integer"
                      }
                    }
                  }
                },
                "MemoryInfo": {
                  "locationName": "memoryInfo",
                  "type": "structure",
                  "members": {
                    "SizeInMiB": {
                      "locationName": "sizeInMiB",
                      "type": "long"
                    }
                  }
                },
                "InstanceStorageSupported": {
                  "locationName": "instanceStorageSupported",
                  "type": "boolean"
                },
                "InstanceStorageInfo": {
                  "locationName": "instanceStorageInfo",
                  "type": "structure",
                  "members": {
                    "TotalSizeInGB": {
                      "locationName": "totalSizeInGB",
                      "type": "long"
                    },
                    "Disks": {
                      "locationName": "disks",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "SizeInGB": {
                            "locationName": "sizeInGB",
                            "type": "long"
                          },
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "Type": {
                            "locationName": "type"
                          }
                        }
                      }
                    },
                    "NvmeSupport": {
                      "locationName": "nvmeSupport"
                    },
                    "EncryptionSupport": {
                      "locationName": "encryptionSupport"
                    }
                  }
                },
                "EbsInfo": {
                  "locationName": "ebsInfo",
                  "type": "structure",
                  "members": {
                    "EbsOptimizedSupport": {
                      "locationName": "ebsOptimizedSupport"
                    },
                    "EncryptionSupport": {
                      "locationName": "encryptionSupport"
                    },
                    "EbsOptimizedInfo": {
                      "locationName": "ebsOptimizedInfo",
                      "type": "structure",
                      "members": {
                        "BaselineBandwidthInMbps": {
                          "locationName": "baselineBandwidthInMbps",
                          "type": "integer"
                        },
                        "BaselineThroughputInMBps": {
                          "locationName": "baselineThroughputInMBps",
                          "type": "double"
                        },
                        "BaselineIops": {
                          "locationName": "baselineIops",
                          "type": "integer"
                        },
                        "MaximumBandwidthInMbps": {
                          "locationName": "maximumBandwidthInMbps",
                          "type": "integer"
                        },
                        "MaximumThroughputInMBps": {
                          "locationName": "maximumThroughputInMBps",
                          "type": "double"
                        },
                        "MaximumIops": {
                          "locationName": "maximumIops",
                          "type": "integer"
                        }
                      }
                    },
                    "NvmeSupport": {
                      "locationName": "nvmeSupport"
                    }
                  }
                },
                "NetworkInfo": {
                  "locationName": "networkInfo",
                  "type": "structure",
                  "members": {
                    "NetworkPerformance": {
                      "locationName": "networkPerformance"
                    },
                    "MaximumNetworkInterfaces": {
                      "locationName": "maximumNetworkInterfaces",
                      "type": "integer"
                    },
                    "MaximumNetworkCards": {
                      "locationName": "maximumNetworkCards",
                      "type": "integer"
                    },
                    "DefaultNetworkCardIndex": {
                      "locationName": "defaultNetworkCardIndex",
                      "type": "integer"
                    },
                    "NetworkCards": {
                      "locationName": "networkCards",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "NetworkCardIndex": {
                            "locationName": "networkCardIndex",
                            "type": "integer"
                          },
                          "NetworkPerformance": {
                            "locationName": "networkPerformance"
                          },
                          "MaximumNetworkInterfaces": {
                            "locationName": "maximumNetworkInterfaces",
                            "type": "integer"
                          },
                          "BaselineBandwidthInGbps": {
                            "locationName": "baselineBandwidthInGbps",
                            "type": "double"
                          },
                          "PeakBandwidthInGbps": {
                            "locationName": "peakBandwidthInGbps",
                            "type": "double"
                          }
                        }
                      }
                    },
                    "Ipv4AddressesPerInterface": {
                      "locationName": "ipv4AddressesPerInterface",
                      "type": "integer"
                    },
                    "Ipv6AddressesPerInterface": {
                      "locationName": "ipv6AddressesPerInterface",
                      "type": "integer"
                    },
                    "Ipv6Supported": {
                      "locationName": "ipv6Supported",
                      "type": "boolean"
                    },
                    "EnaSupport": {
                      "locationName": "enaSupport"
                    },
                    "EfaSupported": {
                      "locationName": "efaSupported",
                      "type": "boolean"
                    },
                    "EfaInfo": {
                      "locationName": "efaInfo",
                      "type": "structure",
                      "members": {
                        "MaximumEfaInterfaces": {
                          "locationName": "maximumEfaInterfaces",
                          "type": "integer"
                        }
                      }
                    },
                    "EncryptionInTransitSupported": {
                      "locationName": "encryptionInTransitSupported",
                      "type": "boolean"
                    },
                    "EnaSrdSupported": {
                      "locationName": "enaSrdSupported",
                      "type": "boolean"
                    }
                  }
                },
                "GpuInfo": {
                  "locationName": "gpuInfo",
                  "type": "structure",
                  "members": {
                    "Gpus": {
                      "locationName": "gpus",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          },
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalGpuMemoryInMiB": {
                      "locationName": "totalGpuMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "FpgaInfo": {
                  "locationName": "fpgaInfo",
                  "type": "structure",
                  "members": {
                    "Fpgas": {
                      "locationName": "fpgas",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          },
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalFpgaMemoryInMiB": {
                      "locationName": "totalFpgaMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "PlacementGroupInfo": {
                  "locationName": "placementGroupInfo",
                  "type": "structure",
                  "members": {
                    "SupportedStrategies": {
                      "locationName": "supportedStrategies",
                      "type": "list",
                      "member": {
                        "locationName": "item"
                      }
                    }
                  }
                },
                "InferenceAcceleratorInfo": {
                  "locationName": "inferenceAcceleratorInfo",
                  "type": "structure",
                  "members": {
                    "Accelerators": {
                      "locationName": "item",
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalInferenceMemoryInMiB": {
                      "locationName": "totalInferenceMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "HibernationSupported": {
                  "locationName": "hibernationSupported",
                  "type": "boolean"
                },
                "BurstablePerformanceSupported": {
                  "locationName": "burstablePerformanceSupported",
                  "type": "boolean"
                },
                "DedicatedHostsSupported": {
                  "locationName": "dedicatedHostsSupported",
                  "type": "boolean"
                },
                "AutoRecoverySupported": {
                  "locationName": "autoRecoverySupported",
                  "type": "boolean"
                },
                "SupportedBootModes": {
                  "locationName": "supportedBootModes",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "NitroEnclavesSupport": {
                  "locationName": "nitroEnclavesSupport"
                },
                "NitroTpmSupport": {
                  "locationName": "nitroTpmSupport"
                },
                "NitroTpmInfo": {
                  "locationName": "nitroTpmInfo",
                  "type": "structure",
                  "members": {
                    "SupportedVersions": {
                      "locationName": "supportedVersions",
                      "type": "list",
                      "member": {
                        "locationName": "item"
                      }
                    }
                  }
                },
                "MediaAcceleratorInfo": {
                  "locationName": "mediaAcceleratorInfo",
                  "type": "structure",
                  "members": {
                    "Accelerators": {
                      "locationName": "accelerators",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalMediaMemoryInMiB": {
                      "locationName": "totalMediaMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "NeuronInfo": {
                  "locationName": "neuronInfo",
                  "type": "structure",
                  "members": {
                    "NeuronDevices": {
                      "locationName": "neuronDevices",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "Name": {
                            "locationName": "name"
                          },
                          "CoreInfo": {
                            "locationName": "coreInfo",
                            "type": "structure",
                            "members": {
                              "Count": {
                                "locationName": "count",
                                "type": "integer"
                              },
                              "Version": {
                                "locationName": "version",
                                "type": "integer"
                              }
                            }
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalNeuronDeviceMemoryInMiB": {
                      "locationName": "totalNeuronDeviceMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "PhcSupport": {
                  "locationName": "phcSupport"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Reservations": {
            "locationName": "reservationSet",
            "type": "list",
            "member": {
              "shape": "S1eu",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayIds": {
            "locationName": "internetGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateways": {
            "locationName": "internetGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sfp",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIpamByoasn": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Byoasns": {
            "locationName": "byoasnSet",
            "type": "list",
            "member": {
              "shape": "Szn",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIpamExternalResourceVerificationTokens": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "IpamExternalResourceVerificationTokenIds": {
            "shape": "So",
            "locationName": "IpamExternalResourceVerificationTokenId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "IpamExternalResourceVerificationTokens": {
            "locationName": "ipamExternalResourceVerificationTokenSet",
            "type": "list",
            "member": {
              "shape": "Sg2",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeIpamPools": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IpamPoolIds": {
            "shape": "So",
            "locationName": "IpamPoolId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "IpamPools": {
            "locationName": "ipamPoolSet",
            "type": "list",
            "member": {
              "shape": "Sgg",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeIpamResourceDiscoveries": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryIds": {
            "shape": "So",
            "locationName": "IpamResourceDiscoveryId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscoveries": {
            "locationName": "ipamResourceDiscoverySet",
            "type": "list",
            "member": {
              "shape": "Sgo",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIpamResourceDiscoveryAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryAssociationIds": {
            "shape": "So",
            "locationName": "IpamResourceDiscoveryAssociationId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscoveryAssociations": {
            "locationName": "ipamResourceDiscoveryAssociationSet",
            "type": "list",
            "member": {
              "shape": "S4l",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIpamScopes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IpamScopeIds": {
            "shape": "So",
            "locationName": "IpamScopeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "IpamScopes": {
            "locationName": "ipamScopeSet",
            "type": "list",
            "member": {
              "shape": "Sgs",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeIpams": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IpamIds": {
            "shape": "So",
            "locationName": "IpamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Ipams": {
            "locationName": "ipamSet",
            "type": "list",
            "member": {
              "shape": "Sfv",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeIpv6Pools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "locationName": "PoolId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6Pools": {
            "locationName": "ipv6PoolSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PoolId": {
                  "locationName": "poolId"
                },
                "Description": {
                  "locationName": "description"
                },
                "PoolCidrBlocks": {
                  "locationName": "poolCidrBlockSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Cidr": {
                        "locationName": "poolCidrBlock"
                      }
                    }
                  }
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeKeyPairs": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "KeyNames": {
            "locationName": "KeyName",
            "type": "list",
            "member": {
              "locationName": "KeyName"
            }
          },
          "KeyPairIds": {
            "locationName": "KeyPairId",
            "type": "list",
            "member": {
              "locationName": "KeyPairId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "IncludePublicKey": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPairs": {
            "locationName": "keySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "KeyPairId": {
                  "locationName": "keyPairId"
                },
                "KeyFingerprint": {
                  "locationName": "keyFingerprint"
                },
                "KeyName": {
                  "locationName": "keyName"
                },
                "KeyType": {
                  "locationName": "keyType"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "PublicKey": {
                  "locationName": "publicKey"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeLaunchTemplateVersions": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "Versions": {
            "shape": "Swd",
            "locationName": "LaunchTemplateVersion"
          },
          "MinVersion": {},
          "MaxVersion": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "ResolveAlias": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateVersions": {
            "locationName": "launchTemplateVersionSet",
            "type": "list",
            "member": {
              "shape": "Sis",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLaunchTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateIds": {
            "locationName": "LaunchTemplateId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "LaunchTemplateNames": {
            "locationName": "LaunchTemplateName",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplates": {
            "locationName": "launchTemplates",
            "type": "list",
            "member": {
              "shape": "Sim",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayRouteTableVirtualInterfaceGroupAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociationIds": {
            "locationName": "LocalGatewayRouteTableVirtualInterfaceGroupAssociationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociations": {
            "locationName": "localGatewayRouteTableVirtualInterfaceGroupAssociationSet",
            "type": "list",
            "member": {
              "shape": "Sk9",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayRouteTableVpcAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociationIds": {
            "locationName": "LocalGatewayRouteTableVpcAssociationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociations": {
            "locationName": "localGatewayRouteTableVpcAssociationSet",
            "type": "list",
            "member": {
              "shape": "Skd",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableIds": {
            "locationName": "LocalGatewayRouteTableId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTables": {
            "locationName": "localGatewayRouteTableSet",
            "type": "list",
            "member": {
              "shape": "Sk5",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayVirtualInterfaceGroups": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaceGroupIds": {
            "locationName": "LocalGatewayVirtualInterfaceGroupId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaceGroups": {
            "locationName": "localGatewayVirtualInterfaceGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayVirtualInterfaceGroupId": {
                  "locationName": "localGatewayVirtualInterfaceGroupId"
                },
                "LocalGatewayVirtualInterfaceIds": {
                  "shape": "S1ht",
                  "locationName": "localGatewayVirtualInterfaceIdSet"
                },
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayVirtualInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaceIds": {
            "shape": "S1ht",
            "locationName": "LocalGatewayVirtualInterfaceId"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaces": {
            "locationName": "localGatewayVirtualInterfaceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayVirtualInterfaceId": {
                  "locationName": "localGatewayVirtualInterfaceId"
                },
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "Vlan": {
                  "locationName": "vlan",
                  "type": "integer"
                },
                "LocalAddress": {
                  "locationName": "localAddress"
                },
                "PeerAddress": {
                  "locationName": "peerAddress"
                },
                "LocalBgpAsn": {
                  "locationName": "localBgpAsn",
                  "type": "integer"
                },
                "PeerBgpAsn": {
                  "locationName": "peerBgpAsn",
                  "type": "integer"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGateways": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayIds": {
            "locationName": "LocalGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGateways": {
            "locationName": "localGatewaySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "State": {
                  "locationName": "state"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLockedSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SnapshotIds": {
            "shape": "S1i6",
            "locationName": "SnapshotId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "LockState": {
                  "locationName": "lockState"
                },
                "LockDuration": {
                  "locationName": "lockDuration",
                  "type": "integer"
                },
                "CoolOffPeriod": {
                  "locationName": "coolOffPeriod",
                  "type": "integer"
                },
                "CoolOffPeriodExpiresOn": {
                  "locationName": "coolOffPeriodExpiresOn",
                  "type": "timestamp"
                },
                "LockCreatedOn": {
                  "locationName": "lockCreatedOn",
                  "type": "timestamp"
                },
                "LockDurationStartTime": {
                  "locationName": "lockDurationStartTime",
                  "type": "timestamp"
                },
                "LockExpiresOn": {
                  "locationName": "lockExpiresOn",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeMacHosts": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "HostIds": {
            "shape": "S17s",
            "locationName": "HostId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MacHosts": {
            "locationName": "macHostSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "HostId": {
                  "locationName": "hostId"
                },
                "MacOSLatestSupportedVersions": {
                  "locationName": "macOSLatestSupportedVersionSet",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeManagedPrefixLists": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PrefixListIds": {
            "shape": "So",
            "locationName": "PrefixListId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "PrefixLists": {
            "locationName": "prefixListSet",
            "type": "list",
            "member": {
              "shape": "Skj",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeMovingAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "PublicIps": {
            "shape": "So",
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MovingAddressStatuses": {
            "locationName": "movingAddressStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "MoveStatus": {
                  "locationName": "moveStatus"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNatGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filter": {
            "shape": "S10p"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NatGatewayIds": {
            "locationName": "NatGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGateways": {
            "locationName": "natGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sko",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkAcls": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclIds": {
            "locationName": "NetworkAclId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcls": {
            "locationName": "networkAclSet",
            "type": "list",
            "member": {
              "shape": "Skt",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInsightsAccessScopeAnalyses": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeAnalysisIds": {
            "locationName": "NetworkInsightsAccessScopeAnalysisId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NetworkInsightsAccessScopeId": {},
          "AnalysisStartTimeBegin": {
            "type": "timestamp"
          },
          "AnalysisStartTimeEnd": {
            "type": "timestamp"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeAnalyses": {
            "locationName": "networkInsightsAccessScopeAnalysisSet",
            "type": "list",
            "member": {
              "shape": "S1j8",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInsightsAccessScopes": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeIds": {
            "locationName": "NetworkInsightsAccessScopeId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopes": {
            "locationName": "networkInsightsAccessScopeSet",
            "type": "list",
            "member": {
              "shape": "Sle",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInsightsAnalyses": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInsightsAnalysisIds": {
            "locationName": "NetworkInsightsAnalysisId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NetworkInsightsPathId": {},
          "AnalysisStartTime": {
            "type": "timestamp"
          },
          "AnalysisEndTime": {
            "type": "timestamp"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAnalyses": {
            "locationName": "networkInsightsAnalysisSet",
            "type": "list",
            "member": {
              "shape": "S1jj",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInsightsPaths": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInsightsPathIds": {
            "locationName": "NetworkInsightsPathId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsPaths": {
            "locationName": "networkInsightsPathSet",
            "type": "list",
            "member": {
              "shape": "Slv",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attachment": {
            "shape": "Sm4",
            "locationName": "attachment"
          },
          "Description": {
            "shape": "Sc5",
            "locationName": "description"
          },
          "Groups": {
            "shape": "Sm8",
            "locationName": "groupSet"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "shape": "S19o",
            "locationName": "sourceDestCheck"
          },
          "AssociatePublicIpAddress": {
            "locationName": "associatePublicIpAddress",
            "type": "boolean"
          }
        }
      }
    },
    "DescribeNetworkInterfacePermissions": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInterfacePermissionIds": {
            "locationName": "NetworkInterfacePermissionId",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfacePermissions": {
            "locationName": "networkInterfacePermissions",
            "type": "list",
            "member": {
              "shape": "Sml",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceIds": {
            "locationName": "NetworkInterfaceId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaces": {
            "locationName": "networkInterfaceSet",
            "type": "list",
            "member": {
              "shape": "Sm2",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribePlacementGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupNames": {
            "locationName": "groupName",
            "type": "list",
            "member": {}
          },
          "GroupIds": {
            "locationName": "GroupId",
            "type": "list",
            "member": {
              "locationName": "GroupId"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlacementGroups": {
            "locationName": "placementGroupSet",
            "type": "list",
            "member": {
              "shape": "Sms",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribePrefixLists": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PrefixListIds": {
            "locationName": "PrefixListId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "PrefixLists": {
            "locationName": "prefixListSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Cidrs": {
                  "shape": "So",
                  "locationName": "cidrSet"
                },
                "PrefixListId": {
                  "locationName": "prefixListId"
                },
                "PrefixListName": {
                  "locationName": "prefixListName"
                }
              }
            }
          }
        }
      }
    },
    "DescribePrincipalIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Resources": {
            "locationName": "Resource",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "locationName": "principalSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "Statuses": {
                  "shape": "S116",
                  "locationName": "statusSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribePublicIpv4Pools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "locationName": "PoolId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIpv4Pools": {
            "locationName": "publicIpv4PoolSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PoolId": {
                  "locationName": "poolId"
                },
                "Description": {
                  "locationName": "description"
                },
                "PoolAddressRanges": {
                  "locationName": "poolAddressRangeSet",
                  "type": "list",
                  "member": {
                    "shape": "S1lm",
                    "locationName": "item"
                  }
                },
                "TotalAddressCount": {
                  "locationName": "totalAddressCount",
                  "type": "integer"
                },
                "TotalAvailableAddressCount": {
                  "locationName": "totalAvailableAddressCount",
                  "type": "integer"
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRegions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "RegionNames": {
            "locationName": "RegionName",
            "type": "list",
            "member": {
              "locationName": "RegionName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AllRegions": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Regions": {
            "locationName": "regionInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Endpoint": {
                  "locationName": "regionEndpoint"
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "OptInStatus": {
                  "locationName": "optInStatus"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReplaceRootVolumeTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ReplaceRootVolumeTaskIds": {
            "locationName": "ReplaceRootVolumeTaskId",
            "type": "list",
            "member": {
              "locationName": "ReplaceRootVolumeTaskId"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplaceRootVolumeTasks": {
            "locationName": "replaceRootVolumeTaskSet",
            "type": "list",
            "member": {
              "shape": "Smy",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeReservedInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "OfferingClass": {},
          "ReservedInstancesIds": {
            "shape": "S1lz",
            "locationName": "ReservedInstancesId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "OfferingType": {
            "locationName": "offeringType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstances": {
            "locationName": "reservedInstancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "RecurringCharges": {
                  "shape": "S1m7",
                  "locationName": "recurringCharges"
                },
                "Scope": {
                  "locationName": "scope"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesListings": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S8m",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "DescribeReservedInstancesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "ReservedInstancesModificationIds": {
            "locationName": "ReservedInstancesModificationId",
            "type": "list",
            "member": {
              "locationName": "ReservedInstancesModificationId"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ReservedInstancesModifications": {
            "locationName": "reservedInstancesModificationsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "CreateDate": {
                  "locationName": "createDate",
                  "type": "timestamp"
                },
                "EffectiveDate": {
                  "locationName": "effectiveDate",
                  "type": "timestamp"
                },
                "ModificationResults": {
                  "locationName": "modificationResultSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      },
                      "TargetConfiguration": {
                        "shape": "S1ml",
                        "locationName": "targetConfiguration"
                      }
                    }
                  }
                },
                "ReservedInstancesIds": {
                  "locationName": "reservedInstancesSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      }
                    }
                  }
                },
                "ReservedInstancesModificationId": {
                  "locationName": "reservedInstancesModificationId"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "UpdateDate": {
                  "locationName": "updateDate",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZone": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "IncludeMarketplace": {
            "type": "boolean"
          },
          "InstanceType": {},
          "MaxDuration": {
            "type": "long"
          },
          "MaxInstanceCount": {
            "type": "integer"
          },
          "MinDuration": {
            "type": "long"
          },
          "OfferingClass": {},
          "ProductDescription": {},
          "ReservedInstancesOfferingIds": {
            "locationName": "ReservedInstancesOfferingId",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "OfferingType": {
            "locationName": "offeringType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesOfferings": {
            "locationName": "reservedInstancesOfferingsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "ReservedInstancesOfferingId": {
                  "locationName": "reservedInstancesOfferingId"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "Marketplace": {
                  "locationName": "marketplace",
                  "type": "boolean"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "PricingDetails": {
                  "locationName": "pricingDetailsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Count": {
                        "locationName": "count",
                        "type": "integer"
                      },
                      "Price": {
                        "locationName": "price",
                        "type": "double"
                      }
                    }
                  }
                },
                "RecurringCharges": {
                  "shape": "S1m7",
                  "locationName": "recurringCharges"
                },
                "Scope": {
                  "locationName": "scope"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableIds": {
            "locationName": "RouteTableId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTables": {
            "locationName": "routeTableSet",
            "type": "list",
            "member": {
              "shape": "Sne",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeScheduledInstanceAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "FirstSlotStartTimeRange",
          "Recurrence"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "FirstSlotStartTimeRange": {
            "type": "structure",
            "required": [
              "EarliestTime",
              "LatestTime"
            ],
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "MaxSlotDurationInHours": {
            "type": "integer"
          },
          "MinSlotDurationInHours": {
            "type": "integer"
          },
          "NextToken": {},
          "Recurrence": {
            "type": "structure",
            "members": {
              "Frequency": {},
              "Interval": {
                "type": "integer"
              },
              "OccurrenceDays": {
                "locationName": "OccurrenceDay",
                "type": "list",
                "member": {
                  "locationName": "OccurenceDay",
                  "type": "integer"
                }
              },
              "OccurrenceRelativeToEnd": {
                "type": "boolean"
              },
              "OccurrenceUnit": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceAvailabilitySet": {
            "locationName": "scheduledInstanceAvailabilitySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "AvailableInstanceCount": {
                  "locationName": "availableInstanceCount",
                  "type": "integer"
                },
                "FirstSlotStartTime": {
                  "locationName": "firstSlotStartTime",
                  "type": "timestamp"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "MaxTermDurationInDays": {
                  "locationName": "maxTermDurationInDays",
                  "type": "integer"
                },
                "MinTermDurationInDays": {
                  "locationName": "minTermDurationInDays",
                  "type": "integer"
                },
                "NetworkPlatform": {
                  "locationName": "networkPlatform"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "PurchaseToken": {
                  "locationName": "purchaseToken"
                },
                "Recurrence": {
                  "shape": "S1n8",
                  "locationName": "recurrence"
                },
                "SlotDurationInHours": {
                  "locationName": "slotDurationInHours",
                  "type": "integer"
                },
                "TotalScheduledInstanceHours": {
                  "locationName": "totalScheduledInstanceHours",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeScheduledInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ScheduledInstanceIds": {
            "locationName": "ScheduledInstanceId",
            "type": "list",
            "member": {
              "locationName": "ScheduledInstanceId"
            }
          },
          "SlotStartTimeRange": {
            "type": "structure",
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "S1ng",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeSecurityGroupReferences": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupReferenceSet": {
            "locationName": "securityGroupReferenceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupId": {
                  "locationName": "groupId"
                },
                "ReferencingVpcId": {
                  "locationName": "referencingVpcId"
                },
                "VpcPeeringConnectionId": {
                  "locationName": "vpcPeeringConnectionId"
                },
                "TransitGatewayId": {
                  "locationName": "transitGatewayId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSecurityGroupRules": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "SecurityGroupRuleIds": {
            "shape": "S1nn",
            "locationName": "SecurityGroupRuleId"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupRules": {
            "shape": "S7a",
            "locationName": "securityGroupRuleSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "GroupIds": {
            "shape": "S5x",
            "locationName": "GroupId"
          },
          "GroupNames": {
            "shape": "S1nr",
            "locationName": "GroupName"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroups": {
            "locationName": "securityGroupInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "groupDescription"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "IpPermissions": {
                  "shape": "S6z",
                  "locationName": "ipPermissions"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "IpPermissionsEgress": {
                  "shape": "S6z",
                  "locationName": "ipPermissionsEgress"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateVolumePermissions": {
            "shape": "S1nz",
            "locationName": "createVolumePermission"
          },
          "ProductCodes": {
            "shape": "S16z",
            "locationName": "productCodes"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          }
        }
      }
    },
    "DescribeSnapshotTierStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotTierStatuses": {
            "locationName": "snapshotTierStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "Status": {
                  "locationName": "status"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "StorageTier": {
                  "locationName": "storageTier"
                },
                "LastTieringStartTime": {
                  "locationName": "lastTieringStartTime",
                  "type": "timestamp"
                },
                "LastTieringProgress": {
                  "locationName": "lastTieringProgress",
                  "type": "integer"
                },
                "LastTieringOperationStatus": {
                  "locationName": "lastTieringOperationStatus"
                },
                "LastTieringOperationStatusDetail": {
                  "locationName": "lastTieringOperationStatusDetail"
                },
                "ArchivalCompleteTime": {
                  "locationName": "archivalCompleteTime",
                  "type": "timestamp"
                },
                "RestoreExpiryTime": {
                  "locationName": "restoreExpiryTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "OwnerIds": {
            "shape": "S174",
            "locationName": "Owner"
          },
          "RestorableByUserIds": {
            "locationName": "RestorableBy",
            "type": "list",
            "member": {}
          },
          "SnapshotIds": {
            "shape": "S1i6",
            "locationName": "SnapshotId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "shape": "Snq",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "So4",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "DescribeSpotFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveInstances": {
            "shape": "S162",
            "locationName": "activeInstanceSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "DescribeSpotFleetRequestHistory": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EventType": {
            "locationName": "eventType"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "EventInformation": {
                  "shape": "S15z",
                  "locationName": "eventInformation"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSpotFleetRequests": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestIds": {
            "shape": "S8y",
            "locationName": "spotFleetRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestConfigs": {
            "locationName": "spotFleetRequestConfigSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ActivityStatus": {
                  "locationName": "activityStatus"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "SpotFleetRequestConfig": {
                  "shape": "S1or",
                  "locationName": "spotFleetRequestConfig"
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                },
                "SpotFleetRequestState": {
                  "locationName": "spotFleetRequestState"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S99",
            "locationName": "SpotInstanceRequestId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "S1pj",
            "locationName": "spotInstanceRequestSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotPriceHistory": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "ProductDescriptions": {
            "locationName": "ProductDescription",
            "type": "list",
            "member": {}
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotPriceHistory": {
            "locationName": "spotPriceHistorySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "SpotPrice": {
                  "locationName": "spotPrice"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStaleSecurityGroups": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "StaleSecurityGroupSet": {
            "locationName": "staleSecurityGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "StaleIpPermissions": {
                  "shape": "S1q1",
                  "locationName": "staleIpPermissions"
                },
                "StaleIpPermissionsEgress": {
                  "shape": "S1q1",
                  "locationName": "staleIpPermissionsEgress"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStoreImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ImageIds": {
            "locationName": "ImageId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StoreImageTaskResults": {
            "locationName": "storeImageTaskResultSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AmiId": {
                  "locationName": "amiId"
                },
                "TaskStartTime": {
                  "locationName": "taskStartTime",
                  "type": "timestamp"
                },
                "Bucket": {
                  "locationName": "bucket"
                },
                "S3objectKey": {
                  "locationName": "s3objectKey"
                },
                "ProgressPercentage": {
                  "locationName": "progressPercentage",
                  "type": "integer"
                },
                "StoreTaskState": {
                  "locationName": "storeTaskState"
                },
                "StoreTaskFailureReason": {
                  "locationName": "storeTaskFailureReason"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSubnets": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "SubnetIds": {
            "locationName": "SubnetId",
            "type": "list",
            "member": {
              "locationName": "SubnetId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnets": {
            "locationName": "subnetSet",
            "type": "list",
            "member": {
              "shape": "Sbk",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Tags": {
            "locationName": "tagSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {
                  "locationName": "key"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "Value": {
                  "locationName": "value"
                }
              }
            }
          }
        }
      }
    },
    "DescribeTrafficMirrorFilterRules": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRuleIds": {
            "locationName": "TrafficMirrorFilterRuleId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "TrafficMirrorFilterId": {},
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRules": {
            "locationName": "trafficMirrorFilterRuleSet",
            "type": "list",
            "member": {
              "shape": "Sop",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorFilters": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterIds": {
            "locationName": "TrafficMirrorFilterId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilters": {
            "locationName": "trafficMirrorFilterSet",
            "type": "list",
            "member": {
              "shape": "Son",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorSessions": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessionIds": {
            "locationName": "TrafficMirrorSessionId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessions": {
            "locationName": "trafficMirrorSessionSet",
            "type": "list",
            "member": {
              "shape": "Sp2",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorTargets": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargetIds": {
            "locationName": "TrafficMirrorTargetId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargets": {
            "locationName": "trafficMirrorTargetSet",
            "type": "list",
            "member": {
              "shape": "Sp5",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S1r3"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachments": {
            "locationName": "transitGatewayAttachments",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "TransitGatewayId": {
                  "locationName": "transitGatewayId"
                },
                "TransitGatewayOwnerId": {
                  "locationName": "transitGatewayOwnerId"
                },
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "State": {
                  "locationName": "state"
                },
                "Association": {
                  "locationName": "association",
                  "type": "structure",
                  "members": {
                    "TransitGatewayRouteTableId": {
                      "locationName": "transitGatewayRouteTableId"
                    },
                    "State": {
                      "locationName": "state"
                    }
                  }
                },
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayConnectPeers": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeerIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeers": {
            "locationName": "transitGatewayConnectPeerSet",
            "type": "list",
            "member": {
              "shape": "Spt",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayConnects": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S1r3"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnects": {
            "locationName": "transitGatewayConnectSet",
            "type": "list",
            "member": {
              "shape": "Spn",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayMulticastDomains": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomains": {
            "locationName": "transitGatewayMulticastDomains",
            "type": "list",
            "member": {
              "shape": "Sq6",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayPeeringAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S1r3"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachments": {
            "locationName": "transitGatewayPeeringAttachments",
            "type": "list",
            "member": {
              "shape": "Sx",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayPolicyTables": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayPolicyTableIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPolicyTables": {
            "locationName": "transitGatewayPolicyTables",
            "type": "list",
            "member": {
              "shape": "Sqf",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayRouteTableAnnouncements": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableAnnouncementIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableAnnouncements": {
            "locationName": "transitGatewayRouteTableAnnouncements",
            "type": "list",
            "member": {
              "shape": "Sr0",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTables": {
            "locationName": "transitGatewayRouteTables",
            "type": "list",
            "member": {
              "shape": "Sqw",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayVpcAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S1r3"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachments": {
            "locationName": "transitGatewayVpcAttachments",
            "type": "list",
            "member": {
              "shape": "S16",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGateways": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateways": {
            "locationName": "transitGatewaySet",
            "type": "list",
            "member": {
              "shape": "Spg",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrunkInterfaceAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "locationName": "AssociationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InterfaceAssociations": {
            "locationName": "interfaceAssociationSet",
            "type": "list",
            "member": {
              "shape": "S5m",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVerifiedAccessEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "VerifiedAccessEndpointIds": {
            "locationName": "VerifiedAccessEndpointId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "VerifiedAccessInstanceId": {},
          "VerifiedAccessGroupId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessEndpoints": {
            "locationName": "verifiedAccessEndpointSet",
            "type": "list",
            "member": {
              "shape": "Srk",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVerifiedAccessGroups": {
      "input": {
        "type": "structure",
        "members": {
          "VerifiedAccessGroupIds": {
            "locationName": "VerifiedAccessGroupId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "VerifiedAccessInstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessGroups": {
            "locationName": "verifiedAccessGroupSet",
            "type": "list",
            "member": {
              "shape": "Srs",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVerifiedAccessInstanceLoggingConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "VerifiedAccessInstanceIds": {
            "shape": "S1sm",
            "locationName": "VerifiedAccessInstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingConfigurations": {
            "locationName": "loggingConfigurationSet",
            "type": "list",
            "member": {
              "shape": "S1sq",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVerifiedAccessInstances": {
      "input": {
        "type": "structure",
        "members": {
          "VerifiedAccessInstanceIds": {
            "shape": "S1sm",
            "locationName": "VerifiedAccessInstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessInstances": {
            "locationName": "verifiedAccessInstanceSet",
            "type": "list",
            "member": {
              "shape": "S6i",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVerifiedAccessTrustProviders": {
      "input": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProviderIds": {
            "locationName": "VerifiedAccessTrustProviderId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProviders": {
            "locationName": "verifiedAccessTrustProviderSet",
            "type": "list",
            "member": {
              "shape": "S69",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "VolumeId"
        ],
        "members": {
          "Attribute": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutoEnableIO": {
            "shape": "S19o",
            "locationName": "autoEnableIO"
          },
          "ProductCodes": {
            "shape": "S16z",
            "locationName": "productCodes"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "DescribeVolumeStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "VolumeIds": {
            "shape": "Snx",
            "locationName": "VolumeId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "VolumeStatuses": {
            "locationName": "volumeStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Actions": {
                  "locationName": "actionsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Code": {
                        "locationName": "code"
                      },
                      "Description": {
                        "locationName": "description"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      }
                    }
                  }
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Description": {
                        "locationName": "description"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      },
                      "NotAfter": {
                        "locationName": "notAfter",
                        "type": "timestamp"
                      },
                      "NotBefore": {
                        "locationName": "notBefore",
                        "type": "timestamp"
                      },
                      "InstanceId": {
                        "locationName": "instanceId"
                      }
                    }
                  }
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "VolumeStatus": {
                  "locationName": "volumeStatus",
                  "type": "structure",
                  "members": {
                    "Details": {
                      "locationName": "details",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Status": {
                            "locationName": "status"
                          }
                        }
                      }
                    },
                    "Status": {
                      "locationName": "status"
                    }
                  }
                },
                "AttachmentStatuses": {
                  "locationName": "attachmentStatuses",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "IoPerformance": {
                        "locationName": "ioPerformance"
                      },
                      "InstanceId": {
                        "locationName": "instanceId"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "VolumeIds": {
            "shape": "Snx",
            "locationName": "VolumeId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "locationName": "volumeSet",
            "type": "list",
            "member": {
              "shape": "Ss0",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VolumeIds": {
            "shape": "Snx",
            "locationName": "VolumeId"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumesModifications": {
            "locationName": "volumeModificationSet",
            "type": "list",
            "member": {
              "shape": "S1tu",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "VpcId"
        ],
        "members": {
          "Attribute": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "EnableDnsHostnames": {
            "shape": "S19o",
            "locationName": "enableDnsHostnames"
          },
          "EnableDnsSupport": {
            "shape": "S19o",
            "locationName": "enableDnsSupport"
          },
          "EnableNetworkAddressUsageMetrics": {
            "shape": "S19o",
            "locationName": "enableNetworkAddressUsageMetrics"
          }
        }
      }
    },
    "DescribeVpcClassicLink": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcIds": {
            "shape": "S1u0",
            "locationName": "VpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClassicLinkEnabled": {
                  "locationName": "classicLinkEnabled",
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "VpcIds": {
            "shape": "S1u0"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Vpcs": {
            "locationName": "vpcs",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClassicLinkDnsSupported": {
                  "locationName": "classicLinkDnsSupported",
                  "type": "boolean"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcEndpointConnectionNotifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionNotificationSet": {
            "locationName": "connectionNotificationSet",
            "type": "list",
            "member": {
              "shape": "Ssq",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpointConnections": {
            "locationName": "vpcEndpointConnectionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ServiceId": {
                  "locationName": "serviceId"
                },
                "VpcEndpointId": {
                  "locationName": "vpcEndpointId"
                },
                "VpcEndpointOwner": {
                  "locationName": "vpcEndpointOwner"
                },
                "VpcEndpointState": {
                  "locationName": "vpcEndpointState"
                },
                "CreationTimestamp": {
                  "locationName": "creationTimestamp",
                  "type": "timestamp"
                },
                "DnsEntries": {
                  "shape": "Ssl",
                  "locationName": "dnsEntrySet"
                },
                "NetworkLoadBalancerArns": {
                  "shape": "So",
                  "locationName": "networkLoadBalancerArnSet"
                },
                "GatewayLoadBalancerArns": {
                  "shape": "So",
                  "locationName": "gatewayLoadBalancerArnSet"
                },
                "IpAddressType": {
                  "locationName": "ipAddressType"
                },
                "VpcEndpointConnectionId": {
                  "locationName": "vpcEndpointConnectionId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServiceConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceIds": {
            "shape": "Sza",
            "locationName": "ServiceId"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceConfigurations": {
            "locationName": "serviceConfigurationSet",
            "type": "list",
            "member": {
              "shape": "Ssv",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllowedPrincipals": {
            "locationName": "allowedPrincipals",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrincipalType": {
                  "locationName": "principalType"
                },
                "Principal": {
                  "locationName": "principal"
                },
                "ServicePermissionId": {
                  "locationName": "servicePermissionId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "ServiceId": {
                  "locationName": "serviceId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServices": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceNames": {
            "shape": "So",
            "locationName": "ServiceName"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceNames": {
            "shape": "So",
            "locationName": "serviceNameSet"
          },
          "ServiceDetails": {
            "locationName": "serviceDetailSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ServiceName": {
                  "locationName": "serviceName"
                },
                "ServiceId": {
                  "locationName": "serviceId"
                },
                "ServiceType": {
                  "shape": "Ssw",
                  "locationName": "serviceType"
                },
                "AvailabilityZones": {
                  "shape": "So",
                  "locationName": "availabilityZoneSet"
                },
                "Owner": {
                  "locationName": "owner"
                },
                "BaseEndpointDnsNames": {
                  "shape": "So",
                  "locationName": "baseEndpointDnsNameSet"
                },
                "PrivateDnsName": {
                  "locationName": "privateDnsName"
                },
                "PrivateDnsNames": {
                  "locationName": "privateDnsNameSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "PrivateDnsName": {
                        "locationName": "privateDnsName"
                      }
                    }
                  }
                },
                "VpcEndpointPolicySupported": {
                  "locationName": "vpcEndpointPolicySupported",
                  "type": "boolean"
                },
                "AcceptanceRequired": {
                  "locationName": "acceptanceRequired",
                  "type": "boolean"
                },
                "ManagesVpcEndpoints": {
                  "locationName": "managesVpcEndpoints",
                  "type": "boolean"
                },
                "PayerResponsibility": {
                  "locationName": "payerResponsibility"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "PrivateDnsNameVerificationState": {
                  "locationName": "privateDnsNameVerificationState"
                },
                "SupportedIpAddressTypes": {
                  "shape": "St0",
                  "locationName": "supportedIpAddressTypeSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "S1e",
            "locationName": "VpcEndpointId"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoints": {
            "locationName": "vpcEndpointSet",
            "type": "list",
            "member": {
              "shape": "Ssg",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcPeeringConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionIds": {
            "locationName": "VpcPeeringConnectionId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnections": {
            "locationName": "vpcPeeringConnectionSet",
            "type": "list",
            "member": {
              "shape": "S1n",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcs": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "VpcIds": {
            "locationName": "VpcId",
            "type": "list",
            "member": {
              "locationName": "VpcId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "shape": "Sbs",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpnConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "VpnConnectionIds": {
            "locationName": "VpnConnectionId",
            "type": "list",
            "member": {
              "locationName": "VpnConnectionId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnections": {
            "locationName": "vpnConnectionSet",
            "type": "list",
            "member": {
              "shape": "Stw",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeVpnGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "VpnGatewayIds": {
            "locationName": "VpnGatewayId",
            "type": "list",
            "member": {
              "locationName": "VpnGatewayId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateways": {
            "locationName": "vpnGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sut",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DetachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DetachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "DetachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "AttachmentId"
        ],
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DetachVerifiedAccessTrustProvider": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessInstanceId",
          "VerifiedAccessTrustProviderId"
        ],
        "members": {
          "VerifiedAccessInstanceId": {},
          "VerifiedAccessTrustProviderId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProvider": {
            "shape": "S69",
            "locationName": "verifiedAccessTrustProvider"
          },
          "VerifiedAccessInstance": {
            "shape": "S6i",
            "locationName": "verifiedAccessInstance"
          }
        }
      }
    },
    "DetachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "Device": {},
          "Force": {
            "type": "boolean"
          },
          "InstanceId": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S6n"
      }
    },
    "DetachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "VpnGatewayId"
        ],
        "members": {
          "VpcId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisableAddressTransfer": {
      "input": {
        "type": "structure",
        "required": [
          "AllocationId"
        ],
        "members": {
          "AllocationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressTransfer": {
            "shape": "Sa",
            "locationName": "addressTransfer"
          }
        }
      }
    },
    "DisableAwsNetworkPerformanceMetricSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "Source": {},
          "Destination": {},
          "Metric": {},
          "Statistic": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Output": {
            "locationName": "output",
            "type": "boolean"
          }
        }
      }
    },
    "DisableEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "DisableFastLaunch": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "Force": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          },
          "ResourceType": {
            "locationName": "resourceType"
          },
          "SnapshotConfiguration": {
            "shape": "S15l",
            "locationName": "snapshotConfiguration"
          },
          "LaunchTemplate": {
            "shape": "S15m",
            "locationName": "launchTemplate"
          },
          "MaxParallelLaunches": {
            "locationName": "maxParallelLaunches",
            "type": "integer"
          },
          "OwnerId": {
            "locationName": "ownerId"
          },
          "State": {
            "locationName": "state"
          },
          "StateTransitionReason": {
            "locationName": "stateTransitionReason"
          },
          "StateTransitionTime": {
            "locationName": "stateTransitionTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DisableFastSnapshotRestores": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZones",
          "SourceSnapshotIds"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S1w0",
            "locationName": "AvailabilityZone"
          },
          "SourceSnapshotIds": {
            "shape": "S1i6",
            "locationName": "SourceSnapshotId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "locationName": "successful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "EnablingTime": {
                  "locationName": "enablingTime",
                  "type": "timestamp"
                },
                "OptimizingTime": {
                  "locationName": "optimizingTime",
                  "type": "timestamp"
                },
                "EnabledTime": {
                  "locationName": "enabledTime",
                  "type": "timestamp"
                },
                "DisablingTime": {
                  "locationName": "disablingTime",
                  "type": "timestamp"
                },
                "DisabledTime": {
                  "locationName": "disabledTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "Unsuccessful": {
            "locationName": "unsuccessful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "FastSnapshotRestoreStateErrors": {
                  "locationName": "fastSnapshotRestoreStateErrorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AvailabilityZone": {
                        "locationName": "availabilityZone"
                      },
                      "Error": {
                        "locationName": "error",
                        "type": "structure",
                        "members": {
                          "Code": {
                            "locationName": "code"
                          },
                          "Message": {
                            "locationName": "message"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DisableImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisableImageBlockPublicAccess": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageBlockPublicAccessState": {
            "locationName": "imageBlockPublicAccessState"
          }
        }
      }
    },
    "DisableImageDeprecation": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisableImageDeregistrationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return"
          }
        }
      }
    },
    "DisableIpamOrganizationAdminAccount": {
      "input": {
        "type": "structure",
        "required": [
          "DelegatedAdminAccountId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "DelegatedAdminAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Success": {
            "locationName": "success",
            "type": "boolean"
          }
        }
      }
    },
    "DisableSerialConsoleAccess": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SerialConsoleAccessEnabled": {
            "locationName": "serialConsoleAccessEnabled",
            "type": "boolean"
          }
        }
      }
    },
    "DisableSnapshotBlockPublicAccess": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "DisableTransitGatewayRouteTablePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          },
          "TransitGatewayRouteTableAnnouncementId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Propagation": {
            "shape": "S1wr",
            "locationName": "propagation"
          }
        }
      }
    },
    "DisableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayId",
          "RouteTableId"
        ],
        "members": {
          "GatewayId": {},
          "RouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "DisableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationId": {},
          "PublicIp": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "AssociationId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "AssociationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "Status": {
            "shape": "S3m",
            "locationName": "status"
          }
        }
      }
    },
    "DisassociateEnclaveCertificateIamRole": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "RoleArn"
        ],
        "members": {
          "CertificateArn": {},
          "RoleArn": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S3x",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "DisassociateInstanceEventWindow": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceEventWindowId",
          "AssociationTarget"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceEventWindowId": {},
          "AssociationTarget": {
            "type": "structure",
            "members": {
              "InstanceIds": {
                "shape": "S43",
                "locationName": "InstanceId"
              },
              "InstanceTags": {
                "shape": "S6",
                "locationName": "InstanceTag"
              },
              "DedicatedHostIds": {
                "shape": "S44",
                "locationName": "DedicatedHostId"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceEventWindow": {
            "shape": "S47",
            "locationName": "instanceEventWindow"
          }
        }
      }
    },
    "DisassociateIpamByoasn": {
      "input": {
        "type": "structure",
        "required": [
          "Asn",
          "Cidr"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Asn": {},
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AsnAssociation": {
            "shape": "S20",
            "locationName": "asnAssociation"
          }
        }
      }
    },
    "DisassociateIpamResourceDiscovery": {
      "input": {
        "type": "structure",
        "required": [
          "IpamResourceDiscoveryAssociationId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryAssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscoveryAssociation": {
            "shape": "S4l",
            "locationName": "ipamResourceDiscoveryAssociation"
          }
        }
      }
    },
    "DisassociateNatGatewayAddress": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId",
          "AssociationIds"
        ],
        "members": {
          "NatGatewayId": {},
          "AssociationIds": {
            "locationName": "AssociationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxDrainDurationSeconds": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "NatGatewayAddresses": {
            "shape": "S3b",
            "locationName": "natGatewayAddressSet"
          }
        }
      }
    },
    "DisassociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S52",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "DisassociateTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId",
          "TransitGatewayAttachmentId",
          "SubnetIds"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "TransitGatewayAttachmentId": {},
          "SubnetIds": {
            "shape": "S59"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "shape": "Sq",
            "locationName": "associations"
          }
        }
      }
    },
    "DisassociateTransitGatewayPolicyTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayPolicyTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayPolicyTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S5e",
            "locationName": "association"
          }
        }
      }
    },
    "DisassociateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S5j",
            "locationName": "association"
          }
        }
      }
    },
    "DisassociateTrunkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "DisassociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S5s",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "CidrBlockAssociation": {
            "shape": "S5v",
            "locationName": "cidrBlockAssociation"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "EnableAddressTransfer": {
      "input": {
        "type": "structure",
        "required": [
          "AllocationId",
          "TransferAccountId"
        ],
        "members": {
          "AllocationId": {},
          "TransferAccountId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressTransfer": {
            "shape": "Sa",
            "locationName": "addressTransfer"
          }
        }
      }
    },
    "EnableAwsNetworkPerformanceMetricSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "Source": {},
          "Destination": {},
          "Metric": {},
          "Statistic": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Output": {
            "locationName": "output",
            "type": "boolean"
          }
        }
      }
    },
    "EnableEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "EnableFastLaunch": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "ResourceType": {},
          "SnapshotConfiguration": {
            "type": "structure",
            "members": {
              "TargetResourceCount": {
                "type": "integer"
              }
            }
          },
          "LaunchTemplate": {
            "type": "structure",
            "required": [
              "Version"
            ],
            "members": {
              "LaunchTemplateId": {},
              "LaunchTemplateName": {},
              "Version": {}
            }
          },
          "MaxParallelLaunches": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          },
          "ResourceType": {
            "locationName": "resourceType"
          },
          "SnapshotConfiguration": {
            "shape": "S15l",
            "locationName": "snapshotConfiguration"
          },
          "LaunchTemplate": {
            "shape": "S15m",
            "locationName": "launchTemplate"
          },
          "MaxParallelLaunches": {
            "locationName": "maxParallelLaunches",
            "type": "integer"
          },
          "OwnerId": {
            "locationName": "ownerId"
          },
          "State": {
            "locationName": "state"
          },
          "StateTransitionReason": {
            "locationName": "stateTransitionReason"
          },
          "StateTransitionTime": {
            "locationName": "stateTransitionTime",
            "type": "timestamp"
          }
        }
      }
    },
    "EnableFastSnapshotRestores": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZones",
          "SourceSnapshotIds"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S1w0",
            "locationName": "AvailabilityZone"
          },
          "SourceSnapshotIds": {
            "shape": "S1i6",
            "locationName": "SourceSnapshotId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "locationName": "successful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "EnablingTime": {
                  "locationName": "enablingTime",
                  "type": "timestamp"
                },
                "OptimizingTime": {
                  "locationName": "optimizingTime",
                  "type": "timestamp"
                },
                "EnabledTime": {
                  "locationName": "enabledTime",
                  "type": "timestamp"
                },
                "DisablingTime": {
                  "locationName": "disablingTime",
                  "type": "timestamp"
                },
                "DisabledTime": {
                  "locationName": "disabledTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "Unsuccessful": {
            "locationName": "unsuccessful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "FastSnapshotRestoreStateErrors": {
                  "locationName": "fastSnapshotRestoreStateErrorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AvailabilityZone": {
                        "locationName": "availabilityZone"
                      },
                      "Error": {
                        "locationName": "error",
                        "type": "structure",
                        "members": {
                          "Code": {
                            "locationName": "code"
                          },
                          "Message": {
                            "locationName": "message"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "EnableImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "EnableImageBlockPublicAccess": {
      "input": {
        "type": "structure",
        "required": [
          "ImageBlockPublicAccessState"
        ],
        "members": {
          "ImageBlockPublicAccessState": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageBlockPublicAccessState": {
            "locationName": "imageBlockPublicAccessState"
          }
        }
      }
    },
    "EnableImageDeprecation": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId",
          "DeprecateAt"
        ],
        "members": {
          "ImageId": {},
          "DeprecateAt": {
            "type": "timestamp"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "EnableImageDeregistrationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "WithCooldown": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return"
          }
        }
      }
    },
    "EnableIpamOrganizationAdminAccount": {
      "input": {
        "type": "structure",
        "required": [
          "DelegatedAdminAccountId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "DelegatedAdminAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Success": {
            "locationName": "success",
            "type": "boolean"
          }
        }
      }
    },
    "EnableReachabilityAnalyzerOrganizationSharing": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "returnValue",
            "type": "boolean"
          }
        }
      }
    },
    "EnableSerialConsoleAccess": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SerialConsoleAccessEnabled": {
            "locationName": "serialConsoleAccessEnabled",
            "type": "boolean"
          }
        }
      }
    },
    "EnableSnapshotBlockPublicAccess": {
      "input": {
        "type": "structure",
        "required": [
          "State"
        ],
        "members": {
          "State": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "EnableTransitGatewayRouteTablePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          },
          "TransitGatewayRouteTableAnnouncementId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Propagation": {
            "shape": "S1wr",
            "locationName": "propagation"
          }
        }
      }
    },
    "EnableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayId",
          "RouteTableId"
        ],
        "members": {
          "GatewayId": {},
          "RouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "EnableVolumeIO": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "EnableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "EnableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ExportClientVpnClientCertificateRevocationList": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateRevocationList": {
            "locationName": "certificateRevocationList"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              }
            }
          }
        }
      }
    },
    "ExportClientVpnClientConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientConfiguration": {
            "locationName": "clientConfiguration"
          }
        }
      }
    },
    "ExportImage": {
      "input": {
        "type": "structure",
        "required": [
          "DiskImageFormat",
          "ImageId",
          "S3ExportLocation"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "DiskImageFormat": {},
          "DryRun": {
            "type": "boolean"
          },
          "ImageId": {},
          "S3ExportLocation": {
            "type": "structure",
            "required": [
              "S3Bucket"
            ],
            "members": {
              "S3Bucket": {},
              "S3Prefix": {}
            }
          },
          "RoleName": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DiskImageFormat": {
            "locationName": "diskImageFormat"
          },
          "ExportImageTaskId": {
            "locationName": "exportImageTaskId"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "RoleName": {
            "locationName": "roleName"
          },
          "Progress": {
            "locationName": "progress"
          },
          "S3ExportLocation": {
            "shape": "S158",
            "locationName": "s3ExportLocation"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ExportTransitGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "S3Bucket"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "S3Bucket": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "S3Location": {
            "locationName": "s3Location"
          }
        }
      }
    },
    "GetAssociatedEnclaveCertificateIamRoles": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociatedRoles": {
            "locationName": "associatedRoleSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AssociatedRoleArn": {
                  "locationName": "associatedRoleArn"
                },
                "CertificateS3BucketName": {
                  "locationName": "certificateS3BucketName"
                },
                "CertificateS3ObjectKey": {
                  "locationName": "certificateS3ObjectKey"
                },
                "EncryptionKmsKeyId": {
                  "locationName": "encryptionKmsKeyId"
                }
              }
            }
          }
        }
      }
    },
    "GetAssociatedIpv6PoolCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "PoolId"
        ],
        "members": {
          "PoolId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrAssociations": {
            "locationName": "ipv6CidrAssociationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Ipv6Cidr": {
                  "locationName": "ipv6Cidr"
                },
                "AssociatedResource": {
                  "locationName": "associatedResource"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetAwsNetworkPerformanceData": {
      "input": {
        "type": "structure",
        "members": {
          "DataQueries": {
            "locationName": "DataQuery",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Source": {},
                "Destination": {},
                "Metric": {},
                "Statistic": {},
                "Period": {}
              }
            }
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataResponses": {
            "locationName": "dataResponseSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Id": {
                  "locationName": "id"
                },
                "Source": {
                  "locationName": "source"
                },
                "Destination": {
                  "locationName": "destination"
                },
                "Metric": {
                  "locationName": "metric"
                },
                "Statistic": {
                  "locationName": "statistic"
                },
                "Period": {
                  "locationName": "period"
                },
                "MetricPoints": {
                  "locationName": "metricPointSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "StartDate": {
                        "locationName": "startDate",
                        "type": "timestamp"
                      },
                      "EndDate": {
                        "locationName": "endDate",
                        "type": "timestamp"
                      },
                      "Value": {
                        "locationName": "value",
                        "type": "float"
                      },
                      "Status": {
                        "locationName": "status"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetCapacityReservationUsage": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservationId": {
            "locationName": "capacityReservationId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "TotalInstanceCount": {
            "locationName": "totalInstanceCount",
            "type": "integer"
          },
          "AvailableInstanceCount": {
            "locationName": "availableInstanceCount",
            "type": "integer"
          },
          "State": {
            "locationName": "state"
          },
          "InstanceUsages": {
            "locationName": "instanceUsageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AccountId": {
                  "locationName": "accountId"
                },
                "UsedInstanceCount": {
                  "locationName": "usedInstanceCount",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "GetCoipPoolUsage": {
      "input": {
        "type": "structure",
        "required": [
          "PoolId"
        ],
        "members": {
          "PoolId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipPoolId": {
            "locationName": "coipPoolId"
          },
          "CoipAddressUsages": {
            "locationName": "coipAddressUsageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AllocationId": {
                  "locationName": "allocationId"
                },
                "AwsAccountId": {
                  "locationName": "awsAccountId"
                },
                "AwsService": {
                  "locationName": "awsService"
                },
                "CoIp": {
                  "locationName": "coIp"
                }
              }
            }
          },
          "LocalGatewayRouteTableId": {
            "locationName": "localGatewayRouteTableId"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetConsoleOutput": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Latest": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Output": {
            "locationName": "output"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          }
        }
      }
    },
    "GetConsoleScreenshot": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "WakeUp": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageData": {
            "locationName": "imageData"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      }
    },
    "GetDefaultCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceFamily"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceFamily": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceFamilyCreditSpecification": {
            "shape": "S20b",
            "locationName": "instanceFamilyCreditSpecification"
          }
        }
      }
    },
    "GetEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "GetEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          },
          "SseType": {
            "locationName": "sseType"
          }
        }
      }
    },
    "GetFlowLogsIntegrationTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "FlowLogId",
          "ConfigDeliveryS3DestinationArn",
          "IntegrateServices"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FlowLogId": {},
          "ConfigDeliveryS3DestinationArn": {},
          "IntegrateServices": {
            "locationName": "IntegrateService",
            "type": "structure",
            "members": {
              "AthenaIntegrations": {
                "locationName": "AthenaIntegration",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "required": [
                    "IntegrationResultS3DestinationArn",
                    "PartitionLoadFrequency"
                  ],
                  "members": {
                    "IntegrationResultS3DestinationArn": {},
                    "PartitionLoadFrequency": {},
                    "PartitionStartDate": {
                      "type": "timestamp"
                    },
                    "PartitionEndDate": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {
            "locationName": "result"
          }
        }
      }
    },
    "GetGroupsForCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservationGroups": {
            "locationName": "capacityReservationGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupArn": {
                  "locationName": "groupArn"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                }
              }
            }
          }
        }
      }
    },
    "GetHostReservationPurchasePreview": {
      "input": {
        "type": "structure",
        "required": [
          "HostIdSet",
          "OfferingId"
        ],
        "members": {
          "HostIdSet": {
            "shape": "S20s"
          },
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Purchase": {
            "shape": "S20u",
            "locationName": "purchase"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          }
        }
      }
    },
    "GetImageBlockPublicAccessState": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageBlockPublicAccessState": {
            "locationName": "imageBlockPublicAccessState"
          }
        }
      }
    },
    "GetInstanceMetadataDefaults": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountLevel": {
            "locationName": "accountLevel",
            "type": "structure",
            "members": {
              "HttpTokens": {
                "locationName": "httpTokens"
              },
              "HttpPutResponseHopLimit": {
                "locationName": "httpPutResponseHopLimit",
                "type": "integer"
              },
              "HttpEndpoint": {
                "locationName": "httpEndpoint"
              },
              "InstanceMetadataTags": {
                "locationName": "instanceMetadataTags"
              }
            }
          }
        }
      }
    },
    "GetInstanceTpmEkPub": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "KeyType",
          "KeyFormat"
        ],
        "members": {
          "InstanceId": {},
          "KeyType": {},
          "KeyFormat": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "KeyType": {
            "locationName": "keyType"
          },
          "KeyFormat": {
            "locationName": "keyFormat"
          },
          "KeyValue": {
            "locationName": "keyValue",
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetInstanceTypesFromInstanceRequirements": {
      "input": {
        "type": "structure",
        "required": [
          "ArchitectureTypes",
          "VirtualizationTypes",
          "InstanceRequirements"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ArchitectureTypes": {
            "shape": "S218",
            "locationName": "ArchitectureType"
          },
          "VirtualizationTypes": {
            "shape": "S219",
            "locationName": "VirtualizationType"
          },
          "InstanceRequirements": {
            "shape": "Scy"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypes": {
            "locationName": "instanceTypeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetInstanceUefiData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "UefiData": {
            "locationName": "uefiData"
          }
        }
      }
    },
    "GetIpamAddressHistory": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr",
          "IpamScopeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Cidr": {},
          "IpamScopeId": {},
          "VpcId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "ResourceRegion": {
                  "locationName": "resourceRegion"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceCidr": {
                  "locationName": "resourceCidr"
                },
                "ResourceName": {
                  "locationName": "resourceName"
                },
                "ResourceComplianceStatus": {
                  "locationName": "resourceComplianceStatus"
                },
                "ResourceOverlapStatus": {
                  "locationName": "resourceOverlapStatus"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "SampledStartTime": {
                  "locationName": "sampledStartTime",
                  "type": "timestamp"
                },
                "SampledEndTime": {
                  "locationName": "sampledEndTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetIpamDiscoveredAccounts": {
      "input": {
        "type": "structure",
        "required": [
          "IpamResourceDiscoveryId",
          "DiscoveryRegion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryId": {},
          "DiscoveryRegion": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamDiscoveredAccounts": {
            "locationName": "ipamDiscoveredAccountSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AccountId": {
                  "locationName": "accountId"
                },
                "DiscoveryRegion": {
                  "locationName": "discoveryRegion"
                },
                "FailureReason": {
                  "locationName": "failureReason",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "LastAttemptedDiscoveryTime": {
                  "locationName": "lastAttemptedDiscoveryTime",
                  "type": "timestamp"
                },
                "LastSuccessfulDiscoveryTime": {
                  "locationName": "lastSuccessfulDiscoveryTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetIpamDiscoveredPublicAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "IpamResourceDiscoveryId",
          "AddressRegion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryId": {},
          "AddressRegion": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamDiscoveredPublicAddresses": {
            "locationName": "ipamDiscoveredPublicAddressSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "IpamResourceDiscoveryId": {
                  "locationName": "ipamResourceDiscoveryId"
                },
                "AddressRegion": {
                  "locationName": "addressRegion"
                },
                "Address": {
                  "locationName": "address"
                },
                "AddressOwnerId": {
                  "locationName": "addressOwnerId"
                },
                "AddressAllocationId": {
                  "locationName": "addressAllocationId"
                },
                "AssociationStatus": {
                  "locationName": "associationStatus"
                },
                "AddressType": {
                  "locationName": "addressType"
                },
                "Service": {
                  "locationName": "service"
                },
                "ServiceResource": {
                  "locationName": "serviceResource"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "SubnetId": {
                  "locationName": "subnetId"
                },
                "PublicIpv4PoolId": {
                  "locationName": "publicIpv4PoolId"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "NetworkInterfaceDescription": {
                  "locationName": "networkInterfaceDescription"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Tags": {
                  "locationName": "tags",
                  "type": "structure",
                  "members": {
                    "EipTags": {
                      "locationName": "eipTagSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Key": {
                            "locationName": "key"
                          },
                          "Value": {
                            "locationName": "value"
                          }
                        }
                      }
                    }
                  }
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                },
                "SecurityGroups": {
                  "locationName": "securityGroupSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "GroupName": {
                        "locationName": "groupName"
                      },
                      "GroupId": {
                        "locationName": "groupId"
                      }
                    }
                  }
                },
                "SampleTime": {
                  "locationName": "sampleTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "OldestSampleTime": {
            "locationName": "oldestSampleTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetIpamDiscoveredResourceCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "IpamResourceDiscoveryId",
          "ResourceRegion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryId": {},
          "ResourceRegion": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamDiscoveredResourceCidrs": {
            "locationName": "ipamDiscoveredResourceCidrSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "IpamResourceDiscoveryId": {
                  "locationName": "ipamResourceDiscoveryId"
                },
                "ResourceRegion": {
                  "locationName": "resourceRegion"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "ResourceCidr": {
                  "locationName": "resourceCidr"
                },
                "IpSource": {
                  "locationName": "ipSource"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceTags": {
                  "shape": "Sgj",
                  "locationName": "resourceTagSet"
                },
                "IpUsage": {
                  "locationName": "ipUsage",
                  "type": "double"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "NetworkInterfaceAttachmentStatus": {
                  "locationName": "networkInterfaceAttachmentStatus"
                },
                "SampleTime": {
                  "locationName": "sampleTime",
                  "type": "timestamp"
                },
                "AvailabilityZoneId": {
                  "locationName": "availabilityZoneId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetIpamPoolAllocations": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "IpamPoolAllocationId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPoolAllocations": {
            "locationName": "ipamPoolAllocationSet",
            "type": "list",
            "member": {
              "shape": "S2l",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetIpamPoolCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPoolCidrs": {
            "locationName": "ipamPoolCidrSet",
            "type": "list",
            "member": {
              "shape": "Szr",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetIpamResourceCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "IpamScopeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IpamScopeId": {},
          "IpamPoolId": {},
          "ResourceId": {},
          "ResourceType": {},
          "ResourceTag": {
            "shape": "Sga"
          },
          "ResourceOwner": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "IpamResourceCidrs": {
            "locationName": "ipamResourceCidrSet",
            "type": "list",
            "member": {
              "shape": "S22n",
              "locationName": "item"
            }
          }
        }
      }
    },
    "GetLaunchTemplateData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateData": {
            "shape": "Sit",
            "locationName": "launchTemplateData"
          }
        }
      }
    },
    "GetManagedPrefixListAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "PrefixListId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrefixListAssociations": {
            "locationName": "prefixListAssociationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceOwner": {
                  "locationName": "resourceOwner"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetManagedPrefixListEntries": {
      "input": {
        "type": "structure",
        "required": [
          "PrefixListId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListId": {},
          "TargetVersion": {
            "type": "long"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entries": {
            "locationName": "entrySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Cidr": {
                  "locationName": "cidr"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetNetworkInsightsAccessScopeAnalysisFindings": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsAccessScopeAnalysisId"
        ],
        "members": {
          "NetworkInsightsAccessScopeAnalysisId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeAnalysisId": {
            "locationName": "networkInsightsAccessScopeAnalysisId"
          },
          "AnalysisStatus": {
            "locationName": "analysisStatus"
          },
          "AnalysisFindings": {
            "locationName": "analysisFindingSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "NetworkInsightsAccessScopeAnalysisId": {
                  "locationName": "networkInsightsAccessScopeAnalysisId"
                },
                "NetworkInsightsAccessScopeId": {
                  "locationName": "networkInsightsAccessScopeId"
                },
                "FindingId": {
                  "locationName": "findingId"
                },
                "FindingComponents": {
                  "shape": "S1jl",
                  "locationName": "findingComponentSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetNetworkInsightsAccessScopeContent": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsAccessScopeId"
        ],
        "members": {
          "NetworkInsightsAccessScopeId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeContent": {
            "shape": "Slg",
            "locationName": "networkInsightsAccessScopeContent"
          }
        }
      }
    },
    "GetPasswordData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "PasswordData": {
            "locationName": "passwordData",
            "type": "string",
            "sensitive": true
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          }
        }
      }
    },
    "GetReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "Se",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "Sg",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "IsValidExchange": {
            "locationName": "isValidExchange",
            "type": "boolean"
          },
          "OutputReservedInstancesWillExpireAt": {
            "locationName": "outputReservedInstancesWillExpireAt",
            "type": "timestamp"
          },
          "PaymentDue": {
            "locationName": "paymentDue"
          },
          "ReservedInstanceValueRollup": {
            "shape": "S23c",
            "locationName": "reservedInstanceValueRollup"
          },
          "ReservedInstanceValueSet": {
            "locationName": "reservedInstanceValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservationValue": {
                  "shape": "S23c",
                  "locationName": "reservationValue"
                },
                "ReservedInstanceId": {
                  "locationName": "reservedInstanceId"
                }
              }
            }
          },
          "TargetConfigurationValueRollup": {
            "shape": "S23c",
            "locationName": "targetConfigurationValueRollup"
          },
          "TargetConfigurationValueSet": {
            "locationName": "targetConfigurationValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservationValue": {
                  "shape": "S23c",
                  "locationName": "reservationValue"
                },
                "TargetConfiguration": {
                  "locationName": "targetConfiguration",
                  "type": "structure",
                  "members": {
                    "InstanceCount": {
                      "locationName": "instanceCount",
                      "type": "integer"
                    },
                    "OfferingId": {
                      "locationName": "offeringId"
                    }
                  }
                }
              }
            }
          },
          "ValidationFailureReason": {
            "locationName": "validationFailureReason"
          }
        }
      }
    },
    "GetSecurityGroupsForVpc": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SecurityGroupForVpcs": {
            "locationName": "securityGroupForVpcSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "Tags": {
                  "shape": "S6",
                  "locationName": "tagSet"
                },
                "PrimaryVpcId": {
                  "locationName": "primaryVpcId"
                }
              }
            }
          }
        }
      }
    },
    "GetSerialConsoleAccessStatus": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SerialConsoleAccessEnabled": {
            "locationName": "serialConsoleAccessEnabled",
            "type": "boolean"
          }
        }
      }
    },
    "GetSnapshotBlockPublicAccessState": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "GetSpotPlacementScores": {
      "input": {
        "type": "structure",
        "required": [
          "TargetCapacity"
        ],
        "members": {
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "TargetCapacity": {
            "type": "integer"
          },
          "TargetCapacityUnitType": {},
          "SingleAvailabilityZone": {
            "type": "boolean"
          },
          "RegionNames": {
            "locationName": "RegionName",
            "type": "list",
            "member": {}
          },
          "InstanceRequirementsWithMetadata": {
            "type": "structure",
            "members": {
              "ArchitectureTypes": {
                "shape": "S218",
                "locationName": "ArchitectureType"
              },
              "VirtualizationTypes": {
                "shape": "S219",
                "locationName": "VirtualizationType"
              },
              "InstanceRequirements": {
                "shape": "Scy"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotPlacementScores": {
            "locationName": "spotPlacementScoreSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Region": {
                  "locationName": "region"
                },
                "AvailabilityZoneId": {
                  "locationName": "availabilityZoneId"
                },
                "Score": {
                  "locationName": "score",
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetSubnetCidrReservations": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "SubnetId": {},
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SubnetIpv4CidrReservations": {
            "shape": "S243",
            "locationName": "subnetIpv4CidrReservationSet"
          },
          "SubnetIpv6CidrReservations": {
            "shape": "S243",
            "locationName": "subnetIpv6CidrReservationSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayAttachmentPropagations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentPropagations": {
            "locationName": "transitGatewayAttachmentPropagations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayRouteTableId": {
                  "locationName": "transitGatewayRouteTableId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayMulticastDomainAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MulticastDomainAssociations": {
            "locationName": "multicastDomainAssociations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "Subnet": {
                  "shape": "St",
                  "locationName": "subnet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayPolicyTableAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayPolicyTableId"
        ],
        "members": {
          "TransitGatewayPolicyTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "locationName": "associations",
            "type": "list",
            "member": {
              "shape": "S5e",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayPolicyTableEntries": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayPolicyTableId"
        ],
        "members": {
          "TransitGatewayPolicyTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPolicyTableEntries": {
            "locationName": "transitGatewayPolicyTableEntries",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PolicyRuleNumber": {
                  "locationName": "policyRuleNumber"
                },
                "PolicyRule": {
                  "locationName": "policyRule",
                  "type": "structure",
                  "members": {
                    "SourceCidrBlock": {
                      "locationName": "sourceCidrBlock"
                    },
                    "SourcePortRange": {
                      "locationName": "sourcePortRange"
                    },
                    "DestinationCidrBlock": {
                      "locationName": "destinationCidrBlock"
                    },
                    "DestinationPortRange": {
                      "locationName": "destinationPortRange"
                    },
                    "Protocol": {
                      "locationName": "protocol"
                    },
                    "MetaData": {
                      "locationName": "metaData",
                      "type": "structure",
                      "members": {
                        "MetaDataKey": {
                          "locationName": "metaDataKey"
                        },
                        "MetaDataValue": {
                          "locationName": "metaDataValue"
                        }
                      }
                    }
                  }
                },
                "TargetRouteTableId": {
                  "locationName": "targetRouteTableId"
                }
              }
            }
          }
        }
      }
    },
    "GetTransitGatewayPrefixListReferences": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPrefixListReferences": {
            "locationName": "transitGatewayPrefixListReferenceSet",
            "type": "list",
            "member": {
              "shape": "Sqj",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayRouteTableAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "locationName": "associations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayRouteTablePropagations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTablePropagations": {
            "locationName": "transitGatewayRouteTablePropagations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "State": {
                  "locationName": "state"
                },
                "TransitGatewayRouteTableAnnouncementId": {
                  "locationName": "transitGatewayRouteTableAnnouncementId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetVerifiedAccessEndpointPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessEndpointId"
        ],
        "members": {
          "VerifiedAccessEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyEnabled": {
            "locationName": "policyEnabled",
            "type": "boolean"
          },
          "PolicyDocument": {
            "locationName": "policyDocument"
          }
        }
      }
    },
    "GetVerifiedAccessGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessGroupId"
        ],
        "members": {
          "VerifiedAccessGroupId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyEnabled": {
            "locationName": "policyEnabled",
            "type": "boolean"
          },
          "PolicyDocument": {
            "locationName": "policyDocument"
          }
        }
      }
    },
    "GetVpnConnectionDeviceSampleConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnConnectionDeviceTypeId"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnConnectionDeviceTypeId": {},
          "InternetKeyExchangeVersion": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnectionDeviceSampleConfiguration": {
            "locationName": "vpnConnectionDeviceSampleConfiguration",
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetVpnConnectionDeviceTypes": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnectionDeviceTypes": {
            "locationName": "vpnConnectionDeviceTypeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "VpnConnectionDeviceTypeId": {
                  "locationName": "vpnConnectionDeviceTypeId"
                },
                "Vendor": {
                  "locationName": "vendor"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "Software": {
                  "locationName": "software"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetVpnTunnelReplacementStatus": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnectionId": {
            "locationName": "vpnConnectionId"
          },
          "TransitGatewayId": {
            "locationName": "transitGatewayId"
          },
          "CustomerGatewayId": {
            "locationName": "customerGatewayId"
          },
          "VpnGatewayId": {
            "locationName": "vpnGatewayId"
          },
          "VpnTunnelOutsideIpAddress": {
            "locationName": "vpnTunnelOutsideIpAddress"
          },
          "MaintenanceDetails": {
            "locationName": "maintenanceDetails",
            "type": "structure",
            "members": {
              "PendingMaintenance": {
                "locationName": "pendingMaintenance"
              },
              "MaintenanceAutoAppliedAfter": {
                "locationName": "maintenanceAutoAppliedAfter",
                "type": "timestamp"
              },
              "LastMaintenanceApplied": {
                "locationName": "lastMaintenanceApplied",
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "ImportClientVpnClientCertificateRevocationList": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "CertificateRevocationList"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "CertificateRevocationList": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ImportImage": {
      "input": {
        "type": "structure",
        "members": {
          "Architecture": {},
          "ClientData": {
            "shape": "S25f"
          },
          "ClientToken": {},
          "Description": {},
          "DiskContainers": {
            "locationName": "DiskContainer",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {},
                "DeviceName": {},
                "Format": {},
                "SnapshotId": {},
                "Url": {
                  "shape": "S197"
                },
                "UserBucket": {
                  "shape": "S25i"
                }
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "Hypervisor": {},
          "KmsKeyId": {},
          "LicenseType": {},
          "Platform": {},
          "RoleName": {},
          "LicenseSpecifications": {
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LicenseConfigurationArn": {}
              }
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "UsageOperation": {},
          "BootMode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Architecture": {
            "locationName": "architecture"
          },
          "Description": {
            "locationName": "description"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "Hypervisor": {
            "locationName": "hypervisor"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "LicenseType": {
            "locationName": "licenseType"
          },
          "Platform": {
            "locationName": "platform"
          },
          "Progress": {
            "locationName": "progress"
          },
          "SnapshotDetails": {
            "shape": "S195",
            "locationName": "snapshotDetailSet"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "LicenseSpecifications": {
            "shape": "S199",
            "locationName": "licenseSpecifications"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          },
          "UsageOperation": {
            "locationName": "usageOperation"
          }
        }
      }
    },
    "ImportInstance": {
      "input": {
        "type": "structure",
        "required": [
          "Platform"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DiskImages": {
            "locationName": "diskImage",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "Image": {
                  "shape": "S25p"
                },
                "Volume": {
                  "shape": "S25q"
                }
              }
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "AdditionalInfo": {
                "locationName": "additionalInfo"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "GroupIds": {
                "shape": "Sh9",
                "locationName": "GroupId"
              },
              "GroupNames": {
                "shape": "Shx",
                "locationName": "GroupName"
              },
              "InstanceInitiatedShutdownBehavior": {
                "locationName": "instanceInitiatedShutdownBehavior"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "boolean"
              },
              "Placement": {
                "shape": "Scv",
                "locationName": "placement"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData",
                "type": "structure",
                "members": {
                  "Data": {
                    "locationName": "data"
                  }
                },
                "sensitive": true
              }
            }
          },
          "Platform": {
            "locationName": "platform"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "S144",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ImportKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName",
          "PublicKeyMaterial"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "PublicKeyMaterial": {
            "locationName": "publicKeyMaterial",
            "type": "blob"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "KeyPairId": {
            "locationName": "keyPairId"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ImportSnapshot": {
      "input": {
        "type": "structure",
        "members": {
          "ClientData": {
            "shape": "S25f"
          },
          "ClientToken": {},
          "Description": {},
          "DiskContainer": {
            "type": "structure",
            "members": {
              "Description": {},
              "Format": {},
              "Url": {
                "shape": "S197"
              },
              "UserBucket": {
                "shape": "S25i"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "RoleName": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "SnapshotTaskDetail": {
            "shape": "S19h",
            "locationName": "snapshotTaskDetail"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ImportVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone",
          "Image",
          "Volume"
        ],
        "members": {
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Image": {
            "shape": "S25p",
            "locationName": "image"
          },
          "Volume": {
            "shape": "S25q",
            "locationName": "volume"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "S144",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ListImagesInRecycleBin": {
      "input": {
        "type": "structure",
        "members": {
          "ImageIds": {
            "shape": "S18m",
            "locationName": "ImageId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "locationName": "imageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ImageId": {
                  "locationName": "imageId"
                },
                "Name": {
                  "locationName": "name"
                },
                "Description": {
                  "locationName": "description"
                },
                "RecycleBinEnterTime": {
                  "locationName": "recycleBinEnterTime",
                  "type": "timestamp"
                },
                "RecycleBinExitTime": {
                  "locationName": "recycleBinExitTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListSnapshotsInRecycleBin": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SnapshotIds": {
            "shape": "S1i6",
            "locationName": "SnapshotId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "RecycleBinEnterTime": {
                  "locationName": "recycleBinEnterTime",
                  "type": "timestamp"
                },
                "RecycleBinExitTime": {
                  "locationName": "recycleBinExitTime",
                  "type": "timestamp"
                },
                "Description": {
                  "locationName": "description"
                },
                "VolumeId": {
                  "locationName": "volumeId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "LockSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId",
          "LockMode"
        ],
        "members": {
          "SnapshotId": {},
          "DryRun": {
            "type": "boolean"
          },
          "LockMode": {},
          "CoolOffPeriod": {
            "type": "integer"
          },
          "LockDuration": {
            "type": "integer"
          },
          "ExpirationDate": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "LockState": {
            "locationName": "lockState"
          },
          "LockDuration": {
            "locationName": "lockDuration",
            "type": "integer"
          },
          "CoolOffPeriod": {
            "locationName": "coolOffPeriod",
            "type": "integer"
          },
          "CoolOffPeriodExpiresOn": {
            "locationName": "coolOffPeriodExpiresOn",
            "type": "timestamp"
          },
          "LockCreatedOn": {
            "locationName": "lockCreatedOn",
            "type": "timestamp"
          },
          "LockExpiresOn": {
            "locationName": "lockExpiresOn",
            "type": "timestamp"
          },
          "LockDurationStartTime": {
            "locationName": "lockDurationStartTime",
            "type": "timestamp"
          }
        }
      }
    },
    "ModifyAddressAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "AllocationId"
        ],
        "members": {
          "AllocationId": {},
          "DomainName": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Address": {
            "shape": "S112",
            "locationName": "address"
          }
        }
      }
    },
    "ModifyAvailabilityZoneGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "OptInStatus"
        ],
        "members": {
          "GroupName": {},
          "OptInStatus": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "InstanceCount": {
            "type": "integer"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "EndDateType": {},
          "Accept": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "AdditionalInfo": {},
          "InstanceMatchCriteria": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyCapacityReservationFleet": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationFleetId"
        ],
        "members": {
          "CapacityReservationFleetId": {},
          "TotalTargetCapacity": {
            "type": "integer"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RemoveEndDate": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "ServerCertificateArn": {},
          "ConnectionLogOptions": {
            "shape": "Sau"
          },
          "DnsServers": {
            "type": "structure",
            "members": {
              "CustomDnsServers": {
                "shape": "So"
              },
              "Enabled": {
                "type": "boolean"
              }
            }
          },
          "VpnPort": {
            "type": "integer"
          },
          "Description": {},
          "SplitTunnel": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "SecurityGroupIds": {
            "shape": "S2r",
            "locationName": "SecurityGroupId"
          },
          "VpcId": {},
          "SelfServicePortal": {},
          "ClientConnectOptions": {
            "shape": "Sax"
          },
          "SessionTimeoutHours": {
            "type": "integer"
          },
          "ClientLoginBannerOptions": {
            "shape": "Say"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyDefaultCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceFamily",
          "CpuCredits"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceFamily": {},
          "CpuCredits": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceFamilyCreditSpecification": {
            "shape": "S20b",
            "locationName": "instanceFamilyCreditSpecification"
          }
        }
      }
    },
    "ModifyEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "required": [
          "KmsKeyId"
        ],
        "members": {
          "KmsKeyId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "ModifyFleet": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ExcessCapacityTerminationPolicy": {},
          "LaunchTemplateConfigs": {
            "shape": "Sco",
            "locationName": "LaunchTemplateConfig"
          },
          "FleetId": {},
          "TargetCapacitySpecification": {
            "shape": "Sdr"
          },
          "Context": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {},
          "OperationType": {},
          "UserIds": {
            "shape": "S270",
            "locationName": "UserId"
          },
          "UserGroups": {
            "shape": "S271",
            "locationName": "UserGroup"
          },
          "ProductCodes": {
            "shape": "S272",
            "locationName": "ProductCode"
          },
          "LoadPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "S274"
              },
              "Remove": {
                "shape": "S274"
              }
            }
          },
          "Description": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageAttribute": {
            "shape": "S16v",
            "locationName": "fpgaImageAttribute"
          }
        }
      }
    },
    "ModifyHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "HostIds": {
            "shape": "S17s",
            "locationName": "hostId"
          },
          "HostRecovery": {},
          "InstanceType": {},
          "InstanceFamily": {},
          "HostMaintenance": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "S2f",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "S279",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ModifyIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "Resource": {},
          "UseLongIds": {
            "type": "boolean"
          }
        }
      }
    },
    "ModifyIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn",
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "PrincipalArn": {
            "locationName": "principalArn"
          },
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "Description": {
            "shape": "Sc5"
          },
          "ImageId": {},
          "LaunchPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "S18i"
              },
              "Remove": {
                "shape": "S18i"
              }
            }
          },
          "OperationType": {},
          "ProductCodes": {
            "shape": "S272",
            "locationName": "ProductCode"
          },
          "UserGroups": {
            "shape": "S271",
            "locationName": "UserGroup"
          },
          "UserIds": {
            "shape": "S270",
            "locationName": "UserId"
          },
          "Value": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "OrganizationArns": {
            "locationName": "OrganizationArn",
            "type": "list",
            "member": {
              "locationName": "OrganizationArn"
            }
          },
          "OrganizationalUnitArns": {
            "locationName": "OrganizationalUnitArn",
            "type": "list",
            "member": {
              "locationName": "OrganizationalUnitArn"
            }
          },
          "ImdsSupport": {
            "shape": "Sc5"
          }
        }
      }
    },
    "ModifyInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "SourceDestCheck": {
            "shape": "S19o"
          },
          "Attribute": {
            "locationName": "attribute"
          },
          "BlockDeviceMappings": {
            "locationName": "blockDeviceMapping",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "DeviceName": {
                  "locationName": "deviceName"
                },
                "Ebs": {
                  "locationName": "ebs",
                  "type": "structure",
                  "members": {
                    "DeleteOnTermination": {
                      "locationName": "deleteOnTermination",
                      "type": "boolean"
                    },
                    "VolumeId": {
                      "locationName": "volumeId"
                    }
                  }
                },
                "NoDevice": {
                  "locationName": "noDevice"
                },
                "VirtualName": {
                  "locationName": "virtualName"
                }
              }
            }
          },
          "DisableApiTermination": {
            "shape": "S19o",
            "locationName": "disableApiTermination"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EbsOptimized": {
            "shape": "S19o",
            "locationName": "ebsOptimized"
          },
          "EnaSupport": {
            "shape": "S19o",
            "locationName": "enaSupport"
          },
          "Groups": {
            "shape": "S5x",
            "locationName": "GroupId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "Sc5",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "InstanceType": {
            "shape": "Sc5",
            "locationName": "instanceType"
          },
          "Kernel": {
            "shape": "Sc5",
            "locationName": "kernel"
          },
          "Ramdisk": {
            "shape": "Sc5",
            "locationName": "ramdisk"
          },
          "SriovNetSupport": {
            "shape": "Sc5",
            "locationName": "sriovNetSupport"
          },
          "UserData": {
            "locationName": "userData",
            "type": "structure",
            "members": {
              "Value": {
                "locationName": "value",
                "type": "blob"
              }
            }
          },
          "Value": {
            "locationName": "value"
          },
          "DisableApiStop": {
            "shape": "S19o"
          }
        }
      }
    },
    "ModifyInstanceCapacityReservationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "CapacityReservationSpecification"
        ],
        "members": {
          "InstanceId": {},
          "CapacityReservationSpecification": {
            "shape": "S27m"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyInstanceCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceCreditSpecifications"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "InstanceCreditSpecifications": {
            "locationName": "InstanceCreditSpecification",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "InstanceId"
              ],
              "members": {
                "InstanceId": {},
                "CpuCredits": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulInstanceCreditSpecifications": {
            "locationName": "successfulInstanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                }
              }
            }
          },
          "UnsuccessfulInstanceCreditSpecifications": {
            "locationName": "unsuccessfulInstanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ModifyInstanceEventStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InstanceEventId",
          "NotBefore"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "InstanceEventId": {},
          "NotBefore": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Event": {
            "shape": "S1ab",
            "locationName": "event"
          }
        }
      }
    },
    "ModifyInstanceEventWindow": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceEventWindowId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Name": {},
          "InstanceEventWindowId": {},
          "TimeRanges": {
            "shape": "Sfa",
            "locationName": "TimeRange"
          },
          "CronExpression": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceEventWindow": {
            "shape": "S47",
            "locationName": "instanceEventWindow"
          }
        }
      }
    },
    "ModifyInstanceMaintenanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "AutoRecovery": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "AutoRecovery": {
            "locationName": "autoRecovery"
          }
        }
      }
    },
    "ModifyInstanceMetadataDefaults": {
      "input": {
        "type": "structure",
        "members": {
          "HttpTokens": {},
          "HttpPutResponseHopLimit": {
            "type": "integer"
          },
          "HttpEndpoint": {},
          "InstanceMetadataTags": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyInstanceMetadataOptions": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "HttpTokens": {},
          "HttpPutResponseHopLimit": {
            "type": "integer"
          },
          "HttpEndpoint": {},
          "DryRun": {
            "type": "boolean"
          },
          "HttpProtocolIpv6": {},
          "InstanceMetadataTags": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceMetadataOptions": {
            "shape": "S1fm",
            "locationName": "instanceMetadataOptions"
          }
        }
      }
    },
    "ModifyInstancePlacement": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "Affinity": {
            "locationName": "affinity"
          },
          "GroupName": {},
          "HostId": {
            "locationName": "hostId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Tenancy": {
            "locationName": "tenancy"
          },
          "PartitionNumber": {
            "type": "integer"
          },
          "HostResourceGroupArn": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyIpam": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "Description": {},
          "AddOperatingRegions": {
            "shape": "Sfr",
            "locationName": "AddOperatingRegion"
          },
          "RemoveOperatingRegions": {
            "shape": "S28g",
            "locationName": "RemoveOperatingRegion"
          },
          "Tier": {},
          "EnablePrivateGua": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipam": {
            "shape": "Sfv",
            "locationName": "ipam"
          }
        }
      }
    },
    "ModifyIpamPool": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Description": {},
          "AutoImport": {
            "type": "boolean"
          },
          "AllocationMinNetmaskLength": {
            "type": "integer"
          },
          "AllocationMaxNetmaskLength": {
            "type": "integer"
          },
          "AllocationDefaultNetmaskLength": {
            "type": "integer"
          },
          "ClearAllocationDefaultNetmaskLength": {
            "type": "boolean"
          },
          "AddAllocationResourceTags": {
            "shape": "Sg9",
            "locationName": "AddAllocationResourceTag"
          },
          "RemoveAllocationResourceTags": {
            "shape": "Sg9",
            "locationName": "RemoveAllocationResourceTag"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPool": {
            "shape": "Sgg",
            "locationName": "ipamPool"
          }
        }
      }
    },
    "ModifyIpamResourceCidr": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "ResourceCidr",
          "ResourceRegion",
          "CurrentIpamScopeId",
          "Monitored"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ResourceId": {},
          "ResourceCidr": {},
          "ResourceRegion": {},
          "CurrentIpamScopeId": {},
          "DestinationIpamScopeId": {},
          "Monitored": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceCidr": {
            "shape": "S22n",
            "locationName": "ipamResourceCidr"
          }
        }
      }
    },
    "ModifyIpamResourceDiscovery": {
      "input": {
        "type": "structure",
        "required": [
          "IpamResourceDiscoveryId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamResourceDiscoveryId": {},
          "Description": {},
          "AddOperatingRegions": {
            "shape": "Sfr",
            "locationName": "AddOperatingRegion"
          },
          "RemoveOperatingRegions": {
            "shape": "S28g",
            "locationName": "RemoveOperatingRegion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamResourceDiscovery": {
            "shape": "Sgo",
            "locationName": "ipamResourceDiscovery"
          }
        }
      }
    },
    "ModifyIpamScope": {
      "input": {
        "type": "structure",
        "required": [
          "IpamScopeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamScopeId": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamScope": {
            "shape": "Sgs",
            "locationName": "ipamScope"
          }
        }
      }
    },
    "ModifyLaunchTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "DefaultVersion": {
            "locationName": "SetDefaultVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "Sim",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "ModifyLocalGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "LocalGatewayRouteTableId": {},
          "LocalGatewayVirtualInterfaceGroupId": {},
          "NetworkInterfaceId": {},
          "DryRun": {
            "type": "boolean"
          },
          "DestinationPrefixListId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sjy",
            "locationName": "route"
          }
        }
      }
    },
    "ModifyManagedPrefixList": {
      "input": {
        "type": "structure",
        "required": [
          "PrefixListId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListId": {},
          "CurrentVersion": {
            "type": "long"
          },
          "PrefixListName": {},
          "AddEntries": {
            "shape": "Skg",
            "locationName": "AddEntry"
          },
          "RemoveEntries": {
            "locationName": "RemoveEntry",
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Cidr"
              ],
              "members": {
                "Cidr": {}
              }
            }
          },
          "MaxEntries": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrefixList": {
            "shape": "Skj",
            "locationName": "prefixList"
          }
        }
      }
    },
    "ModifyNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Attachment": {
            "locationName": "attachment",
            "type": "structure",
            "members": {
              "AttachmentId": {
                "locationName": "attachmentId"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              }
            }
          },
          "Description": {
            "shape": "Sc5",
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "Sh9",
            "locationName": "SecurityGroupId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "shape": "S19o",
            "locationName": "sourceDestCheck"
          },
          "EnaSrdSpecification": {
            "shape": "S62"
          },
          "EnablePrimaryIpv6": {
            "type": "boolean"
          },
          "ConnectionTrackingSpecification": {
            "shape": "Shk"
          },
          "AssociatePublicIpAddress": {
            "type": "boolean"
          }
        }
      }
    },
    "ModifyPrivateDnsNameOptions": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "PrivateDnsHostnameType": {},
          "EnableResourceNameDnsARecord": {
            "type": "boolean"
          },
          "EnableResourceNameDnsAAAARecord": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyReservedInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesIds",
          "TargetConfigurations"
        ],
        "members": {
          "ReservedInstancesIds": {
            "shape": "S1lz",
            "locationName": "ReservedInstancesId"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "TargetConfigurations": {
            "locationName": "ReservedInstancesConfigurationSetItemType",
            "type": "list",
            "member": {
              "shape": "S1ml",
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesModificationId": {
            "locationName": "reservedInstancesModificationId"
          }
        }
      }
    },
    "ModifySecurityGroupRules": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "SecurityGroupRules"
        ],
        "members": {
          "GroupId": {},
          "SecurityGroupRules": {
            "locationName": "SecurityGroupRule",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "SecurityGroupRuleId"
              ],
              "members": {
                "SecurityGroupRuleId": {},
                "SecurityGroupRule": {
                  "type": "structure",
                  "members": {
                    "IpProtocol": {},
                    "FromPort": {
                      "type": "integer"
                    },
                    "ToPort": {
                      "type": "integer"
                    },
                    "CidrIpv4": {},
                    "CidrIpv6": {},
                    "PrefixListId": {},
                    "ReferencedGroupId": {},
                    "Description": {}
                  }
                }
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "CreateVolumePermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "S1nz"
              },
              "Remove": {
                "shape": "S1nz"
              }
            }
          },
          "GroupNames": {
            "shape": "S1nr",
            "locationName": "UserGroup"
          },
          "OperationType": {},
          "SnapshotId": {},
          "UserIds": {
            "shape": "S270",
            "locationName": "UserId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySnapshotTier": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "StorageTier": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "TieringStartTime": {
            "locationName": "tieringStartTime",
            "type": "timestamp"
          }
        }
      }
    },
    "ModifySpotFleetRequest": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "ExcessCapacityTerminationPolicy": {
            "locationName": "excessCapacityTerminationPolicy"
          },
          "LaunchTemplateConfigs": {
            "shape": "S1p6",
            "locationName": "LaunchTemplateConfig"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "TargetCapacity": {
            "locationName": "targetCapacity",
            "type": "integer"
          },
          "OnDemandTargetCapacity": {
            "type": "integer"
          },
          "Context": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySubnetAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "AssignIpv6AddressOnCreation": {
            "shape": "S19o"
          },
          "MapPublicIpOnLaunch": {
            "shape": "S19o"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "MapCustomerOwnedIpOnLaunch": {
            "shape": "S19o"
          },
          "CustomerOwnedIpv4Pool": {},
          "EnableDns64": {
            "shape": "S19o"
          },
          "PrivateDnsHostnameTypeOnLaunch": {},
          "EnableResourceNameDnsARecordOnLaunch": {
            "shape": "S19o"
          },
          "EnableResourceNameDnsAAAARecordOnLaunch": {
            "shape": "S19o"
          },
          "EnableLniAtDeviceIndex": {
            "type": "integer"
          },
          "DisableLniAtDeviceIndex": {
            "shape": "S19o"
          }
        }
      }
    },
    "ModifyTrafficMirrorFilterNetworkServices": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "AddNetworkServices": {
            "shape": "Sot",
            "locationName": "AddNetworkService"
          },
          "RemoveNetworkServices": {
            "shape": "Sot",
            "locationName": "RemoveNetworkService"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilter": {
            "shape": "Son",
            "locationName": "trafficMirrorFilter"
          }
        }
      }
    },
    "ModifyTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterRuleId"
        ],
        "members": {
          "TrafficMirrorFilterRuleId": {},
          "TrafficDirection": {},
          "RuleNumber": {
            "type": "integer"
          },
          "RuleAction": {},
          "DestinationPortRange": {
            "shape": "Sox"
          },
          "SourcePortRange": {
            "shape": "Sox"
          },
          "Protocol": {
            "type": "integer"
          },
          "DestinationCidrBlock": {},
          "SourceCidrBlock": {},
          "Description": {},
          "RemoveFields": {
            "locationName": "RemoveField",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRule": {
            "shape": "Sop",
            "locationName": "trafficMirrorFilterRule"
          }
        }
      }
    },
    "ModifyTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorSessionId"
        ],
        "members": {
          "TrafficMirrorSessionId": {},
          "TrafficMirrorTargetId": {},
          "TrafficMirrorFilterId": {},
          "PacketLength": {
            "type": "integer"
          },
          "SessionNumber": {
            "type": "integer"
          },
          "VirtualNetworkId": {
            "type": "integer"
          },
          "Description": {},
          "RemoveFields": {
            "locationName": "RemoveField",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSession": {
            "shape": "Sp2",
            "locationName": "trafficMirrorSession"
          }
        }
      }
    },
    "ModifyTransitGateway": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "Description": {},
          "Options": {
            "type": "structure",
            "members": {
              "AddTransitGatewayCidrBlocks": {
                "shape": "Spe"
              },
              "RemoveTransitGatewayCidrBlocks": {
                "shape": "Spe"
              },
              "VpnEcmpSupport": {},
              "DnsSupport": {},
              "SecurityGroupReferencingSupport": {},
              "AutoAcceptSharedAttachments": {},
              "DefaultRouteTableAssociation": {},
              "AssociationDefaultRouteTableId": {},
              "DefaultRouteTablePropagation": {},
              "PropagationDefaultRouteTableId": {},
              "AmazonSideAsn": {
                "type": "long"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Spg",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "ModifyTransitGatewayPrefixListReference": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "PrefixListId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "PrefixListId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPrefixListReference": {
            "shape": "Sqj",
            "locationName": "transitGatewayPrefixListReference"
          }
        }
      }
    },
    "ModifyTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "AddSubnetIds": {
            "shape": "S59"
          },
          "RemoveSubnetIds": {
            "shape": "S59"
          },
          "Options": {
            "type": "structure",
            "members": {
              "DnsSupport": {},
              "SecurityGroupReferencingSupport": {},
              "Ipv6Support": {},
              "ApplianceModeSupport": {}
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "S16",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "ModifyVerifiedAccessEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessEndpointId"
        ],
        "members": {
          "VerifiedAccessEndpointId": {},
          "VerifiedAccessGroupId": {},
          "LoadBalancerOptions": {
            "type": "structure",
            "members": {
              "SubnetIds": {
                "locationName": "SubnetId",
                "type": "list",
                "member": {
                  "locationName": "item"
                }
              },
              "Protocol": {},
              "Port": {
                "type": "integer"
              }
            }
          },
          "NetworkInterfaceOptions": {
            "type": "structure",
            "members": {
              "Protocol": {},
              "Port": {
                "type": "integer"
              }
            }
          },
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessEndpoint": {
            "shape": "Srk",
            "locationName": "verifiedAccessEndpoint"
          }
        }
      }
    },
    "ModifyVerifiedAccessEndpointPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessEndpointId"
        ],
        "members": {
          "VerifiedAccessEndpointId": {},
          "PolicyEnabled": {
            "type": "boolean"
          },
          "PolicyDocument": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SseSpecification": {
            "shape": "Sri"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyEnabled": {
            "locationName": "policyEnabled",
            "type": "boolean"
          },
          "PolicyDocument": {
            "locationName": "policyDocument"
          },
          "SseSpecification": {
            "shape": "S6g",
            "locationName": "sseSpecification"
          }
        }
      }
    },
    "ModifyVerifiedAccessGroup": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessGroupId"
        ],
        "members": {
          "VerifiedAccessGroupId": {},
          "VerifiedAccessInstanceId": {},
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessGroup": {
            "shape": "Srs",
            "locationName": "verifiedAccessGroup"
          }
        }
      }
    },
    "ModifyVerifiedAccessGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessGroupId"
        ],
        "members": {
          "VerifiedAccessGroupId": {},
          "PolicyEnabled": {
            "type": "boolean"
          },
          "PolicyDocument": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SseSpecification": {
            "shape": "Sri"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyEnabled": {
            "locationName": "policyEnabled",
            "type": "boolean"
          },
          "PolicyDocument": {
            "locationName": "policyDocument"
          },
          "SseSpecification": {
            "shape": "S6g",
            "locationName": "sseSpecification"
          }
        }
      }
    },
    "ModifyVerifiedAccessInstance": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessInstanceId"
        ],
        "members": {
          "VerifiedAccessInstanceId": {},
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessInstance": {
            "shape": "S6i",
            "locationName": "verifiedAccessInstance"
          }
        }
      }
    },
    "ModifyVerifiedAccessInstanceLoggingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessInstanceId",
          "AccessLogs"
        ],
        "members": {
          "VerifiedAccessInstanceId": {},
          "AccessLogs": {
            "type": "structure",
            "members": {
              "S3": {
                "type": "structure",
                "required": [
                  "Enabled"
                ],
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  },
                  "BucketName": {},
                  "Prefix": {},
                  "BucketOwner": {}
                }
              },
              "CloudWatchLogs": {
                "type": "structure",
                "required": [
                  "Enabled"
                ],
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  },
                  "LogGroup": {}
                }
              },
              "KinesisDataFirehose": {
                "type": "structure",
                "required": [
                  "Enabled"
                ],
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  },
                  "DeliveryStream": {}
                }
              },
              "LogVersion": {},
              "IncludeTrustContext": {
                "type": "boolean"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingConfiguration": {
            "shape": "S1sq",
            "locationName": "loggingConfiguration"
          }
        }
      }
    },
    "ModifyVerifiedAccessTrustProvider": {
      "input": {
        "type": "structure",
        "required": [
          "VerifiedAccessTrustProviderId"
        ],
        "members": {
          "VerifiedAccessTrustProviderId": {},
          "OidcOptions": {
            "type": "structure",
            "members": {
              "Issuer": {},
              "AuthorizationEndpoint": {},
              "TokenEndpoint": {},
              "UserInfoEndpoint": {},
              "ClientId": {},
              "ClientSecret": {
                "shape": "S6e"
              },
              "Scope": {}
            }
          },
          "DeviceOptions": {
            "type": "structure",
            "members": {
              "PublicSigningKeyUrl": {}
            }
          },
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "SseSpecification": {
            "shape": "Sri"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VerifiedAccessTrustProvider": {
            "shape": "S69",
            "locationName": "verifiedAccessTrustProvider"
          }
        }
      }
    },
    "ModifyVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VolumeId": {},
          "Size": {
            "type": "integer"
          },
          "VolumeType": {},
          "Iops": {
            "type": "integer"
          },
          "Throughput": {
            "type": "integer"
          },
          "MultiAttachEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeModification": {
            "shape": "S1tu",
            "locationName": "volumeModification"
          }
        }
      }
    },
    "ModifyVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "AutoEnableIO": {
            "shape": "S19o"
          },
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "EnableDnsHostnames": {
            "shape": "S19o"
          },
          "EnableDnsSupport": {
            "shape": "S19o"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "EnableNetworkAddressUsageMetrics": {
            "shape": "S19o"
          }
        }
      }
    },
    "ModifyVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointId": {},
          "ResetPolicy": {
            "type": "boolean"
          },
          "PolicyDocument": {},
          "AddRouteTableIds": {
            "shape": "Ss7",
            "locationName": "AddRouteTableId"
          },
          "RemoveRouteTableIds": {
            "shape": "Ss7",
            "locationName": "RemoveRouteTableId"
          },
          "AddSubnetIds": {
            "shape": "Ss8",
            "locationName": "AddSubnetId"
          },
          "RemoveSubnetIds": {
            "shape": "Ss8",
            "locationName": "RemoveSubnetId"
          },
          "AddSecurityGroupIds": {
            "shape": "Ss9",
            "locationName": "AddSecurityGroupId"
          },
          "RemoveSecurityGroupIds": {
            "shape": "Ss9",
            "locationName": "RemoveSecurityGroupId"
          },
          "IpAddressType": {},
          "DnsOptions": {
            "shape": "Ssb"
          },
          "PrivateDnsEnabled": {
            "type": "boolean"
          },
          "SubnetConfigurations": {
            "shape": "Ssd",
            "locationName": "SubnetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointConnectionNotification": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationId": {},
          "ConnectionNotificationArn": {},
          "ConnectionEvents": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServiceConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "PrivateDnsName": {},
          "RemovePrivateDnsName": {
            "type": "boolean"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "AddNetworkLoadBalancerArns": {
            "shape": "So",
            "locationName": "AddNetworkLoadBalancerArn"
          },
          "RemoveNetworkLoadBalancerArns": {
            "shape": "So",
            "locationName": "RemoveNetworkLoadBalancerArn"
          },
          "AddGatewayLoadBalancerArns": {
            "shape": "So",
            "locationName": "AddGatewayLoadBalancerArn"
          },
          "RemoveGatewayLoadBalancerArns": {
            "shape": "So",
            "locationName": "RemoveGatewayLoadBalancerArn"
          },
          "AddSupportedIpAddressTypes": {
            "shape": "So",
            "locationName": "AddSupportedIpAddressType"
          },
          "RemoveSupportedIpAddressTypes": {
            "shape": "So",
            "locationName": "RemoveSupportedIpAddressType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServicePayerResponsibility": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "PayerResponsibility"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "PayerResponsibility": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "AddAllowedPrincipals": {
            "shape": "So"
          },
          "RemoveAllowedPrincipals": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddedPrincipals": {
            "locationName": "addedPrincipalSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrincipalType": {
                  "locationName": "principalType"
                },
                "Principal": {
                  "locationName": "principal"
                },
                "ServicePermissionId": {
                  "locationName": "servicePermissionId"
                },
                "ServiceId": {
                  "locationName": "serviceId"
                }
              }
            }
          },
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcPeeringConnectionOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "AccepterPeeringConnectionOptions": {
            "shape": "S2b5"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RequesterPeeringConnectionOptions": {
            "shape": "S2b5"
          },
          "VpcPeeringConnectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccepterPeeringConnectionOptions": {
            "shape": "S2b7",
            "locationName": "accepterPeeringConnectionOptions"
          },
          "RequesterPeeringConnectionOptions": {
            "shape": "S2b7",
            "locationName": "requesterPeeringConnectionOptions"
          }
        }
      }
    },
    "ModifyVpcTenancy": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "InstanceTenancy"
        ],
        "members": {
          "VpcId": {},
          "InstanceTenancy": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "TransitGatewayId": {},
          "CustomerGatewayId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Stw",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "ModifyVpnConnectionOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "LocalIpv4NetworkCidr": {},
          "RemoteIpv4NetworkCidr": {},
          "LocalIpv6NetworkCidr": {},
          "RemoteIpv6NetworkCidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Stw",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "ModifyVpnTunnelCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Stw",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "ModifyVpnTunnelOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress",
          "TunnelOptions"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "TunnelOptions": {
            "type": "structure",
            "members": {
              "TunnelInsideCidr": {},
              "TunnelInsideIpv6Cidr": {},
              "PreSharedKey": {
                "shape": "Std"
              },
              "Phase1LifetimeSeconds": {
                "type": "integer"
              },
              "Phase2LifetimeSeconds": {
                "type": "integer"
              },
              "RekeyMarginTimeSeconds": {
                "type": "integer"
              },
              "RekeyFuzzPercentage": {
                "type": "integer"
              },
              "ReplayWindowSize": {
                "type": "integer"
              },
              "DPDTimeoutSeconds": {
                "type": "integer"
              },
              "DPDTimeoutAction": {},
              "Phase1EncryptionAlgorithms": {
                "shape": "Ste",
                "locationName": "Phase1EncryptionAlgorithm"
              },
              "Phase2EncryptionAlgorithms": {
                "shape": "Stg",
                "locationName": "Phase2EncryptionAlgorithm"
              },
              "Phase1IntegrityAlgorithms": {
                "shape": "Sti",
                "locationName": "Phase1IntegrityAlgorithm"
              },
              "Phase2IntegrityAlgorithms": {
                "shape": "Stk",
                "locationName": "Phase2IntegrityAlgorithm"
              },
              "Phase1DHGroupNumbers": {
                "shape": "Stm",
                "locationName": "Phase1DHGroupNumber"
              },
              "Phase2DHGroupNumbers": {
                "shape": "Sto",
                "locationName": "Phase2DHGroupNumber"
              },
              "IKEVersions": {
                "shape": "Stq",
                "locationName": "IKEVersion"
              },
              "StartupAction": {},
              "LogOptions": {
                "shape": "Sts"
              },
              "EnableTunnelLifecycleControl": {
                "type": "boolean"
              }
            },
            "sensitive": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SkipTunnelReplacement": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Stw",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "MonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "S2bm",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "MoveAddressToVpc": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllocationId": {
            "locationName": "allocationId"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "MoveByoipCidrToIpam": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr",
          "IpamPoolId",
          "IpamPoolOwner"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Cidr": {},
          "IpamPoolId": {},
          "IpamPoolOwner": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1y",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "MoveCapacityReservationInstances": {
      "input": {
        "type": "structure",
        "required": [
          "SourceCapacityReservationId",
          "DestinationCapacityReservationId",
          "InstanceCount"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "SourceCapacityReservationId": {},
          "DestinationCapacityReservationId": {},
          "InstanceCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceCapacityReservation": {
            "shape": "S9z",
            "locationName": "sourceCapacityReservation"
          },
          "DestinationCapacityReservation": {
            "shape": "S9z",
            "locationName": "destinationCapacityReservation"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          }
        }
      }
    },
    "ProvisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "CidrAuthorizationContext": {
            "type": "structure",
            "required": [
              "Message",
              "Signature"
            ],
            "members": {
              "Message": {},
              "Signature": {}
            }
          },
          "PubliclyAdvertisable": {
            "type": "boolean"
          },
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "PoolTagSpecifications": {
            "shape": "S3",
            "locationName": "PoolTagSpecification"
          },
          "MultiRegion": {
            "type": "boolean"
          },
          "NetworkBorderGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1y",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "ProvisionIpamByoasn": {
      "input": {
        "type": "structure",
        "required": [
          "IpamId",
          "Asn",
          "AsnAuthorizationContext"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamId": {},
          "Asn": {},
          "AsnAuthorizationContext": {
            "type": "structure",
            "required": [
              "Message",
              "Signature"
            ],
            "members": {
              "Message": {},
              "Signature": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Byoasn": {
            "shape": "Szn",
            "locationName": "byoasn"
          }
        }
      }
    },
    "ProvisionIpamPoolCidr": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Cidr": {},
          "CidrAuthorizationContext": {
            "type": "structure",
            "members": {
              "Message": {},
              "Signature": {}
            }
          },
          "NetmaskLength": {
            "type": "integer"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "VerificationMethod": {},
          "IpamExternalResourceVerificationTokenId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IpamPoolCidr": {
            "shape": "Szr",
            "locationName": "ipamPoolCidr"
          }
        }
      }
    },
    "ProvisionPublicIpv4PoolCidr": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId",
          "PoolId",
          "NetmaskLength"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "PoolId": {},
          "NetmaskLength": {
            "type": "integer"
          },
          "NetworkBorderGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PoolId": {
            "locationName": "poolId"
          },
          "PoolAddressRange": {
            "shape": "S1lm",
            "locationName": "poolAddressRange"
          }
        }
      }
    },
    "PurchaseCapacityBlock": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityBlockOfferingId",
          "InstancePlatform"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "CapacityBlockOfferingId": {},
          "InstancePlatform": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityReservation": {
            "shape": "S9z",
            "locationName": "capacityReservation"
          }
        }
      }
    },
    "PurchaseHostReservation": {
      "input": {
        "type": "structure",
        "required": [
          "HostIdSet",
          "OfferingId"
        ],
        "members": {
          "ClientToken": {},
          "CurrencyCode": {},
          "HostIdSet": {
            "shape": "S20s"
          },
          "LimitPrice": {},
          "OfferingId": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Purchase": {
            "shape": "S20u",
            "locationName": "purchase"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          }
        }
      }
    },
    "PurchaseReservedInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceCount",
          "ReservedInstancesOfferingId"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "ReservedInstancesOfferingId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "LimitPrice": {
            "locationName": "limitPrice",
            "type": "structure",
            "members": {
              "Amount": {
                "locationName": "amount",
                "type": "double"
              },
              "CurrencyCode": {
                "locationName": "currencyCode"
              }
            }
          },
          "PurchaseTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      }
    },
    "PurchaseScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "PurchaseRequests"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "PurchaseRequests": {
            "locationName": "PurchaseRequest",
            "type": "list",
            "member": {
              "locationName": "PurchaseRequest",
              "type": "structure",
              "required": [
                "InstanceCount",
                "PurchaseToken"
              ],
              "members": {
                "InstanceCount": {
                  "type": "integer"
                },
                "PurchaseToken": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "S1ng",
              "locationName": "item"
            }
          }
        }
      }
    },
    "RebootInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RegisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "ImageLocation": {},
          "Architecture": {
            "locationName": "architecture"
          },
          "BlockDeviceMappings": {
            "shape": "Sev",
            "locationName": "BlockDeviceMapping"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EnaSupport": {
            "locationName": "enaSupport",
            "type": "boolean"
          },
          "KernelId": {
            "locationName": "kernelId"
          },
          "Name": {
            "locationName": "name"
          },
          "BillingProducts": {
            "locationName": "BillingProduct",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "RamdiskId": {
            "locationName": "ramdiskId"
          },
          "RootDeviceName": {
            "locationName": "rootDeviceName"
          },
          "SriovNetSupport": {
            "locationName": "sriovNetSupport"
          },
          "VirtualizationType": {
            "locationName": "virtualizationType"
          },
          "BootMode": {},
          "TpmSupport": {},
          "UefiData": {},
          "ImdsSupport": {},
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "RegisterInstanceEventNotificationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceTagAttribute"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceTagAttribute": {
            "type": "structure",
            "members": {
              "IncludeAllTagsOfInstance": {
                "type": "boolean"
              },
              "InstanceTagKeys": {
                "shape": "S102",
                "locationName": "InstanceTagKey"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTagAttribute": {
            "shape": "S104",
            "locationName": "instanceTagAttribute"
          }
        }
      }
    },
    "RegisterTransitGatewayMulticastGroupMembers": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId",
          "NetworkInterfaceIds"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "S106"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegisteredMulticastGroupMembers": {
            "locationName": "registeredMulticastGroupMembers",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "RegisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "registeredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "RegisterTransitGatewayMulticastGroupSources": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId",
          "NetworkInterfaceIds"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "S106"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegisteredMulticastGroupSources": {
            "locationName": "registeredMulticastGroupSources",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "RegisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "registeredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "RejectTransitGatewayMulticastDomainAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "TransitGatewayAttachmentId": {},
          "SubnetIds": {
            "shape": "So"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "shape": "Sq",
            "locationName": "associations"
          }
        }
      }
    },
    "RejectTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Sx",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "RejectTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "S16",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "RejectVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointIds": {
            "shape": "S1e",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S1h",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "RejectVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationId": {},
          "PublicIp": {},
          "NetworkBorderGroup": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "HostIds": {
            "shape": "S17s",
            "locationName": "hostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "S2f",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "S279",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ReleaseIpamPoolAllocation": {
      "input": {
        "type": "structure",
        "required": [
          "IpamPoolId",
          "Cidr",
          "IpamPoolAllocationId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "IpamPoolId": {},
          "Cidr": {},
          "IpamPoolAllocationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Success": {
            "locationName": "success",
            "type": "boolean"
          }
        }
      }
    },
    "ReplaceIamInstanceProfileAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "AssociationId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S3v"
          },
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S3x",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "ReplaceNetworkAclAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "NetworkAclId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          }
        }
      }
    },
    "ReplaceNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "Protocol",
          "RuleAction",
          "RuleNumber"
        ],
        "members": {
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "IcmpTypeCode": {
            "shape": "Sky",
            "locationName": "Icmp"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "PortRange": {
            "shape": "Skz",
            "locationName": "portRange"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "ReplaceRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DestinationPrefixListId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcEndpointId": {},
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "LocalTarget": {
            "type": "boolean"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "TransitGatewayId": {},
          "LocalGatewayId": {},
          "CarrierGatewayId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          },
          "CoreNetworkArn": {}
        }
      }
    },
    "ReplaceRouteTableAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "RouteTableId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          },
          "AssociationState": {
            "shape": "S4x",
            "locationName": "associationState"
          }
        }
      }
    },
    "ReplaceTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sqo",
            "locationName": "route"
          }
        }
      }
    },
    "ReplaceVpnTunnel": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "ApplyPendingMaintenance": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ReportInstanceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Instances",
          "ReasonCodes",
          "Status"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "Instances": {
            "shape": "S12k",
            "locationName": "instanceId"
          },
          "ReasonCodes": {
            "locationName": "reasonCode",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "RequestSpotFleet": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestConfig"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestConfig": {
            "shape": "S1or",
            "locationName": "spotFleetRequestConfig"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "RequestSpotInstances": {
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "LaunchSpecification": {
            "type": "structure",
            "members": {
              "SecurityGroupIds": {
                "locationName": "SecurityGroupId",
                "type": "list",
                "member": {
                  "locationName": "item"
                }
              },
              "SecurityGroups": {
                "locationName": "SecurityGroup",
                "type": "list",
                "member": {
                  "locationName": "item"
                }
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "S18h",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S3v",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "Monitoring": {
                "shape": "S1pm",
                "locationName": "monitoring"
              },
              "NetworkInterfaces": {
                "shape": "S1p1",
                "locationName": "NetworkInterface"
              },
              "Placement": {
                "shape": "S1p3",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "shape": "Sgy",
                "locationName": "userData"
              }
            }
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "InstanceInterruptionBehavior": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "S1pj",
            "locationName": "spotInstanceRequestSet"
          }
        }
      }
    },
    "ResetAddressAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "AllocationId",
          "Attribute"
        ],
        "members": {
          "AllocationId": {},
          "Attribute": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Address": {
            "shape": "S112",
            "locationName": "address"
          }
        }
      }
    },
    "ResetEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "ResetFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ResetImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ResetInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "InstanceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      }
    },
    "ResetNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "ResetSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RestoreAddressToClassic": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "RestoreImageFromRecycleBin": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "RestoreManagedPrefixListVersion": {
      "input": {
        "type": "structure",
        "required": [
          "PrefixListId",
          "PreviousVersion",
          "CurrentVersion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListId": {},
          "PreviousVersion": {
            "type": "long"
          },
          "CurrentVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrefixList": {
            "shape": "Skj",
            "locationName": "prefixList"
          }
        }
      }
    },
    "RestoreSnapshotFromRecycleBin": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "OutpostArn": {
            "locationName": "outpostArn"
          },
          "Description": {
            "locationName": "description"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "OwnerId": {
            "locationName": "ownerId"
          },
          "Progress": {
            "locationName": "progress"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "State": {
            "locationName": "status"
          },
          "VolumeId": {
            "locationName": "volumeId"
          },
          "VolumeSize": {
            "locationName": "volumeSize",
            "type": "integer"
          },
          "SseType": {
            "locationName": "sseType"
          }
        }
      }
    },
    "RestoreSnapshotTier": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "TemporaryRestoreDays": {
            "type": "integer"
          },
          "PermanentRestore": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "RestoreStartTime": {
            "locationName": "restoreStartTime",
            "type": "timestamp"
          },
          "RestoreDuration": {
            "locationName": "restoreDuration",
            "type": "integer"
          },
          "IsPermanentRestore": {
            "locationName": "isPermanentRestore",
            "type": "boolean"
          }
        }
      }
    },
    "RevokeClientVpnIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "TargetNetworkCidr"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetNetworkCidr": {},
          "AccessGroupId": {},
          "RevokeAllGroups": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S6w",
            "locationName": "status"
          }
        }
      }
    },
    "RevokeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "IpPermissions": {
            "shape": "S6z",
            "locationName": "ipPermissions"
          },
          "SecurityGroupRuleIds": {
            "shape": "S1nn",
            "locationName": "SecurityGroupRuleId"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          },
          "UnknownIpPermissions": {
            "shape": "S6z",
            "locationName": "unknownIpPermissionSet"
          }
        }
      }
    },
    "RevokeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "CidrIp": {},
          "FromPort": {
            "type": "integer"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S6z"
          },
          "IpProtocol": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "ToPort": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SecurityGroupRuleIds": {
            "shape": "S1nn",
            "locationName": "SecurityGroupRuleId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          },
          "UnknownIpPermissions": {
            "shape": "S6z",
            "locationName": "unknownIpPermissionSet"
          }
        }
      }
    },
    "RunInstances": {
      "input": {
        "type": "structure",
        "required": [
          "MaxCount",
          "MinCount"
        ],
        "members": {
          "BlockDeviceMappings": {
            "shape": "Sev",
            "locationName": "BlockDeviceMapping"
          },
          "ImageId": {},
          "InstanceType": {},
          "Ipv6AddressCount": {
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "Sj0",
            "locationName": "Ipv6Address"
          },
          "KernelId": {},
          "KeyName": {},
          "MaxCount": {
            "type": "integer"
          },
          "MinCount": {
            "type": "integer"
          },
          "Monitoring": {
            "shape": "S1pm"
          },
          "Placement": {
            "shape": "Scv"
          },
          "RamdiskId": {},
          "SecurityGroupIds": {
            "shape": "Sh9",
            "locationName": "SecurityGroupId"
          },
          "SecurityGroups": {
            "shape": "Shx",
            "locationName": "SecurityGroup"
          },
          "SubnetId": {},
          "UserData": {
            "type": "string",
            "sensitive": true
          },
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "ClientToken": {
            "idempotencyToken": true,
            "locationName": "clientToken"
          },
          "DisableApiTermination": {
            "locationName": "disableApiTermination",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EbsOptimized": {
            "locationName": "ebsOptimized",
            "type": "boolean"
          },
          "IamInstanceProfile": {
            "shape": "S3v",
            "locationName": "iamInstanceProfile"
          },
          "InstanceInitiatedShutdownBehavior": {
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "NetworkInterfaces": {
            "shape": "S1p1",
            "locationName": "networkInterface"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "ElasticGpuSpecification": {
            "type": "list",
            "member": {
              "shape": "Sht",
              "locationName": "item"
            }
          },
          "ElasticInferenceAccelerators": {
            "locationName": "ElasticInferenceAccelerator",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "Type"
              ],
              "members": {
                "Type": {},
                "Count": {
                  "type": "integer"
                }
              }
            }
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "LaunchTemplate": {
            "type": "structure",
            "members": {
              "LaunchTemplateId": {},
              "LaunchTemplateName": {},
              "Version": {}
            }
          },
          "InstanceMarketOptions": {
            "type": "structure",
            "members": {
              "MarketType": {},
              "SpotOptions": {
                "type": "structure",
                "members": {
                  "MaxPrice": {},
                  "SpotInstanceType": {},
                  "BlockDurationMinutes": {
                    "type": "integer"
                  },
                  "ValidUntil": {
                    "type": "timestamp"
                  },
                  "InstanceInterruptionBehavior": {}
                }
              }
            }
          },
          "CreditSpecification": {
            "shape": "Si3"
          },
          "CpuOptions": {
            "type": "structure",
            "members": {
              "CoreCount": {
                "type": "integer"
              },
              "ThreadsPerCore": {
                "type": "integer"
              },
              "AmdSevSnp": {}
            }
          },
          "CapacityReservationSpecification": {
            "shape": "S27m"
          },
          "HibernationOptions": {
            "type": "structure",
            "members": {
              "Configured": {
                "type": "boolean"
              }
            }
          },
          "LicenseSpecifications": {
            "locationName": "LicenseSpecification",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LicenseConfigurationArn": {}
              }
            }
          },
          "MetadataOptions": {
            "type": "structure",
            "members": {
              "HttpTokens": {},
              "HttpPutResponseHopLimit": {
                "type": "integer"
              },
              "HttpEndpoint": {},
              "HttpProtocolIpv6": {},
              "InstanceMetadataTags": {}
            }
          },
          "EnclaveOptions": {
            "type": "structure",
            "members": {
              "Enabled": {
                "type": "boolean"
              }
            }
          },
          "PrivateDnsNameOptions": {
            "type": "structure",
            "members": {
              "HostnameType": {},
              "EnableResourceNameDnsARecord": {
                "type": "boolean"
              },
              "EnableResourceNameDnsAAAARecord": {
                "type": "boolean"
              }
            }
          },
          "MaintenanceOptions": {
            "type": "structure",
            "members": {
              "AutoRecovery": {}
            }
          },
          "DisableApiStop": {
            "type": "boolean"
          },
          "EnablePrimaryIpv6": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S1eu"
      }
    },
    "RunScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchSpecification",
          "ScheduledInstanceId"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "InstanceCount": {
            "type": "integer"
          },
          "LaunchSpecification": {
            "type": "structure",
            "required": [
              "ImageId"
            ],
            "members": {
              "BlockDeviceMappings": {
                "locationName": "BlockDeviceMapping",
                "type": "list",
                "member": {
                  "locationName": "BlockDeviceMapping",
                  "type": "structure",
                  "members": {
                    "DeviceName": {},
                    "Ebs": {
                      "type": "structure",
                      "members": {
                        "DeleteOnTermination": {
                          "type": "boolean"
                        },
                        "Encrypted": {
                          "type": "boolean"
                        },
                        "Iops": {
                          "type": "integer"
                        },
                        "SnapshotId": {},
                        "VolumeSize": {
                          "type": "integer"
                        },
                        "VolumeType": {}
                      }
                    },
                    "NoDevice": {},
                    "VirtualName": {}
                  }
                }
              },
              "EbsOptimized": {
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "type": "structure",
                "members": {
                  "Arn": {},
                  "Name": {}
                }
              },
              "ImageId": {},
              "InstanceType": {},
              "KernelId": {},
              "KeyName": {},
              "Monitoring": {
                "type": "structure",
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "NetworkInterfaces": {
                "locationName": "NetworkInterface",
                "type": "list",
                "member": {
                  "locationName": "NetworkInterface",
                  "type": "structure",
                  "members": {
                    "AssociatePublicIpAddress": {
                      "type": "boolean"
                    },
                    "DeleteOnTermination": {
                      "type": "boolean"
                    },
                    "Description": {},
                    "DeviceIndex": {
                      "type": "integer"
                    },
                    "Groups": {
                      "shape": "S2fj",
                      "locationName": "Group"
                    },
                    "Ipv6AddressCount": {
                      "type": "integer"
                    },
                    "Ipv6Addresses": {
                      "locationName": "Ipv6Address",
                      "type": "list",
                      "member": {
                        "locationName": "Ipv6Address",
                        "type": "structure",
                        "members": {
                          "Ipv6Address": {}
                        }
                      }
                    },
                    "NetworkInterfaceId": {},
                    "PrivateIpAddress": {},
                    "PrivateIpAddressConfigs": {
                      "locationName": "PrivateIpAddressConfig",
                      "type": "list",
                      "member": {
                        "locationName": "PrivateIpAddressConfigSet",
                        "type": "structure",
                        "members": {
                          "Primary": {
                            "type": "boolean"
                          },
                          "PrivateIpAddress": {}
                        }
                      }
                    },
                    "SecondaryPrivateIpAddressCount": {
                      "type": "integer"
                    },
                    "SubnetId": {}
                  }
                }
              },
              "Placement": {
                "type": "structure",
                "members": {
                  "AvailabilityZone": {},
                  "GroupName": {}
                }
              },
              "RamdiskId": {},
              "SecurityGroupIds": {
                "shape": "S2fj",
                "locationName": "SecurityGroupId"
              },
              "SubnetId": {},
              "UserData": {}
            },
            "sensitive": true
          },
          "ScheduledInstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceIdSet": {
            "locationName": "instanceIdSet",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      }
    },
    "SearchLocalGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routeSet",
            "type": "list",
            "member": {
              "shape": "Sjy",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "SearchTransitGatewayMulticastGroups": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MulticastGroups": {
            "locationName": "multicastGroups",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupIpAddress": {
                  "locationName": "groupIpAddress"
                },
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "SubnetId": {
                  "locationName": "subnetId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "GroupMember": {
                  "locationName": "groupMember",
                  "type": "boolean"
                },
                "GroupSource": {
                  "locationName": "groupSource",
                  "type": "boolean"
                },
                "MemberType": {
                  "locationName": "memberType"
                },
                "SourceType": {
                  "locationName": "sourceType"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "SearchTransitGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "Filters"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "S10p",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routeSet",
            "type": "list",
            "member": {
              "shape": "Sqo",
              "locationName": "item"
            }
          },
          "AdditionalRoutesAvailable": {
            "locationName": "additionalRoutesAvailable",
            "type": "boolean"
          }
        }
      }
    },
    "SendDiagnosticInterrupt": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "StartInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StartingInstances": {
            "shape": "S2g7",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "StartNetworkInsightsAccessScopeAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsAccessScopeId",
          "ClientToken"
        ],
        "members": {
          "NetworkInsightsAccessScopeId": {},
          "DryRun": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAccessScopeAnalysis": {
            "shape": "S1j8",
            "locationName": "networkInsightsAccessScopeAnalysis"
          }
        }
      }
    },
    "StartNetworkInsightsAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInsightsPathId",
          "ClientToken"
        ],
        "members": {
          "NetworkInsightsPathId": {},
          "AdditionalAccounts": {
            "shape": "So",
            "locationName": "AdditionalAccount"
          },
          "FilterInArns": {
            "shape": "S1jk",
            "locationName": "FilterInArn"
          },
          "DryRun": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S3",
            "locationName": "TagSpecification"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInsightsAnalysis": {
            "shape": "S1jj",
            "locationName": "networkInsightsAnalysis"
          }
        }
      }
    },
    "StartVpcEndpointServicePrivateDnsVerification": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "StopInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "Hibernate": {
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StoppingInstances": {
            "shape": "S2g7",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "TerminateClientVpnConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "ConnectionId": {},
          "Username": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointId": {
            "locationName": "clientVpnEndpointId"
          },
          "Username": {
            "locationName": "username"
          },
          "ConnectionStatuses": {
            "locationName": "connectionStatuses",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ConnectionId": {
                  "locationName": "connectionId"
                },
                "PreviousStatus": {
                  "shape": "S12z",
                  "locationName": "previousStatus"
                },
                "CurrentStatus": {
                  "shape": "S12z",
                  "locationName": "currentStatus"
                }
              }
            }
          }
        }
      }
    },
    "TerminateInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminatingInstances": {
            "shape": "S2g7",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UnassignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Ipv6Addresses": {
            "shape": "S2v",
            "locationName": "ipv6Addresses"
          },
          "Ipv6Prefixes": {
            "shape": "S2w",
            "locationName": "Ipv6Prefix"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "UnassignedIpv6Addresses": {
            "shape": "S2v",
            "locationName": "unassignedIpv6Addresses"
          },
          "UnassignedIpv6Prefixes": {
            "shape": "S2w",
            "locationName": "unassignedIpv6PrefixSet"
          }
        }
      }
    },
    "UnassignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "S30",
            "locationName": "privateIpAddress"
          },
          "Ipv4Prefixes": {
            "shape": "S2w",
            "locationName": "Ipv4Prefix"
          }
        }
      }
    },
    "UnassignPrivateNatGatewayAddress": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId",
          "PrivateIpAddresses"
        ],
        "members": {
          "NatGatewayId": {},
          "PrivateIpAddresses": {
            "shape": "S38",
            "locationName": "PrivateIpAddress"
          },
          "MaxDrainDurationSeconds": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "NatGatewayAddresses": {
            "shape": "S3b",
            "locationName": "natGatewayAddressSet"
          }
        }
      }
    },
    "UnlockSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          }
        }
      }
    },
    "UnmonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S12k",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "S2bm",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UpdateSecurityGroupRuleDescriptionsEgress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S6z"
          },
          "SecurityGroupRuleDescriptions": {
            "shape": "S2gx",
            "locationName": "SecurityGroupRuleDescription"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "UpdateSecurityGroupRuleDescriptionsIngress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S6z"
          },
          "SecurityGroupRuleDescriptions": {
            "shape": "S2gx",
            "locationName": "SecurityGroupRuleDescription"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "WithdrawByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1y",
            "locationName": "byoipCidr"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ResourceType": {
            "locationName": "resourceType"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "Tag"
          }
        }
      }
    },
    "S6": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Key": {
            "locationName": "key"
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "PublicIp": {
          "locationName": "publicIp"
        },
        "AllocationId": {
          "locationName": "allocationId"
        },
        "TransferAccountId": {
          "locationName": "transferAccountId"
        },
        "TransferOfferExpirationTimestamp": {
          "locationName": "transferOfferExpirationTimestamp",
          "type": "timestamp"
        },
        "TransferOfferAcceptedTimestamp": {
          "locationName": "transferOfferAcceptedTimestamp",
          "type": "timestamp"
        },
        "AddressTransferStatus": {
          "locationName": "addressTransferStatus"
        }
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstanceId"
      }
    },
    "Sg": {
      "type": "list",
      "member": {
        "locationName": "TargetConfigurationRequest",
        "type": "structure",
        "required": [
          "OfferingId"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "OfferingId": {}
        }
      }
    },
    "So": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "TransitGatewayMulticastDomainId": {
          "locationName": "transitGatewayMulticastDomainId"
        },
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "ResourceOwnerId": {
          "locationName": "resourceOwnerId"
        },
        "Subnets": {
          "locationName": "subnets",
          "type": "list",
          "member": {
            "shape": "St",
            "locationName": "item"
          }
        }
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "SubnetId": {
          "locationName": "subnetId"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "AccepterTransitGatewayAttachmentId": {
          "locationName": "accepterTransitGatewayAttachmentId"
        },
        "RequesterTgwInfo": {
          "shape": "Sy",
          "locationName": "requesterTgwInfo"
        },
        "AccepterTgwInfo": {
          "shape": "Sy",
          "locationName": "accepterTgwInfo"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "DynamicRouting": {
              "locationName": "dynamicRouting"
            }
          }
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "CoreNetworkId": {
          "locationName": "coreNetworkId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Region": {
          "locationName": "region"
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcOwnerId": {
          "locationName": "vpcOwnerId"
        },
        "State": {
          "locationName": "state"
        },
        "SubnetIds": {
          "shape": "So",
          "locationName": "subnetIds"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "DnsSupport": {
              "locationName": "dnsSupport"
            },
            "SecurityGroupReferencingSupport": {
              "locationName": "securityGroupReferencingSupport"
            },
            "Ipv6Support": {
              "locationName": "ipv6Support"
            },
            "ApplianceModeSupport": {
              "locationName": "applianceModeSupport"
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "shape": "S1i",
        "locationName": "item"
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "Error": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "ResourceId": {
          "locationName": "resourceId"
        }
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "AccepterVpcInfo": {
          "shape": "S1o",
          "locationName": "accepterVpcInfo"
        },
        "ExpirationTime": {
          "locationName": "expirationTime",
          "type": "timestamp"
        },
        "RequesterVpcInfo": {
          "shape": "S1o",
          "locationName": "requesterVpcInfo"
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "Ipv6CidrBlockSet": {
          "locationName": "ipv6CidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              }
            }
          }
        },
        "CidrBlockSet": {
          "locationName": "cidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "CidrBlock": {
                "locationName": "cidrBlock"
              }
            }
          }
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PeeringOptions": {
          "locationName": "peeringOptions",
          "type": "structure",
          "members": {
            "AllowDnsResolutionFromRemoteVpc": {
              "locationName": "allowDnsResolutionFromRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalClassicLinkToRemoteVpc": {
              "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalVpcToRemoteClassicLink": {
              "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
              "type": "boolean"
            }
          }
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Region": {
          "locationName": "region"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "Description": {
          "locationName": "description"
        },
        "AsnAssociations": {
          "locationName": "asnAssociationSet",
          "type": "list",
          "member": {
            "shape": "S20",
            "locationName": "item"
          }
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "State": {
          "locationName": "state"
        },
        "NetworkBorderGroup": {
          "locationName": "networkBorderGroup"
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "Asn": {
          "locationName": "asn"
        },
        "Cidr": {
          "locationName": "cidr"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "IpamPoolAllocationId": {
          "locationName": "ipamPoolAllocationId"
        },
        "Description": {
          "locationName": "description"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "ResourceRegion": {
          "locationName": "resourceRegion"
        },
        "ResourceOwner": {
          "locationName": "resourceOwner"
        }
      }
    },
    "S2r": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S2v": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S2w": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S30": {
      "type": "list",
      "member": {
        "locationName": "PrivateIpAddress"
      }
    },
    "S34": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv4Prefix": {
            "locationName": "ipv4Prefix"
          }
        }
      }
    },
    "S38": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S3b": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "AllocationId": {
            "locationName": "allocationId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIp": {
            "locationName": "privateIp"
          },
          "PublicIp": {
            "locationName": "publicIp"
          },
          "AssociationId": {
            "locationName": "associationId"
          },
          "IsPrimary": {
            "locationName": "isPrimary",
            "type": "boolean"
          },
          "FailureMessage": {
            "locationName": "failureMessage"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "S3m": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S3v": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "IamInstanceProfile": {
          "shape": "S3y",
          "locationName": "iamInstanceProfile"
        },
        "State": {
          "locationName": "state"
        },
        "Timestamp": {
          "locationName": "timestamp",
          "type": "timestamp"
        }
      }
    },
    "S3y": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Id": {
          "locationName": "id"
        }
      }
    },
    "S43": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S44": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S47": {
      "type": "structure",
      "members": {
        "InstanceEventWindowId": {
          "locationName": "instanceEventWindowId"
        },
        "TimeRanges": {
          "locationName": "timeRangeSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "StartWeekDay": {
                "locationName": "startWeekDay"
              },
              "StartHour": {
                "locationName": "startHour",
                "type": "integer"
              },
              "EndWeekDay": {
                "locationName": "endWeekDay"
              },
              "EndHour": {
                "locationName": "endHour",
                "type": "integer"
              }
            }
          }
        },
        "Name": {
          "locationName": "name"
        },
        "CronExpression": {
          "locationName": "cronExpression"
        },
        "AssociationTarget": {
          "locationName": "associationTarget",
          "type": "structure",
          "members": {
            "InstanceIds": {
              "shape": "S43",
              "locationName": "instanceIdSet"
            },
            "Tags": {
              "shape": "S6",
              "locationName": "tagSet"
            },
            "DedicatedHostIds": {
              "shape": "S44",
              "locationName": "dedicatedHostIdSet"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S4l": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "IpamResourceDiscoveryAssociationId": {
          "locationName": "ipamResourceDiscoveryAssociationId"
        },
        "IpamResourceDiscoveryAssociationArn": {
          "locationName": "ipamResourceDiscoveryAssociationArn"
        },
        "IpamResourceDiscoveryId": {
          "locationName": "ipamResourceDiscoveryId"
        },
        "IpamId": {
          "locationName": "ipamId"
        },
        "IpamArn": {
          "locationName": "ipamArn"
        },
        "IpamRegion": {
          "locationName": "ipamRegion"
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "ResourceDiscoveryStatus": {
          "locationName": "resourceDiscoveryStatus"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S4r": {
      "type": "list",
      "member": {
        "locationName": "AllocationId"
      }
    },
    "S4x": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        }
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "locationName": "ipv6CidrBlockState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        },
        "Ipv6AddressAttribute": {
          "locationName": "ipv6AddressAttribute"
        },
        "IpSource": {
          "locationName": "ipSource"
        }
      }
    },
    "S59": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "TransitGatewayPolicyTableId": {
          "locationName": "transitGatewayPolicyTableId"
        },
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S5j": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S5m": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "BranchInterfaceId": {
          "locationName": "branchInterfaceId"
        },
        "TrunkInterfaceId": {
          "locationName": "trunkInterfaceId"
        },
        "InterfaceProtocol": {
          "locationName": "interfaceProtocol"
        },
        "VlanId": {
          "locationName": "vlanId",
          "type": "integer"
        },
        "GreKey": {
          "locationName": "greKey",
          "type": "integer"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S5s": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "shape": "S5t",
          "locationName": "ipv6CidrBlockState"
        },
        "NetworkBorderGroup": {
          "locationName": "networkBorderGroup"
        },
        "Ipv6Pool": {
          "locationName": "ipv6Pool"
        },
        "Ipv6AddressAttribute": {
          "locationName": "ipv6AddressAttribute"
        },
        "IpSource": {
          "locationName": "ipSource"
        }
      }
    },
    "S5t": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        }
      }
    },
    "S5v": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "CidrBlockState": {
          "shape": "S5t",
          "locationName": "cidrBlockState"
        }
      }
    },
    "S5x": {
      "type": "list",
      "member": {
        "locationName": "groupId"
      }
    },
    "S62": {
      "type": "structure",
      "members": {
        "EnaSrdEnabled": {
          "type": "boolean"
        },
        "EnaSrdUdpSpecification": {
          "type": "structure",
          "members": {
            "EnaSrdUdpEnabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S69": {
      "type": "structure",
      "members": {
        "VerifiedAccessTrustProviderId": {
          "locationName": "verifiedAccessTrustProviderId"
        },
        "Description": {
          "locationName": "description"
        },
        "TrustProviderType": {
          "locationName": "trustProviderType"
        },
        "UserTrustProviderType": {
          "locationName": "userTrustProviderType"
        },
        "DeviceTrustProviderType": {
          "locationName": "deviceTrustProviderType"
        },
        "OidcOptions": {
          "locationName": "oidcOptions",
          "type": "structure",
          "members": {
            "Issuer": {
              "locationName": "issuer"
            },
            "AuthorizationEndpoint": {
              "locationName": "authorizationEndpoint"
            },
            "TokenEndpoint": {
              "locationName": "tokenEndpoint"
            },
            "UserInfoEndpoint": {
              "locationName": "userInfoEndpoint"
            },
            "ClientId": {
              "locationName": "clientId"
            },
            "ClientSecret": {
              "shape": "S6e",
              "locationName": "clientSecret"
            },
            "Scope": {
              "locationName": "scope"
            }
          }
        },
        "DeviceOptions": {
          "locationName": "deviceOptions",
          "type": "structure",
          "members": {
            "TenantId": {
              "locationName": "tenantId"
            },
            "PublicSigningKeyUrl": {
              "locationName": "publicSigningKeyUrl"
            }
          }
        },
        "PolicyReferenceName": {
          "locationName": "policyReferenceName"
        },
        "CreationTime": {
          "locationName": "creationTime"
        },
        "LastUpdatedTime": {
          "locationName": "lastUpdatedTime"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "SseSpecification": {
          "shape": "S6g",
          "locationName": "sseSpecification"
        }
      }
    },
    "S6e": {
      "type": "string",
      "sensitive": true
    },
    "S6g": {
      "type": "structure",
      "members": {
        "CustomerManagedKeyEnabled": {
          "locationName": "customerManagedKeyEnabled",
          "type": "boolean"
        },
        "KmsKeyArn": {
          "locationName": "kmsKeyArn"
        }
      }
    },
    "S6i": {
      "type": "structure",
      "members": {
        "VerifiedAccessInstanceId": {
          "locationName": "verifiedAccessInstanceId"
        },
        "Description": {
          "locationName": "description"
        },
        "VerifiedAccessTrustProviders": {
          "locationName": "verifiedAccessTrustProviderSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "VerifiedAccessTrustProviderId": {
                "locationName": "verifiedAccessTrustProviderId"
              },
              "Description": {
                "locationName": "description"
              },
              "TrustProviderType": {
                "locationName": "trustProviderType"
              },
              "UserTrustProviderType": {
                "locationName": "userTrustProviderType"
              },
              "DeviceTrustProviderType": {
                "locationName": "deviceTrustProviderType"
              }
            }
          }
        },
        "CreationTime": {
          "locationName": "creationTime"
        },
        "LastUpdatedTime": {
          "locationName": "lastUpdatedTime"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "FipsEnabled": {
          "locationName": "fipsEnabled",
          "type": "boolean"
        }
      }
    },
    "S6n": {
      "type": "structure",
      "members": {
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "Device": {
          "locationName": "device"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "State": {
          "locationName": "status"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        },
        "AssociatedResource": {
          "locationName": "associatedResource"
        },
        "InstanceOwningService": {
          "locationName": "instanceOwningService"
        }
      }
    },
    "S6s": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        }
      }
    },
    "S6w": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S6z": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIp": {
                  "locationName": "cidrIp"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "Ipv6Ranges": {
            "locationName": "ipv6Ranges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIpv6": {
                  "locationName": "cidrIpv6"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "PrefixListId": {
                  "locationName": "prefixListId"
                }
              }
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S78",
              "locationName": "item"
            }
          }
        }
      }
    },
    "S78": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "GroupId": {
          "locationName": "groupId"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "PeeringStatus": {
          "locationName": "peeringStatus"
        },
        "UserId": {
          "locationName": "userId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "S7a": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "SecurityGroupRuleId": {
            "locationName": "securityGroupRuleId"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "GroupOwnerId": {
            "locationName": "groupOwnerId"
          },
          "IsEgress": {
            "locationName": "isEgress",
            "type": "boolean"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "CidrIpv4": {
            "locationName": "cidrIpv4"
          },
          "CidrIpv6": {
            "locationName": "cidrIpv6"
          },
          "PrefixListId": {
            "locationName": "prefixListId"
          },
          "ReferencedGroupInfo": {
            "locationName": "referencedGroupInfo",
            "type": "structure",
            "members": {
              "GroupId": {
                "locationName": "groupId"
              },
              "PeeringStatus": {
                "locationName": "peeringStatus"
              },
              "UserId": {
                "locationName": "userId"
              },
              "VpcId": {
                "locationName": "vpcId"
              },
              "VpcPeeringConnectionId": {
                "locationName": "vpcPeeringConnectionId"
              }
            }
          },
          "Description": {
            "locationName": "description"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          }
        }
      }
    },
    "S7j": {
      "type": "structure",
      "members": {
        "S3": {
          "type": "structure",
          "members": {
            "AWSAccessKeyId": {},
            "Bucket": {
              "locationName": "bucket"
            },
            "Prefix": {
              "locationName": "prefix"
            },
            "UploadPolicy": {
              "locationName": "uploadPolicy",
              "type": "blob"
            },
            "UploadPolicySignature": {
              "locationName": "uploadPolicySignature",
              "type": "string",
              "sensitive": true
            }
          }
        }
      }
    },
    "S7o": {
      "type": "structure",
      "members": {
        "BundleId": {
          "locationName": "bundleId"
        },
        "BundleTaskError": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "State": {
          "locationName": "state"
        },
        "Storage": {
          "shape": "S7j",
          "locationName": "storage"
        },
        "UpdateTime": {
          "locationName": "updateTime",
          "type": "timestamp"
        }
      }
    },
    "S7y": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S8m": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "CreateDate": {
            "locationName": "createDate",
            "type": "timestamp"
          },
          "InstanceCounts": {
            "locationName": "instanceCounts",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Active": {
                  "locationName": "active",
                  "type": "boolean"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "Term": {
                  "locationName": "term",
                  "type": "long"
                }
              }
            }
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          },
          "UpdateDate": {
            "locationName": "updateDate",
            "type": "timestamp"
          }
        }
      }
    },
    "S8y": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S99": {
      "type": "list",
      "member": {
        "locationName": "SpotInstanceRequestId"
      }
    },
    "S9z": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {
          "locationName": "capacityReservationId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "CapacityReservationArn": {
          "locationName": "capacityReservationArn"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "InstancePlatform": {
          "locationName": "instancePlatform"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "TotalInstanceCount": {
          "locationName": "totalInstanceCount",
          "type": "integer"
        },
        "AvailableInstanceCount": {
          "locationName": "availableInstanceCount",
          "type": "integer"
        },
        "EbsOptimized": {
          "locationName": "ebsOptimized",
          "type": "boolean"
        },
        "EphemeralStorage": {
          "locationName": "ephemeralStorage",
          "type": "boolean"
        },
        "State": {
          "locationName": "state"
        },
        "StartDate": {
          "locationName": "startDate",
          "type": "timestamp"
        },
        "EndDate": {
          "locationName": "endDate",
          "type": "timestamp"
        },
        "EndDateType": {
          "locationName": "endDateType"
        },
        "InstanceMatchCriteria": {
          "locationName": "instanceMatchCriteria"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "CapacityReservationFleetId": {
          "locationName": "capacityReservationFleetId"
        },
        "PlacementGroupArn": {
          "locationName": "placementGroupArn"
        },
        "CapacityAllocations": {
          "locationName": "capacityAllocationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AllocationType": {
                "locationName": "allocationType"
              },
              "Count": {
                "locationName": "count",
                "type": "integer"
              }
            }
          }
        },
        "ReservationType": {
          "locationName": "reservationType"
        }
      }
    },
    "Sag": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CapacityReservationId": {
            "locationName": "capacityReservationId"
          },
          "AvailabilityZoneId": {
            "locationName": "availabilityZoneId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "InstancePlatform": {
            "locationName": "instancePlatform"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "TotalInstanceCount": {
            "locationName": "totalInstanceCount",
            "type": "integer"
          },
          "FulfilledCapacity": {
            "locationName": "fulfilledCapacity",
            "type": "double"
          },
          "EbsOptimized": {
            "locationName": "ebsOptimized",
            "type": "boolean"
          },
          "CreateDate": {
            "locationName": "createDate",
            "type": "timestamp"
          },
          "Weight": {
            "locationName": "weight",
            "type": "double"
          },
          "Priority": {
            "locationName": "priority",
            "type": "integer"
          }
        }
      }
    },
    "Sak": {
      "type": "structure",
      "members": {
        "CarrierGatewayId": {
          "locationName": "carrierGatewayId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "State": {
          "locationName": "state"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sau": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "CloudwatchLogGroup": {},
        "CloudwatchLogStream": {}
      }
    },
    "Sax": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LambdaFunctionArn": {}
      }
    },
    "Say": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "BannerText": {}
      }
    },
    "Sb0": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sb4": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sb9": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "CoipPoolId": {
          "locationName": "coipPoolId"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        }
      }
    },
    "Sbd": {
      "type": "structure",
      "members": {
        "PoolId": {
          "locationName": "poolId"
        },
        "PoolCidrs": {
          "shape": "So",
          "locationName": "poolCidrSet"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "PoolArn": {
          "locationName": "poolArn"
        }
      }
    },
    "Sbh": {
      "type": "structure",
      "members": {
        "BgpAsn": {
          "locationName": "bgpAsn"
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "IpAddress": {
          "locationName": "ipAddress"
        },
        "CertificateArn": {
          "locationName": "certificateArn"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "DeviceName": {
          "locationName": "deviceName"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "BgpAsnExtended": {
          "locationName": "bgpAsnExtended"
        }
      }
    },
    "Sbk": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        },
        "AvailableIpAddressCount": {
          "locationName": "availableIpAddressCount",
          "type": "integer"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DefaultForAz": {
          "locationName": "defaultForAz",
          "type": "boolean"
        },
        "EnableLniAtDeviceIndex": {
          "locationName": "enableLniAtDeviceIndex",
          "type": "integer"
        },
        "MapPublicIpOnLaunch": {
          "locationName": "mapPublicIpOnLaunch",
          "type": "boolean"
        },
        "MapCustomerOwnedIpOnLaunch": {
          "locationName": "mapCustomerOwnedIpOnLaunch",
          "type": "boolean"
        },
        "CustomerOwnedIpv4Pool": {
          "locationName": "customerOwnedIpv4Pool"
        },
        "State": {
          "locationName": "state"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "AssignIpv6AddressOnCreation": {
          "locationName": "assignIpv6AddressOnCreation",
          "type": "boolean"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S52",
            "locationName": "item"
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "SubnetArn": {
          "locationName": "subnetArn"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "EnableDns64": {
          "locationName": "enableDns64",
          "type": "boolean"
        },
        "Ipv6Native": {
          "locationName": "ipv6Native",
          "type": "boolean"
        },
        "PrivateDnsNameOptionsOnLaunch": {
          "locationName": "privateDnsNameOptionsOnLaunch",
          "type": "structure",
          "members": {
            "HostnameType": {
              "locationName": "hostnameType"
            },
            "EnableResourceNameDnsARecord": {
              "locationName": "enableResourceNameDnsARecord",
              "type": "boolean"
            },
            "EnableResourceNameDnsAAAARecord": {
              "locationName": "enableResourceNameDnsAAAARecord",
              "type": "boolean"
            }
          }
        }
      }
    },
    "Sbs": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "InstanceTenancy": {
          "locationName": "instanceTenancy"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S5s",
            "locationName": "item"
          }
        },
        "CidrBlockAssociationSet": {
          "locationName": "cidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S5v",
            "locationName": "item"
          }
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sc1": {
      "type": "structure",
      "members": {
        "DhcpConfigurations": {
          "locationName": "dhcpConfigurationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Key": {
                "locationName": "key"
              },
              "Values": {
                "locationName": "valueSet",
                "type": "list",
                "member": {
                  "shape": "Sc5",
                  "locationName": "item"
                }
              }
            }
          }
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sc5": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value"
        }
      }
    },
    "Sc8": {
      "type": "structure",
      "members": {
        "Attachments": {
          "shape": "Sc9",
          "locationName": "attachmentSet"
        },
        "EgressOnlyInternetGatewayId": {
          "locationName": "egressOnlyInternetGatewayId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sc9": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "State": {
            "locationName": "state"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "Sco": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "LaunchTemplateSpecification": {
            "type": "structure",
            "members": {
              "LaunchTemplateId": {},
              "LaunchTemplateName": {},
              "Version": {}
            }
          },
          "Overrides": {
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {},
                "MaxPrice": {},
                "SubnetId": {},
                "AvailabilityZone": {},
                "WeightedCapacity": {
                  "type": "double"
                },
                "Priority": {
                  "type": "double"
                },
                "Placement": {
                  "shape": "Scv"
                },
                "InstanceRequirements": {
                  "shape": "Scy"
                },
                "ImageId": {}
              }
            }
          }
        }
      }
    },
    "Scv": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Affinity": {
          "locationName": "affinity"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "PartitionNumber": {
          "locationName": "partitionNumber",
          "type": "integer"
        },
        "HostId": {
          "locationName": "hostId"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "SpreadDomain": {
          "locationName": "spreadDomain"
        },
        "HostResourceGroupArn": {
          "locationName": "hostResourceGroupArn"
        },
        "GroupId": {
          "locationName": "groupId"
        }
      }
    },
    "Scy": {
      "type": "structure",
      "required": [
        "VCpuCount",
        "MemoryMiB"
      ],
      "members": {
        "VCpuCount": {
          "type": "structure",
          "required": [
            "Min"
          ],
          "members": {
            "Min": {
              "type": "integer"
            },
            "Max": {
              "type": "integer"
            }
          }
        },
        "MemoryMiB": {
          "type": "structure",
          "required": [
            "Min"
          ],
          "members": {
            "Min": {
              "type": "integer"
            },
            "Max": {
              "type": "integer"
            }
          }
        },
        "CpuManufacturers": {
          "shape": "Sd1",
          "locationName": "CpuManufacturer"
        },
        "MemoryGiBPerVCpu": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "double"
            },
            "Max": {
              "type": "double"
            }
          }
        },
        "ExcludedInstanceTypes": {
          "shape": "Sd4",
          "locationName": "ExcludedInstanceType"
        },
        "InstanceGenerations": {
          "shape": "Sd6",
          "locationName": "InstanceGeneration"
        },
        "SpotMaxPricePercentageOverLowestPrice": {
          "type": "integer"
        },
        "OnDemandMaxPricePercentageOverLowestPrice": {
          "type": "integer"
        },
        "BareMetal": {},
        "BurstablePerformance": {},
        "RequireHibernateSupport": {
          "type": "boolean"
        },
        "NetworkInterfaceCount": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "integer"
            },
            "Max": {
              "type": "integer"
            }
          }
        },
        "LocalStorage": {},
        "LocalStorageTypes": {
          "shape": "Sdc",
          "locationName": "LocalStorageType"
        },
        "TotalLocalStorageGB": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "double"
            },
            "Max": {
              "type": "double"
            }
          }
        },
        "BaselineEbsBandwidthMbps": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "integer"
            },
            "Max": {
              "type": "integer"
            }
          }
        },
        "AcceleratorTypes": {
          "shape": "Sdg",
          "locationName": "AcceleratorType"
        },
        "AcceleratorCount": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "integer"
            },
            "Max": {
              "type": "integer"
            }
          }
        },
        "AcceleratorManufacturers": {
          "shape": "Sdj",
          "locationName": "AcceleratorManufacturer"
        },
        "AcceleratorNames": {
          "shape": "Sdl",
          "locationName": "AcceleratorName"
        },
        "AcceleratorTotalMemoryMiB": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "integer"
            },
            "Max": {
              "type": "integer"
            }
          }
        },
        "NetworkBandwidthGbps": {
          "type": "structure",
          "members": {
            "Min": {
              "type": "double"
            },
            "Max": {
              "type": "double"
            }
          }
        },
        "AllowedInstanceTypes": {
          "shape": "Sdp",
          "locationName": "AllowedInstanceType"
        },
        "MaxSpotPriceAsPercentageOfOptimalOnDemandPrice": {
          "type": "integer"
        }
      }
    },
    "Sd1": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sd4": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sd6": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sdc": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sdg": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sdj": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sdl": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sdp": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sdr": {
      "type": "structure",
      "required": [
        "TotalTargetCapacity"
      ],
      "members": {
        "TotalTargetCapacity": {
          "type": "integer"
        },
        "OnDemandTargetCapacity": {
          "type": "integer"
        },
        "SpotTargetCapacity": {
          "type": "integer"
        },
        "DefaultTargetCapacityType": {},
        "TargetCapacityUnitType": {}
      }
    },
    "Sdz": {
      "type": "structure",
      "members": {
        "LaunchTemplateSpecification": {
          "shape": "Se0",
          "locationName": "launchTemplateSpecification"
        },
        "Overrides": {
          "shape": "Se1",
          "locationName": "overrides"
        }
      }
    },
    "Se0": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "Version": {
          "locationName": "version"
        }
      }
    },
    "Se1": {
      "type": "structure",
      "members": {
        "InstanceType": {
          "locationName": "instanceType"
        },
        "MaxPrice": {
          "locationName": "maxPrice"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "WeightedCapacity": {
          "locationName": "weightedCapacity",
          "type": "double"
        },
        "Priority": {
          "locationName": "priority",
          "type": "double"
        },
        "Placement": {
          "locationName": "placement",
          "type": "structure",
          "members": {
            "GroupName": {
              "locationName": "groupName"
            }
          }
        },
        "InstanceRequirements": {
          "shape": "Se3",
          "locationName": "instanceRequirements"
        },
        "ImageId": {
          "locationName": "imageId"
        }
      }
    },
    "Se3": {
      "type": "structure",
      "members": {
        "VCpuCount": {
          "locationName": "vCpuCount",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "integer"
            },
            "Max": {
              "locationName": "max",
              "type": "integer"
            }
          }
        },
        "MemoryMiB": {
          "locationName": "memoryMiB",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "integer"
            },
            "Max": {
              "locationName": "max",
              "type": "integer"
            }
          }
        },
        "CpuManufacturers": {
          "shape": "Sd1",
          "locationName": "cpuManufacturerSet"
        },
        "MemoryGiBPerVCpu": {
          "locationName": "memoryGiBPerVCpu",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "double"
            },
            "Max": {
              "locationName": "max",
              "type": "double"
            }
          }
        },
        "ExcludedInstanceTypes": {
          "shape": "Sd4",
          "locationName": "excludedInstanceTypeSet"
        },
        "InstanceGenerations": {
          "shape": "Sd6",
          "locationName": "instanceGenerationSet"
        },
        "SpotMaxPricePercentageOverLowestPrice": {
          "locationName": "spotMaxPricePercentageOverLowestPrice",
          "type": "integer"
        },
        "OnDemandMaxPricePercentageOverLowestPrice": {
          "locationName": "onDemandMaxPricePercentageOverLowestPrice",
          "type": "integer"
        },
        "BareMetal": {
          "locationName": "bareMetal"
        },
        "BurstablePerformance": {
          "locationName": "burstablePerformance"
        },
        "RequireHibernateSupport": {
          "locationName": "requireHibernateSupport",
          "type": "boolean"
        },
        "NetworkInterfaceCount": {
          "locationName": "networkInterfaceCount",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "integer"
            },
            "Max": {
              "locationName": "max",
              "type": "integer"
            }
          }
        },
        "LocalStorage": {
          "locationName": "localStorage"
        },
        "LocalStorageTypes": {
          "shape": "Sdc",
          "locationName": "localStorageTypeSet"
        },
        "TotalLocalStorageGB": {
          "locationName": "totalLocalStorageGB",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "double"
            },
            "Max": {
              "locationName": "max",
              "type": "double"
            }
          }
        },
        "BaselineEbsBandwidthMbps": {
          "locationName": "baselineEbsBandwidthMbps",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "integer"
            },
            "Max": {
              "locationName": "max",
              "type": "integer"
            }
          }
        },
        "AcceleratorTypes": {
          "shape": "Sdg",
          "locationName": "acceleratorTypeSet"
        },
        "AcceleratorCount": {
          "locationName": "acceleratorCount",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "integer"
            },
            "Max": {
              "locationName": "max",
              "type": "integer"
            }
          }
        },
        "AcceleratorManufacturers": {
          "shape": "Sdj",
          "locationName": "acceleratorManufacturerSet"
        },
        "AcceleratorNames": {
          "shape": "Sdl",
          "locationName": "acceleratorNameSet"
        },
        "AcceleratorTotalMemoryMiB": {
          "locationName": "acceleratorTotalMemoryMiB",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "integer"
            },
            "Max": {
              "locationName": "max",
              "type": "integer"
            }
          }
        },
        "NetworkBandwidthGbps": {
          "locationName": "networkBandwidthGbps",
          "type": "structure",
          "members": {
            "Min": {
              "locationName": "min",
              "type": "double"
            },
            "Max": {
              "locationName": "max",
              "type": "double"
            }
          }
        },
        "AllowedInstanceTypes": {
          "shape": "Sdp",
          "locationName": "allowedInstanceTypeSet"
        },
        "MaxSpotPriceAsPercentageOfOptimalOnDemandPrice": {
          "locationName": "maxSpotPriceAsPercentageOfOptimalOnDemandPrice",
          "type": "integer"
        }
      }
    },
    "Seg": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Ses": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Key": {}
      }
    },
    "Sev": {
      "type": "list",
      "member": {
        "shape": "Sew",
        "locationName": "BlockDeviceMapping"
      }
    },
    "Sew": {
      "type": "structure",
      "members": {
        "DeviceName": {
          "locationName": "deviceName"
        },
        "VirtualName": {
          "locationName": "virtualName"
        },
        "Ebs": {
          "locationName": "ebs",
          "type": "structure",
          "members": {
            "DeleteOnTermination": {
              "locationName": "deleteOnTermination",
              "type": "boolean"
            },
            "Iops": {
              "locationName": "iops",
              "type": "integer"
            },
            "SnapshotId": {
              "locationName": "snapshotId"
            },
            "VolumeSize": {
              "locationName": "volumeSize",
              "type": "integer"
            },
            "VolumeType": {
              "locationName": "volumeType"
            },
            "KmsKeyId": {
              "locationName": "kmsKeyId"
            },
            "Throughput": {
              "locationName": "throughput",
              "type": "integer"
            },
            "OutpostArn": {
              "locationName": "outpostArn"
            },
            "Encrypted": {
              "locationName": "encrypted",
              "type": "boolean"
            }
          }
        },
        "NoDevice": {
          "locationName": "noDevice"
        }
      }
    },
    "Sf4": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "InstanceConnectEndpointId": {
          "locationName": "instanceConnectEndpointId"
        },
        "InstanceConnectEndpointArn": {
          "locationName": "instanceConnectEndpointArn"
        },
        "State": {
          "locationName": "state"
        },
        "StateMessage": {
          "locationName": "stateMessage"
        },
        "DnsName": {
          "locationName": "dnsName"
        },
        "FipsDnsName": {
          "locationName": "fipsDnsName"
        },
        "NetworkInterfaceIds": {
          "locationName": "networkInterfaceIdSet",
          "type": "list",
          "member": {
            "locationName": "item"
          }
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreatedAt": {
          "locationName": "createdAt",
          "type": "timestamp"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "PreserveClientIp": {
          "locationName": "preserveClientIp",
          "type": "boolean"
        },
        "SecurityGroupIds": {
          "locationName": "securityGroupIdSet",
          "type": "list",
          "member": {
            "locationName": "item"
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sfa": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StartWeekDay": {},
          "StartHour": {
            "type": "integer"
          },
          "EndWeekDay": {},
          "EndHour": {
            "type": "integer"
          }
        }
      }
    },
    "Sfj": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "ExportTaskId": {
          "locationName": "exportTaskId"
        },
        "ExportToS3Task": {
          "locationName": "exportToS3",
          "type": "structure",
          "members": {
            "ContainerFormat": {
              "locationName": "containerFormat"
            },
            "DiskImageFormat": {
              "locationName": "diskImageFormat"
            },
            "S3Bucket": {
              "locationName": "s3Bucket"
            },
            "S3Key": {
              "locationName": "s3Key"
            }
          }
        },
        "InstanceExportDetails": {
          "locationName": "instanceExport",
          "type": "structure",
          "members": {
            "InstanceId": {
              "locationName": "instanceId"
            },
            "TargetEnvironment": {
              "locationName": "targetEnvironment"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sfp": {
      "type": "structure",
      "members": {
        "Attachments": {
          "shape": "Sc9",
          "locationName": "attachmentSet"
        },
        "InternetGatewayId": {
          "locationName": "internetGatewayId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sfr": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RegionName": {}
        }
      }
    },
    "Sfv": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "IpamId": {
          "locationName": "ipamId"
        },
        "IpamArn": {
          "locationName": "ipamArn"
        },
        "IpamRegion": {
          "locationName": "ipamRegion"
        },
        "PublicDefaultScopeId": {
          "locationName": "publicDefaultScopeId"
        },
        "PrivateDefaultScopeId": {
          "locationName": "privateDefaultScopeId"
        },
        "ScopeCount": {
          "locationName": "scopeCount",
          "type": "integer"
        },
        "Description": {
          "locationName": "description"
        },
        "OperatingRegions": {
          "shape": "Sfx",
          "locationName": "operatingRegionSet"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "DefaultResourceDiscoveryId": {
          "locationName": "defaultResourceDiscoveryId"
        },
        "DefaultResourceDiscoveryAssociationId": {
          "locationName": "defaultResourceDiscoveryAssociationId"
        },
        "ResourceDiscoveryAssociationCount": {
          "locationName": "resourceDiscoveryAssociationCount",
          "type": "integer"
        },
        "StateMessage": {
          "locationName": "stateMessage"
        },
        "Tier": {
          "locationName": "tier"
        },
        "EnablePrivateGua": {
          "locationName": "enablePrivateGua",
          "type": "boolean"
        }
      }
    },
    "Sfx": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "RegionName": {
            "locationName": "regionName"
          }
        }
      }
    },
    "Sg2": {
      "type": "structure",
      "members": {
        "IpamExternalResourceVerificationTokenId": {
          "locationName": "ipamExternalResourceVerificationTokenId"
        },
        "IpamExternalResourceVerificationTokenArn": {
          "locationName": "ipamExternalResourceVerificationTokenArn"
        },
        "IpamId": {
          "locationName": "ipamId"
        },
        "IpamArn": {
          "locationName": "ipamArn"
        },
        "IpamRegion": {
          "locationName": "ipamRegion"
        },
        "TokenValue": {
          "locationName": "tokenValue"
        },
        "TokenName": {
          "locationName": "tokenName"
        },
        "NotAfter": {
          "locationName": "notAfter",
          "type": "timestamp"
        },
        "Status": {
          "locationName": "status"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sg9": {
      "type": "list",
      "member": {
        "shape": "Sga",
        "locationName": "item"
      }
    },
    "Sga": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "Sgg": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "IpamPoolId": {
          "locationName": "ipamPoolId"
        },
        "SourceIpamPoolId": {
          "locationName": "sourceIpamPoolId"
        },
        "IpamPoolArn": {
          "locationName": "ipamPoolArn"
        },
        "IpamScopeArn": {
          "locationName": "ipamScopeArn"
        },
        "IpamScopeType": {
          "locationName": "ipamScopeType"
        },
        "IpamArn": {
          "locationName": "ipamArn"
        },
        "IpamRegion": {
          "locationName": "ipamRegion"
        },
        "Locale": {
          "locationName": "locale"
        },
        "PoolDepth": {
          "locationName": "poolDepth",
          "type": "integer"
        },
        "State": {
          "locationName": "state"
        },
        "StateMessage": {
          "locationName": "stateMessage"
        },
        "Description": {
          "locationName": "description"
        },
        "AutoImport": {
          "locationName": "autoImport",
          "type": "boolean"
        },
        "PubliclyAdvertisable": {
          "locationName": "publiclyAdvertisable",
          "type": "boolean"
        },
        "AddressFamily": {
          "locationName": "addressFamily"
        },
        "AllocationMinNetmaskLength": {
          "locationName": "allocationMinNetmaskLength",
          "type": "integer"
        },
        "AllocationMaxNetmaskLength": {
          "locationName": "allocationMaxNetmaskLength",
          "type": "integer"
        },
        "AllocationDefaultNetmaskLength": {
          "locationName": "allocationDefaultNetmaskLength",
          "type": "integer"
        },
        "AllocationResourceTags": {
          "shape": "Sgj",
          "locationName": "allocationResourceTagSet"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "AwsService": {
          "locationName": "awsService"
        },
        "PublicIpSource": {
          "locationName": "publicIpSource"
        },
        "SourceResource": {
          "locationName": "sourceResource",
          "type": "structure",
          "members": {
            "ResourceId": {
              "locationName": "resourceId"
            },
            "ResourceType": {
              "locationName": "resourceType"
            },
            "ResourceRegion": {
              "locationName": "resourceRegion"
            },
            "ResourceOwner": {
              "locationName": "resourceOwner"
            }
          }
        }
      }
    },
    "Sgj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Key": {
            "locationName": "key"
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "Sgo": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "IpamResourceDiscoveryId": {
          "locationName": "ipamResourceDiscoveryId"
        },
        "IpamResourceDiscoveryArn": {
          "locationName": "ipamResourceDiscoveryArn"
        },
        "IpamResourceDiscoveryRegion": {
          "locationName": "ipamResourceDiscoveryRegion"
        },
        "Description": {
          "locationName": "description"
        },
        "OperatingRegions": {
          "shape": "Sfx",
          "locationName": "operatingRegionSet"
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sgs": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "IpamScopeId": {
          "locationName": "ipamScopeId"
        },
        "IpamScopeArn": {
          "locationName": "ipamScopeArn"
        },
        "IpamArn": {
          "locationName": "ipamArn"
        },
        "IpamRegion": {
          "locationName": "ipamRegion"
        },
        "IpamScopeType": {
          "locationName": "ipamScopeType"
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "Description": {
          "locationName": "description"
        },
        "PoolCount": {
          "locationName": "poolCount",
          "type": "integer"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sgy": {
      "type": "string",
      "sensitive": true
    },
    "Sh1": {
      "type": "structure",
      "members": {
        "KernelId": {},
        "EbsOptimized": {
          "type": "boolean"
        },
        "IamInstanceProfile": {
          "type": "structure",
          "members": {
            "Arn": {},
            "Name": {}
          }
        },
        "BlockDeviceMappings": {
          "locationName": "BlockDeviceMapping",
          "type": "list",
          "member": {
            "locationName": "BlockDeviceMapping",
            "type": "structure",
            "members": {
              "DeviceName": {},
              "VirtualName": {},
              "Ebs": {
                "type": "structure",
                "members": {
                  "Encrypted": {
                    "type": "boolean"
                  },
                  "DeleteOnTermination": {
                    "type": "boolean"
                  },
                  "Iops": {
                    "type": "integer"
                  },
                  "KmsKeyId": {},
                  "SnapshotId": {},
                  "VolumeSize": {
                    "type": "integer"
                  },
                  "VolumeType": {},
                  "Throughput": {
                    "type": "integer"
                  }
                }
              },
              "NoDevice": {}
            }
          }
        },
        "NetworkInterfaces": {
          "locationName": "NetworkInterface",
          "type": "list",
          "member": {
            "locationName": "InstanceNetworkInterfaceSpecification",
            "type": "structure",
            "members": {
              "AssociateCarrierIpAddress": {
                "type": "boolean"
              },
              "AssociatePublicIpAddress": {
                "type": "boolean"
              },
              "DeleteOnTermination": {
                "type": "boolean"
              },
              "Description": {},
              "DeviceIndex": {
                "type": "integer"
              },
              "Groups": {
                "shape": "Sh9",
                "locationName": "SecurityGroupId"
              },
              "InterfaceType": {},
              "Ipv6AddressCount": {
                "type": "integer"
              },
              "Ipv6Addresses": {
                "type": "list",
                "member": {
                  "locationName": "InstanceIpv6Address",
                  "type": "structure",
                  "members": {
                    "Ipv6Address": {}
                  }
                }
              },
              "NetworkInterfaceId": {},
              "PrivateIpAddress": {},
              "PrivateIpAddresses": {
                "shape": "Shc"
              },
              "SecondaryPrivateIpAddressCount": {
                "type": "integer"
              },
              "SubnetId": {},
              "NetworkCardIndex": {
                "type": "integer"
              },
              "Ipv4Prefixes": {
                "shape": "She",
                "locationName": "Ipv4Prefix"
              },
              "Ipv4PrefixCount": {
                "type": "integer"
              },
              "Ipv6Prefixes": {
                "shape": "Shg",
                "locationName": "Ipv6Prefix"
              },
              "Ipv6PrefixCount": {
                "type": "integer"
              },
              "PrimaryIpv6": {
                "type": "boolean"
              },
              "EnaSrdSpecification": {
                "shape": "Shi"
              },
              "ConnectionTrackingSpecification": {
                "shape": "Shk"
              }
            }
          }
        },
        "ImageId": {},
        "InstanceType": {},
        "KeyName": {},
        "Monitoring": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        },
        "Placement": {
          "type": "structure",
          "members": {
            "AvailabilityZone": {},
            "Affinity": {},
            "GroupName": {},
            "HostId": {},
            "Tenancy": {},
            "SpreadDomain": {},
            "HostResourceGroupArn": {},
            "PartitionNumber": {
              "type": "integer"
            },
            "GroupId": {}
          }
        },
        "RamDiskId": {},
        "DisableApiTermination": {
          "type": "boolean"
        },
        "InstanceInitiatedShutdownBehavior": {},
        "UserData": {
          "shape": "Sgy"
        },
        "TagSpecifications": {
          "locationName": "TagSpecification",
          "type": "list",
          "member": {
            "locationName": "LaunchTemplateTagSpecificationRequest",
            "type": "structure",
            "members": {
              "ResourceType": {},
              "Tags": {
                "shape": "S6",
                "locationName": "Tag"
              }
            }
          }
        },
        "ElasticGpuSpecifications": {
          "locationName": "ElasticGpuSpecification",
          "type": "list",
          "member": {
            "shape": "Sht",
            "locationName": "ElasticGpuSpecification"
          }
        },
        "ElasticInferenceAccelerators": {
          "locationName": "ElasticInferenceAccelerator",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Count": {
                "type": "integer"
              }
            }
          }
        },
        "SecurityGroupIds": {
          "shape": "Sh9",
          "locationName": "SecurityGroupId"
        },
        "SecurityGroups": {
          "shape": "Shx",
          "locationName": "SecurityGroup"
        },
        "InstanceMarketOptions": {
          "type": "structure",
          "members": {
            "MarketType": {},
            "SpotOptions": {
              "type": "structure",
              "members": {
                "MaxPrice": {},
                "SpotInstanceType": {},
                "BlockDurationMinutes": {
                  "type": "integer"
                },
                "ValidUntil": {
                  "type": "timestamp"
                },
                "InstanceInterruptionBehavior": {}
              }
            }
          }
        },
        "CreditSpecification": {
          "shape": "Si3"
        },
        "CpuOptions": {
          "type": "structure",
          "members": {
            "CoreCount": {
              "type": "integer"
            },
            "ThreadsPerCore": {
              "type": "integer"
            },
            "AmdSevSnp": {}
          }
        },
        "CapacityReservationSpecification": {
          "type": "structure",
          "members": {
            "CapacityReservationPreference": {},
            "CapacityReservationTarget": {
              "shape": "Si8"
            }
          }
        },
        "LicenseSpecifications": {
          "locationName": "LicenseSpecification",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LicenseConfigurationArn": {}
            }
          }
        },
        "HibernationOptions": {
          "type": "structure",
          "members": {
            "Configured": {
              "type": "boolean"
            }
          }
        },
        "MetadataOptions": {
          "type": "structure",
          "members": {
            "HttpTokens": {},
            "HttpPutResponseHopLimit": {
              "type": "integer"
            },
            "HttpEndpoint": {},
            "HttpProtocolIpv6": {},
            "InstanceMetadataTags": {}
          }
        },
        "EnclaveOptions": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        },
        "InstanceRequirements": {
          "shape": "Scy"
        },
        "PrivateDnsNameOptions": {
          "type": "structure",
          "members": {
            "HostnameType": {},
            "EnableResourceNameDnsARecord": {
              "type": "boolean"
            },
            "EnableResourceNameDnsAAAARecord": {
              "type": "boolean"
            }
          }
        },
        "MaintenanceOptions": {
          "type": "structure",
          "members": {
            "AutoRecovery": {}
          }
        },
        "DisableApiStop": {
          "type": "boolean"
        }
      }
    },
    "Sh9": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "Shc": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Primary": {
            "locationName": "primary",
            "type": "boolean"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          }
        }
      }
    },
    "She": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv4Prefix": {}
        }
      }
    },
    "Shg": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv6Prefix": {}
        }
      }
    },
    "Shi": {
      "type": "structure",
      "members": {
        "EnaSrdEnabled": {
          "type": "boolean"
        },
        "EnaSrdUdpSpecification": {
          "type": "structure",
          "members": {
            "EnaSrdUdpEnabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "Shk": {
      "type": "structure",
      "members": {
        "TcpEstablishedTimeout": {
          "type": "integer"
        },
        "UdpStreamTimeout": {
          "type": "integer"
        },
        "UdpTimeout": {
          "type": "integer"
        }
      }
    },
    "Sht": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {}
      }
    },
    "Shx": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroup"
      }
    },
    "Si3": {
      "type": "structure",
      "required": [
        "CpuCredits"
      ],
      "members": {
        "CpuCredits": {}
      }
    },
    "Si8": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {},
        "CapacityReservationResourceGroupArn": {}
      }
    },
    "Sim": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "CreatedBy": {
          "locationName": "createdBy"
        },
        "DefaultVersionNumber": {
          "locationName": "defaultVersionNumber",
          "type": "long"
        },
        "LatestVersionNumber": {
          "locationName": "latestVersionNumber",
          "type": "long"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sin": {
      "type": "structure",
      "members": {
        "Errors": {
          "locationName": "errorSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              }
            }
          }
        }
      }
    },
    "Sis": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "VersionNumber": {
          "locationName": "versionNumber",
          "type": "long"
        },
        "VersionDescription": {
          "locationName": "versionDescription"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "CreatedBy": {
          "locationName": "createdBy"
        },
        "DefaultVersion": {
          "locationName": "defaultVersion",
          "type": "boolean"
        },
        "LaunchTemplateData": {
          "shape": "Sit",
          "locationName": "launchTemplateData"
        }
      }
    },
    "Sit": {
      "type": "structure",
      "members": {
        "KernelId": {
          "locationName": "kernelId"
        },
        "EbsOptimized": {
          "locationName": "ebsOptimized",
          "type": "boolean"
        },
        "IamInstanceProfile": {
          "locationName": "iamInstanceProfile",
          "type": "structure",
          "members": {
            "Arn": {
              "locationName": "arn"
            },
            "Name": {
              "locationName": "name"
            }
          }
        },
        "BlockDeviceMappings": {
          "locationName": "blockDeviceMappingSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DeviceName": {
                "locationName": "deviceName"
              },
              "VirtualName": {
                "locationName": "virtualName"
              },
              "Ebs": {
                "locationName": "ebs",
                "type": "structure",
                "members": {
                  "Encrypted": {
                    "locationName": "encrypted",
                    "type": "boolean"
                  },
                  "DeleteOnTermination": {
                    "locationName": "deleteOnTermination",
                    "type": "boolean"
                  },
                  "Iops": {
                    "locationName": "iops",
                    "type": "integer"
                  },
                  "KmsKeyId": {
                    "locationName": "kmsKeyId"
                  },
                  "SnapshotId": {
                    "locationName": "snapshotId"
                  },
                  "VolumeSize": {
                    "locationName": "volumeSize",
                    "type": "integer"
                  },
                  "VolumeType": {
                    "locationName": "volumeType"
                  },
                  "Throughput": {
                    "locationName": "throughput",
                    "type": "integer"
                  }
                }
              },
              "NoDevice": {
                "locationName": "noDevice"
              }
            }
          }
        },
        "NetworkInterfaces": {
          "locationName": "networkInterfaceSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AssociateCarrierIpAddress": {
                "locationName": "associateCarrierIpAddress",
                "type": "boolean"
              },
              "AssociatePublicIpAddress": {
                "locationName": "associatePublicIpAddress",
                "type": "boolean"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              },
              "Description": {
                "locationName": "description"
              },
              "DeviceIndex": {
                "locationName": "deviceIndex",
                "type": "integer"
              },
              "Groups": {
                "shape": "S5x",
                "locationName": "groupSet"
              },
              "InterfaceType": {
                "locationName": "interfaceType"
              },
              "Ipv6AddressCount": {
                "locationName": "ipv6AddressCount",
                "type": "integer"
              },
              "Ipv6Addresses": {
                "shape": "Sj0",
                "locationName": "ipv6AddressesSet"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "PrivateIpAddresses": {
                "shape": "Shc",
                "locationName": "privateIpAddressesSet"
              },
              "SecondaryPrivateIpAddressCount": {
                "locationName": "secondaryPrivateIpAddressCount",
                "type": "integer"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "NetworkCardIndex": {
                "locationName": "networkCardIndex",
                "type": "integer"
              },
              "Ipv4Prefixes": {
                "locationName": "ipv4PrefixSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "Ipv4Prefix": {
                      "locationName": "ipv4Prefix"
                    }
                  }
                }
              },
              "Ipv4PrefixCount": {
                "locationName": "ipv4PrefixCount",
                "type": "integer"
              },
              "Ipv6Prefixes": {
                "locationName": "ipv6PrefixSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "Ipv6Prefix": {
                      "locationName": "ipv6Prefix"
                    }
                  }
                }
              },
              "Ipv6PrefixCount": {
                "locationName": "ipv6PrefixCount",
                "type": "integer"
              },
              "PrimaryIpv6": {
                "locationName": "primaryIpv6",
                "type": "boolean"
              },
              "EnaSrdSpecification": {
                "locationName": "enaSrdSpecification",
                "type": "structure",
                "members": {
                  "EnaSrdEnabled": {
                    "locationName": "enaSrdEnabled",
                    "type": "boolean"
                  },
                  "EnaSrdUdpSpecification": {
                    "locationName": "enaSrdUdpSpecification",
                    "type": "structure",
                    "members": {
                      "EnaSrdUdpEnabled": {
                        "locationName": "enaSrdUdpEnabled",
                        "type": "boolean"
                      }
                    }
                  }
                }
              },
              "ConnectionTrackingSpecification": {
                "locationName": "connectionTrackingSpecification",
                "type": "structure",
                "members": {
                  "TcpEstablishedTimeout": {
                    "locationName": "tcpEstablishedTimeout",
                    "type": "integer"
                  },
                  "UdpTimeout": {
                    "locationName": "udpTimeout",
                    "type": "integer"
                  },
                  "UdpStreamTimeout": {
                    "locationName": "udpStreamTimeout",
                    "type": "integer"
                  }
                }
              }
            }
          }
        },
        "ImageId": {
          "locationName": "imageId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "KeyName": {
          "locationName": "keyName"
        },
        "Monitoring": {
          "locationName": "monitoring",
          "type": "structure",
          "members": {
            "Enabled": {
              "locationName": "enabled",
              "type": "boolean"
            }
          }
        },
        "Placement": {
          "locationName": "placement",
          "type": "structure",
          "members": {
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "Affinity": {
              "locationName": "affinity"
            },
            "GroupName": {
              "locationName": "groupName"
            },
            "HostId": {
              "locationName": "hostId"
            },
            "Tenancy": {
              "locationName": "tenancy"
            },
            "SpreadDomain": {
              "locationName": "spreadDomain"
            },
            "HostResourceGroupArn": {
              "locationName": "hostResourceGroupArn"
            },
            "PartitionNumber": {
              "locationName": "partitionNumber",
              "type": "integer"
            },
            "GroupId": {
              "locationName": "groupId"
            }
          }
        },
        "RamDiskId": {
          "locationName": "ramDiskId"
        },
        "DisableApiTermination": {
          "locationName": "disableApiTermination",
          "type": "boolean"
        },
        "InstanceInitiatedShutdownBehavior": {
          "locationName": "instanceInitiatedShutdownBehavior"
        },
        "UserData": {
          "shape": "Sgy",
          "locationName": "userData"
        },
        "TagSpecifications": {
          "locationName": "tagSpecificationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ResourceType": {
                "locationName": "resourceType"
              },
              "Tags": {
                "shape": "S6",
                "locationName": "tagSet"
              }
            }
          }
        },
        "ElasticGpuSpecifications": {
          "locationName": "elasticGpuSpecificationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Type": {
                "locationName": "type"
              }
            }
          }
        },
        "ElasticInferenceAccelerators": {
          "locationName": "elasticInferenceAcceleratorSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Type": {
                "locationName": "type"
              },
              "Count": {
                "locationName": "count",
                "type": "integer"
              }
            }
          }
        },
        "SecurityGroupIds": {
          "shape": "So",
          "locationName": "securityGroupIdSet"
        },
        "SecurityGroups": {
          "shape": "So",
          "locationName": "securityGroupSet"
        },
        "InstanceMarketOptions": {
          "locationName": "instanceMarketOptions",
          "type": "structure",
          "members": {
            "MarketType": {
              "locationName": "marketType"
            },
            "SpotOptions": {
              "locationName": "spotOptions",
              "type": "structure",
              "members": {
                "MaxPrice": {
                  "locationName": "maxPrice"
                },
                "SpotInstanceType": {
                  "locationName": "spotInstanceType"
                },
                "BlockDurationMinutes": {
                  "locationName": "blockDurationMinutes",
                  "type": "integer"
                },
                "ValidUntil": {
                  "locationName": "validUntil",
                  "type": "timestamp"
                },
                "InstanceInterruptionBehavior": {
                  "locationName": "instanceInterruptionBehavior"
                }
              }
            }
          }
        },
        "CreditSpecification": {
          "locationName": "creditSpecification",
          "type": "structure",
          "members": {
            "CpuCredits": {
              "locationName": "cpuCredits"
            }
          }
        },
        "CpuOptions": {
          "locationName": "cpuOptions",
          "type": "structure",
          "members": {
            "CoreCount": {
              "locationName": "coreCount",
              "type": "integer"
            },
            "ThreadsPerCore": {
              "locationName": "threadsPerCore",
              "type": "integer"
            },
            "AmdSevSnp": {
              "locationName": "amdSevSnp"
            }
          }
        },
        "CapacityReservationSpecification": {
          "locationName": "capacityReservationSpecification",
          "type": "structure",
          "members": {
            "CapacityReservationPreference": {
              "locationName": "capacityReservationPreference"
            },
            "CapacityReservationTarget": {
              "shape": "Sjm",
              "locationName": "capacityReservationTarget"
            }
          }
        },
        "LicenseSpecifications": {
          "locationName": "licenseSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LicenseConfigurationArn": {
                "locationName": "licenseConfigurationArn"
              }
            }
          }
        },
        "HibernationOptions": {
          "locationName": "hibernationOptions",
          "type": "structure",
          "members": {
            "Configured": {
              "locationName": "configured",
              "type": "boolean"
            }
          }
        },
        "MetadataOptions": {
          "locationName": "metadataOptions",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "HttpTokens": {
              "locationName": "httpTokens"
            },
            "HttpPutResponseHopLimit": {
              "locationName": "httpPutResponseHopLimit",
              "type": "integer"
            },
            "HttpEndpoint": {
              "locationName": "httpEndpoint"
            },
            "HttpProtocolIpv6": {
              "locationName": "httpProtocolIpv6"
            },
            "InstanceMetadataTags": {
              "locationName": "instanceMetadataTags"
            }
          }
        },
        "EnclaveOptions": {
          "locationName": "enclaveOptions",
          "type": "structure",
          "members": {
            "Enabled": {
              "locationName": "enabled",
              "type": "boolean"
            }
          }
        },
        "InstanceRequirements": {
          "shape": "Se3",
          "locationName": "instanceRequirements"
        },
        "PrivateDnsNameOptions": {
          "locationName": "privateDnsNameOptions",
          "type": "structure",
          "members": {
            "HostnameType": {
              "locationName": "hostnameType"
            },
            "EnableResourceNameDnsARecord": {
              "locationName": "enableResourceNameDnsARecord",
              "type": "boolean"
            },
            "EnableResourceNameDnsAAAARecord": {
              "locationName": "enableResourceNameDnsAAAARecord",
              "type": "boolean"
            }
          }
        },
        "MaintenanceOptions": {
          "locationName": "maintenanceOptions",
          "type": "structure",
          "members": {
            "AutoRecovery": {
              "locationName": "autoRecovery"
            }
          }
        },
        "DisableApiStop": {
          "locationName": "disableApiStop",
          "type": "boolean"
        }
      }
    },
    "Sj0": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv6Address": {
            "locationName": "ipv6Address"
          },
          "IsPrimaryIpv6": {
            "locationName": "isPrimaryIpv6",
            "type": "boolean"
          }
        }
      }
    },
    "Sjm": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {
          "locationName": "capacityReservationId"
        },
        "CapacityReservationResourceGroupArn": {
          "locationName": "capacityReservationResourceGroupArn"
        }
      }
    },
    "Sjy": {
      "type": "structure",
      "members": {
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "LocalGatewayVirtualInterfaceGroupId": {
          "locationName": "localGatewayVirtualInterfaceGroupId"
        },
        "Type": {
          "locationName": "type"
        },
        "State": {
          "locationName": "state"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        },
        "LocalGatewayRouteTableArn": {
          "locationName": "localGatewayRouteTableArn"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "CoipPoolId": {
          "locationName": "coipPoolId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "DestinationPrefixListId": {
          "locationName": "destinationPrefixListId"
        }
      }
    },
    "Sk5": {
      "type": "structure",
      "members": {
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        },
        "LocalGatewayRouteTableArn": {
          "locationName": "localGatewayRouteTableArn"
        },
        "LocalGatewayId": {
          "locationName": "localGatewayId"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "Mode": {
          "locationName": "mode"
        },
        "StateReason": {
          "shape": "Sk6",
          "locationName": "stateReason"
        }
      }
    },
    "Sk6": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sk9": {
      "type": "structure",
      "members": {
        "LocalGatewayRouteTableVirtualInterfaceGroupAssociationId": {
          "locationName": "localGatewayRouteTableVirtualInterfaceGroupAssociationId"
        },
        "LocalGatewayVirtualInterfaceGroupId": {
          "locationName": "localGatewayVirtualInterfaceGroupId"
        },
        "LocalGatewayId": {
          "locationName": "localGatewayId"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        },
        "LocalGatewayRouteTableArn": {
          "locationName": "localGatewayRouteTableArn"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Skd": {
      "type": "structure",
      "members": {
        "LocalGatewayRouteTableVpcAssociationId": {
          "locationName": "localGatewayRouteTableVpcAssociationId"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        },
        "LocalGatewayRouteTableArn": {
          "locationName": "localGatewayRouteTableArn"
        },
        "LocalGatewayId": {
          "locationName": "localGatewayId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Skg": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "Description": {}
        }
      }
    },
    "Skj": {
      "type": "structure",
      "members": {
        "PrefixListId": {
          "locationName": "prefixListId"
        },
        "AddressFamily": {
          "locationName": "addressFamily"
        },
        "State": {
          "locationName": "state"
        },
        "StateMessage": {
          "locationName": "stateMessage"
        },
        "PrefixListArn": {
          "locationName": "prefixListArn"
        },
        "PrefixListName": {
          "locationName": "prefixListName"
        },
        "MaxEntries": {
          "locationName": "maxEntries",
          "type": "integer"
        },
        "Version": {
          "locationName": "version",
          "type": "long"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Sko": {
      "type": "structure",
      "members": {
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "DeleteTime": {
          "locationName": "deleteTime",
          "type": "timestamp"
        },
        "FailureCode": {
          "locationName": "failureCode"
        },
        "FailureMessage": {
          "locationName": "failureMessage"
        },
        "NatGatewayAddresses": {
          "shape": "S3b",
          "locationName": "natGatewayAddressSet"
        },
        "NatGatewayId": {
          "locationName": "natGatewayId"
        },
        "ProvisionedBandwidth": {
          "locationName": "provisionedBandwidth",
          "type": "structure",
          "members": {
            "ProvisionTime": {
              "locationName": "provisionTime",
              "type": "timestamp"
            },
            "Provisioned": {
              "locationName": "provisioned"
            },
            "RequestTime": {
              "locationName": "requestTime",
              "type": "timestamp"
            },
            "Requested": {
              "locationName": "requested"
            },
            "Status": {
              "locationName": "status"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "ConnectivityType": {
          "locationName": "connectivityType"
        }
      }
    },
    "Skt": {
      "type": "structure",
      "members": {
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "NetworkAclAssociationId": {
                "locationName": "networkAclAssociationId"
              },
              "NetworkAclId": {
                "locationName": "networkAclId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "Entries": {
          "locationName": "entrySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "CidrBlock": {
                "locationName": "cidrBlock"
              },
              "Egress": {
                "locationName": "egress",
                "type": "boolean"
              },
              "IcmpTypeCode": {
                "shape": "Sky",
                "locationName": "icmpTypeCode"
              },
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              },
              "PortRange": {
                "shape": "Skz",
                "locationName": "portRange"
              },
              "Protocol": {
                "locationName": "protocol"
              },
              "RuleAction": {
                "locationName": "ruleAction"
              },
              "RuleNumber": {
                "locationName": "ruleNumber",
                "type": "integer"
              }
            }
          }
        },
        "IsDefault": {
          "locationName": "default",
          "type": "boolean"
        },
        "NetworkAclId": {
          "locationName": "networkAclId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Sky": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Type": {
          "locationName": "type",
          "type": "integer"
        }
      }
    },
    "Skz": {
      "type": "structure",
      "members": {
        "From": {
          "locationName": "from",
          "type": "integer"
        },
        "To": {
          "locationName": "to",
          "type": "integer"
        }
      }
    },
    "Sl4": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Source": {
            "shape": "Sl6"
          },
          "Destination": {
            "shape": "Sl6"
          },
          "ThroughResources": {
            "locationName": "ThroughResource",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ResourceStatement": {
                  "shape": "Sla"
                }
              }
            }
          }
        }
      }
    },
    "Sl6": {
      "type": "structure",
      "members": {
        "PacketHeaderStatement": {
          "type": "structure",
          "members": {
            "SourceAddresses": {
              "shape": "So",
              "locationName": "SourceAddress"
            },
            "DestinationAddresses": {
              "shape": "So",
              "locationName": "DestinationAddress"
            },
            "SourcePorts": {
              "shape": "So",
              "locationName": "SourcePort"
            },
            "DestinationPorts": {
              "shape": "So",
              "locationName": "DestinationPort"
            },
            "SourcePrefixLists": {
              "shape": "So",
              "locationName": "SourcePrefixList"
            },
            "DestinationPrefixLists": {
              "shape": "So",
              "locationName": "DestinationPrefixList"
            },
            "Protocols": {
              "shape": "Sl8",
              "locationName": "Protocol"
            }
          }
        },
        "ResourceStatement": {
          "shape": "Sla"
        }
      }
    },
    "Sl8": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sla": {
      "type": "structure",
      "members": {
        "Resources": {
          "shape": "So",
          "locationName": "Resource"
        },
        "ResourceTypes": {
          "shape": "So",
          "locationName": "ResourceType"
        }
      }
    },
    "Sle": {
      "type": "structure",
      "members": {
        "NetworkInsightsAccessScopeId": {
          "locationName": "networkInsightsAccessScopeId"
        },
        "NetworkInsightsAccessScopeArn": {
          "locationName": "networkInsightsAccessScopeArn"
        },
        "CreatedDate": {
          "locationName": "createdDate",
          "type": "timestamp"
        },
        "UpdatedDate": {
          "locationName": "updatedDate",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Slg": {
      "type": "structure",
      "members": {
        "NetworkInsightsAccessScopeId": {
          "locationName": "networkInsightsAccessScopeId"
        },
        "MatchPaths": {
          "shape": "Slh",
          "locationName": "matchPathSet"
        },
        "ExcludePaths": {
          "shape": "Slh",
          "locationName": "excludePathSet"
        }
      }
    },
    "Slh": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Source": {
            "shape": "Slj",
            "locationName": "source"
          },
          "Destination": {
            "shape": "Slj",
            "locationName": "destination"
          },
          "ThroughResources": {
            "locationName": "throughResourceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ResourceStatement": {
                  "shape": "Sll",
                  "locationName": "resourceStatement"
                }
              }
            }
          }
        }
      }
    },
    "Slj": {
      "type": "structure",
      "members": {
        "PacketHeaderStatement": {
          "locationName": "packetHeaderStatement",
          "type": "structure",
          "members": {
            "SourceAddresses": {
              "shape": "So",
              "locationName": "sourceAddressSet"
            },
            "DestinationAddresses": {
              "shape": "So",
              "locationName": "destinationAddressSet"
            },
            "SourcePorts": {
              "shape": "So",
              "locationName": "sourcePortSet"
            },
            "DestinationPorts": {
              "shape": "So",
              "locationName": "destinationPortSet"
            },
            "SourcePrefixLists": {
              "shape": "So",
              "locationName": "sourcePrefixListSet"
            },
            "DestinationPrefixLists": {
              "shape": "So",
              "locationName": "destinationPrefixListSet"
            },
            "Protocols": {
              "shape": "Sl8",
              "locationName": "protocolSet"
            }
          }
        },
        "ResourceStatement": {
          "shape": "Sll",
          "locationName": "resourceStatement"
        }
      }
    },
    "Sll": {
      "type": "structure",
      "members": {
        "Resources": {
          "shape": "So",
          "locationName": "resourceSet"
        },
        "ResourceTypes": {
          "shape": "So",
          "locationName": "resourceTypeSet"
        }
      }
    },
    "Sls": {
      "type": "structure",
      "members": {
        "SourceAddress": {},
        "SourcePortRange": {
          "shape": "Slt"
        },
        "DestinationAddress": {},
        "DestinationPortRange": {
          "shape": "Slt"
        }
      }
    },
    "Slt": {
      "type": "structure",
      "members": {
        "FromPort": {
          "type": "integer"
        },
        "ToPort": {
          "type": "integer"
        }
      }
    },
    "Slv": {
      "type": "structure",
      "members": {
        "NetworkInsightsPathId": {
          "locationName": "networkInsightsPathId"
        },
        "NetworkInsightsPathArn": {
          "locationName": "networkInsightsPathArn"
        },
        "CreatedDate": {
          "locationName": "createdDate",
          "type": "timestamp"
        },
        "Source": {
          "locationName": "source"
        },
        "Destination": {
          "locationName": "destination"
        },
        "SourceArn": {
          "locationName": "sourceArn"
        },
        "DestinationArn": {
          "locationName": "destinationArn"
        },
        "SourceIp": {
          "locationName": "sourceIp"
        },
        "DestinationIp": {
          "locationName": "destinationIp"
        },
        "Protocol": {
          "locationName": "protocol"
        },
        "DestinationPort": {
          "locationName": "destinationPort",
          "type": "integer"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "FilterAtSource": {
          "shape": "Slx",
          "locationName": "filterAtSource"
        },
        "FilterAtDestination": {
          "shape": "Slx",
          "locationName": "filterAtDestination"
        }
      }
    },
    "Slx": {
      "type": "structure",
      "members": {
        "SourceAddress": {
          "locationName": "sourceAddress"
        },
        "SourcePortRange": {
          "shape": "Sly",
          "locationName": "sourcePortRange"
        },
        "DestinationAddress": {
          "locationName": "destinationAddress"
        },
        "DestinationPortRange": {
          "shape": "Sly",
          "locationName": "destinationPortRange"
        }
      }
    },
    "Sly": {
      "type": "structure",
      "members": {
        "FromPort": {
          "locationName": "fromPort",
          "type": "integer"
        },
        "ToPort": {
          "locationName": "toPort",
          "type": "integer"
        }
      }
    },
    "Sm2": {
      "type": "structure",
      "members": {
        "Association": {
          "shape": "Sm3",
          "locationName": "association"
        },
        "Attachment": {
          "shape": "Sm4",
          "locationName": "attachment"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "ConnectionTrackingConfiguration": {
          "locationName": "connectionTrackingConfiguration",
          "type": "structure",
          "members": {
            "TcpEstablishedTimeout": {
              "locationName": "tcpEstablishedTimeout",
              "type": "integer"
            },
            "UdpStreamTimeout": {
              "locationName": "udpStreamTimeout",
              "type": "integer"
            },
            "UdpTimeout": {
              "locationName": "udpTimeout",
              "type": "integer"
            }
          }
        },
        "Description": {
          "locationName": "description"
        },
        "Groups": {
          "shape": "Sm8",
          "locationName": "groupSet"
        },
        "InterfaceType": {
          "locationName": "interfaceType"
        },
        "Ipv6Addresses": {
          "locationName": "ipv6AddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6Address": {
                "locationName": "ipv6Address"
              },
              "IsPrimaryIpv6": {
                "locationName": "isPrimaryIpv6",
                "type": "boolean"
              }
            }
          }
        },
        "MacAddress": {
          "locationName": "macAddress"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "PrivateIpAddress": {
          "locationName": "privateIpAddress"
        },
        "PrivateIpAddresses": {
          "locationName": "privateIpAddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Association": {
                "shape": "Sm3",
                "locationName": "association"
              },
              "Primary": {
                "locationName": "primary",
                "type": "boolean"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              }
            }
          }
        },
        "Ipv4Prefixes": {
          "shape": "S34",
          "locationName": "ipv4PrefixSet"
        },
        "Ipv6Prefixes": {
          "locationName": "ipv6PrefixSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6Prefix": {
                "locationName": "ipv6Prefix"
              }
            }
          }
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "SourceDestCheck": {
          "locationName": "sourceDestCheck",
          "type": "boolean"
        },
        "Status": {
          "locationName": "status"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "TagSet": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "DenyAllIgwTraffic": {
          "locationName": "denyAllIgwTraffic",
          "type": "boolean"
        },
        "Ipv6Native": {
          "locationName": "ipv6Native",
          "type": "boolean"
        },
        "Ipv6Address": {
          "locationName": "ipv6Address"
        }
      }
    },
    "Sm3": {
      "type": "structure",
      "members": {
        "AllocationId": {
          "locationName": "allocationId"
        },
        "AssociationId": {
          "locationName": "associationId"
        },
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "PublicIp": {
          "locationName": "publicIp"
        },
        "CustomerOwnedIp": {
          "locationName": "customerOwnedIp"
        },
        "CarrierIp": {
          "locationName": "carrierIp"
        }
      }
    },
    "Sm4": {
      "type": "structure",
      "members": {
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "AttachmentId": {
          "locationName": "attachmentId"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        },
        "DeviceIndex": {
          "locationName": "deviceIndex",
          "type": "integer"
        },
        "NetworkCardIndex": {
          "locationName": "networkCardIndex",
          "type": "integer"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "InstanceOwnerId": {
          "locationName": "instanceOwnerId"
        },
        "Status": {
          "locationName": "status"
        },
        "EnaSrdSpecification": {
          "locationName": "enaSrdSpecification",
          "type": "structure",
          "members": {
            "EnaSrdEnabled": {
              "locationName": "enaSrdEnabled",
              "type": "boolean"
            },
            "EnaSrdUdpSpecification": {
              "locationName": "enaSrdUdpSpecification",
              "type": "structure",
              "members": {
                "EnaSrdUdpEnabled": {
                  "locationName": "enaSrdUdpEnabled",
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "Sm8": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "GroupName": {
            "locationName": "groupName"
          },
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "Sml": {
      "type": "structure",
      "members": {
        "NetworkInterfacePermissionId": {
          "locationName": "networkInterfacePermissionId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "AwsAccountId": {
          "locationName": "awsAccountId"
        },
        "AwsService": {
          "locationName": "awsService"
        },
        "Permission": {
          "locationName": "permission"
        },
        "PermissionState": {
          "locationName": "permissionState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        }
      }
    },
    "Sms": {
      "type": "structure",
      "members": {
        "GroupName": {
          "locationName": "groupName"
        },
        "State": {
          "locationName": "state"
        },
        "Strategy": {
          "locationName": "strategy"
        },
        "PartitionCount": {
          "locationName": "partitionCount",
          "type": "integer"
        },
        "GroupId": {
          "locationName": "groupId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "GroupArn": {
          "locationName": "groupArn"
        },
        "SpreadLevel": {
          "locationName": "spreadLevel"
        }
      }
    },
    "Smy": {
      "type": "structure",
      "members": {
        "ReplaceRootVolumeTaskId": {
          "locationName": "replaceRootVolumeTaskId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "TaskState": {
          "locationName": "taskState"
        },
        "StartTime": {
          "locationName": "startTime"
        },
        "CompleteTime": {
          "locationName": "completeTime"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "ImageId": {
          "locationName": "imageId"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "DeleteReplacedRootVolume": {
          "locationName": "deleteReplacedRootVolume",
          "type": "boolean"
        }
      }
    },
    "Sne": {
      "type": "structure",
      "members": {
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Main": {
                "locationName": "main",
                "type": "boolean"
              },
              "RouteTableAssociationId": {
                "locationName": "routeTableAssociationId"
              },
              "RouteTableId": {
                "locationName": "routeTableId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "GatewayId": {
                "locationName": "gatewayId"
              },
              "AssociationState": {
                "shape": "S4x",
                "locationName": "associationState"
              }
            }
          }
        },
        "PropagatingVgws": {
          "locationName": "propagatingVgwSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GatewayId": {
                "locationName": "gatewayId"
              }
            }
          }
        },
        "RouteTableId": {
          "locationName": "routeTableId"
        },
        "Routes": {
          "locationName": "routeSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "DestinationIpv6CidrBlock": {
                "locationName": "destinationIpv6CidrBlock"
              },
              "DestinationPrefixListId": {
                "locationName": "destinationPrefixListId"
              },
              "EgressOnlyInternetGatewayId": {
                "locationName": "egressOnlyInternetGatewayId"
              },
              "GatewayId": {
                "locationName": "gatewayId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceOwnerId": {
                "locationName": "instanceOwnerId"
              },
              "NatGatewayId": {
                "locationName": "natGatewayId"
              },
              "TransitGatewayId": {
                "locationName": "transitGatewayId"
              },
              "LocalGatewayId": {
                "locationName": "localGatewayId"
              },
              "CarrierGatewayId": {
                "locationName": "carrierGatewayId"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "Origin": {
                "locationName": "origin"
              },
              "State": {
                "locationName": "state"
              },
              "VpcPeeringConnectionId": {
                "locationName": "vpcPeeringConnectionId"
              },
              "CoreNetworkArn": {
                "locationName": "coreNetworkArn"
              }
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Snq": {
      "type": "structure",
      "members": {
        "DataEncryptionKeyId": {
          "locationName": "dataEncryptionKeyId"
        },
        "Description": {
          "locationName": "description"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "State": {
          "locationName": "status"
        },
        "StateMessage": {
          "locationName": "statusMessage"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "VolumeSize": {
          "locationName": "volumeSize",
          "type": "integer"
        },
        "OwnerAlias": {
          "locationName": "ownerAlias"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "StorageTier": {
          "locationName": "storageTier"
        },
        "RestoreExpiryTime": {
          "locationName": "restoreExpiryTime",
          "type": "timestamp"
        },
        "SseType": {
          "locationName": "sseType"
        }
      }
    },
    "Snx": {
      "type": "list",
      "member": {
        "locationName": "VolumeId"
      }
    },
    "So4": {
      "type": "structure",
      "members": {
        "Bucket": {
          "locationName": "bucket"
        },
        "Fault": {
          "shape": "So5",
          "locationName": "fault"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Prefix": {
          "locationName": "prefix"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "So5": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sog": {
      "type": "structure",
      "members": {
        "SubnetCidrReservationId": {
          "locationName": "subnetCidrReservationId"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "Cidr": {
          "locationName": "cidr"
        },
        "ReservationType": {
          "locationName": "reservationType"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Soj": {
      "type": "list",
      "member": {}
    },
    "Son": {
      "type": "structure",
      "members": {
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "IngressFilterRules": {
          "shape": "Soo",
          "locationName": "ingressFilterRuleSet"
        },
        "EgressFilterRules": {
          "shape": "Soo",
          "locationName": "egressFilterRuleSet"
        },
        "NetworkServices": {
          "shape": "Sot",
          "locationName": "networkServiceSet"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Soo": {
      "type": "list",
      "member": {
        "shape": "Sop",
        "locationName": "item"
      }
    },
    "Sop": {
      "type": "structure",
      "members": {
        "TrafficMirrorFilterRuleId": {
          "locationName": "trafficMirrorFilterRuleId"
        },
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "TrafficDirection": {
          "locationName": "trafficDirection"
        },
        "RuleNumber": {
          "locationName": "ruleNumber",
          "type": "integer"
        },
        "RuleAction": {
          "locationName": "ruleAction"
        },
        "Protocol": {
          "locationName": "protocol",
          "type": "integer"
        },
        "DestinationPortRange": {
          "shape": "Sos",
          "locationName": "destinationPortRange"
        },
        "SourcePortRange": {
          "shape": "Sos",
          "locationName": "sourcePortRange"
        },
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "SourceCidrBlock": {
          "locationName": "sourceCidrBlock"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sos": {
      "type": "structure",
      "members": {
        "FromPort": {
          "locationName": "fromPort",
          "type": "integer"
        },
        "ToPort": {
          "locationName": "toPort",
          "type": "integer"
        }
      }
    },
    "Sot": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sox": {
      "type": "structure",
      "members": {
        "FromPort": {
          "type": "integer"
        },
        "ToPort": {
          "type": "integer"
        }
      }
    },
    "Sp2": {
      "type": "structure",
      "members": {
        "TrafficMirrorSessionId": {
          "locationName": "trafficMirrorSessionId"
        },
        "TrafficMirrorTargetId": {
          "locationName": "trafficMirrorTargetId"
        },
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PacketLength": {
          "locationName": "packetLength",
          "type": "integer"
        },
        "SessionNumber": {
          "locationName": "sessionNumber",
          "type": "integer"
        },
        "VirtualNetworkId": {
          "locationName": "virtualNetworkId",
          "type": "integer"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sp5": {
      "type": "structure",
      "members": {
        "TrafficMirrorTargetId": {
          "locationName": "trafficMirrorTargetId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "NetworkLoadBalancerArn": {
          "locationName": "networkLoadBalancerArn"
        },
        "Type": {
          "locationName": "type"
        },
        "Description": {
          "locationName": "description"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "GatewayLoadBalancerEndpointId": {
          "locationName": "gatewayLoadBalancerEndpointId"
        }
      }
    },
    "Spe": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Spg": {
      "type": "structure",
      "members": {
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "TransitGatewayArn": {
          "locationName": "transitGatewayArn"
        },
        "State": {
          "locationName": "state"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Description": {
          "locationName": "description"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "AmazonSideAsn": {
              "locationName": "amazonSideAsn",
              "type": "long"
            },
            "TransitGatewayCidrBlocks": {
              "shape": "So",
              "locationName": "transitGatewayCidrBlocks"
            },
            "AutoAcceptSharedAttachments": {
              "locationName": "autoAcceptSharedAttachments"
            },
            "DefaultRouteTableAssociation": {
              "locationName": "defaultRouteTableAssociation"
            },
            "AssociationDefaultRouteTableId": {
              "locationName": "associationDefaultRouteTableId"
            },
            "DefaultRouteTablePropagation": {
              "locationName": "defaultRouteTablePropagation"
            },
            "PropagationDefaultRouteTableId": {
              "locationName": "propagationDefaultRouteTableId"
            },
            "VpnEcmpSupport": {
              "locationName": "vpnEcmpSupport"
            },
            "DnsSupport": {
              "locationName": "dnsSupport"
            },
            "SecurityGroupReferencingSupport": {
              "locationName": "securityGroupReferencingSupport"
            },
            "MulticastSupport": {
              "locationName": "multicastSupport"
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Spn": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "TransportTransitGatewayAttachmentId": {
          "locationName": "transportTransitGatewayAttachmentId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "Protocol": {
              "locationName": "protocol"
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Spr": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Spt": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "TransitGatewayConnectPeerId": {
          "locationName": "transitGatewayConnectPeerId"
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "ConnectPeerConfiguration": {
          "locationName": "connectPeerConfiguration",
          "type": "structure",
          "members": {
            "TransitGatewayAddress": {
              "locationName": "transitGatewayAddress"
            },
            "PeerAddress": {
              "locationName": "peerAddress"
            },
            "InsideCidrBlocks": {
              "shape": "Spr",
              "locationName": "insideCidrBlocks"
            },
            "Protocol": {
              "locationName": "protocol"
            },
            "BgpConfigurations": {
              "locationName": "bgpConfigurations",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "members": {
                  "TransitGatewayAsn": {
                    "locationName": "transitGatewayAsn",
                    "type": "long"
                  },
                  "PeerAsn": {
                    "locationName": "peerAsn",
                    "type": "long"
                  },
                  "TransitGatewayAddress": {
                    "locationName": "transitGatewayAddress"
                  },
                  "PeerAddress": {
                    "locationName": "peerAddress"
                  },
                  "BgpStatus": {
                    "locationName": "bgpStatus"
                  }
                }
              }
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sq6": {
      "type": "structure",
      "members": {
        "TransitGatewayMulticastDomainId": {
          "locationName": "transitGatewayMulticastDomainId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "TransitGatewayMulticastDomainArn": {
          "locationName": "transitGatewayMulticastDomainArn"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "Igmpv2Support": {
              "locationName": "igmpv2Support"
            },
            "StaticSourcesSupport": {
              "locationName": "staticSourcesSupport"
            },
            "AutoAcceptSharedAssociations": {
              "locationName": "autoAcceptSharedAssociations"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sqf": {
      "type": "structure",
      "members": {
        "TransitGatewayPolicyTableId": {
          "locationName": "transitGatewayPolicyTableId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sqj": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "PrefixListId": {
          "locationName": "prefixListId"
        },
        "PrefixListOwnerId": {
          "locationName": "prefixListOwnerId"
        },
        "State": {
          "locationName": "state"
        },
        "Blackhole": {
          "locationName": "blackhole",
          "type": "boolean"
        },
        "TransitGatewayAttachment": {
          "locationName": "transitGatewayAttachment",
          "type": "structure",
          "members": {
            "TransitGatewayAttachmentId": {
              "locationName": "transitGatewayAttachmentId"
            },
            "ResourceType": {
              "locationName": "resourceType"
            },
            "ResourceId": {
              "locationName": "resourceId"
            }
          }
        }
      }
    },
    "Sqo": {
      "type": "structure",
      "members": {
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "PrefixListId": {
          "locationName": "prefixListId"
        },
        "TransitGatewayRouteTableAnnouncementId": {
          "locationName": "transitGatewayRouteTableAnnouncementId"
        },
        "TransitGatewayAttachments": {
          "locationName": "transitGatewayAttachments",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ResourceId": {
                "locationName": "resourceId"
              },
              "TransitGatewayAttachmentId": {
                "locationName": "transitGatewayAttachmentId"
              },
              "ResourceType": {
                "locationName": "resourceType"
              }
            }
          }
        },
        "Type": {
          "locationName": "type"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sqw": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "DefaultAssociationRouteTable": {
          "locationName": "defaultAssociationRouteTable",
          "type": "boolean"
        },
        "DefaultPropagationRouteTable": {
          "locationName": "defaultPropagationRouteTable",
          "type": "boolean"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Sr0": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableAnnouncementId": {
          "locationName": "transitGatewayRouteTableAnnouncementId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "CoreNetworkId": {
          "locationName": "coreNetworkId"
        },
        "PeerTransitGatewayId": {
          "locationName": "peerTransitGatewayId"
        },
        "PeerCoreNetworkId": {
          "locationName": "peerCoreNetworkId"
        },
        "PeeringAttachmentId": {
          "locationName": "peeringAttachmentId"
        },
        "AnnouncementDirection": {
          "locationName": "announcementDirection"
        },
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Srb": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sri": {
      "type": "structure",
      "members": {
        "CustomerManagedKeyEnabled": {
          "type": "boolean"
        },
        "KmsKeyArn": {}
      }
    },
    "Srk": {
      "type": "structure",
      "members": {
        "VerifiedAccessInstanceId": {
          "locationName": "verifiedAccessInstanceId"
        },
        "VerifiedAccessGroupId": {
          "locationName": "verifiedAccessGroupId"
        },
        "VerifiedAccessEndpointId": {
          "locationName": "verifiedAccessEndpointId"
        },
        "ApplicationDomain": {
          "locationName": "applicationDomain"
        },
        "EndpointType": {
          "locationName": "endpointType"
        },
        "AttachmentType": {
          "locationName": "attachmentType"
        },
        "DomainCertificateArn": {
          "locationName": "domainCertificateArn"
        },
        "EndpointDomain": {
          "locationName": "endpointDomain"
        },
        "DeviceValidationDomain": {
          "locationName": "deviceValidationDomain"
        },
        "SecurityGroupIds": {
          "shape": "Srb",
          "locationName": "securityGroupIdSet"
        },
        "LoadBalancerOptions": {
          "locationName": "loadBalancerOptions",
          "type": "structure",
          "members": {
            "Protocol": {
              "locationName": "protocol"
            },
            "Port": {
              "locationName": "port",
              "type": "integer"
            },
            "LoadBalancerArn": {
              "locationName": "loadBalancerArn"
            },
            "SubnetIds": {
              "locationName": "subnetIdSet",
              "type": "list",
              "member": {
                "locationName": "item"
              }
            }
          }
        },
        "NetworkInterfaceOptions": {
          "locationName": "networkInterfaceOptions",
          "type": "structure",
          "members": {
            "NetworkInterfaceId": {
              "locationName": "networkInterfaceId"
            },
            "Protocol": {
              "locationName": "protocol"
            },
            "Port": {
              "locationName": "port",
              "type": "integer"
            }
          }
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "Description": {
          "locationName": "description"
        },
        "CreationTime": {
          "locationName": "creationTime"
        },
        "LastUpdatedTime": {
          "locationName": "lastUpdatedTime"
        },
        "DeletionTime": {
          "locationName": "deletionTime"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "SseSpecification": {
          "shape": "S6g",
          "locationName": "sseSpecification"
        }
      }
    },
    "Srs": {
      "type": "structure",
      "members": {
        "VerifiedAccessGroupId": {
          "locationName": "verifiedAccessGroupId"
        },
        "VerifiedAccessInstanceId": {
          "locationName": "verifiedAccessInstanceId"
        },
        "Description": {
          "locationName": "description"
        },
        "Owner": {
          "locationName": "owner"
        },
        "VerifiedAccessGroupArn": {
          "locationName": "verifiedAccessGroupArn"
        },
        "CreationTime": {
          "locationName": "creationTime"
        },
        "LastUpdatedTime": {
          "locationName": "lastUpdatedTime"
        },
        "DeletionTime": {
          "locationName": "deletionTime"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "SseSpecification": {
          "shape": "S6g",
          "locationName": "sseSpecification"
        }
      }
    },
    "Ss0": {
      "type": "structure",
      "members": {
        "Attachments": {
          "locationName": "attachmentSet",
          "type": "list",
          "member": {
            "shape": "S6n",
            "locationName": "item"
          }
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "Size": {
          "locationName": "size",
          "type": "integer"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "State": {
          "locationName": "status"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "Iops": {
          "locationName": "iops",
          "type": "integer"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "VolumeType": {
          "locationName": "volumeType"
        },
        "FastRestored": {
          "locationName": "fastRestored",
          "type": "boolean"
        },
        "MultiAttachEnabled": {
          "locationName": "multiAttachEnabled",
          "type": "boolean"
        },
        "Throughput": {
          "locationName": "throughput",
          "type": "integer"
        },
        "SseType": {
          "locationName": "sseType"
        }
      }
    },
    "Ss7": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Ss8": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Ss9": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Ssb": {
      "type": "structure",
      "members": {
        "DnsRecordIpType": {},
        "PrivateDnsOnlyForInboundResolverEndpoint": {
          "type": "boolean"
        }
      }
    },
    "Ssd": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "SubnetId": {},
          "Ipv4": {},
          "Ipv6": {}
        }
      }
    },
    "Ssg": {
      "type": "structure",
      "members": {
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "VpcEndpointType": {
          "locationName": "vpcEndpointType"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "State": {
          "locationName": "state"
        },
        "PolicyDocument": {
          "locationName": "policyDocument"
        },
        "RouteTableIds": {
          "shape": "So",
          "locationName": "routeTableIdSet"
        },
        "SubnetIds": {
          "shape": "So",
          "locationName": "subnetIdSet"
        },
        "Groups": {
          "locationName": "groupSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GroupId": {
                "locationName": "groupId"
              },
              "GroupName": {
                "locationName": "groupName"
              }
            }
          }
        },
        "IpAddressType": {
          "locationName": "ipAddressType"
        },
        "DnsOptions": {
          "locationName": "dnsOptions",
          "type": "structure",
          "members": {
            "DnsRecordIpType": {
              "locationName": "dnsRecordIpType"
            },
            "PrivateDnsOnlyForInboundResolverEndpoint": {
              "locationName": "privateDnsOnlyForInboundResolverEndpoint",
              "type": "boolean"
            }
          }
        },
        "PrivateDnsEnabled": {
          "locationName": "privateDnsEnabled",
          "type": "boolean"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "NetworkInterfaceIds": {
          "shape": "So",
          "locationName": "networkInterfaceIdSet"
        },
        "DnsEntries": {
          "shape": "Ssl",
          "locationName": "dnsEntrySet"
        },
        "CreationTimestamp": {
          "locationName": "creationTimestamp",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "LastError": {
          "locationName": "lastError",
          "type": "structure",
          "members": {
            "Message": {
              "locationName": "message"
            },
            "Code": {
              "locationName": "code"
            }
          }
        }
      }
    },
    "Ssl": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DnsName": {
            "locationName": "dnsName"
          },
          "HostedZoneId": {
            "locationName": "hostedZoneId"
          }
        }
      }
    },
    "Ssq": {
      "type": "structure",
      "members": {
        "ConnectionNotificationId": {
          "locationName": "connectionNotificationId"
        },
        "ServiceId": {
          "locationName": "serviceId"
        },
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "ConnectionNotificationType": {
          "locationName": "connectionNotificationType"
        },
        "ConnectionNotificationArn": {
          "locationName": "connectionNotificationArn"
        },
        "ConnectionEvents": {
          "shape": "So",
          "locationName": "connectionEvents"
        },
        "ConnectionNotificationState": {
          "locationName": "connectionNotificationState"
        }
      }
    },
    "Ssv": {
      "type": "structure",
      "members": {
        "ServiceType": {
          "shape": "Ssw",
          "locationName": "serviceType"
        },
        "ServiceId": {
          "locationName": "serviceId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "ServiceState": {
          "locationName": "serviceState"
        },
        "AvailabilityZones": {
          "shape": "So",
          "locationName": "availabilityZoneSet"
        },
        "AcceptanceRequired": {
          "locationName": "acceptanceRequired",
          "type": "boolean"
        },
        "ManagesVpcEndpoints": {
          "locationName": "managesVpcEndpoints",
          "type": "boolean"
        },
        "NetworkLoadBalancerArns": {
          "shape": "So",
          "locationName": "networkLoadBalancerArnSet"
        },
        "GatewayLoadBalancerArns": {
          "shape": "So",
          "locationName": "gatewayLoadBalancerArnSet"
        },
        "SupportedIpAddressTypes": {
          "shape": "St0",
          "locationName": "supportedIpAddressTypeSet"
        },
        "BaseEndpointDnsNames": {
          "shape": "So",
          "locationName": "baseEndpointDnsNameSet"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "PrivateDnsNameConfiguration": {
          "locationName": "privateDnsNameConfiguration",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "Type": {
              "locationName": "type"
            },
            "Value": {
              "locationName": "value"
            },
            "Name": {
              "locationName": "name"
            }
          }
        },
        "PayerResponsibility": {
          "locationName": "payerResponsibility"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Ssw": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ServiceType": {
            "locationName": "serviceType"
          }
        }
      }
    },
    "St0": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Std": {
      "type": "string",
      "sensitive": true
    },
    "Ste": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Stg": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Sti": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Stk": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Stm": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {
            "type": "integer"
          }
        }
      }
    },
    "Sto": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {
            "type": "integer"
          }
        }
      }
    },
    "Stq": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Sts": {
      "type": "structure",
      "members": {
        "CloudWatchLogOptions": {
          "type": "structure",
          "members": {
            "LogEnabled": {
              "type": "boolean"
            },
            "LogGroupArn": {},
            "LogOutputFormat": {}
          }
        }
      }
    },
    "Stw": {
      "type": "structure",
      "members": {
        "CustomerGatewayConfiguration": {
          "locationName": "customerGatewayConfiguration",
          "type": "string",
          "sensitive": true
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "Category": {
          "locationName": "category"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "VpnConnectionId": {
          "locationName": "vpnConnectionId"
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "CoreNetworkArn": {
          "locationName": "coreNetworkArn"
        },
        "CoreNetworkAttachmentArn": {
          "locationName": "coreNetworkAttachmentArn"
        },
        "GatewayAssociationState": {
          "locationName": "gatewayAssociationState"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "EnableAcceleration": {
              "locationName": "enableAcceleration",
              "type": "boolean"
            },
            "StaticRoutesOnly": {
              "locationName": "staticRoutesOnly",
              "type": "boolean"
            },
            "LocalIpv4NetworkCidr": {
              "locationName": "localIpv4NetworkCidr"
            },
            "RemoteIpv4NetworkCidr": {
              "locationName": "remoteIpv4NetworkCidr"
            },
            "LocalIpv6NetworkCidr": {
              "locationName": "localIpv6NetworkCidr"
            },
            "RemoteIpv6NetworkCidr": {
              "locationName": "remoteIpv6NetworkCidr"
            },
            "OutsideIpAddressType": {
              "locationName": "outsideIpAddressType"
            },
            "TransportTransitGatewayAttachmentId": {
              "locationName": "transportTransitGatewayAttachmentId"
            },
            "TunnelInsideIpVersion": {
              "locationName": "tunnelInsideIpVersion"
            },
            "TunnelOptions": {
              "locationName": "tunnelOptionSet",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "members": {
                  "OutsideIpAddress": {
                    "locationName": "outsideIpAddress"
                  },
                  "TunnelInsideCidr": {
                    "locationName": "tunnelInsideCidr"
                  },
                  "TunnelInsideIpv6Cidr": {
                    "locationName": "tunnelInsideIpv6Cidr"
                  },
                  "PreSharedKey": {
                    "shape": "Std",
                    "locationName": "preSharedKey"
                  },
                  "Phase1LifetimeSeconds": {
                    "locationName": "phase1LifetimeSeconds",
                    "type": "integer"
                  },
                  "Phase2LifetimeSeconds": {
                    "locationName": "phase2LifetimeSeconds",
                    "type": "integer"
                  },
                  "RekeyMarginTimeSeconds": {
                    "locationName": "rekeyMarginTimeSeconds",
                    "type": "integer"
                  },
                  "RekeyFuzzPercentage": {
                    "locationName": "rekeyFuzzPercentage",
                    "type": "integer"
                  },
                  "ReplayWindowSize": {
                    "locationName": "replayWindowSize",
                    "type": "integer"
                  },
                  "DpdTimeoutSeconds": {
                    "locationName": "dpdTimeoutSeconds",
                    "type": "integer"
                  },
                  "DpdTimeoutAction": {
                    "locationName": "dpdTimeoutAction"
                  },
                  "Phase1EncryptionAlgorithms": {
                    "locationName": "phase1EncryptionAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase2EncryptionAlgorithms": {
                    "locationName": "phase2EncryptionAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase1IntegrityAlgorithms": {
                    "locationName": "phase1IntegrityAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase2IntegrityAlgorithms": {
                    "locationName": "phase2IntegrityAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase1DHGroupNumbers": {
                    "locationName": "phase1DHGroupNumberSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value",
                          "type": "integer"
                        }
                      }
                    }
                  },
                  "Phase2DHGroupNumbers": {
                    "locationName": "phase2DHGroupNumberSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value",
                          "type": "integer"
                        }
                      }
                    }
                  },
                  "IkeVersions": {
                    "locationName": "ikeVersionSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "StartupAction": {
                    "locationName": "startupAction"
                  },
                  "LogOptions": {
                    "locationName": "logOptions",
                    "type": "structure",
                    "members": {
                      "CloudWatchLogOptions": {
                        "locationName": "cloudWatchLogOptions",
                        "type": "structure",
                        "members": {
                          "LogEnabled": {
                            "locationName": "logEnabled",
                            "type": "boolean"
                          },
                          "LogGroupArn": {
                            "locationName": "logGroupArn"
                          },
                          "LogOutputFormat": {
                            "locationName": "logOutputFormat"
                          }
                        }
                      }
                    }
                  },
                  "EnableTunnelLifecycleControl": {
                    "locationName": "enableTunnelLifecycleControl",
                    "type": "boolean"
                  }
                }
              }
            }
          }
        },
        "Routes": {
          "locationName": "routes",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "Source": {
                "locationName": "source"
              },
              "State": {
                "locationName": "state"
              }
            }
          }
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        },
        "VgwTelemetry": {
          "locationName": "vgwTelemetry",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AcceptedRouteCount": {
                "locationName": "acceptedRouteCount",
                "type": "integer"
              },
              "LastStatusChange": {
                "locationName": "lastStatusChange",
                "type": "timestamp"
              },
              "OutsideIpAddress": {
                "locationName": "outsideIpAddress"
              },
              "Status": {
                "locationName": "status"
              },
              "StatusMessage": {
                "locationName": "statusMessage"
              },
              "CertificateArn": {
                "locationName": "certificateArn"
              }
            }
          }
        }
      }
    },
    "Sut": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "VpcAttachments": {
          "locationName": "attachments",
          "type": "list",
          "member": {
            "shape": "S6s",
            "locationName": "item"
          }
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "AmazonSideAsn": {
          "locationName": "amazonSideAsn",
          "type": "long"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "Svb": {
      "type": "list",
      "member": {}
    },
    "Svl": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Swd": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sza": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Szn": {
      "type": "structure",
      "members": {
        "Asn": {
          "locationName": "asn"
        },
        "IpamId": {
          "locationName": "ipamId"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Szr": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "State": {
          "locationName": "state"
        },
        "FailureReason": {
          "locationName": "failureReason",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "IpamPoolCidrId": {
          "locationName": "ipamPoolCidrId"
        },
        "NetmaskLength": {
          "locationName": "netmaskLength",
          "type": "integer"
        }
      }
    },
    "S102": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S104": {
      "type": "structure",
      "members": {
        "InstanceTagKeys": {
          "shape": "S102",
          "locationName": "instanceTagKeySet"
        },
        "IncludeAllTagsOfInstance": {
          "locationName": "includeAllTagsOfInstance",
          "type": "boolean"
        }
      }
    },
    "S106": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S10p": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "shape": "So",
            "locationName": "Value"
          }
        }
      }
    },
    "S112": {
      "type": "structure",
      "members": {
        "PublicIp": {
          "locationName": "publicIp"
        },
        "AllocationId": {
          "locationName": "allocationId"
        },
        "PtrRecord": {
          "locationName": "ptrRecord"
        },
        "PtrRecordUpdate": {
          "locationName": "ptrRecordUpdate",
          "type": "structure",
          "members": {
            "Value": {
              "locationName": "value"
            },
            "Status": {
              "locationName": "status"
            },
            "Reason": {
              "locationName": "reason"
            }
          }
        }
      }
    },
    "S116": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Deadline": {
            "locationName": "deadline",
            "type": "timestamp"
          },
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          }
        }
      }
    },
    "S12k": {
      "type": "list",
      "member": {
        "locationName": "InstanceId"
      }
    },
    "S12z": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S144": {
      "type": "structure",
      "members": {
        "ConversionTaskId": {
          "locationName": "conversionTaskId"
        },
        "ExpirationTime": {
          "locationName": "expirationTime"
        },
        "ImportInstance": {
          "locationName": "importInstance",
          "type": "structure",
          "members": {
            "Description": {
              "locationName": "description"
            },
            "InstanceId": {
              "locationName": "instanceId"
            },
            "Platform": {
              "locationName": "platform"
            },
            "Volumes": {
              "locationName": "volumes",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "members": {
                  "AvailabilityZone": {
                    "locationName": "availabilityZone"
                  },
                  "BytesConverted": {
                    "locationName": "bytesConverted",
                    "type": "long"
                  },
                  "Description": {
                    "locationName": "description"
                  },
                  "Image": {
                    "shape": "S148",
                    "locationName": "image"
                  },
                  "Status": {
                    "locationName": "status"
                  },
                  "StatusMessage": {
                    "locationName": "statusMessage"
                  },
                  "Volume": {
                    "shape": "S14a",
                    "locationName": "volume"
                  }
                }
              }
            }
          }
        },
        "ImportVolume": {
          "locationName": "importVolume",
          "type": "structure",
          "members": {
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "BytesConverted": {
              "locationName": "bytesConverted",
              "type": "long"
            },
            "Description": {
              "locationName": "description"
            },
            "Image": {
              "shape": "S148",
              "locationName": "image"
            },
            "Volume": {
              "shape": "S14a",
              "locationName": "volume"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S148": {
      "type": "structure",
      "members": {
        "Checksum": {
          "locationName": "checksum"
        },
        "Format": {
          "locationName": "format"
        },
        "ImportManifestUrl": {
          "shape": "S149",
          "locationName": "importManifestUrl"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "S149": {
      "type": "string",
      "sensitive": true
    },
    "S14a": {
      "type": "structure",
      "members": {
        "Id": {
          "locationName": "id"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "S158": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Prefix": {
          "locationName": "s3Prefix"
        }
      }
    },
    "S15l": {
      "type": "structure",
      "members": {
        "TargetResourceCount": {
          "locationName": "targetResourceCount",
          "type": "integer"
        }
      }
    },
    "S15m": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "Version": {
          "locationName": "version"
        }
      }
    },
    "S15z": {
      "type": "structure",
      "members": {
        "EventDescription": {
          "locationName": "eventDescription"
        },
        "EventSubType": {
          "locationName": "eventSubType"
        },
        "InstanceId": {
          "locationName": "instanceId"
        }
      }
    },
    "S162": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "InstanceHealth": {
            "locationName": "instanceHealth"
          }
        }
      }
    },
    "S16v": {
      "type": "structure",
      "members": {
        "FpgaImageId": {
          "locationName": "fpgaImageId"
        },
        "Name": {
          "locationName": "name"
        },
        "Description": {
          "locationName": "description"
        },
        "LoadPermissions": {
          "locationName": "loadPermissions",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "UserId": {
                "locationName": "userId"
              },
              "Group": {
                "locationName": "group"
              }
            }
          }
        },
        "ProductCodes": {
          "shape": "S16z",
          "locationName": "productCodes"
        }
      }
    },
    "S16z": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ProductCodeId": {
            "locationName": "productCode"
          },
          "ProductCodeType": {
            "locationName": "type"
          }
        }
      }
    },
    "S174": {
      "type": "list",
      "member": {
        "locationName": "Owner"
      }
    },
    "S17p": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S17s": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S18h": {
      "type": "list",
      "member": {
        "shape": "Sew",
        "locationName": "item"
      }
    },
    "S18i": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {
            "locationName": "group"
          },
          "UserId": {
            "locationName": "userId"
          },
          "OrganizationArn": {
            "locationName": "organizationArn"
          },
          "OrganizationalUnitArn": {
            "locationName": "organizationalUnitArn"
          }
        }
      }
    },
    "S18m": {
      "type": "list",
      "member": {
        "locationName": "ImageId"
      }
    },
    "S195": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DeviceName": {
            "locationName": "deviceName"
          },
          "DiskImageSize": {
            "locationName": "diskImageSize",
            "type": "double"
          },
          "Format": {
            "locationName": "format"
          },
          "Progress": {
            "locationName": "progress"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Url": {
            "shape": "S197",
            "locationName": "url"
          },
          "UserBucket": {
            "shape": "S198",
            "locationName": "userBucket"
          }
        }
      }
    },
    "S197": {
      "type": "string",
      "sensitive": true
    },
    "S198": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Key": {
          "locationName": "s3Key"
        }
      }
    },
    "S199": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "LicenseConfigurationArn": {
            "locationName": "licenseConfigurationArn"
          }
        }
      }
    },
    "S19h": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "DiskImageSize": {
          "locationName": "diskImageSize",
          "type": "double"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "Format": {
          "locationName": "format"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "Status": {
          "locationName": "status"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Url": {
          "shape": "S197",
          "locationName": "url"
        },
        "UserBucket": {
          "shape": "S198",
          "locationName": "userBucket"
        }
      }
    },
    "S19l": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DeviceName": {
            "locationName": "deviceName"
          },
          "Ebs": {
            "locationName": "ebs",
            "type": "structure",
            "members": {
              "AttachTime": {
                "locationName": "attachTime",
                "type": "timestamp"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              },
              "Status": {
                "locationName": "status"
              },
              "VolumeId": {
                "locationName": "volumeId"
              },
              "AssociatedResource": {
                "locationName": "associatedResource"
              },
              "VolumeOwnerId": {
                "locationName": "volumeOwnerId"
              }
            }
          }
        }
      }
    },
    "S19o": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value",
          "type": "boolean"
        }
      }
    },
    "S19p": {
      "type": "structure",
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        }
      }
    },
    "S1ab": {
      "type": "structure",
      "members": {
        "InstanceEventId": {
          "locationName": "instanceEventId"
        },
        "Code": {
          "locationName": "code"
        },
        "Description": {
          "locationName": "description"
        },
        "NotAfter": {
          "locationName": "notAfter",
          "type": "timestamp"
        },
        "NotBefore": {
          "locationName": "notBefore",
          "type": "timestamp"
        },
        "NotBeforeDeadline": {
          "locationName": "notBeforeDeadline",
          "type": "timestamp"
        }
      }
    },
    "S1ae": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "S1ag": {
      "type": "structure",
      "members": {
        "Details": {
          "locationName": "details",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ImpairedSince": {
                "locationName": "impairedSince",
                "type": "timestamp"
              },
              "Name": {
                "locationName": "name"
              },
              "Status": {
                "locationName": "status"
              }
            }
          }
        },
        "Status": {
          "locationName": "status"
        }
      }
    },
    "S1eu": {
      "type": "structure",
      "members": {
        "Groups": {
          "shape": "Sm8",
          "locationName": "groupSet"
        },
        "Instances": {
          "locationName": "instancesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AmiLaunchIndex": {
                "locationName": "amiLaunchIndex",
                "type": "integer"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "LaunchTime": {
                "locationName": "launchTime",
                "type": "timestamp"
              },
              "Monitoring": {
                "shape": "S1ex",
                "locationName": "monitoring"
              },
              "Placement": {
                "shape": "Scv",
                "locationName": "placement"
              },
              "Platform": {
                "locationName": "platform"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "ProductCodes": {
                "shape": "S16z",
                "locationName": "productCodes"
              },
              "PublicDnsName": {
                "locationName": "dnsName"
              },
              "PublicIpAddress": {
                "locationName": "ipAddress"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "State": {
                "shape": "S1ae",
                "locationName": "instanceState"
              },
              "StateTransitionReason": {
                "locationName": "reason"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "VpcId": {
                "locationName": "vpcId"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "BlockDeviceMappings": {
                "shape": "S19l",
                "locationName": "blockDeviceMapping"
              },
              "ClientToken": {
                "locationName": "clientToken"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "EnaSupport": {
                "locationName": "enaSupport",
                "type": "boolean"
              },
              "Hypervisor": {
                "locationName": "hypervisor"
              },
              "IamInstanceProfile": {
                "shape": "S3y",
                "locationName": "iamInstanceProfile"
              },
              "InstanceLifecycle": {
                "locationName": "instanceLifecycle"
              },
              "ElasticGpuAssociations": {
                "locationName": "elasticGpuAssociationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ElasticGpuId": {
                      "locationName": "elasticGpuId"
                    },
                    "ElasticGpuAssociationId": {
                      "locationName": "elasticGpuAssociationId"
                    },
                    "ElasticGpuAssociationState": {
                      "locationName": "elasticGpuAssociationState"
                    },
                    "ElasticGpuAssociationTime": {
                      "locationName": "elasticGpuAssociationTime"
                    }
                  }
                }
              },
              "ElasticInferenceAcceleratorAssociations": {
                "locationName": "elasticInferenceAcceleratorAssociationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ElasticInferenceAcceleratorArn": {
                      "locationName": "elasticInferenceAcceleratorArn"
                    },
                    "ElasticInferenceAcceleratorAssociationId": {
                      "locationName": "elasticInferenceAcceleratorAssociationId"
                    },
                    "ElasticInferenceAcceleratorAssociationState": {
                      "locationName": "elasticInferenceAcceleratorAssociationState"
                    },
                    "ElasticInferenceAcceleratorAssociationTime": {
                      "locationName": "elasticInferenceAcceleratorAssociationTime",
                      "type": "timestamp"
                    }
                  }
                }
              },
              "NetworkInterfaces": {
                "locationName": "networkInterfaceSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "Association": {
                      "shape": "S1f6",
                      "locationName": "association"
                    },
                    "Attachment": {
                      "locationName": "attachment",
                      "type": "structure",
                      "members": {
                        "AttachTime": {
                          "locationName": "attachTime",
                          "type": "timestamp"
                        },
                        "AttachmentId": {
                          "locationName": "attachmentId"
                        },
                        "DeleteOnTermination": {
                          "locationName": "deleteOnTermination",
                          "type": "boolean"
                        },
                        "DeviceIndex": {
                          "locationName": "deviceIndex",
                          "type": "integer"
                        },
                        "Status": {
                          "locationName": "status"
                        },
                        "NetworkCardIndex": {
                          "locationName": "networkCardIndex",
                          "type": "integer"
                        },
                        "EnaSrdSpecification": {
                          "locationName": "enaSrdSpecification",
                          "type": "structure",
                          "members": {
                            "EnaSrdEnabled": {
                              "locationName": "enaSrdEnabled",
                              "type": "boolean"
                            },
                            "EnaSrdUdpSpecification": {
                              "locationName": "enaSrdUdpSpecification",
                              "type": "structure",
                              "members": {
                                "EnaSrdUdpEnabled": {
                                  "locationName": "enaSrdUdpEnabled",
                                  "type": "boolean"
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "Description": {
                      "locationName": "description"
                    },
                    "Groups": {
                      "shape": "Sm8",
                      "locationName": "groupSet"
                    },
                    "Ipv6Addresses": {
                      "shape": "Sj0",
                      "locationName": "ipv6AddressesSet"
                    },
                    "MacAddress": {
                      "locationName": "macAddress"
                    },
                    "NetworkInterfaceId": {
                      "locationName": "networkInterfaceId"
                    },
                    "OwnerId": {
                      "locationName": "ownerId"
                    },
                    "PrivateDnsName": {
                      "locationName": "privateDnsName"
                    },
                    "PrivateIpAddress": {
                      "locationName": "privateIpAddress"
                    },
                    "PrivateIpAddresses": {
                      "locationName": "privateIpAddressesSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Association": {
                            "shape": "S1f6",
                            "locationName": "association"
                          },
                          "Primary": {
                            "locationName": "primary",
                            "type": "boolean"
                          },
                          "PrivateDnsName": {
                            "locationName": "privateDnsName"
                          },
                          "PrivateIpAddress": {
                            "locationName": "privateIpAddress"
                          }
                        }
                      }
                    },
                    "SourceDestCheck": {
                      "locationName": "sourceDestCheck",
                      "type": "boolean"
                    },
                    "Status": {
                      "locationName": "status"
                    },
                    "SubnetId": {
                      "locationName": "subnetId"
                    },
                    "VpcId": {
                      "locationName": "vpcId"
                    },
                    "InterfaceType": {
                      "locationName": "interfaceType"
                    },
                    "Ipv4Prefixes": {
                      "locationName": "ipv4PrefixSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Ipv4Prefix": {
                            "locationName": "ipv4Prefix"
                          }
                        }
                      }
                    },
                    "Ipv6Prefixes": {
                      "locationName": "ipv6PrefixSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Ipv6Prefix": {
                            "locationName": "ipv6Prefix"
                          }
                        }
                      }
                    },
                    "ConnectionTrackingConfiguration": {
                      "locationName": "connectionTrackingConfiguration",
                      "type": "structure",
                      "members": {
                        "TcpEstablishedTimeout": {
                          "locationName": "tcpEstablishedTimeout",
                          "type": "integer"
                        },
                        "UdpStreamTimeout": {
                          "locationName": "udpStreamTimeout",
                          "type": "integer"
                        },
                        "UdpTimeout": {
                          "locationName": "udpTimeout",
                          "type": "integer"
                        }
                      }
                    }
                  }
                }
              },
              "OutpostArn": {
                "locationName": "outpostArn"
              },
              "RootDeviceName": {
                "locationName": "rootDeviceName"
              },
              "RootDeviceType": {
                "locationName": "rootDeviceType"
              },
              "SecurityGroups": {
                "shape": "Sm8",
                "locationName": "groupSet"
              },
              "SourceDestCheck": {
                "locationName": "sourceDestCheck",
                "type": "boolean"
              },
              "SpotInstanceRequestId": {
                "locationName": "spotInstanceRequestId"
              },
              "SriovNetSupport": {
                "locationName": "sriovNetSupport"
              },
              "StateReason": {
                "shape": "Sk6",
                "locationName": "stateReason"
              },
              "Tags": {
                "shape": "S6",
                "locationName": "tagSet"
              },
              "VirtualizationType": {
                "locationName": "virtualizationType"
              },
              "CpuOptions": {
                "locationName": "cpuOptions",
                "type": "structure",
                "members": {
                  "CoreCount": {
                    "locationName": "coreCount",
                    "type": "integer"
                  },
                  "ThreadsPerCore": {
                    "locationName": "threadsPerCore",
                    "type": "integer"
                  },
                  "AmdSevSnp": {
                    "locationName": "amdSevSnp"
                  }
                }
              },
              "CapacityReservationId": {
                "locationName": "capacityReservationId"
              },
              "CapacityReservationSpecification": {
                "locationName": "capacityReservationSpecification",
                "type": "structure",
                "members": {
                  "CapacityReservationPreference": {
                    "locationName": "capacityReservationPreference"
                  },
                  "CapacityReservationTarget": {
                    "shape": "Sjm",
                    "locationName": "capacityReservationTarget"
                  }
                }
              },
              "HibernationOptions": {
                "locationName": "hibernationOptions",
                "type": "structure",
                "members": {
                  "Configured": {
                    "locationName": "configured",
                    "type": "boolean"
                  }
                }
              },
              "Licenses": {
                "locationName": "licenseSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "LicenseConfigurationArn": {
                      "locationName": "licenseConfigurationArn"
                    }
                  }
                }
              },
              "MetadataOptions": {
                "shape": "S1fm",
                "locationName": "metadataOptions"
              },
              "EnclaveOptions": {
                "shape": "S19p",
                "locationName": "enclaveOptions"
              },
              "BootMode": {
                "locationName": "bootMode"
              },
              "PlatformDetails": {
                "locationName": "platformDetails"
              },
              "UsageOperation": {
                "locationName": "usageOperation"
              },
              "UsageOperationUpdateTime": {
                "locationName": "usageOperationUpdateTime",
                "type": "timestamp"
              },
              "PrivateDnsNameOptions": {
                "locationName": "privateDnsNameOptions",
                "type": "structure",
                "members": {
                  "HostnameType": {
                    "locationName": "hostnameType"
                  },
                  "EnableResourceNameDnsARecord": {
                    "locationName": "enableResourceNameDnsARecord",
                    "type": "boolean"
                  },
                  "EnableResourceNameDnsAAAARecord": {
                    "locationName": "enableResourceNameDnsAAAARecord",
                    "type": "boolean"
                  }
                }
              },
              "Ipv6Address": {
                "locationName": "ipv6Address"
              },
              "TpmSupport": {
                "locationName": "tpmSupport"
              },
              "MaintenanceOptions": {
                "locationName": "maintenanceOptions",
                "type": "structure",
                "members": {
                  "AutoRecovery": {
                    "locationName": "autoRecovery"
                  }
                }
              },
              "CurrentInstanceBootMode": {
                "locationName": "currentInstanceBootMode"
              }
            }
          }
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "ReservationId": {
          "locationName": "reservationId"
        }
      }
    },
    "S1ex": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        }
      }
    },
    "S1f6": {
      "type": "structure",
      "members": {
        "CarrierIp": {
          "locationName": "carrierIp"
        },
        "CustomerOwnedIp": {
          "locationName": "customerOwnedIp"
        },
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "PublicIp": {
          "locationName": "publicIp"
        }
      }
    },
    "S1fm": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "HttpTokens": {
          "locationName": "httpTokens"
        },
        "HttpPutResponseHopLimit": {
          "locationName": "httpPutResponseHopLimit",
          "type": "integer"
        },
        "HttpEndpoint": {
          "locationName": "httpEndpoint"
        },
        "HttpProtocolIpv6": {
          "locationName": "httpProtocolIpv6"
        },
        "InstanceMetadataTags": {
          "locationName": "instanceMetadataTags"
        }
      }
    },
    "S1ht": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1i6": {
      "type": "list",
      "member": {
        "locationName": "SnapshotId"
      }
    },
    "S1j8": {
      "type": "structure",
      "members": {
        "NetworkInsightsAccessScopeAnalysisId": {
          "locationName": "networkInsightsAccessScopeAnalysisId"
        },
        "NetworkInsightsAccessScopeAnalysisArn": {
          "locationName": "networkInsightsAccessScopeAnalysisArn"
        },
        "NetworkInsightsAccessScopeId": {
          "locationName": "networkInsightsAccessScopeId"
        },
        "Status": {
          "locationName": "status"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "WarningMessage": {
          "locationName": "warningMessage"
        },
        "StartDate": {
          "locationName": "startDate",
          "type": "timestamp"
        },
        "EndDate": {
          "locationName": "endDate",
          "type": "timestamp"
        },
        "FindingsFound": {
          "locationName": "findingsFound"
        },
        "AnalyzedEniCount": {
          "locationName": "analyzedEniCount",
          "type": "integer"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S1jj": {
      "type": "structure",
      "members": {
        "NetworkInsightsAnalysisId": {
          "locationName": "networkInsightsAnalysisId"
        },
        "NetworkInsightsAnalysisArn": {
          "locationName": "networkInsightsAnalysisArn"
        },
        "NetworkInsightsPathId": {
          "locationName": "networkInsightsPathId"
        },
        "AdditionalAccounts": {
          "shape": "So",
          "locationName": "additionalAccountSet"
        },
        "FilterInArns": {
          "shape": "S1jk",
          "locationName": "filterInArnSet"
        },
        "StartDate": {
          "locationName": "startDate",
          "type": "timestamp"
        },
        "Status": {
          "locationName": "status"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "WarningMessage": {
          "locationName": "warningMessage"
        },
        "NetworkPathFound": {
          "locationName": "networkPathFound",
          "type": "boolean"
        },
        "ForwardPathComponents": {
          "shape": "S1jl",
          "locationName": "forwardPathComponentSet"
        },
        "ReturnPathComponents": {
          "shape": "S1jl",
          "locationName": "returnPathComponentSet"
        },
        "Explanations": {
          "shape": "S1k5",
          "locationName": "explanationSet"
        },
        "AlternatePathHints": {
          "locationName": "alternatePathHintSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ComponentId": {
                "locationName": "componentId"
              },
              "ComponentArn": {
                "locationName": "componentArn"
              }
            }
          }
        },
        "SuggestedAccounts": {
          "shape": "So",
          "locationName": "suggestedAccountSet"
        },
        "Tags": {
          "shape": "S6",
          "locationName": "tagSet"
        }
      }
    },
    "S1jk": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1jl": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "SequenceNumber": {
            "locationName": "sequenceNumber",
            "type": "integer"
          },
          "AclRule": {
            "shape": "S1jn",
            "locationName": "aclRule"
          },
          "AttachedTo": {
            "shape": "S1jo",
            "locationName": "attachedTo"
          },
          "Component": {
            "shape": "S1jo",
            "locationName": "component"
          },
          "DestinationVpc": {
            "shape": "S1jo",
            "locationName": "destinationVpc"
          },
          "OutboundHeader": {
            "shape": "S1jp",
            "locationName": "outboundHeader"
          },
          "InboundHeader": {
            "shape": "S1jp",
            "locationName": "inboundHeader"
          },
          "RouteTableRoute": {
            "shape": "S1js",
            "locationName": "routeTableRoute"
          },
          "SecurityGroupRule": {
            "shape": "S1jt",
            "locationName": "securityGroupRule"
          },
          "SourceVpc": {
            "shape": "S1jo",
            "locationName": "sourceVpc"
          },
          "Subnet": {
            "shape": "S1jo",
            "locationName": "subnet"
          },
          "Vpc": {
            "shape": "S1jo",
            "locationName": "vpc"
          },
          "AdditionalDetails": {
            "locationName": "additionalDetailSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AdditionalDetailType": {
                  "locationName": "additionalDetailType"
                },
                "Component": {
                  "shape": "S1jo",
                  "locationName": "component"
                },
                "VpcEndpointService": {
                  "shape": "S1jo",
                  "locationName": "vpcEndpointService"
                },
                "RuleOptions": {
                  "shape": "S1jw",
                  "locationName": "ruleOptionSet"
                },
                "RuleGroupTypePairs": {
                  "locationName": "ruleGroupTypePairSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "RuleGroupArn": {
                        "locationName": "ruleGroupArn"
                      },
                      "RuleGroupType": {
                        "locationName": "ruleGroupType"
                      }
                    }
                  }
                },
                "RuleGroupRuleOptionsPairs": {
                  "locationName": "ruleGroupRuleOptionsPairSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "RuleGroupArn": {
                        "locationName": "ruleGroupArn"
                      },
                      "RuleOptions": {
                        "shape": "S1jw",
                        "locationName": "ruleOptionSet"
                      }
                    }
                  }
                },
                "ServiceName": {
                  "locationName": "serviceName"
                },
                "LoadBalancers": {
                  "shape": "S1k3",
                  "locationName": "loadBalancerSet"
                }
              }
            }
          },
          "TransitGateway": {
            "shape": "S1jo",
            "locationName": "transitGateway"
          },
          "TransitGatewayRouteTableRoute": {
            "shape": "S1k4",
            "locationName": "transitGatewayRouteTableRoute"
          },
          "Explanations": {
            "shape": "S1k5",
            "locationName": "explanationSet"
          },
          "ElasticLoadBalancerListener": {
            "shape": "S1jo",
            "locationName": "elasticLoadBalancerListener"
          },
          "FirewallStatelessRule": {
            "shape": "S1kb",
            "locationName": "firewallStatelessRule"
          },
          "FirewallStatefulRule": {
            "shape": "S1kf",
            "locationName": "firewallStatefulRule"
          },
          "ServiceName": {
            "locationName": "serviceName"
          }
        }
      }
    },
    "S1jn": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "Egress": {
          "locationName": "egress",
          "type": "boolean"
        },
        "PortRange": {
          "shape": "Skz",
          "locationName": "portRange"
        },
        "Protocol": {
          "locationName": "protocol"
        },
        "RuleAction": {
          "locationName": "ruleAction"
        },
        "RuleNumber": {
          "locationName": "ruleNumber",
          "type": "integer"
        }
      }
    },
    "S1jo": {
      "type": "structure",
      "members": {
        "Id": {
          "locationName": "id"
        },
        "Arn": {
          "locationName": "arn"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "S1jp": {
      "type": "structure",
      "members": {
        "DestinationAddresses": {
          "shape": "S1jq",
          "locationName": "destinationAddressSet"
        },
        "DestinationPortRanges": {
          "shape": "S1jr",
          "locationName": "destinationPortRangeSet"
        },
        "Protocol": {
          "locationName": "protocol"
        },
        "SourceAddresses": {
          "shape": "S1jq",
          "locationName": "sourceAddressSet"
        },
        "SourcePortRanges": {
          "shape": "S1jr",
          "locationName": "sourcePortRangeSet"
        }
      }
    },
    "S1jq": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1jr": {
      "type": "list",
      "member": {
        "shape": "Skz",
        "locationName": "item"
      }
    },
    "S1js": {
      "type": "structure",
      "members": {
        "DestinationCidr": {
          "locationName": "destinationCidr"
        },
        "DestinationPrefixListId": {
          "locationName": "destinationPrefixListId"
        },
        "EgressOnlyInternetGatewayId": {
          "locationName": "egressOnlyInternetGatewayId"
        },
        "GatewayId": {
          "locationName": "gatewayId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "NatGatewayId": {
          "locationName": "natGatewayId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "Origin": {
          "locationName": "origin"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        },
        "State": {
          "locationName": "state"
        },
        "CarrierGatewayId": {
          "locationName": "carrierGatewayId"
        },
        "CoreNetworkArn": {
          "locationName": "coreNetworkArn"
        },
        "LocalGatewayId": {
          "locationName": "localGatewayId"
        }
      }
    },
    "S1jt": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "Direction": {
          "locationName": "direction"
        },
        "SecurityGroupId": {
          "locationName": "securityGroupId"
        },
        "PortRange": {
          "shape": "Skz",
          "locationName": "portRange"
        },
        "PrefixListId": {
          "locationName": "prefixListId"
        },
        "Protocol": {
          "locationName": "protocol"
        }
      }
    },
    "S1jw": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Keyword": {
            "locationName": "keyword"
          },
          "Settings": {
            "shape": "S1jy",
            "locationName": "settingSet"
          }
        }
      }
    },
    "S1jy": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1k3": {
      "type": "list",
      "member": {
        "shape": "S1jo",
        "locationName": "item"
      }
    },
    "S1k4": {
      "type": "structure",
      "members": {
        "DestinationCidr": {
          "locationName": "destinationCidr"
        },
        "State": {
          "locationName": "state"
        },
        "RouteOrigin": {
          "locationName": "routeOrigin"
        },
        "PrefixListId": {
          "locationName": "prefixListId"
        },
        "AttachmentId": {
          "locationName": "attachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        }
      }
    },
    "S1k5": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Acl": {
            "shape": "S1jo",
            "locationName": "acl"
          },
          "AclRule": {
            "shape": "S1jn",
            "locationName": "aclRule"
          },
          "Address": {
            "locationName": "address"
          },
          "Addresses": {
            "shape": "S1jq",
            "locationName": "addressSet"
          },
          "AttachedTo": {
            "shape": "S1jo",
            "locationName": "attachedTo"
          },
          "AvailabilityZones": {
            "shape": "So",
            "locationName": "availabilityZoneSet"
          },
          "Cidrs": {
            "shape": "So",
            "locationName": "cidrSet"
          },
          "Component": {
            "shape": "S1jo",
            "locationName": "component"
          },
          "CustomerGateway": {
            "shape": "S1jo",
            "locationName": "customerGateway"
          },
          "Destination": {
            "shape": "S1jo",
            "locationName": "destination"
          },
          "DestinationVpc": {
            "shape": "S1jo",
            "locationName": "destinationVpc"
          },
          "Direction": {
            "locationName": "direction"
          },
          "ExplanationCode": {
            "locationName": "explanationCode"
          },
          "IngressRouteTable": {
            "shape": "S1jo",
            "locationName": "ingressRouteTable"
          },
          "InternetGateway": {
            "shape": "S1jo",
            "locationName": "internetGateway"
          },
          "LoadBalancerArn": {
            "locationName": "loadBalancerArn"
          },
          "ClassicLoadBalancerListener": {
            "locationName": "classicLoadBalancerListener",
            "type": "structure",
            "members": {
              "LoadBalancerPort": {
                "locationName": "loadBalancerPort",
                "type": "integer"
              },
              "InstancePort": {
                "locationName": "instancePort",
                "type": "integer"
              }
            }
          },
          "LoadBalancerListenerPort": {
            "locationName": "loadBalancerListenerPort",
            "type": "integer"
          },
          "LoadBalancerTarget": {
            "locationName": "loadBalancerTarget",
            "type": "structure",
            "members": {
              "Address": {
                "locationName": "address"
              },
              "AvailabilityZone": {
                "locationName": "availabilityZone"
              },
              "Instance": {
                "shape": "S1jo",
                "locationName": "instance"
              },
              "Port": {
                "locationName": "port",
                "type": "integer"
              }
            }
          },
          "LoadBalancerTargetGroup": {
            "shape": "S1jo",
            "locationName": "loadBalancerTargetGroup"
          },
          "LoadBalancerTargetGroups": {
            "shape": "S1k3",
            "locationName": "loadBalancerTargetGroupSet"
          },
          "LoadBalancerTargetPort": {
            "locationName": "loadBalancerTargetPort",
            "type": "integer"
          },
          "ElasticLoadBalancerListener": {
            "shape": "S1jo",
            "locationName": "elasticLoadBalancerListener"
          },
          "MissingComponent": {
            "locationName": "missingComponent"
          },
          "NatGateway": {
            "shape": "S1jo",
            "locationName": "natGateway"
          },
          "NetworkInterface": {
            "shape": "S1jo",
            "locationName": "networkInterface"
          },
          "PacketField": {
            "locationName": "packetField"
          },
          "VpcPeeringConnection": {
            "shape": "S1jo",
            "locationName": "vpcPeeringConnection"
          },
          "Port": {
            "locationName": "port",
            "type": "integer"
          },
          "PortRanges": {
            "shape": "S1jr",
            "locationName": "portRangeSet"
          },
          "PrefixList": {
            "shape": "S1jo",
            "locationName": "prefixList"
          },
          "Protocols": {
            "shape": "S1jy",
            "locationName": "protocolSet"
          },
          "RouteTableRoute": {
            "shape": "S1js",
            "locationName": "routeTableRoute"
          },
          "RouteTable": {
            "shape": "S1jo",
            "locationName": "routeTable"
          },
          "SecurityGroup": {
            "shape": "S1jo",
            "locationName": "securityGroup"
          },
          "SecurityGroupRule": {
            "shape": "S1jt",
            "locationName": "securityGroupRule"
          },
          "SecurityGroups": {
            "shape": "S1k3",
            "locationName": "securityGroupSet"
          },
          "SourceVpc": {
            "shape": "S1jo",
            "locationName": "sourceVpc"
          },
          "State": {
            "locationName": "state"
          },
          "Subnet": {
            "shape": "S1jo",
            "locationName": "subnet"
          },
          "SubnetRouteTable": {
            "shape": "S1jo",
            "locationName": "subnetRouteTable"
          },
          "Vpc": {
            "shape": "S1jo",
            "locationName": "vpc"
          },
          "VpcEndpoint": {
            "shape": "S1jo",
            "locationName": "vpcEndpoint"
          },
          "VpnConnection": {
            "shape": "S1jo",
            "locationName": "vpnConnection"
          },
          "VpnGateway": {
            "shape": "S1jo",
            "locationName": "vpnGateway"
          },
          "TransitGateway": {
            "shape": "S1jo",
            "locationName": "transitGateway"
          },
          "TransitGatewayRouteTable": {
            "shape": "S1jo",
            "locationName": "transitGatewayRouteTable"
          },
          "TransitGatewayRouteTableRoute": {
            "shape": "S1k4",
            "locationName": "transitGatewayRouteTableRoute"
          },
          "TransitGatewayAttachment": {
            "shape": "S1jo",
            "locationName": "transitGatewayAttachment"
          },
          "ComponentAccount": {
            "locationName": "componentAccount"
          },
          "ComponentRegion": {
            "locationName": "componentRegion"
          },
          "FirewallStatelessRule": {
            "shape": "S1kb",
            "locationName": "firewallStatelessRule"
          },
          "FirewallStatefulRule": {
            "shape": "S1kf",
            "locationName": "firewallStatefulRule"
          }
        }
      }
    },
    "S1kb": {
      "type": "structure",
      "members": {
        "RuleGroupArn": {
          "locationName": "ruleGroupArn"
        },
        "Sources": {
          "shape": "So",
          "locationName": "sourceSet"
        },
        "Destinations": {
          "shape": "So",
          "locationName": "destinationSet"
        },
        "SourcePorts": {
          "shape": "S1jr",
          "locationName": "sourcePortSet"
        },
        "DestinationPorts": {
          "shape": "S1jr",
          "locationName": "destinationPortSet"
        },
        "Protocols": {
          "locationName": "protocolSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "integer"
          }
        },
        "RuleAction": {
          "locationName": "ruleAction"
        },
        "Priority": {
          "locationName": "priority",
          "type": "integer"
        }
      }
    },
    "S1kf": {
      "type": "structure",
      "members": {
        "RuleGroupArn": {
          "locationName": "ruleGroupArn"
        },
        "Sources": {
          "shape": "So",
          "locationName": "sourceSet"
        },
        "Destinations": {
          "shape": "So",
          "locationName": "destinationSet"
        },
        "SourcePorts": {
          "shape": "S1jr",
          "locationName": "sourcePortSet"
        },
        "DestinationPorts": {
          "shape": "S1jr",
          "locationName": "destinationPortSet"
        },
        "Protocol": {
          "locationName": "protocol"
        },
        "RuleAction": {
          "locationName": "ruleAction"
        },
        "Direction": {
          "locationName": "direction"
        }
      }
    },
    "S1lm": {
      "type": "structure",
      "members": {
        "FirstAddress": {
          "locationName": "firstAddress"
        },
        "LastAddress": {
          "locationName": "lastAddress"
        },
        "AddressCount": {
          "locationName": "addressCount",
          "type": "integer"
        },
        "AvailableAddressCount": {
          "locationName": "availableAddressCount",
          "type": "integer"
        }
      }
    },
    "S1lz": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstancesId"
      }
    },
    "S1m7": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Amount": {
            "locationName": "amount",
            "type": "double"
          },
          "Frequency": {
            "locationName": "frequency"
          }
        }
      }
    },
    "S1ml": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "Platform": {
          "locationName": "platform"
        },
        "Scope": {
          "locationName": "scope"
        }
      }
    },
    "S1n8": {
      "type": "structure",
      "members": {
        "Frequency": {
          "locationName": "frequency"
        },
        "Interval": {
          "locationName": "interval",
          "type": "integer"
        },
        "OccurrenceDaySet": {
          "locationName": "occurrenceDaySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "integer"
          }
        },
        "OccurrenceRelativeToEnd": {
          "locationName": "occurrenceRelativeToEnd",
          "type": "boolean"
        },
        "OccurrenceUnit": {
          "locationName": "occurrenceUnit"
        }
      }
    },
    "S1ng": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        },
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "NetworkPlatform": {
          "locationName": "networkPlatform"
        },
        "NextSlotStartTime": {
          "locationName": "nextSlotStartTime",
          "type": "timestamp"
        },
        "Platform": {
          "locationName": "platform"
        },
        "PreviousSlotEndTime": {
          "locationName": "previousSlotEndTime",
          "type": "timestamp"
        },
        "Recurrence": {
          "shape": "S1n8",
          "locationName": "recurrence"
        },
        "ScheduledInstanceId": {
          "locationName": "scheduledInstanceId"
        },
        "SlotDurationInHours": {
          "locationName": "slotDurationInHours",
          "type": "integer"
        },
        "TermEndDate": {
          "locationName": "termEndDate",
          "type": "timestamp"
        },
        "TermStartDate": {
          "locationName": "termStartDate",
          "type": "timestamp"
        },
        "TotalScheduledInstanceHours": {
          "locationName": "totalScheduledInstanceHours",
          "type": "integer"
        }
      }
    },
    "S1nn": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1nr": {
      "type": "list",
      "member": {
        "locationName": "GroupName"
      }
    },
    "S1nz": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {
            "locationName": "group"
          },
          "UserId": {
            "locationName": "userId"
          }
        }
      }
    },
    "S1or": {
      "type": "structure",
      "required": [
        "IamFleetRole",
        "TargetCapacity"
      ],
      "members": {
        "AllocationStrategy": {
          "locationName": "allocationStrategy"
        },
        "OnDemandAllocationStrategy": {
          "locationName": "onDemandAllocationStrategy"
        },
        "SpotMaintenanceStrategies": {
          "locationName": "spotMaintenanceStrategies",
          "type": "structure",
          "members": {
            "CapacityRebalance": {
              "locationName": "capacityRebalance",
              "type": "structure",
              "members": {
                "ReplacementStrategy": {
                  "locationName": "replacementStrategy"
                },
                "TerminationDelay": {
                  "locationName": "terminationDelay",
                  "type": "integer"
                }
              }
            }
          }
        },
        "ClientToken": {
          "locationName": "clientToken"
        },
        "ExcessCapacityTerminationPolicy": {
          "locationName": "excessCapacityTerminationPolicy"
        },
        "FulfilledCapacity": {
          "locationName": "fulfilledCapacity",
          "type": "double"
        },
        "OnDemandFulfilledCapacity": {
          "locationName": "onDemandFulfilledCapacity",
          "type": "double"
        },
        "IamFleetRole": {
          "locationName": "iamFleetRole"
        },
        "LaunchSpecifications": {
          "locationName": "launchSpecifications",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "SecurityGroups": {
                "shape": "Sm8",
                "locationName": "groupSet"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "S18h",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S3v",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "structure",
                "members": {
                  "Enabled": {
                    "locationName": "enabled",
                    "type": "boolean"
                  }
                }
              },
              "NetworkInterfaces": {
                "shape": "S1p1",
                "locationName": "networkInterfaceSet"
              },
              "Placement": {
                "shape": "S1p3",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SpotPrice": {
                "locationName": "spotPrice"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "shape": "Sgy",
                "locationName": "userData"
              },
              "WeightedCapacity": {
                "locationName": "weightedCapacity",
                "type": "double"
              },
              "TagSpecifications": {
                "locationName": "tagSpecificationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ResourceType": {
                      "locationName": "resourceType"
                    },
                    "Tags": {
                      "shape": "S6",
                      "locationName": "tag"
                    }
                  }
                }
              },
              "InstanceRequirements": {
                "shape": "Se3",
                "locationName": "instanceRequirements"
              }
            }
          }
        },
        "LaunchTemplateConfigs": {
          "shape": "S1p6",
          "locationName": "launchTemplateConfigs"
        },
        "SpotPrice": {
          "locationName": "spotPrice"
        },
        "TargetCapacity": {
          "locationName": "targetCapacity",
          "type": "integer"
        },
        "OnDemandTargetCapacity": {
          "locationName": "onDemandTargetCapacity",
          "type": "integer"
        },
        "OnDemandMaxTotalPrice": {
          "locationName": "onDemandMaxTotalPrice"
        },
        "SpotMaxTotalPrice": {
          "locationName": "spotMaxTotalPrice"
        },
        "TerminateInstancesWithExpiration": {
          "locationName": "terminateInstancesWithExpiration",
          "type": "boolean"
        },
        "Type": {
          "locationName": "type"
        },
        "ValidFrom": {
          "locationName": "validFrom",
          "type": "timestamp"
        },
        "ValidUntil": {
          "locationName": "validUntil",
          "type": "timestamp"
        },
        "ReplaceUnhealthyInstances": {
          "locationName": "replaceUnhealthyInstances",
          "type": "boolean"
        },
        "InstanceInterruptionBehavior": {
          "locationName": "instanceInterruptionBehavior"
        },
        "LoadBalancersConfig": {
          "locationName": "loadBalancersConfig",
          "type": "structure",
          "members": {
            "ClassicLoadBalancersConfig": {
              "locationName": "classicLoadBalancersConfig",
              "type": "structure",
              "members": {
                "ClassicLoadBalancers": {
                  "locationName": "classicLoadBalancers",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Name": {
                        "locationName": "name"
                      }
                    }
                  }
                }
              }
            },
            "TargetGroupsConfig": {
              "locationName": "targetGroupsConfig",
              "type": "structure",
              "members": {
                "TargetGroups": {
                  "locationName": "targetGroups",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Arn": {
                        "locationName": "arn"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "InstancePoolsToUseCount": {
          "locationName": "instancePoolsToUseCount",
          "type": "integer"
        },
        "Context": {
          "locationName": "context"
        },
        "TargetCapacityUnitType": {
          "locationName": "targetCapacityUnitType"
        },
        "TagSpecifications": {
          "shape": "S3",
          "locationName": "TagSpecification"
        }
      }
    },
    "S1p1": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "AssociatePublicIpAddress": {
            "locationName": "associatePublicIpAddress",
            "type": "boolean"
          },
          "DeleteOnTermination": {
            "locationName": "deleteOnTermination",
            "type": "boolean"
          },
          "Description": {
            "locationName": "description"
          },
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "Groups": {
            "shape": "Sh9",
            "locationName": "SecurityGroupId"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "Sj0",
            "locationName": "ipv6AddressesSet",
            "queryName": "Ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "PrivateIpAddresses": {
            "shape": "Shc",
            "locationName": "privateIpAddressesSet",
            "queryName": "PrivateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "AssociateCarrierIpAddress": {
            "type": "boolean"
          },
          "InterfaceType": {},
          "NetworkCardIndex": {
            "type": "integer"
          },
          "Ipv4Prefixes": {
            "shape": "She",
            "locationName": "Ipv4Prefix"
          },
          "Ipv4PrefixCount": {
            "type": "integer"
          },
          "Ipv6Prefixes": {
            "shape": "Shg",
            "locationName": "Ipv6Prefix"
          },
          "Ipv6PrefixCount": {
            "type": "integer"
          },
          "PrimaryIpv6": {
            "type": "boolean"
          },
          "EnaSrdSpecification": {
            "shape": "Shi"
          },
          "ConnectionTrackingSpecification": {
            "shape": "Shk"
          }
        }
      }
    },
    "S1p3": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "Tenancy": {
          "locationName": "tenancy"
        }
      }
    },
    "S1p6": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "LaunchTemplateSpecification": {
            "shape": "Se0",
            "locationName": "launchTemplateSpecification"
          },
          "Overrides": {
            "locationName": "overrides",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "SpotPrice": {
                  "locationName": "spotPrice"
                },
                "SubnetId": {
                  "locationName": "subnetId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "WeightedCapacity": {
                  "locationName": "weightedCapacity",
                  "type": "double"
                },
                "Priority": {
                  "locationName": "priority",
                  "type": "double"
                },
                "InstanceRequirements": {
                  "shape": "Se3",
                  "locationName": "instanceRequirements"
                }
              }
            }
          }
        }
      }
    },
    "S1pj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ActualBlockHourlyPrice": {
            "locationName": "actualBlockHourlyPrice"
          },
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "CreateTime": {
            "locationName": "createTime",
            "type": "timestamp"
          },
          "Fault": {
            "shape": "So5",
            "locationName": "fault"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "UserData": {
                "shape": "Sgy",
                "locationName": "userData"
              },
              "SecurityGroups": {
                "shape": "Sm8",
                "locationName": "groupSet"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "S18h",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S3v",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "NetworkInterfaces": {
                "shape": "S1p1",
                "locationName": "networkInterfaceSet"
              },
              "Placement": {
                "shape": "S1p3",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "Monitoring": {
                "shape": "S1pm",
                "locationName": "monitoring"
              }
            }
          },
          "LaunchedAvailabilityZone": {
            "locationName": "launchedAvailabilityZone"
          },
          "ProductDescription": {
            "locationName": "productDescription"
          },
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "State": {
            "locationName": "state"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              },
              "UpdateTime": {
                "locationName": "updateTime",
                "type": "timestamp"
              }
            }
          },
          "Tags": {
            "shape": "S6",
            "locationName": "tagSet"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "InstanceInterruptionBehavior": {
            "locationName": "instanceInterruptionBehavior"
          }
        }
      }
    },
    "S1pm": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        }
      }
    },
    "S1q1": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S78",
              "locationName": "item"
            }
          }
        }
      }
    },
    "S1r3": {
      "type": "list",
      "member": {}
    },
    "S1sm": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1sq": {
      "type": "structure",
      "members": {
        "VerifiedAccessInstanceId": {
          "locationName": "verifiedAccessInstanceId"
        },
        "AccessLogs": {
          "locationName": "accessLogs",
          "type": "structure",
          "members": {
            "S3": {
              "locationName": "s3",
              "type": "structure",
              "members": {
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                },
                "DeliveryStatus": {
                  "shape": "S1st",
                  "locationName": "deliveryStatus"
                },
                "BucketName": {
                  "locationName": "bucketName"
                },
                "Prefix": {
                  "locationName": "prefix"
                },
                "BucketOwner": {
                  "locationName": "bucketOwner"
                }
              }
            },
            "CloudWatchLogs": {
              "locationName": "cloudWatchLogs",
              "type": "structure",
              "members": {
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                },
                "DeliveryStatus": {
                  "shape": "S1st",
                  "locationName": "deliveryStatus"
                },
                "LogGroup": {
                  "locationName": "logGroup"
                }
              }
            },
            "KinesisDataFirehose": {
              "locationName": "kinesisDataFirehose",
              "type": "structure",
              "members": {
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                },
                "DeliveryStatus": {
                  "shape": "S1st",
                  "locationName": "deliveryStatus"
                },
                "DeliveryStream": {
                  "locationName": "deliveryStream"
                }
              }
            },
            "LogVersion": {
              "locationName": "logVersion"
            },
            "IncludeTrustContext": {
              "locationName": "includeTrustContext",
              "type": "boolean"
            }
          }
        }
      }
    },
    "S1st": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S1tu": {
      "type": "structure",
      "members": {
        "VolumeId": {
          "locationName": "volumeId"
        },
        "ModificationState": {
          "locationName": "modificationState"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "TargetSize": {
          "locationName": "targetSize",
          "type": "integer"
        },
        "TargetIops": {
          "locationName": "targetIops",
          "type": "integer"
        },
        "TargetVolumeType": {
          "locationName": "targetVolumeType"
        },
        "TargetThroughput": {
          "locationName": "targetThroughput",
          "type": "integer"
        },
        "TargetMultiAttachEnabled": {
          "locationName": "targetMultiAttachEnabled",
          "type": "boolean"
        },
        "OriginalSize": {
          "locationName": "originalSize",
          "type": "integer"
        },
        "OriginalIops": {
          "locationName": "originalIops",
          "type": "integer"
        },
        "OriginalVolumeType": {
          "locationName": "originalVolumeType"
        },
        "OriginalThroughput": {
          "locationName": "originalThroughput",
          "type": "integer"
        },
        "OriginalMultiAttachEnabled": {
          "locationName": "originalMultiAttachEnabled",
          "type": "boolean"
        },
        "Progress": {
          "locationName": "progress",
          "type": "long"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "EndTime": {
          "locationName": "endTime",
          "type": "timestamp"
        }
      }
    },
    "S1u0": {
      "type": "list",
      "member": {
        "locationName": "VpcId"
      }
    },
    "S1w0": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "S1wr": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "State": {
          "locationName": "state"
        },
        "TransitGatewayRouteTableAnnouncementId": {
          "locationName": "transitGatewayRouteTableAnnouncementId"
        }
      }
    },
    "S20b": {
      "type": "structure",
      "members": {
        "InstanceFamily": {
          "locationName": "instanceFamily"
        },
        "CpuCredits": {
          "locationName": "cpuCredits"
        }
      }
    },
    "S20s": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S20u": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Duration": {
            "locationName": "duration",
            "type": "integer"
          },
          "HostIdSet": {
            "shape": "S17p",
            "locationName": "hostIdSet"
          },
          "HostReservationId": {
            "locationName": "hostReservationId"
          },
          "HourlyPrice": {
            "locationName": "hourlyPrice"
          },
          "InstanceFamily": {
            "locationName": "instanceFamily"
          },
          "PaymentOption": {
            "locationName": "paymentOption"
          },
          "UpfrontPrice": {
            "locationName": "upfrontPrice"
          }
        }
      }
    },
    "S218": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S219": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S22n": {
      "type": "structure",
      "members": {
        "IpamId": {
          "locationName": "ipamId"
        },
        "IpamScopeId": {
          "locationName": "ipamScopeId"
        },
        "IpamPoolId": {
          "locationName": "ipamPoolId"
        },
        "ResourceRegion": {
          "locationName": "resourceRegion"
        },
        "ResourceOwnerId": {
          "locationName": "resourceOwnerId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceName": {
          "locationName": "resourceName"
        },
        "ResourceCidr": {
          "locationName": "resourceCidr"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "ResourceTags": {
          "shape": "Sgj",
          "locationName": "resourceTagSet"
        },
        "IpUsage": {
          "locationName": "ipUsage",
          "type": "double"
        },
        "ComplianceStatus": {
          "locationName": "complianceStatus"
        },
        "ManagementState": {
          "locationName": "managementState"
        },
        "OverlapStatus": {
          "locationName": "overlapStatus"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        }
      }
    },
    "S23c": {
      "type": "structure",
      "members": {
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "RemainingTotalValue": {
          "locationName": "remainingTotalValue"
        },
        "RemainingUpfrontValue": {
          "locationName": "remainingUpfrontValue"
        }
      }
    },
    "S243": {
      "type": "list",
      "member": {
        "shape": "Sog",
        "locationName": "item"
      }
    },
    "S25f": {
      "type": "structure",
      "members": {
        "Comment": {},
        "UploadEnd": {
          "type": "timestamp"
        },
        "UploadSize": {
          "type": "double"
        },
        "UploadStart": {
          "type": "timestamp"
        }
      }
    },
    "S25i": {
      "type": "structure",
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "S25p": {
      "type": "structure",
      "required": [
        "Bytes",
        "Format",
        "ImportManifestUrl"
      ],
      "members": {
        "Bytes": {
          "locationName": "bytes",
          "type": "long"
        },
        "Format": {
          "locationName": "format"
        },
        "ImportManifestUrl": {
          "shape": "S149",
          "locationName": "importManifestUrl"
        }
      }
    },
    "S25q": {
      "type": "structure",
      "required": [
        "Size"
      ],
      "members": {
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "S270": {
      "type": "list",
      "member": {
        "locationName": "UserId"
      }
    },
    "S271": {
      "type": "list",
      "member": {
        "locationName": "UserGroup"
      }
    },
    "S272": {
      "type": "list",
      "member": {
        "locationName": "ProductCode"
      }
    },
    "S274": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {},
          "UserId": {}
        }
      }
    },
    "S279": {
      "type": "list",
      "member": {
        "shape": "S1i",
        "locationName": "item"
      }
    },
    "S27m": {
      "type": "structure",
      "members": {
        "CapacityReservationPreference": {},
        "CapacityReservationTarget": {
          "shape": "Si8"
        }
      }
    },
    "S28g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RegionName": {}
        }
      }
    },
    "S2b5": {
      "type": "structure",
      "members": {
        "AllowDnsResolutionFromRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "type": "boolean"
        }
      }
    },
    "S2b7": {
      "type": "structure",
      "members": {
        "AllowDnsResolutionFromRemoteVpc": {
          "locationName": "allowDnsResolutionFromRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
          "type": "boolean"
        }
      }
    },
    "S2bm": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Monitoring": {
            "shape": "S1ex",
            "locationName": "monitoring"
          }
        }
      }
    },
    "S2fj": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S2g7": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CurrentState": {
            "shape": "S1ae",
            "locationName": "currentState"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "PreviousState": {
            "shape": "S1ae",
            "locationName": "previousState"
          }
        }
      }
    },
    "S2gx": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "SecurityGroupRuleId": {},
          "Description": {}
        }
      }
    }
  }
}