import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, Route, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard  {
  constructor(private auth: AuthService, private router: Router) {}
  /* canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  } */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    debugger;
    console.log("Can activate", this.auth.authenticated, state.url);
    // ;
    if (this.auth.authenticated()) {
      return true;
    }

    const navigationExtras: NavigationExtras = {
      queryParams: { url: state.url }
    };
    this.router.navigate(["login"]);

    // this.router.navigate(["login", { url: state.url }]);
    return false;
  }
}
