{
  "pagination": {
    "DescribeCustomKeyStores": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "CustomKeyStores"
    },
    "ListAliases": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Aliases"
    },
    "ListGrants": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Grants"
    },
    "ListKeyPolicies": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "PolicyNames"
    },
    "ListKeyRotations": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Rotations"
    },
    "ListKeys": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Keys"
    },
    "ListResourceTags": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Tags"
    },
    "ListRetirableGrants": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Grants"
    }
  }
}