'use strict';

var forEach = require('for-each');
var availableTypedArrays = require('available-typed-arrays');
var callBind = require('call-bind');
var callBound = require('call-bind/callBound');
var gOPD = require('gopd');
var $toString = callBound('Object.prototype.toString');
var hasToStringTag = require('has-tostringtag/shams')();
var g = typeof globalThis === 'undefined' ? global : globalThis;
var typedArrays = availableTypedArrays();
var $slice = callBound('String.prototype.slice');
var getPrototypeOf = Object.getPrototypeOf; // require('getprototypeof');

var $indexOf = callBound('Array.prototype.indexOf', true) || function indexOf(array, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i] === value) {
      return i;
    }
  }
  return -1;
};
var cache = {
  __proto__: null
};
if (hasToStringTag && gOPD && getPrototypeOf) {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();
    if (Symbol.toStringTag in arr) {
      var proto = getPrototypeOf(arr);
      var descriptor = gOPD(proto, Symbol.toStringTag);
      if (!descriptor) {
        var superProto = getPrototypeOf(proto);
        descriptor = gOPD(superProto, Symbol.toStringTag);
      }
      cache['$' + typedArray] = callBind(descriptor.get);
    }
  });
} else {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();
    var fn = arr.slice || arr.set;
    if (fn) {
      cache['$' + typedArray] = callBind(fn);
    }
  });
}
var tryTypedArrays = function tryAllTypedArrays(value) {
  var found = false;
  forEach(cache, function (getter, typedArray) {
    if (!found) {
      try {
        if ('$' + getter(value) === typedArray) {
          found = $slice(typedArray, 1);
        }
      } catch (e) {/**/}
    }
  });
  return found;
};
var trySlices = function tryAllSlices(value) {
  var found = false;
  forEach(cache, function (getter, name) {
    if (!found) {
      try {
        getter(value);
        found = $slice(name, 1);
      } catch (e) {/**/}
    }
  });
  return found;
};
module.exports = function whichTypedArray(value) {
  if (!value || typeof value !== 'object') {
    return false;
  }
  if (!hasToStringTag) {
    var tag = $slice($toString(value), 8, -1);
    if ($indexOf(typedArrays, tag) > -1) {
      return tag;
    }
    if (tag !== 'Object') {
      return false;
    }
    // node < 0.6 hits here on real Typed Arrays
    return trySlices(value);
  }
  if (!gOPD) {
    return null;
  } // unknown engine
  return tryTypedArrays(value);
};