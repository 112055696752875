import { Injectable } from '@angular/core';
//import { MapsAPILoader } from '@agm/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor() { }
  async reverseGeoCoder(lat:number, lng:number):Promise<any> {
    
  
      const geocoder = new google.maps.Geocoder();
      const latlng = {
        lat: lat,
        lng: lng,
      };
      let result = geocoder.geocode({ location: latlng }, (results:any) => {  
        console.log("The results", results);
        return results;
        });
        console.log("The result", result);
        return result;
    /*   geocoder.geocode(
        {
          location: latlng,
        },
        (results:any) => {
          console.log("The results", results);
          if (results[0]) {
            const addressComponents = results[0].address_components;
            this.currentStreet =
              addressComponents[0].long_name +
              " " +
              addressComponents[1].long_name;
            this.currentCity = addressComponents[3].long_name;
            this.currentState = addressComponents[6].long_name;
            this.currentZip = addressComponents[8].long_name;
            this.currentCounty = addressComponents[5].short_name; 
            console.log(
              "Reverse geocoding",
              addressComponents,
             
            );
            debugger;
          } else {
            console.log("Not found");
          }
        }
      );*/
    //});
  } 
}
