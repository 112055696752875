{
  "pagination": {
    "DescribeImageScanFindings": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "registryId",
        "repositoryName",
        "imageId",
        "imageScanStatus",
        "imageScanFindings"
      ],
      "output_token": "nextToken",
      "result_key": [
        "imageScanFindings.findings",
        "imageScanFindings.enhancedFindings"
      ]
    },
    "DescribeImages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "imageDetails"
    },
    "DescribePullThroughCacheRules": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "pullThroughCacheRules"
    },
    "DescribeRepositories": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "repositories"
    },
    "DescribeRepositoryCreationTemplates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "registryId"
      ],
      "output_token": "nextToken",
      "result_key": "repositoryCreationTemplates"
    },
    "GetLifecyclePolicyPreview": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "registryId",
        "repositoryName",
        "lifecyclePolicyText",
        "status",
        "summary"
      ],
      "output_token": "nextToken",
      "result_key": "previewResults"
    },
    "ListImages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "imageIds"
    }
  }
}