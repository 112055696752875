import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {  MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { BarcodeFormat } from '@zxing/library';
@Component({
  selector: "app-qr-scannner",
  templateUrl: "./qr-scannner.component.html",
  styleUrls: ["./qr-scannner.component.scss"],
  standalone:true,
  imports:[CommonModule, MatDialogModule,ZXingScannerModule]
})
export class QrScannnerComponent implements OnInit {
  
  qrResultString: string="";
  selectedFormats: BarcodeFormat[] = [BarcodeFormat.QR_CODE];
  constructor(private dialogRef: MatDialogRef<QrScannnerComponent>) {}

  ngOnInit() {}
  scanDone(): void {
    this.dialogRef.close({ sn: this.qrResultString });
  }
  //QR code

  clearResult(): void {
    this.qrResultString = "";
  }
  onCodeResult(resultString: string) {
    console.log("Result: ", resultString)
   
    
    this.qrResultString = resultString;
  }
}
