import { Component, OnInit } from "@angular/core";
import { DeviceInfoService } from "./../../core/device-info.service";
import { FormsModule, NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Device } from "./../../modelclasses/device.model";


@Component({
  selector: "app-device-information-register",
  templateUrl: "./device-information-register.component.html",
  standalone: true,
  imports:[FormsModule],
  // styleUrls: ["./device-information-register.component.css"]
})
export class DeviceInformationRegisterComponent implements OnInit {
  private serialNumber: string ="";
  private devicesList: Device[]=[];
  //public device: Device;
  //deviceCode:string;

  constructor(
    private deviceInfoService: DeviceInfoService,
    private toastrService: ToastrService
  ) {
    // this.device= new Device();
  /*   
    this.device = {
      serialNumber: "",

   deviceInformation: {
        userId: 1,
        companyName: "Company Name",
       street: "123 Main St. ",
        city: "Indianapolis",
        state: "IN",
        zip: "46268",
        roomNumber: "201",
        county: "",
        address: "",
        deviceLocation: "", // default to Female

        Latitude: 0,
        Longitude: 0, // default to dark theme

        keyPhrase: "protect me now",
        dkeyPhrase: "stop covert",
        downloadurl: "",
      },

    };*/
  }

  ngOnInit() {
    var that = this;
    // this.deviceInfoService.sendMessage();
    /* 
  
*/

    this.deviceInfoService
      .getDevicesList()
      .snapshotChanges()
      .subscribe(async (item) => {
        await that.initilizeDevicesList(item);

        console.log(this.devicesList);
        that.initizeDevice();
      });

    //this.deviceInfoService.getDeviceInformation('123');
  }
  initilizeDevicesList(item:any) {
    this.devicesList = [];
    item.forEach((element:any) => {
      var temp = element.payload.toJSON();
      temp["serialNumber"] = element.key;
      this.devicesList.push(temp as Device);
    });
  }
  initizeDevice() {
    var that = this;
   /*   this.deviceInfoService.getSerialNumber().subscribe((res) => {
      that.serialNumber = res.data.trim();

      that.device =<Device>that.devicesList.find((element) => {
        return element.serialNumber == that.serialNumber;
      });
      if (that.device == undefined) {
          that.device = {
          serialNumber: that.serialNumber,

          deviceInformation: {
            userId: 1,
         companyName: "Company Name ", */
            /* street: "123 Main St.",

            city: "Indianapolis",
            state: "IN",
            zip: "46268",roomNumber: "201", */
           /*  county: "", 
            address: "",*/
          /*     deviceLocation: "", // default to Female

            Latitude: 10,
            Longitude: 10, // default to dark theme

            keyPhrase: "protect me now",
            dkeyPhrase: "stop covert",
            downloadurl: "",
          },

        logs: "", 
        };
        //  that.device.serialNumber=
      }
    });
*/
    //this.device.serialNumber=tempDevice.serialNumber;
    //console.log('hey birhanu'+tempDevice);
  }

  onKey(event: any) {
    // without type info
    /*  this.device.deviceInformation.deviceCode =
      this.device.deviceInformation.companyName +
      this.device.deviceInformation.roomNumber; */
  }
  onSubmit(deviceForm: NgForm) {
   
    /*    this.deviceInfoService.updateDeviceInformation({
      serialNumber: deviceForm.value.serialNumber,
      // default to dark theme

      deviceInformation: {
        userId: 1,
     companyName: deviceForm.value.companyName, */
        /* street: deviceForm.value.street,
        city: deviceForm.value.city,
        state: deviceForm.value.state,
        zip: deviceForm.value.zip,
         roomNumber: deviceForm.value.roomNumber,*/
       /*  county: deviceForm.value.county, 
        address: deviceForm.value.address,
        deviceLocation: deviceForm.value.deviceLocation, // default to Female

        Latitude: deviceForm.value.Latitude,
        Longitude: deviceForm.value.Longitude,
        keyPhrase: deviceForm.value.keyPhrase,
        dkeyPhrase: deviceForm.value.dkeyPhrase,
        downloadurl: this.device.deviceInformation.downloadurl,
      },
    
    });*/
    this.toastrService.success("Successfully updated", "Device Register");
  }
}
