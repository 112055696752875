import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/compat/database";
import  firebase from "firebase/compat/app";

import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
;

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public users: AngularFireList<firebase.User[]>;
  public user: Observable<firebase.User | null>;
  authState: boolean = false;
  constructor(
    private afAuth: AngularFireAuth,
    private database: AngularFireDatabase,
    private router: Router
  ) {
    //debugger;
    this.users = database.list("/devices");
    //why this.user is not working
    
     this.user = afAuth.authState;
    this.user.subscribe((user) => {
      if (user) {
        this.authState = true;
      } else {
        this.authState = false;
      }
    });
  }
  public doRegister(value:any) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            const user = firebase.auth().currentUser;
            user?.sendEmailVerification();
//this.sendEmailVerificationNew(user);
            user?.updateProfile({
              
              displayName: value.firstname,
              photoURL: "assets/images/avatar.svg",
            });
            resolve(res);
          },
          (err) => reject(err.message)
        );
    });
  }

  signOut() {
  
    this.afAuth.signOut().then(() => {
      this.router.navigate(["/"]);
    });
  }
  signIn(value:any) {
    return this.afAuth
      .signInWithEmailAndPassword(value.email, value.password)
      .then((res) => console.log(res));
  }
  forgotPassword(value:any) {
    return this.afAuth.sendPasswordResetEmail(value.email);
  }
  async getCurrentUserToken() {
    const idToken = await firebase.auth().currentUser?.getIdToken(true);
    const x = this.afAuth.idToken.subscribe();
    console.log("token 12", idToken);
    return idToken;
    /*  const user = firebase.auth().currentUser;
    return user
      .getIdToken(true)
      .then((idToken) => console.log("Id token", idToken)); */
  }
  public authenticated(): boolean {
    console.log(this.authState);
    return this.authState;
  }
  async sendEmailVerificationNew(user: any) {
    debugger;
    const actionCodeSettings = {
      url: 'http://localhost:3000/verifyemail',
      handleCodeInApp: true
    };
alert(JSON.stringify(user));
   
      if (user) {
        return user.sendEmailVerification(actionCodeSettings);
      }
   
  }

  async sendEmailVerification(email: string){
    try {
     
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  
      if (email === "") {
        return {errorMessage: "Email can not be empty!!"};
      }
      if (reg.test(email) === false) {
        return {errorMessage: "Please enter valid email address!!"};
      }
  
      var actionCodeSettings = {
           url: "https://mysoteria.page.link/emailsignupverification",
        // This must be true.
        handleCodeInApp: true,
      };
     // debugger;
  
      await this.afAuth.sendSignInLinkToEmail(email, actionCodeSettings);
  
     /*  await AsyncStorage.setItem("emailForSignUp", email);
      await AsyncStorage.setItem("isLinkForSignUpUsed", "false"); */
      return {successMessage: "Signup link sent sucessfully!!"};
    } catch (error:any) {
      return {errorMessage: error.message};
    }
  };
}
