import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SignupformComponent } from '../signupform/signupform.component';

@Component({

  selector: "app-user-signup'",

  templateUrl: "./user-signup.component.html",
  /* styleUrls: ["./user-signup.component.css"], */
  standalone: true,
  imports:[FormsModule,SignupformComponent],
})
export class UserSignupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
