<div id="wificontainer">
  <div class="form-row justify-content-center">
    <div class="form-group col-md-5"></div>
    <div class="form-group col-md-4">
      <div class="alert alert-danger" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <div class="form-group col-md-3"></div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-5"></div>
    <div class="form-group col-md-4">
      <select [(ngModel)]="ssidInput" name="wifi" class="form-control">
        <option [value]="wifi" selected>Select your wifi</option>
        <option *ngFor="let wifi of wifiList" [value]="wifi">
          {{ wifi }}
        </option>
      </select>
    </div>
    <div class="form-group col-md-3"></div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-5"></div>
    <div class="form-group col-md-4">
      <label for="ssid">SSID:</label>
      <input
        type="text"
        name="ssid"
        id="ssid"
        [(ngModel)]="ssidInput"
        class="form-control"
        required
      />
    </div>
    <div class="form-group col-md-3"></div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-5"></div>
    <div class="form-group col-md-4">
      <label for="password">Password:</label>
      <input
        type="text"
        name="password"
        id="password"
        [(ngModel)]="passwordInput"
        class="form-control"
      />
    </div>
    <div class="form-group col-md-3"></div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-5"></div>
    <div class="form-group col-md-4">
      <button
        id="send"
        type="button"
        class="btn inputButtonStyle"
        (click)="sendWifiDetails()"
      >
        Configure
      </button>
    </div>
    <div class="form-group col-md-3"></div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-5"></div>
    <div class="form-group col-md-4">
      <img
        *ngIf="loading"
        src="/assets/images/loader_spinner.gif"
        alt="loading"
      />
    </div>
    <div class="form-group col-md-3"></div>
  </div>
</div>
