import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
@Injectable({
  providedIn: "root"
})
export class WificonfigService {
  constructor(private http: HttpClient) {}
  getC3vlar360(): Observable<any> {
    return this.http.get("http://c3vlar360:3000/api/allc3vlar360", httpOptions);
  }
}
