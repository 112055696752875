{
  "pagination": {
    "DescribeCodeCoverages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "codeCoverages"
    },
    "DescribeTestCases": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "testCases"
    },
    "ListBuildBatches": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "ids"
    },
    "ListBuildBatchesForProject": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "ids"
    },
    "ListBuilds": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "ids"
    },
    "ListBuildsForProject": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "ids"
    },
    "ListFleets": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "projects"
    },
    "ListReportGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reportGroups"
    },
    "ListReports": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reports"
    },
    "ListReportsForReportGroup": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reports"
    },
    "ListSharedProjects": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "projects"
    },
    "ListSharedReportGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reportGroups"
    }
  }
}