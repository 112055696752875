import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Device } from "./../../modelclasses/device.model";
import { DeviceInfoService } from "../../core/device-info.service";
import  firebase from "firebase/compat/app";
import { GoogleMap,  GoogleMapsModule,  MapInfoWindow, MapMarker } from "@angular/google-maps";
import { Observable,of } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { CommonModule } from "@angular/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DatabaselayerService } from "../../core/databaselayer.service";
import { DeviceKeysAndLocations } from "../../modelclasses/device-keys-and-locations";
interface CustomMarkerOptions extends google.maps.MarkerOptions {
  serialNumber: string;
  deviceKeysAndLocations?: DeviceKeysAndLocations; 
}

/* interface MarkerProperties {
  position: {lat: number, lng: number};
   label?: string;
  title?: string;
  options?: google.maps.MarkerOptions; 
} */

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  standalone:true,
  imports:[CommonModule,GoogleMapsModule]
})
export class MapComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined; 

  apiLoaded: Observable<boolean>;
  title: string = "Soteria";
  public device: Device={serialNumber: "", deviceInformation: {userId: 0,   latitude: 0, longitude: 0,keyPhrase:"",  deactivationKeyPhrase : "", floorPlanUrl:""},
  deviceKeysAndLocations:{address: "", deviceLocation: ""}};
  //public devicesList: Device[]= [];
  public numberofC3vlar360: Number = 0;
  zoom: number = 15;
 
  //lat: Number=39.76377818453739;
  //lng: Number=-86.15931511721696;
  mapOptions: google.maps.MapOptions = {center:  {lat: 39.76377818453739, lng: -86.15931511721696}, zoom: 15,mapTypeControl: true};
  devicesList: Device[] = [];
  markers: google.maps.MarkerOptions[] = [];

  @ViewChild(GoogleMap, { static: true }) map!: GoogleMap;
  mapInfoContent:SafeHtml | undefined;

  constructor(private deviceInfoService: DeviceInfoService,httpClient: HttpClient,private sanitizer: DomSanitizer ,private dbLayerService:DatabaselayerService) {
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBaOTCUsTcAskunXXrrwryf-uNj1rR5ZLw', 'callback')
    .pipe(
      map(() => true),
      catchError(() => of(false)),
    );

  }

  ngOnInit() {
    let userId=firebase.auth().currentUser?.uid;
    if(userId){
       let nodeTOFetch="/users/"+userId+"/deviceKeysAndLocations"
      this.dbLayerService.getUserDataByNode(nodeTOFetch).snapshotChanges().subscribe(async (item:any) => {
        const deviceIds = item.payload.toJSON();
     
      Object.entries(deviceIds).map(([key,value]) => {
         
       this.dbLayerService.getDeviceDataByFieldName(key).valueChanges().subscribe(async (item:any) => {
     
     item["serialNumber"] = key
     let obj = Object.assign({}, item, {deviceKeysAndLocations:value});
    console.log("final obj",obj);
    this.devicesList.push(obj as Device); 
    const marker: CustomMarkerOptions={
      position: { lat:Number( obj.deviceInformation.latitude), lng:Number( obj.deviceInformation.longitude) },
      serialNumber: obj.serialNumber,
      deviceKeysAndLocations:{...obj.deviceKeysAndLocations},
     
    };
console.log("marker",marker)
   /*  this.markerPositions.push({ lat: temp.deviceInformation.Latitude, lng: temp.deviceInformation.Longitude }); */
   
    this.markers.push(marker);
     
    }
        )});

      

      });
      
    }
   /*  this.deviceInfoService
      .getDevicesList()
      .snapshotChanges()
      .subscribe(async (item) => {
        await this.initilizeDevicesList(item);
      }); */
  }
  ngAfterViewInit() {
  
   // this.handleMapInitialized();
  }
  initilizeDevicesList(item:any) {
    this.devicesList = [];
    item.forEach((element:any) => {
      const temp = element.payload.toJSON();
      temp["serialNumber"] = element.key;

      if (
        temp.deviceInformation &&
        temp.deviceInformation.userId &&
        temp.deviceInformation.userId == firebase.auth().currentUser?.uid
      ) {
        this.devicesList.push(temp as Device);
       // this.lat = this.devicesList[0].deviceInformation.Latitude;
        //this.lng = this.devicesList[0].deviceInformation.Longitude;
        console.log("devicesList",this.devicesList, this.devicesList[0].deviceInformation)
        console.log("temp",temp,temp.deviceInformation)
        const marker: CustomMarkerOptions={
          position: { lat: temp.deviceInformation.Latitude, lng: temp.deviceInformation.Longitude },
          serialNumber: temp.serialNumber,
        //  address: temp.deviceInformation.address,
         
        };

       /*  this.markerPositions.push({ lat: temp.deviceInformation.Latitude, lng: temp.deviceInformation.Longitude }); */
       
        this.markers.push(marker);

        this.numberofC3vlar360 = this.devicesList.length;
      }
    });
  }
  handleMapInitialized() {

    console.log("devicesList", this.devicesList);
    this.devicesList.forEach((element: any) => {
      
    });
  }
  openInfoWindow(marker: MapMarker) {
    console.log("marker",marker["_options"]["serialNumber"]);
    this.mapInfoContent = this.sanitizer.bypassSecurityTrustHtml(`<h4> ${marker["_options"]["serialNumber"]}</h4><p>${marker["_options"]["deviceKeysAndLocations"]["address"]}</p><p>${marker["_options"]["deviceKeysAndLocations"]["deviceLocation"]}</p>`);
   
    this.infoWindow?.open(marker);
  }

  clickedMarker(deviceCode: string, index: number) {
    console.log(`clicked the marker: ${deviceCode || index}`);
  }
}
// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
