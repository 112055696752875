

import {  enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';


 
import { environment } from './environments/environment';




import { AppComponent } from './app/app.component';



 if (environment.production) {
  enableProdMode();
} 
/* export const appConfig: ApplicationConfig = {
  providers: [provideRouter provideRouter(routes)]
}; */
 // platformBrowserDynamic().bootstrapModule(AppModule)  
/*  bootstrapApplication(AppComponent,
  {
    providers: [
      provideProtractorTestingSupport(), 
      provideRouter(routeConfig)
    ]
  }
)   .catch(err =>{ console.log("Bootstrap error",err.message)});
*/


import { appConfig } from "./app/app.config"


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));