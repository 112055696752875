import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';
import {routes} from "./app.routes"

import { provideClientHydration } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire/compat';
//import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import { HttpClientModule, provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import firebase from 'firebase/auth';


export const appConfig: ApplicationConfig = {
  providers: [ provideHttpClient(withJsonpSupport()),
     provideToastr(),
     provideRouter(routes),
     importProvidersFrom([AngularFireModule.initializeApp(environment.firebase), 
      AngularFireAuthModule,   HttpClientModule, FormsModule,ReactiveFormsModule, RouterModule,CommonModule,BrowserAnimationsModule,GoogleMapsModule]), provideClientHydration()]
};
