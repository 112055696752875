<div sd="wrapper" class="content">
  <div>
    <ng-template #guest> <app-map></app-map> </ng-template>
    <ng-template #authenticated>
      <div *ngIf="auth.user | async as user">
       <app-map></app-map> 
      </div>
    </ng-template>
  </div>
  <div>
    <div *ngIf="auth.user | async; then authenticated; else guest"></div>
  </div>
</div>
