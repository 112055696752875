<form #deviceForm="ngForm" (ngSubmit)="onSubmit(deviceForm)">
  <div class="form-row">
    <div class="form-group col-lg-6">
      <div class="Longitude">
        <label for="">Serial Number</label>
        <!-- <a
          href="http://c3vlar360.local:3000/"
          class="linkStyle"
          target="_blank"
        >
          Scan QR Code
        </a> -->
      </div>
      <div class="form-row">
        <button class="col-md-4 col-sm-12 cameraBtn" (click)="onOpenQRScanner()">
          <!-- Lamiaa Moustafa
          Changing the bootstrap icon to Svg Qr code  -->
          <!-- <i class="glyphicon glyphicon-camera"></i> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code"
            viewBox="0 0 16 16">
            <path d="M2 2h2v2H2V2Z" />
            <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
            <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
            <path
              d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
            <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
          </svg>
          <!-- Lamiaa Moustafa -->
          <p class="text">SCAN QR CODE</p>
        </button>

        <div class="col-md-8 col-sm-12 p-sm-0">
          <input class="form-control" placeholder="xxxxxxxx" name="serialNumber" #serialNumber="ngModel"
            (keyup)="onKey($event)" [(ngModel)]="device.serialNumber" pattern="[a-zA-Z0-9]+" required />
        </div>
      </div>

      <small *ngIf="
          serialNumber.invalid &&
          (serialNumber.dirty || serialNumber.touched || deviceForm.submitted)
        " class="alert-danger">
        <small *ngIf="serialNumber.errors?.['required']; then thenBlock; else elseBlock"></small>
        <ng-template #thenBlock> Serial Number is required </ng-template>
        <ng-template #elseBlock> Invalid serial number </ng-template>
      </small>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="">Address</label>

      <div class="input-group">
        <select class="form-control" name="addressAndCompanyName" [(ngModel)]="selectedAddressCompany"
          (ngModelChange)="onAddressChanged($event)">
          <option *ngFor="let op of deviceAddressList" >
            {{op}}
            
          
          </option>
        </select>
        <span class="input-group-addon"><a (click)="toogleAddAddress()">New</a></span>
      </div>
      <div *ngIf="addAddressCollapse">
        <app-add-company-profile (addressSubmitted)="onAddressSubmitted()"></app-add-company-profile>
      </div>
    </div>
    <!--  <div class="form-group col-md-6">
      <label for="">Address Test</label>

      <div class="input-group">
        <select
          class="form-control"
          name="address1"
          [(ngModel)]="device.deviceInformation.address"
          (ngModelChange)="onAddressChanged($event)"
        >
          <option *ngFor="let op of deviceAddressListT" [ngValue]="op">
            {{ op.addCompInfo.address }}( {{ op.companyName }})
          </option>
        </select>
        <span class="input-group-addon"
          ><a (click)="toogleAddAddress()">New</a></span
        >
        {{ selectedAddressCompany | json }}
      </div>
      <div *ngIf="addAddressCollapse">
        <app-add-company-profile></app-add-company-profile>
      </div>
    </div> -->
  </div>
  <div class="form-row">
    <div class="form-group col-md-6 col-lg-3">
      <label for="">Latitude</label>
      <input class="form-control" type="number" name="latitude" #latitude="ngModel"
        [(ngModel)]="device.deviceInformation.latitude" appLatitudeValidator required />

      <small *ngIf="
          latitude.invalid &&
          (latitude.dirty || latitude.touched || deviceForm.submitted)
        " class="alert-danger">
        <small *ngIf="latitude.errors?.['required']; then thenBlockL; else elseBlockL"></small>

        <ng-template #thenBlockL> Latitude is required </ng-template>
        <ng-template #elseBlockL>
          {{ latitude.errors?.['invalidLatitude'] }}
        </ng-template>
      </small>
    </div>

    <div class="form-group col-md-6 col-lg-3">
      <div class="Longitude">
        <label for="">Longitude</label>
        <a (click)="fillCurrentLatLog()" class="linkStyle" target="_blank">
          Get Lat/Long
        </a>
      </div>
      <input class="form-control" type="number" name="longitude" #longitude="ngModel"
        [(ngModel)]="device.deviceInformation.longitude" appLongitudevalidator required />

      <small *ngIf="
          longitude.invalid &&
          (longitude.dirty || longitude.touched || deviceForm.submitted)
        " class="alert-danger">
        <small *ngIf="longitude.errors?.['required']; then thenBlockLo; else elseBlockLo"></small>

        <ng-template #thenBlockLo> Longitude is required </ng-template>
        <ng-template #elseBlockLo>
          {{ longitude.errors?.['invalidLongitude'] }}
        </ng-template>
      </small>
    </div>
  </div>
  <div class="form-group col-md-6">
    <label for="">deviceLocation</label>
    <input class="form-control" name="deviceLocation" #name="ngModel"
      [(ngModel)]="device.deviceKeysAndLocations.deviceLocation" placeholder="Device Location" />
  </div>

  <!-- <div class="form-row">
    <div class="form-group col-sm-12 col-md-6">
      <label for="">Street Name</label>
      <input
        class="form-control"
        name="street"
        #street="ngModel"
        [(ngModel)]="device.deviceInformation.street"
        placeholder="123 Main st."
        required
      />
      <small
        *ngIf="
          street.invalid &&
          (street.dirty || street.touched || deviceForm.submitted)
        "
        class="alert-danger"
      >
        <small
          *ngIf="
            street.errors.required;
            then thenStreetBlock;
            else elseStreetBlock
          "
        ></small>
        <ng-template #thenStreetBlock> Street Name is required </ng-template>
        <ng-template #elseStreetBlock> Invalid Street Name </ng-template>
      </small>
    </div>
    <div class="form-group col-md-6">
      <label for="">City</label>
      <input
        class="form-control"
        name="city"
        #city="ngModel"
        [(ngModel)]="device.deviceInformation.city"
        placeholder="Indianapolis"
        required
      />
      <small
        *ngIf="
          city.invalid && (city.dirty || city.touched || deviceForm.submitted)
        "
        class="alert-danger"
      >
        <small
          *ngIf="city.errors.required; then thenCityBlock; else elseCityBlock"
        ></small>
        <ng-template #thenCityBlock> City is required </ng-template>
        <ng-template #elseCityBlock> Invalid City </ng-template>
      </small>
    </div>
  </div> 
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="">State</label>
      <input
        class="form-control"
        name="state"
        #state="ngModel"
        [(ngModel)]="device.deviceInformation.state"
        placeholder="IN"
        required
      />
      <small
        *ngIf="
          state.invalid &&
          (state.dirty || state.touched || deviceForm.submitted)
        "
        class="alert-danger"
      >
        <small
          *ngIf="
            state.errors.required;
            then thenStateBlock;
            else elseStateBlock
          "
        ></small>
        <ng-template #thenStateBlock> State is required </ng-template>
        <ng-template #elseStateBlock> Invalid State </ng-template>
      </small>
    </div>
    <div class="form-group col-md-6">
      <label for="">Zip Code</label>
      <input
        class="form-control"
        name="zip"
        #zip="ngModel"
        [(ngModel)]="device.deviceInformation.zip"
        placeholder="46268"
        required
      />
      <small
        *ngIf="
          zip.invalid && (zip.dirty || zip.touched || deviceForm.submitted)
        "
        class="alert-danger"
      >
        <small
          *ngIf="zip.errors.required; then thenZipBlock; else elseZipBlock"
        ></small>
        <ng-template #thenZipBlock> Zip number is required </ng-template>
        <ng-template #elseZipBlock> Invalid zip number </ng-template>
      </small>
    </div>
    <div class="form-group col-md-6">
      <label for="">County</label>
      <input
        class="form-control"
        name="county"
        #county="ngModel"
        [(ngModel)]="device.deviceInformation.county"
        placeholder="Marion"
        required
      />
      <small
        *ngIf="
          county.invalid &&
          (county.dirty || county.touched || deviceForm.submitted)
        "
        class="alert-danger"
      >
        <small
          *ngIf="
            county.errors.required;
            then thenCountyBlock;
            else elseCountyBlock
          "
        ></small>
        <ng-template #thenCountyBlock> County is required </ng-template>
        <ng-template #elseCountyBlock> Invalid County </ng-template>
      </small>
    </div>
  </div>
-->

  <div class="form-row">
    <!-- <div class="form-group col-md-6">
      <label for="">Room Number (Apt, Ste, Flr)</label>
      <input
        class="form-control"
        name="roomNumber"
        #roomNumber="ngModel"
        [(ngModel)]="device.deviceInformation.roomNumber"
        placeholder="---"
        required
      />
      <small
        *ngIf="
          roomNumber.invalid &&
          (roomNumber.dirty || roomNumber.touched || deviceForm.submitted)
        "
        class="alert-danger"
      >
        <small
          *ngIf="
            roomNumber.errors.required;
            then thenRoomNumberBlock;
            else elseRoomNumberBlock
          "
        ></small>
        <ng-template #thenRoomNumberBlock>
          Room Number is required
        </ng-template>
        <ng-template #elseRoomNumberBlock> Invalid Room Number </ng-template>
      </small>
    </div> -->
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="keyPhrase">Key Phrase (Activation)</label>
      <input class="form-control" name="keyPhrase" #keyPhrase="ngModel" [(ngModel)]="device.deviceInformation.keyPhrase"
        appKeyphraseValidator required />

      <small *ngIf="
          keyPhrase.invalid &&
          (keyPhrase.dirty || keyPhrase.touched || deviceForm.submitted)
        " class="alert-danger">
        <small *ngIf="
            keyPhrase.errors?.['required'];
            then thenKeyPhraseBlock;
            else elseKeyPhraseBlock
          "></small>
        <ng-template #thenKeyPhraseBlock> Keyphrase is required </ng-template>
        <ng-template #elseKeyPhraseBlock>
          {{ keyPhrase.errors?.['invalidKeyphrase'] }}
        </ng-template>
      </small>
    </div>
    <div class="form-group col-md-6">
      <label for="dkeyPhrase">Key Phrase (Deactivation)</label>
      <input class="form-control" name="dkeyPhrase" #name="ngModel" [(ngModel)]="device.deviceInformation.deactivationKeyPhrase"
        placeholder="Stop Covert" />
    </div>
  </div>
  <div class="form-row">
    <!-- <div class="form-group col-md-6">
      <label for="county">County</label>
      <input class="form-control" name="county" #county="ngModel" [(ngModel)]="device.deviceInformation.county"
        placeholder="Marion" required />
      <small *ngIf="
          county.invalid &&
          (county.dirty || county.touched || deviceForm.submitted)
        " class="alert-danger">
        <small *ngIf="
            county.errors?.['required'];
            then thenCountyBlock;
            else elseCountyBlock
            then thenCountyBlock;
            else elseCountyBlock
          "></small>
        <ng-template #thenCountyBlock> County is required </ng-template>
        <ng-template #elseCountyBlock> Invalid County </ng-template>
      </small>
    </div> -->
    <div class="form-group col-md-6">
      <label for="uploadLayout">Upload Facility Layout:</label>
      <input class="form-control" name="uploadLayout" (change)="uploadLayout($event)" type="file" />
      <img [src]="localFileURL" alt="Preview image here" width="30%" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <small class="alert-danger"> {{ errorMessage }}</small>
      <div class="row deviceBtn">
        <button class="btn submitStyle" type="submit">
          <i class="glyphicon glyphicon-submit"></i> Submit
        </button>
        <button class="inputButtonStyle" (click)="onCancel()">
          <i class="glyphicon glyphicon-remove"></i> Cancel
        </button>
      </div>
    </div>
  </div>
</form>