import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import * as AWS from "aws-sdk";


@Injectable({
  providedIn: "root",
})
export class LiveStreamService {
  kinesisVideo: any = new AWS.KinesisVideo(environment.Kinesisoptions);
  kinesisVideoArchivedContent: any = new AWS.KinesisVideoArchivedMedia(
    environment.Kinesisoptions
  );
  constructor() {}
  startStream(serialNumber: string) {
    console.log("The serial number", serialNumber, environment.Kinesisoptions);
  }

  getStreamingSessionUrl = (endPoint:any, sn:string) => {
    try {
      this.kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(endPoint);
      // debugger;
      return new Promise((resolve, reject) => {
        this.kinesisVideoArchivedContent.getHLSStreamingSessionURL(
          {
            StreamName: sn,

            HLSFragmentSelector: {
              FragmentSelectorType: "SERVER_TIMESTAMP",
            },
          },
          (err:any, response:any) => {
            if (err) {
              console.log("Live error", err.message);
              reject(err);
            }

            if (response && response.HLSStreamingSessionURL) {
              resolve(response.HLSStreamingSessionURL);
            }
          }
        );
      });
    } catch (error:any) {
      console.log("Live error on streaming session url", error.message);
      return Promise.reject(error);
    }
  };
  getEndPoint = (serialNumber:string) => {
    return new Promise((resolve, reject) => {
      this.kinesisVideo.getDataEndpoint(
        {
          StreamName: serialNumber,
          APIName: "GET_HLS_STREAMING_SESSION_URL",
        },
        (err:any, response:any) => {
          if (err) {
            return reject(err);
          }
          resolve(response.DataEndpoint);
        }
      );
    });
  };

  getStreamingSessionUrl1 = (serialNumber:string, callback:any) => {
    serialNumber = "00000000b017e418";
    let that = this;

    this.kinesisVideo.getDataEndpoint(
      {
        StreamName: serialNumber,
        APIName: "GET_HLS_STREAMING_SESSION_URL",
      },
      (err:any, response:any) => {
        if (err) {
          return console.error("Live stream testing", err);
        }
        console.log("Live testing", response.DataEndpoint);
        // return "response.DataEndpoint";

        that.kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(
          response.DataEndpoint
        );

        // Step 3: Get a Streaming Session URL
        that.kinesisVideoArchivedContent.getHLSStreamingSessionURL(
          {
            StreamName: serialNumber,

            HLSFragmentSelector: {
              FragmentSelectorType: "SERVER_TIMESTAMP",
            },
          },
          callback
        );
      }
    );
    //  return "testing";
  };
}
